import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { HelperService } from '@app/shared/services/helper.service';

import * as fromHelpers from '@app/state/helpers';

@Component({
  selector: 'app-helper',
  templateUrl: './helper.component.html',
  styleUrls: ['./helper.component.scss']
})
export class HelperComponent implements OnInit, OnDestroy {
  show: boolean;
  questionsList: Array<any>;
  questionActive: any;

  private subscription = new Subscription();

  constructor(private store: Store<any>, private helperService: HelperService) {}

  ngOnInit() {
    this.createSubscriptions();
    this.setQuestions();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private createSubscriptions(): void {
    this.subscribeToToggleHelper();
    this.subscribeToQuestionsHelper();
  }

  private subscribeToToggleHelper(): void {
    this.subscription.add(
      this.store.select(fromHelpers.selectors.selectHelpersShow).subscribe(state => (this.show = state))
    );
  }

  private subscribeToQuestionsHelper(): void {
    this.subscription.add(
      this.store.select(fromHelpers.selectors.selectHelperActiveQuestion).subscribe(state => {
        if (this.questionsList && state) {
          this.questionActive = this.questionsList.find(q => q.field === (state as any).field);
        }
      })
    );
  }

  /**
   * Set Questions on init
   */
  setQuestions(): void {
    this.helperService
      .getQuestions()
      .pipe(map(data => (data as Array<any>) || []))
      .subscribe(data => {
        this.questionsList = data;
        const questionsState = data.map(q => ({ field: q.field, active: false }));
        this.store.dispatch(new fromHelpers.actions.SetHelperQuestions(questionsState));
      });
  }

  /**
   * Dispatch action to Close Helper
   */
  closeHelper(): void {
    this.store.dispatch(new fromHelpers.actions.CloseHelper());
  }

  /**
   * Dispatch action to Toggle Helper Question
   */
  onToggleHelperQuestions(fieldName: string) {
    this.store.dispatch(new fromHelpers.actions.ToggleHelperQuestions(fieldName));
  }
}
