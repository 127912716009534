import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, FormControl, ValidatorFn } from '@angular/forms';

import { validateCpf } from './../validators/cpf.validator';
import * as moment from 'moment';
import { validateCnpj } from '@shared/validators/cnpj.validator';

@Injectable()
export class FormValidationService {
  getPatternFullName() {
    return /^[a-zA-ZÀ-ú0-9' \-\/\.]+$/;
  }

  getPatternPhone() {
    return /^[1-9]{2}(?:[2-8]|9[1-9])[0-9]{3}[0-9]{4}$/;
  }

  getPatternEmail() {
    // tslint:disable-next-line:max-line-length
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  }

  isValidFullName(input: AbstractControl) {
    const name = input.value;
    const regex = new RegExp(/^[a-zA-ZÀ-ú ']+$/);
    const spaces = !name ? false : name.trim().indexOf(' ') > 0;

    if (name === null || (regex.test(name) && spaces)) {
      return null;
    }

    return { invalidFullName: true };
  }

  isCpfValid(input: AbstractControl) {
    const cpf = input.value;
    if (!cpf || validateCpf(cpf)) {
      return null;
    }

    return { invalidCpf: true };
  }

  isSameCpf(primaryCpf) {
    return (input: AbstractControl) => {
      const cpf = input.value;
      if (!cpf || !primaryCpf || primaryCpf.value !== cpf) {
        return null;
      }

      return { sameCpf: true };
    };
  }

  isCnpjValid(input: AbstractControl) {
    const cnpj = input.value;
    if (!cnpj || validateCnpj(cnpj)) {
      return null;
    }

    return { invalidCnpj: true };
  }

  isValidDate(dateLimitMin, dateLimitMax) {
    dateLimitMin = dateLimitMin ? moment(dateLimitMin) : moment().subtract(80, 'years');
    dateLimitMax = dateLimitMax ? moment(dateLimitMax) : moment().subtract(16, 'years');

    return (input: AbstractControl) => {
      const date = input.value;
      const minDate = dateLimitMin.format('YYYY-MM-DD');
      const maxDate = dateLimitMax.format('YYYY-MM-DD');
      const currDate = date && date.length === 10 ? moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD') : null;

      if (
        !date ||
        (currDate && (moment(currDate).isValid() && (date.length < 10 || moment(currDate).isBetween(minDate, maxDate))))
      ) {
        return null;
      }

      return { invalidDate: true };
    };
  }

  isValidFinanceTerm(minMonth, maxMonth, channel = 'PC') {
    return (input: AbstractControl) => {
      const month = input.value;
      const currMonth = parseInt(month, 10);

      if (currMonth === null || (currMonth >= minMonth && currMonth <= maxMonth)) {
        return null;
      }

      return { invalidFinanceTerm: true };
    };
  }

  isLowerThanMin(min) {
    return (input: AbstractControl) => {
      const value = +input.value;
      if (value === null || (value >= min && value > 0)) {
        return null;
      }
      return { invalidLowerThanMin: true };
    };
  }

  isGreaterThanMax(max) {
    return (input: AbstractControl) => {
      const value = input.value;
      if (!max || !value || value <= max) {
        return null;
      }

      return { invalidGreaterThanMax: true };
    };
  }

  isValidMonthlyIncome(minValue: number = 2500): ValidatorFn {
    return (group: AbstractControl) => {
      const incomeFirstParticipant = group.get('first').get('monthlyIncome');
      const incomeSecondParticipant = group.get('second').get('monthlyIncome');
      const errorObj = { invalidMonthlyIncome: true };

      if (incomeFirstParticipant.value + incomeSecondParticipant.value >= minValue) {
        incomeFirstParticipant.setErrors(null);
        incomeSecondParticipant.setErrors(null);
        return null;
      }

      incomeFirstParticipant.setErrors(errorObj);
      incomeSecondParticipant.setErrors(errorObj);
    };
  }

  isValidPropertyValue(minValue, maxValue) {
    return (input: AbstractControl) => {
      const value = input.value;

      if (value >= minValue && value <= maxValue) {
        return null;
      }

      return { invalidPropertyValue: true };
    };
  }

  isValidEvaluationPrice(minValue) {
    return (input: AbstractControl) => {
      const value = input.value;

      if (value === null || (value >= minValue || value === 0)) {
        return null;
      }

      return { invalidEvalutionPrice: true };
    };
  }

  isValidFinanceValue(minValue, maxValue) {
    return (input: AbstractControl) => {
      const value = input.value;

      if (value >= minValue && value <= maxValue) {
        return null;
      }

      return { invalidFinanceValue: true };
    };
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  clearValidationAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsUntouched({ onlySelf: true });
        control.setErrors(null);
      } else if (control instanceof FormGroup) {
        this.clearValidationAllFormFields(control);
      }
    });
  }

  getInvalidFields(formGroup: FormGroup) {
    let invalidFields = [];
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl && control.status === 'INVALID') {
        invalidFields = [...invalidFields, field];
      } else if (control instanceof FormGroup) {
        this.getInvalidFields(control);
      }
    });
    return invalidFields;
  }
}
