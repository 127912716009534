import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpEventType } from '@angular/common/http';
import { Store } from '@ngrx/store';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { AddRequestAction, RemoveRequestAction } from './store/loading.actions';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  UID = 0;
  constructor(private store: Store<any>) {}

  generateUID() {
    return `UID-${this.UID++}`;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const id = this.generateUID();
    const payload = { id, request };

    this.store.dispatch(new AddRequestAction(payload));

    return next.handle(request).pipe(
      map(response => {
        if (response.type === HttpEventType.Response) {
          this.store.dispatch(new RemoveRequestAction(payload));
        }
        return response;
      }),
      catchError(error => {
        this.store.dispatch(new RemoveRequestAction(payload));
        return throwError(error);
      })
    );
  }
}
