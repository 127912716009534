import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, withLatestFrom, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ProposalDataService } from '@app/shared/services/proposal-data.service';
import { ParameterService } from '@app/shared/services/parameter.service';
import { ErrorService } from '@app/shared/services/error.service';
import { simulationDomainsConstant } from '@app/shared/constants/simulation-domains.constant';
import { menuPerfilConstant } from '@shared/constants/menu-perfil.constant';

import * as actions from './parameters.actions';
import * as fromProposal from './../proposal';
import * as fromErrorHandling from './../error-handling';
import * as fromHelpers from './../helpers';
import { StorageEncryptService } from '@shared/services/storageEncrypt.service';
import { TabelaGeralService } from '@shared/services/tabela-geral/tabela-geral.service';

@Injectable()
export class ParametersEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<any>,
    private proposalDataService: ProposalDataService,
    private parameterServices: ParameterService,
    private errorService: ErrorService,
    private storageEncryptService: StorageEncryptService,
    private tabelaGeralService: TabelaGeralService
  ) { }

  @Effect()
  offerListGeneralTable$ = this.actions$.pipe(
    ofType<actions.OffersList>(actions.ParametersActionTypes.OFFERS_LIST),
    switchMap((action) =>
      this.tabelaGeralService.buscarTabelasGeral(action.payload.id, action.payload.tipoOrdenacao).pipe(
        switchMap(response => [
          new actions.OffersListSucess(response.tabelaGeralConnections.edges.map(node => node.node)),
        ]),
        catchError(err => {
          if (err.extensions) {
            return of(new fromErrorHandling.actions.AddError(this.errorService.addGraphQLAlert(err)));
          }

          return of(new fromErrorHandling.actions.AddError(this.errorService.addAlertError(err)));
        })
      )
    )
  );

  /**
   * Get Personal Data Parameters and updates proposal (businessKey & currentTaskKey)
   */
  @Effect()
  loadParticipantsParameters$ = this.actions$.pipe(
    ofType<actions.ParticipantsLoad>(actions.ParametersActionTypes.PARTICIPANTS_LOAD),
    withLatestFrom(this.store$.select(fromProposal.selectors.selectSimulationID)),
    switchMap(([_, simulationId]) =>
      this.parameterServices.getPersonalData(simulationId).pipe(
        switchMap(response => [
          new actions.ParticipantsLoadSuccess(response.getPersonalDataParametersSimulation),
          new fromHelpers.actions.GetHelpers()
        ]),
        catchError(err => {
          if (err.extensions) {
            return of(new fromErrorHandling.actions.AddError(this.errorService.addGraphQLAlert(err)));
          }

          return of(new fromErrorHandling.actions.AddError(this.errorService.addAlertError(err)));
        })
      )
    )
  );

  /**
   * Get Proposal Parameters and updates proposal (businessKey & currentTaskKey)
   */
  @Effect()
  loadProposalDataParameters$ = this.actions$.pipe(
    ofType<actions.ProposalLoad>(actions.ParametersActionTypes.PROPOSAL_LOAD),
    withLatestFrom(this.store$.select(fromProposal.selectors.selectSimulationID)),
    switchMap(([action, simulationId]) => {
      const data = JSON.parse(this.storageEncryptService.getSessionStore('data'));
      simulationId = simulationId ? simulationId : data.idProposta;
      return this.parameterServices.getProposalData(action.payload.channel, simulationId,
        action.payload.tpPessoa, action.payload.loanTypeKey).pipe(
          switchMap(response => {
            const proposalDataParametersSimulation = response.getProposalDataParametersSimulation;
            const acoes: any[] = [
              new actions.ProposalLoadSuccess(proposalDataParametersSimulation),
              new fromHelpers.actions.GetHelpers()
            ];
            // if (action.payload.showModalcontractList) {
            //   acoes.push(new fromProposal.actions.GetSimulationContractData(
            //     { idSimulacao: data.idProposta }))
            // }
            return acoes;
          }),
          catchError(err => {
            if (err.extensions) {
              return of(new fromErrorHandling.actions.AddError(this.errorService.addGraphQLAlert(err)));
            }

            return of(new fromErrorHandling.actions.AddError(this.errorService.addAlertError(err)));
          })
        )
    })
  );

  /**
   * Get Simulation Parameters and updates proposal (businessKey & currentTaskKey)
   */
  @Effect()
  getSimulationData = this.actions$.pipe(
    ofType<actions.SimulationLoad>(actions.ParametersActionTypes.SIMULATION_LOAD),
    withLatestFrom(this.store$.pipe(select(fromProposal.selectors.selectProposal))),
    switchMap(([action, proposalData]) =>
      this.parameterServices.getSimulationData(proposalData).pipe(
        switchMap(response => {
          const selectedOpts = this.setDefaultDomainKeys(response.getSimulationDataParameters.calculateSimulation);
          const selectedOptsWithtypeOfferWarrantyShared = {
            ...selectedOpts,
            typeOfferWarrantyShared: proposalData.typeOfferWarrantyShared
          };
          const simulationData = {
            ...response.getSimulationDataParameters.calculateSimulation.simulationValues,
            selectedOptions: selectedOptsWithtypeOfferWarrantyShared
          };

          return [
            new fromProposal.actions.SetProposalData({
              ...simulationData
            }),
            new actions.SimulationLoadSuccess({
              visibleFields: response.getSimulationDataParameters.calculateSimulation.visibleFields,
              editableFields: response.getSimulationDataParameters.calculateSimulation.editableFields,
              simulationDomains: response.getSimulationDataParameters.calculateSimulation.simulationDomains,
              months: response.getSimulationDataParameters.months,
              isSimulationInProgress: response.getSimulationDataParameters.isSimulationInProgress,
              balanceValue: response.getSimulationDataParameters.calculateSimulation.simulationValues.originalContractParameters.balanceValue,
              remainingPaymentTermInMonths: response.getSimulationDataParameters.calculateSimulation.simulationValues.originalContractParameters.remainingPaymentTermInMonths,
              isAppraisalFeeFree: response.getSimulationDataParameters.calculateSimulation.simulationValues.closingCostsCredit.isAppraisalFeeFree
            }),
            new fromHelpers.actions.GetHelpers()
          ];
        }),
        catchError(err => {
          if (err.extensions) {
            return of(new fromErrorHandling.actions.AddError(this.errorService.addGraphQLAlert(err)));
          }

          return of(new fromErrorHandling.actions.AddError(this.errorService.addAlertError(err)));
        })
      )
    )
  );

  // @Effect()
  // getPropertyValueRange = this.actions$.pipe(
  //   ofType<actions.GetPropertyRange>(actions.ParametersActionTypes.GET_PROPERTY_RANGE),
  //   withLatestFrom(this.store$.pipe(select(fromProposal.selectors.selectBusinessKey))),
  //   switchMap(([action, businessKey]) =>
  //     this.proposalDataService.getPropertyValueRange(businessKey, action.payload.input).pipe(
  //       map(response => new actions.GetPropertyRangeSuccess(response.calculatePropertyValueRange)),
  //       catchError(err => {
  //         if (err.extensions) {
  //           return of(new fromErrorHandling.actions.AddError(this.errorService.addGraphQLAlert(err)));
  //         }

  //         return of(new fromErrorHandling.actions.AddError(this.errorService.addAlertError(err)));
  //       })
  //     )
  //   )
  // );

  @Effect()
  getPropertyValueRangeProposal = this.actions$.pipe(
    ofType<actions.GetPropertyRangeProposal>(actions.ParametersActionTypes.GET_PROPERTY_RANGE_PROPOSAL),
    switchMap(action =>
      this.proposalDataService
        .getPropertyValueRangeProposal(
          action.payload.objetivoFinanciamento,
          action.payload.codigoDistrito,
          action.payload.tipoUtilizacaoImovel,
          action.payload.portabilityTypeKey
        )
        .pipe(
          switchMap(response => [
            new actions.GetPropertyRangeSuccess(response.calculatePropertyValueRange),
            new fromHelpers.actions.GetHelpers()
          ]),
          catchError(err => {
            if (err.extensions) {
              return of(new fromErrorHandling.actions.AddError(this.errorService.addGraphQLAlert(err)));
            }

            return of(new fromErrorHandling.actions.AddError(this.errorService.addAlertError(err)));
          })
        )
    )
  );

  @Effect()
  getProposalValueRange = this.actions$.pipe(
    ofType<actions.GetProposalRange>(actions.ParametersActionTypes.GET_PROPOSAL_RANGE),
    withLatestFrom(this.store$.select(fromProposal.selectors.selectSimulationID)),
    switchMap(([action, simulationId]) =>
      this.proposalDataService.getProposalValueRange({ ...action.payload.input, simulationId }).pipe(
        map(response => new actions.GetProposalRangeSuccess(response.calculateProposalValuesRange)),
        catchError(err => {
          if (err.extensions) {
            return of(new fromErrorHandling.actions.AddError(this.errorService.addGraphQLAlert(err)));
          }

          return of(new fromErrorHandling.actions.AddError(this.errorService.addAlertError(err)));
        })
      )
    )
  );

  @Effect()
  getDevelopments = this.actions$.pipe(
    ofType<actions.GetDevelopments>(actions.ParametersActionTypes.GET_DEVELOPMENTS),
    switchMap(action =>
      this.parameterServices.getDevelopments(action.payload.documentNumber).pipe(
        map(response => {
          if (response && response.getDevelopments) {
            return new actions.GetDevelopmentsSucess(response.getDevelopments);
          }

          return new actions.Redirect({ url: '/dados-proposta' });
        }),
        catchError(err => {
          if (err.extensions) {
            return of(new fromErrorHandling.actions.AddError(this.errorService.addGraphQLAlert(err)));
          }

          return of(new fromErrorHandling.actions.AddError(this.errorService.addAlertError(err)));
        })
      )
    )
  );

  @Effect()
  recoverMenuProfile = this.actions$.pipe(
    ofType<actions.RecoverMenuProfile>(actions.ParametersActionTypes.RECOVER_MENU_PROFILE),
    switchMap(action =>
      this.parameterServices.recoverProfile(menuPerfilConstant, action.payload.username).pipe(
        map(response => new actions.RecoverMenuProfileSucess(response.recuperaMenuPerfil.edges.map(node => node.node))),
        catchError(err => {
          if (err.extensions) {
            return of(new fromErrorHandling.actions.AddError(this.errorService.addGraphQLAlert(err)));
          }

          return of(new fromErrorHandling.actions.AddError(this.errorService.addAlertError(err)));
        })
      )
    )
  );

  private setDefaultDomainKeys({ simulationValues, simulationDomains }) {
    const selectedOptions = {};

    Object.keys(simulationDomainsConstant).forEach(o => {
      if (!simulationValues.selectedOptions[simulationDomainsConstant[o]]) return;

      if (o === 'deferredPayment') {
        selectedOptions[simulationDomainsConstant[o]] =
          simulationDomains[o] &&
            simulationDomains[o].deferredPaymentOptions &&
            simulationDomains[o].deferredPaymentOptions.length
            ? this.getDefaultOptionKey(simulationDomains[o].deferredPaymentOptions.find(d => d.defaultOption))
            : '';
        return;
      }

      selectedOptions[simulationDomainsConstant[o]] =
        simulationDomains[o] && simulationDomains[o].options && simulationDomains[o].options.length
          ? this.getDefaultOptionKey(simulationDomains[o].options.find(d => d.defaultOption))
          : '';
    });

    return selectedOptions;
  }

  private getDefaultOptionKey(defaultOption): string {
    if (defaultOption) {
      return defaultOption.key;
    }

    return '';
  }
}
