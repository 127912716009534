import gql from 'graphql-tag';

export const GET_PERSONAL_DATA_PARAMETERS = gql`
  query getPersonalDataParametersSimulation($simulationId: ID) {
    getPersonalDataParametersSimulation(simulationId: $simulationId) {
      minAgeAllowedProfile
      minAgeAllowed
      maxAgeAllowed
      minAgeInMonthsAllowed
      maxAgeInMonthsAllowed
      visibleFields
      editableFields
      isSimulationInProgress
    }
  }
`;

export const RECUPERAR_MENU_PERFIL = gql`
  query recuperaParametrosPerfil($cdsMenu: [ID], $usuario: String) {
    recuperaMenuPerfil(cdsMenu: $cdsMenu, usuario: $usuario) {
      edges {
        node {
          id
          cd
        }
      }
    }
  }
`;

export const GET_DEVELOPMENTS = gql`
  query getDevelopments($documentNumber: String!) {
    getDevelopments(documentNumber: $documentNumber) {
      developments {
        idProposta
        nmFornecCanal
        nmEmpreendimento
        idEmpreendimento
        documentNumber
        __typename
      }
          hasDevelopment
    }
  }
`;
