import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-warning-min-max',
  templateUrl: './warning-min-max.component.html',
  styleUrls: ['./warning-min-max.component.scss']
})
export class WarningMinMaxComponent {
  @Input() isInvalidMin: boolean;
  @Input() isInvalidMax: boolean;
  @Input() formatMinMax?: string;
  @Input() minValue: number;
  @Input() maxValue: number;
  @Input() labelMinValue: number;
  @Input() labelMaxValue: number;
  @Input() isUseCasaMais = false;

  constructor() {}

  
}
