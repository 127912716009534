import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ElementRef, AfterViewInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Renderer2 } from '@angular/core';
import { DataLayerSantanderService } from '@projects/cliente/src/app/shared/service/dataLayer/novo-data-layer.service';
import { StorageEncryptService } from '@shared/services/storageEncrypt.service';

@Component({
  selector: 'app-form-simulation-result',
  templateUrl: './form-simulation-result.component.html',
  styleUrls: ['./form-simulation-result.component.scss']
})
export class FormSimulationResultComponent implements OnInit, OnChanges {
  @Input() formSimulationResult: FormGroup;
  @Input() formConditionResult: FormGroup;
  @Input() simulationData: any;
  @Input() calculatedSimulationResult: any;
  @Input() offerChosen: string;
  @Input() maxTermInMonths: number;
  @Input() minTermInMonths: number;
  @Input() visibleFields: any;
  @Input() maxLoanAmountValue: any;
  @Input() minLoanAmountValue: any;
  @Input() termInYearsList: any;
  @Input() simulationDomains: any;
  @Input() offerList: any;
  @Input() isSimulationInProgress: boolean;
  @Input() isUseCasaMais: boolean;
  @Input() hasUmaOferta: boolean;
  @Output() save = new EventEmitter();
  @Output() changesEmitted = new EventEmitter<{ controlName: string, value: string, eventType: string }[]>();
  changesArray: { controlName: string, value: string, eventType: string }[] = [];

  showText = null;
  showOferta = null;
  public fgIsNotVisible;
  public loanAmountValueLabel;
  public amountFinancedLabel;

  constructor(private renderer: Renderer2, private el: ElementRef, private dataLayerSantander: DataLayerSantanderService, private storageEncryptService: StorageEncryptService) { }

  ngOnInit() {
    this.changeLabelLoanType();
    this.showText = this.formSimulationResult.get('offerType').value;
    this.sendPageView(undefined, '/simuladorcliente/resultado-simulacao');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isSimulationInProgress && changes.isSimulationInProgress.currentValue === false) {
      this.formSimulationResult.disable();
    }

    if (changes.hasUmaOferta && changes.hasUmaOferta.currentValue !== undefined) {
      this.showOferta = this.hasUmaOferta
    }
  }

  changeLabelLoanType() {
    /* Labels useCasa / useImovel */
    const loanTypeKey = this.simulationData.loanTypeKey.toString();
    if (loanTypeKey === '4' || loanTypeKey === '50' || loanTypeKey === '55' || loanTypeKey === '59') {
      this.loanAmountValueLabel = 'common.loanAmountValueUseImovel';
      this.amountFinancedLabel = 'common.amountLoaned';
      return;
    }
    this.loanAmountValueLabel = 'common.loanAmountValue';
    this.amountFinancedLabel = 'common.amountFinanced';
  }

  sendSelectedFieldsSelecteds() {
    const fieldsToSend = ['termInYears']; // Lista dos campos específicos que você quer enviar
    fieldsToSend.forEach(field => {
      const fieldValue = this.formSimulationResult.get(field)?.value;
      this.updateChangesArray(field, fieldValue, 'selecionou');
    });
  }

  sendSelectedFieldsSelectedsField(): void {
    const fieldsToSend = ['loanAmountValue']; // Lista dos campos específicos que você quer enviar
    fieldsToSend.forEach(field => {
      const fieldValue = this.formSimulationResult.get(field)?.value;
      this.updateChangesArray(field, fieldValue, 'preencheu');
    });
  }

  updateChangesArray(controlName: string, value: string, eventType: string) {
    const index = this.changesArray.findIndex(item => item.controlName === controlName);

    if (index !== -1) {
      // Substituir pelo novo valor se já existe
      this.changesArray[index] = { controlName, value, eventType };
    } else {
      // Adicionar novo item ao array
      this.changesArray.push({ controlName, value, eventType });
    }

    // Emitir o array de mudanças para o componente pai
    this.changesEmitted.emit(this.changesArray);
  }

  /**
   * Check visible fields
   */
  isVisible(fieldName: string): boolean {
    if (!this.visibleFields || !this.visibleFields.length) return;

    if (!this.visibleFields.find(f => f === fieldName) || !this.isSimulationInProgress) {
      this.formSimulationResult.get(fieldName).disable();
      return false;
    }

    this.formSimulationResult.get(fieldName).enable();
    return true;
  }

  /**
   * Emit "save" event to [Simulation Result] container
   */
  onSave() {
    let productName = this.storageEncryptService.getSessionStore('productName');
    this.sendToClickEvent(false , 'clicou' , 'botao:continue-analisar-credito:superior', productName);
    this.save.emit();
  }

  onChangeOfferType(value) {
    this.showText = value.codigo;
  }

  sendToClickEvent(nonInterection, eventAction, eventLabel, productName) {
    this.dataLayerSantander.sendCustomToDataLayer(
      nonInterection,
      eventAction,
      eventLabel,
      productName
    );
  }
  sendPageView(url, customUrl) {
    this.dataLayerSantander.sendPageViewToDataLayer(
      url,
      customUrl
    );
  }
}
