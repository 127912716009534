import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { HelperService } from '@shared/services/helper.service';
import { ErrorService } from '@shared/services/error.service';
import { Store } from '@ngrx/store';
import { AppState } from '..';

import * as actions from './helpers.actions';
import * as proposalSelectors from './../proposal/proposal.selectors';
import * as fromErrorHandling from './../error-handling';

@Injectable()
export class HelperEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private helperService: HelperService,
    private errorService: ErrorService
  ) {}

  @Effect()
  getHelpers$ = this.actions$.pipe(
    ofType<actions.GetHelpers>(actions.HelpersActionTypes.GET_HELPERS),
    withLatestFrom(this.store$.select(proposalSelectors.selectProposal)),
    switchMap(([_, proposalData]) => {
      if (!proposalData) return;
      return this.helperService.simulationHelpers(proposalData).pipe(
        map(response => new actions.GetHelpersSuccess({ fields: response })),
        catchError(err => {
          if (err.extensions) {
            return of(new fromErrorHandling.actions.AddError(this.errorService.addGraphQLAlert(err)));
          }

          return of(new fromErrorHandling.actions.AddError(this.errorService.addAlertError(err)));
        })
      );
    })
  );
}
