import { createSelector } from '@ngrx/store';
import { AppState } from '..';

export const selectParticipants = (state: AppState) => state.participants;

export const selectErrors = createSelector(
  selectParticipants,
  state => state.errors
);

export const selectIdProposta = createSelector(
  selectParticipants,
  state => state.idProposta
);

export const selectBorrowers = createSelector(
  selectParticipants,
  state => state.participants
);

export const selectCompany = createSelector(
  selectParticipants,
  state => state.company
);

export const selectCpfMainProponent = createSelector(
  selectBorrowers,
  state => {
    if (!state || state.length < 1) return null;
    return state[0].documentNumber;
  }
);

export const selectCpfSecondProponent = createSelector(
  selectBorrowers,
  state => {
    if (!state || state.length < 2) return null;
    return state[1].documentNumber;
  }
);

export const selectPersonalDataChanged = createSelector(
  selectParticipants,
  state => state.personalDataChanged
);

export const selectGenerateToken = createSelector(
  selectParticipants,
  state => state.send
);

export const selectValidateToken = createSelector(
  selectParticipants,
  state => state.validate
);


// export const selectBorrowersListSimulation = createSelector(
//   selectParticipants,
//   state => state.getBorrowersListSimulation
// );
