import { Action } from '@ngrx/store';

export enum ErrorHandlingActionTypes {
  ADD_ERROR = '[Error Handling] Add Error',
  CLEAR_ERROR = '[Error Handling] Clear Error'
}

export class AddError implements Action {
  readonly type = ErrorHandlingActionTypes.ADD_ERROR;
  constructor(public payload: { message: string }) {}
}

export class ClearError implements Action {
  readonly type = ErrorHandlingActionTypes.CLEAR_ERROR;
  constructor() {}
}

export type ErrorHandlingActions = AddError | ClearError;
