import { Component, Input, Inject, HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Subscription, BehaviorSubject } from 'rxjs';
import { ProposalSummaryService } from '@projects/gerente/src/app/simulation/proposal-summary/proposal-summary.service';
import { Store } from '@ngrx/store';
import { AppState } from '@app/state';
import * as fromProposal from '@app/state/proposal';
import { LoanTypeKey } from '@shared/constants/loan-type-key.enum';
@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent {
  @Input() fase: number;
  @Input() nomeFase: string;
  @Input() scrollPage: number;
  @Input() objective: number;

  loanTypeQfgts = LoanTypeKey.QFGTS;

  constructor(
    @Inject(DOCUMENT) private doc: Document,
    private store$: Store<AppState>) {}

  public fixed = false;
  private subscription: Subscription = new Subscription();
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const num = window.pageYOffset;

    if (num > this.scrollPage) {
      this.fixed = true;
    } else if (this.fixed && num < 5) {
      this.fixed = false;
    }
  }

  

}
