import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { LoadingState, loadingSelector } from './../store/loading.state';

@Component({
  selector: 'app-loading-indicator',
  template: `
    <app-spinner [loading]="isLoading"></app-spinner>
  `
})
export class LoadingIndicatorContainerComponent implements OnInit {
  isLoading = false;

  constructor(private store: Store<LoadingState>) {}

  ngOnInit() {
    this.store
      .select(loadingSelector)
      .subscribe(state => (this.isLoading = Object.keys(state.ongoingRequests).length > 0));
  }
}
