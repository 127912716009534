import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { AppState } from '@app/state';

import * as fromHelpers from '@app/state/helpers';
import { DataLayerSantanderService } from '@projects/cliente/src/app/shared/service/dataLayer/novo-data-layer.service';
import { StorageEncryptService } from '@shared/services/storageEncrypt.service';

@Component({
  selector: 'app-chat-footer',
  templateUrl: './chat-footer.component.html',
  styleUrls: ['./chat-footer.component.scss']
})
export class ChatFooterComponent implements AfterViewInit {
  @Input() isChatBot: boolean;

  urlChatbot: SafeResourceUrl;
  isOpenIframe = false;
  pathImageClient = environment.pathClientAssets;
  fgSdk = environment.featureToggle.fgSdk;
  urlWebSdk = 'https://avi.santander.com.br/assets/websdkjs-index.js';

  constructor(public sanitizer: DomSanitizer, private store$: Store<AppState>, private dataLayerSantander: DataLayerSantanderService, private storageEncryptService: StorageEncryptService) { }



  ngAfterViewInit() {
    if (this.fgSdk) {
      this.loadDynmicallyScript();
    }
  }

  public loadDynmicallyScript() {
    const script = document.createElement('script');
    script.src = this.urlWebSdk;
    document.head.appendChild(script);
  }

  openChatBot() {
    const key = 'openConversation';
    window[key]();
  }

  toggleIframe(): void {
    if (!this.isOpenIframe) {
      this.urlChatbot = this.sanitizer.bypassSecurityTrustResourceUrl(environment.urlChatBot);
      this.store$.dispatch(new fromHelpers.actions.CloseHelper());
    }

    if (this.isOpenIframe) {
      this.urlChatbot = '';
    }

    this.isOpenIframe = !this.isOpenIframe;
  }

  sendEventClickChat(){
    let productName = this.storageEncryptService.getSessionStore('productName');
    this.sendToClickEvent(false, 'clicou', 'botao:chat', productName)
  }

  sendToClickEvent(nonInterection, eventAction, eventLabel, productName) {
    this.dataLayerSantander.sendCustomToDataLayer(
      nonInterection,
      eventAction,
      eventLabel,
      productName
    );
  }

}
