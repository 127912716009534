import { createSelector } from '@ngrx/store';

export const selectHelpers = (state: any) => state.helpers;

export const selectHelpersShow = createSelector(
  selectHelpers,
  state => state.show
);

export const selectHelpersQuestions = createSelector(
  selectHelpers,
  state => state.questions
);

export const selectHelperActiveQuestion = createSelector(
  selectHelpers,
  state => state.questions.find(q => q.active)
);

export const selectHelperFields = createSelector(
  selectHelpers,
  state => state.fields
);
