import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';

@Injectable()
export class SwalService {
  constructor(private translateService: TranslateService) {}

  public createConfirmWarning(text) {
    return Swal.fire({
      text: this.translateService.instant(text),
      icon: 'warning',
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonText: this.translateService.instant('Yes'),
      cancelButtonText: this.translateService.instant('No'),
      customClass: {
        container: 'swal2-reverse-confirm'
      }
    });
  }

  public createAlertWarning(text) {
    return Swal.fire({
      text: this.translateService.instant(text),
      icon: 'warning',
      confirmButtonText: 'OK'
    });
  }
}
