import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { FormValidationService } from '@app/shared/services/form-validation.service';
import { Subscription, BehaviorSubject, combineLatest } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { ModalTemplateComponent } from '@app/shared/widgets/modal-template/modal-template.component';
import { ModalAlertComponent } from '@app/shared/widgets/modal-alert/modal-alert.component';
import { ErrorService } from '@app/shared/services/error.service';
import { AppState } from '@app/state';
import { loanTypesConstant } from '@app/shared/constants/loan-type.constant';
import { distinctUntilChanged } from 'rxjs/operators';
import { Router } from '@angular/router';
import { InputSaveProposalData } from '@shared/graphql/types/query-types';
import { environment } from 'src/environments/environment';
import { DataLayerService } from '../../shared/service/dataLayer/dataLayer.service';
import { ModalService } from '@shared/ui/modal/services/modal.service';
import { SafeUrl } from '@angular/platform-browser';
import { ProposalSummaryService } from '../proposal-summary/proposal-summary.service';
import { StorageEncryptService } from '@shared/services/storageEncrypt.service';

import * as fromParameters from '@app/state/parameters';
import * as fromProposal from '@app/state/proposal';
import * as fromSimulation from '@app/state/simulation';
import * as fromErrorHandling from '@app/state/error-handling';
import * as fromParticipants from '@app/state/participants';
import * as fromLogin from '@app/state/login'
import { DataLayerSantanderService } from '../../shared/service/dataLayer/novo-data-layer.service';

declare const DLECC: any;
@Component({
  selector: 'app-proposal-data',
  templateUrl: './proposal-data.container.html',
  styleUrls: ['./proposal-data.container.scss']
})
export class ProposalDataContainerComponent implements OnInit, OnDestroy {
  proposal: any;
  loanTypes: any;
  minPropertyValue: number;
  maxPropertyValue: number;
  minEvaluationValue: number;
  maxEvaluationValue: number;
  minTermInYears: number;
  maxTermInYears: number;
  minLoanAmountValue: number;
  maxLoanAmountValue: number;
  selectedLoanTypeKey: number;
  loanAmountLabel: string;
  termInMonthsLabel: string;
  arrCarencia: Array<any>;
  deferredPayment: any;
  allowCarencia: boolean;
  showPropertyLocationKey: boolean;
  isProposalFilled: boolean;
  flagPortability = false;
  tipoImovelState = false;
  isSimulationInProgress = false;
  isSimulationSuccessful = false;
  recaptchaOn = environment.featureToggle.recaptchaOn === 'false' ? false : true;

  defaultVisibleFields = [];
  defaultEditableFields = [];
  visibleFields = [];
  editableFields = [];

  formProposalData: FormGroup;
  formLoginRenovado: FormGroup;

  portabilityTypes: Array<any>;
  oldMaxLoanAmountValue: number;
  oldCodigoDistrito: any;
  fgChangeMaxLoanAmount = false;

  contractSelected: any;

  oldMaxLoanAmount: number;
  oldMinLoanAmount: number;
  buildingTypes: any[];
  estimatedOperatingPeriods: any[];
  federalStates: any[];
  loanObjectives: any[];
  singleContract: boolean;
  teste1;

  data = JSON.parse(this.storageEncryptService.getSessionStore('data'));
  username = this.storageEncryptService.getSessionStore('username');
  idProposta: string;
  auxMinEvaluationValue: number;
  auxMaxEvaluationValue: number;

  loanTypeKey: any;
  typePortability: string;

  type: any;
  loanType: any;
  formContractList: FormGroup;
  contractListPC: any[];
  isLoggedRenovado = true

  label
  showAuthentication = false;
  isLoggedSecondScreen = false;
  urlLoginIframe: SafeUrl;
  useMockLogin: any;
  clientPublicKey: string;
  parametrosLogin: {
    name: string;
    cpf: string;
    phone: string;
    urlBase: string;
    urlSuccess: string;
    urlError: string;
    ticket: string;
  };
  showModalContractList = false;
  submitedContractListPC = false;
  selectedLoanObjective = this.storageEncryptService.getSessionStore('selectedLoanObjective');
  alreadyEntered = false;
  isencaoTarifaUseCasa = false;

  modal = new BehaviorSubject({
    show: false,
    template: ModalTemplateComponent,
    content: Object,
    closed: () => {
      return;
    },
    back: () => {
      return;
    }
  });

  private changes: any;
  private modalLoanAmountValueImpactVisible = false;
  private subscription: Subscription = new Subscription();
  private inputsRadio = [
    'isTransferTaxInClosingCostCredit',
    'isIOFIncludedInClosingCostCredit',
    'isAppraisalfeesInClosingCostCredit'
  ];

  constructor(
    private store$: Store<AppState>,
    private formBuilder: FormBuilder,
    private formValidationService: FormValidationService,
    private errorService: ErrorService,
    private router: Router,
    private dataLayerService: DataLayerService,
    private dataLayerSantander: DataLayerSantanderService,
    private storageEncryptService: StorageEncryptService,
    private modalService: ModalService,
    private proposalSummaryService: ProposalSummaryService
  ) {
    this.clientPublicKey = DLECC.init();
  }

  ngOnInit(): void {
    this.subscribeToSimulationID();
    this.createFormProposalData();
    this.createFormContractList();
    this.createSubscriptions();
    this.subscribeToSimulationContractList();
    this.onChangeDeferredPaymentPC();
    this.createFormLoginRenovado();
    this.sendPageView(undefined, '/simuladorcliente/dados-proposta');
    this.loanTypes = loanTypesConstant;
  }

  /**
   * Add event listener to useMockLogin
   *
   * @param {*} event
   * @returns
   * @memberof ProposalSummaryContainerComponent
   */
  @HostListener('window:message', ['$event'])
  mockListener(event: any) {
    if (!this.canUseMockListener(event)) {
      return;
    }

    window.location.href = event.data;

    //  this.store$.dispatch(
    //    new fromSimulation.actions.RedirectResultadoAnaliseCredito({
    //      proposalId: this.proposalId,
    //      borrowerId: this.borrowers[0].id,
    //      url: event.data
    //    })
    //  );
  }

  /**
   * Validate if user can make actions inside Mock Login Iframe
   *
   * @memberof ProposalSummaryContainerComponent
   */
  private canUseMockListener(event: any): boolean {
    return (
      environment.featureToggle.useMockLogin === 'true' &&
      event &&
      event.data &&
      typeof event.data === 'string' &&
      event.data.includes('middleware-token-origination')
    );
  }

  selectSaveFinancingObjective() {
    this.loanType = this.loanObjectives.find(item => item.loanObjectiveId === this.loanTypeKey);

    if (this.typePortability) {
      this.type =
        this.loanType && this.loanType.portabilityTypes.length > 0
          ? this.loanType.portabilityTypes.find(item => item.key === this.typePortability)
          : null;
    }

    if (this.loanType && !this.type) {
      this.onFieldChanged({ controlName: 'loanTypeKey', value: this.loanType.loanObjectiveId });
    } else if (this.loanType && this.type) {
      this.onFieldChanged({ controlName: 'loanTypeKey', value: this.loanType.loanObjectiveId });
      this.onFieldChanged({ controlName: 'portabilityTypeKey', value: this.type.key });
    }
  }

  subscribeSaveFinancingObjective() {
    this.subscription.add(
      this.store$.pipe(select(fromProposal.selectors.selectSaveFinancingObjective)).subscribe(state => {
        if (this.loanTypeKey) return;
        const key = this.formProposalData.value.loanTypeKey;
        if (!state || this.loanObjectives.length < 1 || key) return;
        this.loanTypeKey = state.goal;
        if (state.type) {
          this.typePortability = state.type.toUpperCase();
        }
        this.selectSaveFinancingObjective();
      })
    );
  }
  createFormContractList() {
    this.formContractList = this.formBuilder.group({
      contrato: [null, Validators.required]
    });
  }

  private subscribeToSimulationContractList(): void {
    this.subscription.add(
      this.store$.select(fromProposal.selectors.selectSimulationContractList).subscribe(state => {
        if (!state) return;
        this.contractListPC = state;

        this.singleContract = this.contractListPC.length > 1 ? true : false
        this.showModalContractList = false;
        this.openModalContractList();
      })
    );
  }

  private subscribeToIsLoggedSecondScreen(): void {
    this.subscription.add(
      this.store$.select(fromProposal.selectors.selectIsLoggedSecondScreen).subscribe(state => {
        this.isLoggedSecondScreen = state;
        this.showModalContractList = !!this.isLoggedSecondScreen;


        if (this.showModalContractList) {
          this.showAuthentication = false
          const proposta = this.idProposta ? this.idProposta : this.storageEncryptService.getSessionStore('idProposta');
          this.store$.dispatch(new fromProposal.actions.GetSimulationContractData({ idSimulacao: proposta }));
        }
      })
    );
  }

  openModalContractList() {
    this.modalService.openModal('modalContractList');
  }

  showModalUseCasaRenovado() {
    this.modal.next({
      show: true,
      template: ModalAlertComponent,
      content: Object({
        title: 'USECASA RENOVADO',
        message: 'O Usecasa Renovado é um Empréstimo com Garantia de Imóvel, disponível para clientes que já tem um contrato de Crédito Imobiliário ou Usecasa com o Santander. Com ele, você contrata uma operação nova e liquida a anterior. Assim, você consegue mais crédito com o mesmo imóvel utilizado como garantia. Os recursos que sobrarem, após a liquidação da operação anterior, serão liberados na sua conta corrente após o registro do contrato no cartório, na forma e prazo previstos no seu contrato.',
        buttons: [{ label: 'avançar', action: 'n' }, { label: 'voltar', action: 's' }],
        btnLarge: true
      }),
      closed: () => {
        this.formProposalData.get('loanTypeKey').setValue(null);
      },
      back: () => {
        if (this.contractListPC && this.contractListPC.length > 0) return this.openModalContractList();
        this.subscribeToBorrowers();
      }
    });
  }

  subscribeToSimulationID() {
    this.subscription.add(
      this.store$.select(fromProposal.selectors.selectSimulationID).subscribe(state => {
        const idSimulation = this.storageEncryptService.getSessionStore('idSimulation');
        if (state) {
          this.idProposta = state;
        } else {
          this.idProposta = idSimulation
        }

      })
    );
  }

  onClickEdit() {
    if (this.contractListPC.length > 1) return this.openModalContractList();
  }

  selectContract() {
    this.modalService.closeModal('modalContractList');

    const contract = this.formContractList.get('contrato').value;

    if (this.contractSelected !== contract) {
      this.changeContract();
    }

    this.store$.dispatch(new fromProposal.actions.SaveRenewedContract({ cdContrato: contract.codigoContrato, simulationId: this.idProposta }))

    const endereco = `${contract.tipoLogradouro} ${contract.descricaoLogradouro}, ${contract.numeroLogradouro} - ${contract.descricaoComplemento
      } ${contract.descricaoBairro} ${contract.cep} - ${contract.descricaoCidade}, ${contract.idUf}`;

    this.formProposalData.patchValue({
      endereco,
      codigoContrato: contract.codigoContrato,
      descricaoObjetivoFinanciamento: contract.descricaoObjetivoFinanciamento,
      buildingTypeKey: contract.tipoImovel
    });

    this.onFieldChanged({ controlName: 'buildingTypeKey', value: contract.tipoImovel });
    this.onFieldChanged({ controlName: 'codigoContrato', value: contract.codigoContrato });
    this.onFieldChanged({
      controlName: 'descricaoObjetivoFinanciamento',
      value: contract.descricaoObjetivoFinanciamento
    });
    this.onFieldChanged({ controlName: 'endereco', value: endereco });

    this.contractSelected = contract.codigoContrato;

    if (contract) {
      this.submitedContractListPC = true;
    }
  }

  /*
 * To generate an url string to call Login Santander Iframe
 */
  private getTicketIframe(borrower): void {
    this.proposalSummaryService.getTicket(this.clientPublicKey).subscribe(resTicket => {

      this.parametrosLogin = {
        name: borrower.name,
        cpf: borrower.documentNumber,
        phone: borrower.telephoneNumber,
        urlBase: environment.urlNovaSimulacaoCliente,
        urlSuccess: `${environment.urlNovaSimulacaoCliente}#/middleware-token-origination`.replace(/\/\/$/gm, '/'),
        urlError: `${environment.urlNovaSimulacaoCliente}#/`.replace(/\/\/$/gm, '/'),
        ticket: resTicket.ticket
      };

      this.showAuthentication = true;
      this.storageEncryptService.setSessionStore('idProposta', this.idProposta);
      this.storageEncryptService.setSessionStore('selectedLoanObjective', this.selectedLoanObjective);
      this.urlLoginIframe = this.proposalSummaryService.santanderLogin(
        this.parametrosLogin,
        resTicket.serverPublicKey,
        's'
      );
    });
  }

  changeContract() {
    const fields = [
      'propertyValue',
      'loanAmountValue',
      'termInYears',
      'isAppraisalfeesInClosingCostCredit',
      'isIOFIncludedInClosingCostCredit'
    ];

    fields.forEach(res => {
      const form = this.formProposalData.get(res);

      form.setValue(null);
      this.onFieldChanged({ controlName: res, value: null });
      form.markAsUntouched();
    });

    this.updateProposalValidation();
  }

  private subscribeToBorrowers(): void {
    this.subscription.add(
      this.store$.select(fromParticipants.selectors.selectBorrowers).subscribe(state => {
        if (!state || state.length < 1) return;

        const usuarioLogado = { cpf: state[0].documentNumber };
        this.storageEncryptService.setSessionStore('usuarioLogado', JSON.stringify(usuarioLogado));

        if (!this.useMockLogin) {
          this.getTicketIframe(state[0]);
        } else {
          this.showAuthentication = true;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Create all subscriptions
   */
  createSubscriptions() {
    this.subscribeToErrors();
    this.subscribeToProposalDataPC();
    this.subscribeToInitialParametersPC();
    this.subscribeToIsLoggedSecondScreen();
    this.subscribeToPCSimulationInProgress();
    this.subscribeToProposalValueRangePC();
    this.subscribeToSimulationID();
    this.subscribeToPropertyValueRange();
    this.subscribeToSimulationSuccessful();
    this.getInitialParameters();
  }

  /**
   * Subscribe to return values of fields already filled
   */
  subscribeToProposalDataPC() {
    this.subscription.add(
      this.store$.pipe(select(fromProposal.selectors.selectProposalData)).subscribe(state => {
        if (!state) return;

        this.proposal = {
          loanTypeKey: state.loanTypeKey ? parseInt(state.loanTypeKey, 10) : state.loanTypeKey,
          quizResponseCode: state.quizResponseCode,
          buildingTypeKey: state.buildingTypeKey,
          propertyLocationKey: state.propertyLocationKey,
          portabilityTypeKey: state.portabilityTypeKey,
          propertyValue: state.propertyValue,
          evaluationPrice: state.evaluationPrice,
          loanAmountValue: state.loanAmountValue,
          termInYears: state.termInYears,
          isAppraisalfeesInClosingCostCredit: state.isAppraisalfeesInClosingCostCredit,
          isIOFIncludedInClosingCostCredit: state.isIOFIncludedInClosingCostCredit,
          isTransferTaxInClosingCostCredit: state.isTransferTaxInClosingCostCredit,
          deferredPaymentKey: state.deferredPaymentKey,
          monthsDeferredPaymentQt: state.monthsDeferredPaymentQt,
          endereco: state.endereco,
          codigoContrato: state.codigoContrato,
          descricaoObjetivoFinanciamento: state.descricaoObjetivoFinanciamento
        };

        this.selectedLoanTypeKey = parseInt(state.loanTypeKey, 10);

        if (this.proposal.codigoContrato) {
          this.submitedContractListPC = true;
        }

        if (Object.values(state).some(v => v !== null)) {
          this.formProposalData.patchValue(this.proposal);
          this.markAsTouched();
          this.updateProposalValidation();
          this.propertyValueChanged();
        }
      })
    );
  }

  /**
   * Subscribe to proposal initial parameters
   */
  subscribeToInitialParametersPC() {
    this.subscription.add(
      combineLatest(
        this.store$.select(fromParameters.selectors.selectParameters),
        this.store$.select(fromParticipants.selectors.selectPersonalDataChanged),
        this.formProposalData.valueChanges
      ).subscribe(state => {
        this.buildingTypes = state[0].buildingTypes;
        this.estimatedOperatingPeriods = state[0].estimatedOperatingPeriods;
        this.federalStates = state[0].federalStates;
        this.loanObjectives = state[0].loanObjectives;
        this.defaultVisibleFields = state[0].visibleFields;
        this.defaultEditableFields = state[0].editableFields;

        this.getLoanTypeFields(this.formProposalData.get('loanTypeKey').value);

        this.subscribeSaveFinancingObjective();

        if (this.showModalContractList && this.idProposta && !this.contractListPC && !this.alreadyEntered) {
          this.alreadyEntered = true;
          this.onFieldChanged({ controlName: 'loanTypeKey', value: this.selectedLoanObjective });
        }

        if (this.buildingTypes && this.buildingTypes.length > 0 && state[1]) {
          if (this.onFormChange(state[2])) {
            this.propertyValueChanged();
          }
          this.store$.dispatch(new fromParticipants.actions.SetPersonalDataChanged({ personalDataChanged: false }));
        }
      })
    );
  }

  /**
   * Subscribe to property value range
   */
  subscribeToPropertyValueRange() {
    this.subscription.add(
      this.store$.pipe(select(fromParameters.selectors.selectPropertyValueRange)).subscribe(state => {
        if (Object.values(state).some(v => v === null)) return;
        this.minPropertyValue = state.minPropertyValue;
        this.maxPropertyValue = state.maxPropertyValue;
        this.minEvaluationValue = state.minPropertyValue;
        this.maxEvaluationValue = state.maxPropertyValue;

        /**
         * save min e max evaluation value
         * in aux var
         */

        this.auxMinEvaluationValue = this.minEvaluationValue;
        this.auxMaxEvaluationValue = this.maxEvaluationValue;

        this.updateProposalValidation();
      })
    );
  }

  /**
   * Subscribe to proposal value range
   */
  subscribeToProposalValueRangePC() {
    this.subscription.add(
      this.store$.pipe(select(fromParameters.selectors.selectProposalValueRange)).subscribe(state => {
        if (!state) return;

        this.oldMaxLoanAmount = this.maxLoanAmountValue;
        this.minLoanAmountValue = state.minLoanAmountValue;
        this.maxLoanAmountValue = state.maxLoanAmountValue;
        this.minTermInYears = state.minTermInYears;
        this.maxTermInYears = state.maxTermInYears;
        this.deferredPayment = state.deferredPaymentGroup;
        this.isencaoTarifaUseCasa = state.isFree;
        this.allowCarencia = state.allowCarencia;

        this.changeOnMaxValue();
        this.updateDeferredPayment();
        this.updateProposalValidation();
      })
    );
  }

  /**
   * Subscribe to Simulation Successful to determine if loanTypeKey and portabilityTypeKey fields should be blocked for editing
   *
   * @private
   * @memberof ProposalDataContainerComponent
   */
  private subscribeToSimulationSuccessful(): void {
    this.subscription.add(
      this.store$.select(fromSimulation.selectors.selectSimulationSuccessful).subscribe(state => {
        if (!state) return;

        this.formProposalData.get('loanTypeKey').disable();
        this.formProposalData.get('portabilityTypeKey').disable();
        this.tipoImovelState = true;
        this.flagPortability = true;
        this.tipoImovelState = true;
      })
    );
  }

  private subscribeToPCSimulationInProgress(): void {
    this.subscription.add(
      this.store$.select(fromParameters.selectors.selectIsSimulationInProgress).subscribe(state => {
        this.isSimulationInProgress = state;
      })
    );
  }

  /**
   * Subscribe to errors
   */
  subscribeToErrors(): void {
    this.subscription.add(
      this.store$.pipe(select(fromErrorHandling.selectors.selectErrorMessage)).subscribe(state => {
        if (!state || state === '') return;

        this.modal.next({
          show: true,
          template: ModalAlertComponent,
          content: Object({
            title: 'common.attention',
            message: this.errorService.handleError(state)
          }),
          closed: () => this.store$.dispatch(new fromErrorHandling.actions.ClearError()),
          back: () => ''
        });

        /**
         * Check for a specific error type that returns new finance/loan amount value to update the validation
         */
        if (this.errorService.getErrorCode(state) !== 'msgErrorSIFSIMB01_E0007') return;
        const params = this.errorService.getParametersFromRawError(state) as any;
        this.store$.dispatch(
          new fromParameters.actions.SetFinanceLoanValue({
            minLoanAmountValue: parseFloat(params.param0),
            maxLoanAmountValue: parseFloat(params.param1)
          })
        );
      })
    );
  }

  /**
   * Get initial parameters
   */
  getInitialParameters() {
    this.store$.dispatch(
      new fromParameters.actions.ProposalLoad({
        channel: 'PC',
        tpPessoa: 'F',
        showModalcontractList: this.showModalContractList
      })
    );
  }

  createFormLoginRenovado(): void {
    this.useMockLogin = environment.featureToggle.useMockLogin === 'true' ? true : false
    this.formLoginRenovado = this.formBuilder.group({
      name: [null, Validators.required],
      password: [null, Validators.required]
    })
  }

  /**
   * Creates proposal data form
   */
  createFormProposalData(): void {
    this.formProposalData = this.formBuilder.group({
      loanTypeKey: [null, [Validators.required]],
      portabilityTypeKey: [null, [Validators.required]],
      buildingTypeKey: new FormControl(null, { updateOn: 'change' }),
      quizResponseCode: [null],
      propertyLocationKey: [null, [Validators.required]],
      propertyValue: new FormControl(null, {
        validators: [
          Validators.required,
          this.formValidationService.isValidPropertyValue(this.minPropertyValue, this.maxPropertyValue),
          this.formValidationService.isLowerThanMin(this.minPropertyValue),
          this.formValidationService.isGreaterThanMax(this.maxPropertyValue)
        ]
      }),
      evaluationPrice: new FormControl(null, {
        updateOn: 'change',
        validators: [
          Validators.required,
          this.formValidationService.isValidEvaluationPrice(this.minEvaluationValue),
          this.formValidationService.isGreaterThanMax(this.maxEvaluationValue)
        ]
      }),
      loanAmountValue: new FormControl(null, {
        validators: [
          Validators.required,
          this.formValidationService.isValidPropertyValue(this.minLoanAmountValue, this.maxLoanAmountValue),
          this.formValidationService.isLowerThanMin(this.minLoanAmountValue),
          this.formValidationService.isGreaterThanMax(this.maxLoanAmountValue)
        ]
      }),
      termInYears: [
        null,
        [
          Validators.required,
          this.formValidationService.isLowerThanMin(this.minTermInYears),
          this.formValidationService.isGreaterThanMax(this.maxTermInYears)
        ]
      ],
      deferredPaymentKey: [null],
      monthsDeferredPaymentQt: [null, [Validators.required]],
      isTransferTaxInClosingCostCredit: new FormControl(null, {
        updateOn: 'change',
        validators: Validators.required
      }),
      isIOFIncludedInClosingCostCredit: new FormControl(null, {
        updateOn: 'change',
        validators: Validators.required
      }),
      isAppraisalfeesInClosingCostCredit: new FormControl(null, {
        updateOn: 'change'
      }),
      codigoContrato: [{ value: null, disabled: true }],
      descricaoObjetivoFinanciamento: [{ value: null, disabled: true }],
      endereco: [{ value: null, disabled: true }]
    });

    if (this.recaptchaOn) {
      this.formProposalData.addControl('recaptcha', new FormControl('', Validators.required));
    }

    this.subscribeToLoanTypeKeyChanges();
    this.subscribeToPortabilityTypeKeyChanges();
    this.subscribeToEvaluationPriceChanges();
    this.subscribeToBuildingTypeKeyChanges();
  }

  validFormTarifaAvaliacaoGarantia(field, value) {
    if (field === 'loanTypeKey') {

      if (value === 55) {
        this.formProposalData.get('isAppraisalfeesInClosingCostCredit').clearValidators();
        this.formProposalData.get('isAppraisalfeesInClosingCostCredit').updateValueAndValidity();
      } else {
        this.formProposalData.get('isAppraisalfeesInClosingCostCredit').setValidators(Validators.required);
      }

    }
  }

  /**
   * Subscribe to Loan Objective Changes to set which fields are visible
   *
   * @memberof ProposalDataContainerComponent
   */
  private subscribeToLoanTypeKeyChanges(): void {
    this.subscription.add(
      this.formProposalData
        .get('loanTypeKey')
        .valueChanges.pipe(distinctUntilChanged((prev, curr) => prev === curr))
        .subscribe(value => {
          const isRenovado = value === 55
          /**
           * Check if loanTypeKey is not portability to clear portabilityTypeKey field's value
           *
           */
          if (this.loanObjectives && this.loanObjectives.length > 0) {
            const loanType = this.loanObjectives.find(objective => objective.loanObjectiveId === value);

            if (loanType && (!loanType.portabilityTypes || loanType.portabilityTypes.length < 1)) {
              this.formProposalData.get('portabilityTypeKey').setValue(null);
            }

            this.submitedContractListPC = false;

            if (loanType && loanType.isShowModal === 'S' && isRenovado) {
              this.selectedLoanObjective = loanType.loanObjectiveId;
              if (!this.showModalContractList) {
                this.showModalUseCasaRenovado();
              } else {
                this.openModalContractList();
              }
            }
          }

          this.changeLabelLoanType(isRenovado)
          this.onFieldChanged({ controlName: 'loanTypeKey', value });

          // USE CASA RENOVADO
          if (!isRenovado) {
            const fields = ['codigoContrato', 'descricaoObjetivoFinanciamento', 'endereco'];

            fields.forEach(r => {
              this.formProposalData.get(r).setValue(null);
              this.onFieldChanged({ controlName: r, value: null });
            });

            this.submitedContractListPC = false;
          }

          this.getLoanTypeFields(value);
        })
    );
  }

  /**
   * Subscribe to Building Type Key changes
   *
   * @private
   * @memberof ProposalDataContainerComponent
   */
  private subscribeToBuildingTypeKeyChanges(): void {
    const initialValue = this.proposal ? this.proposal.buildingTypeKey : null;

    this.subscription.add(
      this.formProposalData
        .get('buildingTypeKey')
        .valueChanges.pipe(distinctUntilChanged((prev, curr) => prev === curr))
        .subscribe(value => {
          if (!value) return;

          if (value === 'C') {
            this.formProposalData.get('monthsDeferredPaymentQt').disable();
          }

          this.store$.dispatch(new fromProposal.actions.ResetSelectedOptions());
        })
    );
  }

  /**
   * Subscribe to Portability Type Key to set Property Location Key as visible or not
   *
   * @private
   * @memberof ProposalDataContainerComponent
   */
  private subscribeToPortabilityTypeKeyChanges(): void {
    this.subscription.add(
      this.formProposalData.get('portabilityTypeKey').valueChanges.subscribe(value => {
        const isPortabilityTypeKeyVisible = this.visibleFields.find(field => field === 'portabilityTypeKey');
        this.showPropertyLocationKey = value === 'PF' && !!isPortabilityTypeKeyVisible;

        const propertyLocationKeyField = this.formProposalData.get('propertyLocationKey');
        const fieldPrevState = propertyLocationKeyField.enabled;
        const fieldNextState = this.showPropertyLocationKey ? 'enable' : 'disable';

        if (this.isSimulationInProgress) {
          if ((fieldPrevState && fieldNextState === 'disable') || (!fieldPrevState && fieldNextState === 'enable')) {
            propertyLocationKeyField[fieldNextState]();
          }
        }
      })
    );
  }

  /**
   * Get loan visible and editable fields for a loan type key
   *
   * @private
   * @param {(string | number)} value
   * @returns {void}
   * @memberof ProposalDataContainerComponent
   */
  private getLoanTypeFields(value: string | number): void {
    if (!value || !this.loanObjectives || this.loanObjectives.length < 1) {
      this.visibleFields = this.defaultVisibleFields;
      this.editableFields = this.defaultEditableFields;

      return;
    }

    const loanTypeKey = typeof value === 'string' ? parseInt(value, 10) : value;
    const loanType = this.loanObjectives.find(objective => objective.loanObjectiveId === loanTypeKey);
    this.editableFields = loanType ? [...this.defaultEditableFields, ...loanType.editableFields] : [];
    this.visibleFields = loanType ? [...this.defaultVisibleFields, ...loanType.visibleFields] : [];
    this.portabilityTypes = loanType ? loanType.portabilityTypes : [];
  }

  private subscribeToEvaluationPriceChanges(): void {
    const changeEvaluationPrice = this.formProposalData.get('evaluationPrice');

    this.subscription.add(
      changeEvaluationPrice.valueChanges.subscribe(value => {
        if (value !== null && changeEvaluationPrice.untouched) {
          this.maxEvaluationValue = this.auxMaxEvaluationValue;
          this.minEvaluationValue = this.auxMinEvaluationValue;
          changeEvaluationPrice.markAsTouched();
        }
        if (value === null) {
          this.maxEvaluationValue = 0;
          this.minEvaluationValue = 0;
          changeEvaluationPrice.markAsUntouched();
        }
      })
    );
  }
  updateDeferredPayment() {
    if (this.deferredPayment && this.deferredPayment.showDeferredPaymentBlock) {
      this.formProposalData.get('monthsDeferredPaymentQt').enable();
    } else {
      this.formProposalData.get('monthsDeferredPaymentQt').disable();
    }

    if (this.formProposalData.get('deferredPaymentKey').value || !this.deferredPayment) {
      return;
    }

    const deferredPaymentDefault = this.deferredPayment.deferredPaymentOptions.find(p => p.defaultOption);
    this.formProposalData.get('deferredPaymentKey').setValue(deferredPaymentDefault.key);
    this.onFieldChanged({ controlName: 'deferredPaymentKey', value: deferredPaymentDefault.key });
  }

  onChangeDeferredPaymentPC() {
    this.subscription.add(
      this.formProposalData
        .get('deferredPaymentKey')
        .valueChanges.pipe(distinctUntilChanged((prev, curr) => prev === curr))
        .subscribe(d => {
          if (
            !this.deferredPayment ||
            !this.deferredPayment.deferredPaymentOptions ||
            this.deferredPayment.deferredPaymentOptions.length < 1
          ) {
            return;
          }

          const auxDeferredPayment = this.deferredPayment.deferredPaymentOptions.find(p => p.deferredPaymentType === d);

          this.arrCarencia = [];

          for (
            let i = auxDeferredPayment.minMonthsDeferredPayment;
            i <= auxDeferredPayment.maxMonthsDeferredPayment;
            i++
          ) {
            this.arrCarencia.push(i);
          }
        })
    );
  }

  loginUnico() {
    // this.store$.dispatch(new fromLogin.actions.LoginCipher({ form: this.formLoginRenovado.value, renovadoLogin: true }));
    this.store$.dispatch(new fromLogin.actions
      .LoginWithoutSSO(
        {
          nome: this.formLoginRenovado.get('name').value,
          senha: this.formLoginRenovado.get('password').value
        })
    );
  }

  markAsTouched() {
    this.formProposalData.get('loanAmountValue').markAsTouched();
  }

  onFormChange(formData): boolean {
    return formData.loanTypeKey && formData.buildingTypeKey && formData.propertyValue;
  }

  changeLabelLoanType(value) {
    if (value) {
      this.label = 'modules.simulation.proposal-data.and the value to the loan';
      return;
    }
    this.label = 'modules.simulation.proposal-data.and the value to financiament';
  }
  /**
   * Update proposal validation based on flags
   */
  updateProposalValidation() {
    const group = this.formProposalData;
    const propertyValueField = group.get('propertyValue');
    const evaluationPrice = group.get('evaluationPrice');
    const loanValueField = group.get('loanAmountValue');
    const termInYearsField = group.get('termInYears');
    const loanTypeKey = group.get('loanTypeKey');
    const isAppraisalfeesInClosingCostCredit = group.get('isAppraisalfeesInClosingCostCredit');

    propertyValueField.setValidators([
      Validators.required,
      this.formValidationService.isValidPropertyValue(this.minPropertyValue, this.maxPropertyValue),
      this.formValidationService.isLowerThanMin(this.minPropertyValue),
      this.formValidationService.isGreaterThanMax(this.maxPropertyValue)
    ]);

    evaluationPrice.setValidators([
      Validators.required,
      this.formValidationService.isValidEvaluationPrice(this.minEvaluationValue),
      this.formValidationService.isGreaterThanMax(this.maxEvaluationValue)
    ]);

    loanValueField.setValidators([
      Validators.required,
      this.formValidationService.isValidPropertyValue(this.minLoanAmountValue, this.maxLoanAmountValue),
      this.formValidationService.isLowerThanMin(this.minLoanAmountValue),
      this.formValidationService.isGreaterThanMax(this.maxLoanAmountValue)
    ]);

    termInYearsField.setValidators([
      Validators.required,
      this.formValidationService.isLowerThanMin(this.minTermInYears),
      this.formValidationService.isGreaterThanMax(this.maxTermInYears),
      this.formValidationService.isValidFinanceTerm(this.minTermInYears, this.maxTermInYears)
    ]);

    if (+loanTypeKey.value === 55) {
      isAppraisalfeesInClosingCostCredit.clearValidators();
      isAppraisalfeesInClosingCostCredit.updateValueAndValidity();
    }

    /** Update values to match max value coming from parameteres */
    this.updateProposalDataValue(propertyValueField, this.maxPropertyValue, 'propertyValue');
    this.updateProposalDataValue(evaluationPrice, this.maxEvaluationValue, 'evaluationPrice');
    this.updateProposalDataValue(loanValueField, this.maxLoanAmountValue, 'loanAmountValue');
    this.updateProposalDataValue(termInYearsField, this.maxTermInYears, 'termInYears');

    Object.keys(group.controls).forEach(key => {
      group.get(key).updateValueAndValidity();
    });
  }

  onFieldChanged(payload): void {
    this.changes = this.getFormStateDiff(this.proposal, { [payload.controlName]: payload.value });

    Object.keys(this.changes).forEach(c =>
      this.fieldChanged({ field: c, value: this.changes[c], isProposalFilled: false })
    );

  }

  /**
   * Detects changes on form fields and update store
   */
  fieldChanged({ field, value, isProposalFilled }): void {
    const data = this.formProposalData.getRawValue();

    if (data.loanTypeKey) {
      this.store$.dispatch(
        new fromParameters.actions.GetPropertyRangeProposal({
          objetivoFinanciamento: data.loanTypeKey,
          tipoUtilizacaoImovel: data.buildingTypeKey,
          codigoDistrito: data.propertyLocationKey,
          portabilityTypeKey: data.portabilityTypeKey
        })
      );
    }

    this.store$.dispatch(new fromProposal.actions.FieldChanged({ field, value }));
    this.updateProposalValues(field, value, isProposalFilled);

    this.validFormTarifaAvaliacaoGarantia(field, value);
  }

  focusOutEvaluationValue() {
    this.updateProposalValidation();
  }

  /**
   * Updates proposal values depending on fieldChange
   */
  updateProposalValues(field: string, value: any, isProposalFilled: boolean): void {
    /** Set Months Deferred Payment Quantity to null when changing deferredPaymentKey */
    if (field === 'deferredPaymentKey') {
      this.onFieldChanged({ controlName: 'monthsDeferredPaymentQt', value: null });
    }

    if (field === 'buildingTypeKey' || field === 'loanTypeKey') {
      this.fgChangeMaxLoanAmount = true;
    } else if (field === 'propertyValue') {
      this.fgChangeMaxLoanAmount = false;
    }

    if (this.formProposalData.get('propertyValue').value >= this.minPropertyValue) {
      this.propertyValueChanged();
    }

    if (field === 'buildingTypeKey' || field === 'propertyLocationKey' || field === 'loanTypeKey') {
      this.propertyLocationChanged(isProposalFilled);
      return;
    }

    if (this.minPropertyValue && field === 'propertyValue' && value < this.minPropertyValue) {
      return;
    }

    if (!isProposalFilled && this.inputsRadio.includes(field) && this.formProposalData.get(field).value === true) {
      this.updateLoanAmountValueFieldState(this.inputsRadio);
    }



    this.updateProposalValidation();
  }

  changeOnMaxValue() {
    const auxLoanAmountValue = this.formProposalData.get('loanAmountValue').value;

    if (
      this.fgChangeMaxLoanAmount &&
      this.oldMaxLoanAmount &&
      this.oldMaxLoanAmount !== this.maxLoanAmountValue &&
      auxLoanAmountValue < this.maxLoanAmountValue &&
      auxLoanAmountValue === this.oldMaxLoanAmount
    ) {
      this.showModalLoanAmountValueImpact();
      this.formProposalData.get('loanAmountValue').setValue(this.maxLoanAmountValue);
      this.fgChangeMaxLoanAmount = false;
    }
  }

  /**
   * Mark Loan Amount Value field as Dirty or Pristine depending on other fields
   *
   * @private
   * @param {string[]} formKeys
   * @memberof ProposalDataContainerComponent
   */
  private updateLoanAmountValueFieldState(formKeys: string[]): void {
    const loanAmountValueField = this.formProposalData.get('loanAmountValue');
    const loanAmountValueState =
      loanAmountValueField.dirty || formKeys.some(key => this.formProposalData.get(key).dirty)
        ? 'markAsDirty'
        : 'markAsPristine';

    loanAmountValueField[loanAmountValueState]();
  }

  /**
   * Detects changes on property location
   */

  propertyLocationChanged(formData = this.formProposalData.getRawValue()) {
    this.store$.dispatch(
      new fromParameters.actions.GetPropertyRange({
        input: {
          loanTypeKey: formData.loanTypeKey,
          propertyLocationKey: formData.propertyLocationKey,
          buildingTypeKey: formData.buildingTypeKey
        }
      })
    );
  }

  /**
   * Detects changes on property value
   */
  propertyValueChanged() {
    const loanTypeKey = this.formProposalData.get('loanTypeKey').value;
    const buildingTypeKey = this.formProposalData.get('buildingTypeKey').value;
    const propertyValue = this.formProposalData.get('propertyValue').value;
    if (!loanTypeKey || !buildingTypeKey || !propertyValue) return;
    this.store$.dispatch(
      new fromParameters.actions.GetProposalRange({ input: this.createInputSaveProposalDataPayload() })
    );
  }

  clearValidatorsIsencaoUseCasaEmprestimo() {
    if (this.isencaoTarifaUseCasa) {
      this.formProposalData.get('isAppraisalfeesInClosingCostCredit').clearValidators();
      this.formProposalData.get('isAppraisalfeesInClosingCostCredit').updateValueAndValidity();
    } else {
      this.formProposalData.get('isAppraisalfeesInClosingCostCredit').setValidators(Validators.required);
    }
  }

  /**
   * Dispatch Send Personal Data action
   */
  submitFormProposalData(): void {
    const { loanTypeKey } = this.formProposalData.getRawValue();
    const product = this.loanObjectives.find(l => l.loanObjectiveId === loanTypeKey).loanObjectiveBusinessName;
    this.dataLayerService.sendCustomToDataLayer({ produto: product });
    this.clearValidatorsIsencaoUseCasaEmprestimo();

    this.subscription.add(
      this.store$.pipe(select(fromProposal.selectors.selectSaveFinancingObjective)).subscribe(state => {
        if (!state) return;
        this.loanTypeKey = state.goal.toString();
        this.typePortability = state.type;
        const goal = this.formProposalData.get('loanTypeKey').value.toString();
        const type = this.formProposalData.get('portabilityTypeKey').value;

        if (this.loanTypeKey !== goal) {
          this.loanTypeKey = Number(goal);
          this.storageEncryptService.setSessionStore('goal', goal);
        }

        if (type) {
          this.storageEncryptService.setSessionStore('type', type);
        }

        if ((goal !== '46' || this.loanTypeKey !== '46') && (type || this.typePortability)) {
          this.storageEncryptService.removeSession('type');
        }

        // this.storageEncryptService.removeSession('idSimulation');
      })
    );

    if (this.formProposalData.valid) {
      this.store$.dispatch(new fromProposal.actions.SetIsLoggedSecondScreen(false))
      return this.store$.dispatch(new fromProposal.actions.Send({ input: this.createInputSaveProposalDataPayload() }));
    }

    this.formValidationService.validateAllFormFields(this.formProposalData as FormGroup);
    const invalidFields = this.formValidationService.getInvalidFields(this.formProposalData);
    if (invalidFields.length) this.showValidationErrorsModal(invalidFields);
  }

  /**
   * Create variables payload for type InputSaveProposalData
   *
   * @param {*} [formData=this.formProposalData.getRawValue()]
   * @returns {InputSaveProposalData}
   * @memberof ProposalDataContainerComponent
   */
  createInputSaveProposalDataPayload(formData = this.formProposalData.getRawValue()): InputSaveProposalData {
    const termInMonths = formData.termInYears * 12;

    return {
      selectedProposalOptions: {
        channel: 'PC',
        loanTypeKey: formData.loanTypeKey,
        buildingTypeKey: formData.buildingTypeKey,
        propertyLocationKey: formData.propertyLocationKey,
        portabilityTypeKey: formData.portabilityTypeKey,
        contractCodeRenovado: formData.codigoContrato || null
      },
      simulationEntryValues: {
        propertyValue: formData.propertyValue,
        evaluationValue: formData.evaluationPrice || 0,
        loanAmountValue: formData.loanAmountValue || 0,
        isPropertyNew: formData.isPropertyNew,
        termInMonths: termInMonths || 0,
        termInYears: parseInt(formData.termInYears, 10) || 0,
        monthsDeferredPaymentQt: formData.buildingTypeKey === 'R' ? formData.monthsDeferredPaymentQt : 0,
        downPaymentAmount: 0,
        fgtsAmount: 0
      },
      isAppraisalfeesInClosingCostCredit: formData.isAppraisalfeesInClosingCostCredit || false,
      isIOFIncludedInClosingCostCredit: formData.isIOFIncludedInClosingCostCredit || false,
      isTransferTaxInClosingCostCredit: formData.isTransferTaxInClosingCostCredit || false,
      deferredPaymentKey: formData.buildingTypeKey === 'R' ? formData.deferredPaymentKey : '',
      quizResponseCode: formData.quizResponseCode,
      expensesValue: 0
    };
  }

  /**
   * Show invalid fields modal if it exists
   */
  showValidationErrorsModal(invalidFields: Array<any>) {
    this.modal.next({
      show: true,
      template: ModalAlertComponent,
      content: Object({
        title: 'common.attention',
        message: this.errorService.handleError('msgErrorSIFSIMB01_E0005'),
        list: invalidFields.forEach(f => `common.${f}`)
      }),
      closed: () => '',
      back: () => ''
    });
  }

  private updateProposalDataValue(field: AbstractControl, maxAmountValue: number, fieldName: string): void {
    if (!field.value || !maxAmountValue || field.value <= maxAmountValue || field.pristine) return;

    this.showModalLoanAmountValueImpact();
    field.setValue(maxAmountValue);

    const payload = {
      controlName: fieldName,
      value: maxAmountValue
    }
    this.onFieldChanged(payload);

    if (this.proposal.loanAmountValue !== this.formProposalData.controls[`loanAmountValue`].value) {
      setTimeout(() => {
        const payload = {
          controlName: 'loanAmountValue',
          value: this.formProposalData.controls[`loanAmountValue`].value
        }
        this.onFieldChanged(payload);
      }, 500);
    }
  }

  private showModalLoanAmountValueImpact(): void {
    if (!this.modalLoanAmountValueImpactVisible) {
      this.modalLoanAmountValueImpactVisible = true;

      this.modal.next({
        show: true,
        template: ModalAlertComponent,
        content: Object({
          title: 'common.attention',
          message:
            'modules.simulation.result.you changed information that impact on' +
            ' the financed value requested. Because of that, check the new conditions of the financed value before advance'
        }),
        closed: () => {
          this.modalLoanAmountValueImpactVisible = false;
        },
        back: () => ''
      });
    }
  }

  /**
   * Dispatch action to go to previous page
   */
  back() {
    this.router.navigate(['/dados-pessoais']);
  }

  /**
   * Get form state difference between an old state object and a new state object
   */
  private getFormStateDiff(oldState, newState) {
    const changes = {};

    for (const key in newState) {
      if (oldState[key] !== newState[key]) {
        changes[key] = newState[key];
      }
    }

    return changes;
  }

  sendPageView(url, customUrl) {
    this.dataLayerSantander.sendPageViewToDataLayer(
      url,
      customUrl
    );
  }
}
