import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { FormValidationService } from '@shared/services/form-validation.service';
import { AppState } from '@app/state';
import { Store, select } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { ConsultProposalService } from './consult-proposal.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { StorageEncryptService } from '@shared/services/storageEncrypt.service';
import { ModalTemplateComponent } from '@shared/widgets/modal-template/modal-template.component';
import { ModalAlertComponent } from '@shared/widgets/modal-alert/modal-alert.component';
import { ErrorService } from '@shared/services/error.service';
import { TranslateService } from '@ngx-translate/core';

import * as fromConsult from '@app/state/consult'
import * as fromLogin from '@app/state/login'
import * as fromErrorHandling from '@app/state/error-handling';
import { environment } from 'src/environments/environment';
declare const DLECC: any;

@Component({
  selector: 'app-consult-proposal',
  templateUrl: './consult-proposal.component.html',
  styleUrls: ['./consult-proposal.component.scss']
})
export class ConsultProposalComponent implements OnInit, OnDestroy {

  pathImageClient = environment.pathClientAssets;
  siteKey = environment.recaptcha;
  recaptchaOn = environment.featureToggle.recaptchaOn === 'false' ? false : true;
  useMockLogin = environment.featureToggle.useMockLogin === 'false' ? false : true;

  modal = new BehaviorSubject({
    show: false,
    template: ModalTemplateComponent,
    content: Object,
    closed: () => {
      return;
    }
  });

  urlLoginIframe: SafeUrl;
  clientPublicKey: string;
  parametrosLogin: {
    name: string;
    cpf: string;
    phone: string;
    urlBase: string;
    urlSuccess: string;
    urlError: string;
    ticket: string;
  };

  formConsultProposal: FormGroup;
  token: string;
  typeClient: string;
  isLogin = false;
  isNotCliente = false;

  private subscription: Subscription = new Subscription();

  public formLogin = this.formBuilder.group({
    name: [null],
    password: [null]
  });

  constructor(private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private store$: Store<AppState>,
    private formValidationsService: FormValidationService,
    private store: Store<AppState>,
    private errorService: ErrorService,
    private route: ActivatedRoute,
    private storageEncryptService: StorageEncryptService,
    private consultProposalService: ConsultProposalService,

  ) {
    this.translateService.addLangs(['en', 'pt']);
    this.translateService.setDefaultLang('en');
    this.translateService.use('pt');
  }

  ngOnInit() {
    this.clientPublicKey = DLECC.init();
    this.integration()

    this.createForms();
    this.subscribes();
    this.isTokenSantander();
    this.resetScreen();
  }

  integration() {
    if (this.storageEncryptService.getSessionStore('userData') === null) {
      return this.store.dispatch(new fromLogin.actions.IntegrationPublic());
    }
  }


  createForms() {
    this.createFormProposalData();
  }

  subscribes() {
    this.subscribeToBorrowerVerification();
    this.subscribeToErrorsPC();
    this.subscribeToLogin();
  }

  resetScreen() {
    this.isNotCliente = false;
    this.isLogin = false;
    this.formConsultProposal.reset();
    this.formLogin.reset();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  subscribeToErrorsPC() {
    this.subscription.add(
      this.store.pipe(select(fromErrorHandling.selectors.selectErrorMessage)).subscribe(state => {
        if (!state || state === '') return;

        this.modal.next({
          show: true,
          template: ModalAlertComponent,
          content: Object({
            title: 'common.attention',
            message: this.errorService.handleError(state)
          }),
          closed: () => {
            this.store.dispatch(new fromErrorHandling.actions.ClearError());
            this.resetScreen();
          }
        });

        this.isNotCliente = true;
      })
    );
  }

  isTokenSantander() {
    this.route.queryParams.subscribe(params => {
      this.storageEncryptService.setSessionStore('isnewArch', 'true');
      const token = 'token';
      const typeClient = 'typeClient';
      if (params[token]) {
        this.token = decodeURIComponent(params[token]).replace(/\s/g, '+');
        this.typeClient = params[typeClient];
        this.storageEncryptService.setSessionStore('tokenSantander', this.token);

        /* Obter client credencials */
        const cpf = JSON.parse(this.storageEncryptService.getSessionStore('documentNumber'));
        const userData = JSON.parse(this.storageEncryptService.getSessionStore('userData'));
        this.consultProposalService
          .tokenOpenFinancial(userData.access_token, this.token, cpf.toString(), this.typeClient, environment.clientID)
          .subscribe(response => {
            this.storageEncryptService.setSessionStore('userData', JSON.stringify(response));
            this.borrowerVerification();
          });
      }
    });
  }

  /**
  * Creates proposal data form
  */
  createFormProposalData(): void {
    this.formConsultProposal = this.formBuilder.group({
      documentNumber: [null, [Validators.required, this.formValidationsService.isCpfValid]],
      telephoneNumber: [null, [Validators.required, Validators.pattern(this.formValidationsService.getPatternPhone())]],
    });

    if (this.recaptchaOn) {
      this.formConsultProposal.addControl('recaptcha', new FormControl('', Validators.required));
    }
  }

  public goToLogin() {
    if (!this.formConsultProposal.controls.documentNumber.value && !this.formConsultProposal.controls.telephoneNumber.value) {
      return;
    }
    if (this.formConsultProposal.controls.documentNumber.invalid || this.formConsultProposal.controls.telephoneNumber.invalid) {
      return;
    }
    if (this.recaptchaOn && (!this.formConsultProposal.controls.recaptcha.value || this.formConsultProposal.controls.recaptcha.invalid)) {
      return;
    }
    this.isLogin = true;
    this.storageEncryptService.setSessionStore('documentNumber', JSON.stringify(this.formConsultProposal.controls.documentNumber.value));

    if (!this.useMockLogin) {
      this.getTicketIframe();
    }
  }

  /*
* To generate an url string to call Login Santander Iframe
*/
  private getTicketIframe() {
    this.consultProposalService.getTicket(this.clientPublicKey).subscribe(resTicket => {
      this.parametrosLogin = {
        name: 'CLIENTE',
        cpf: this.formConsultProposal.get('documentNumber').value,
        phone: this.formConsultProposal.get('telephoneNumber').value,
        urlBase: environment.urlNovaSimulacaoCliente,
        urlSuccess: `${environment.urlNovaSimulacaoCliente}#/consult-proposal`.replace(/\/\/$/gm, '/'),
        urlError: `${environment.urlNovaSimulacaoCliente}#/session-expired`.replace(/\/\/$/gm, '/'),
        ticket: resTicket.ticket
      };

      this.urlLoginIframe = this.consultProposalService.santanderLogin(
        this.parametrosLogin,
        resTicket.serverPublicKey,
        's'
      );
    });
  }

  login() {
    this.store$.dispatch(new fromLogin.actions
      .LoginWithoutSSO(
        {
          nome: this.formLogin.controls.name.value,
          senha: this.formLogin.controls.password.value
        })
    );
  }

  subscribeToLogin() {
    this.subscription.add(
      this.store$.select(fromLogin.selectors.selectLoginSucess).subscribe(state => {
        if (state.isLogged) {
          this.store$.dispatch(new fromLogin.actions.ResetLoginState());
          this.borrowerVerification();
        }
      })
    );
  }


  borrowerVerification() {
    const cpf = JSON.parse(this.storageEncryptService.getSessionStore('documentNumber'));
    if (cpf) {
      this.store$.dispatch(new fromConsult.actions.BorrowerVerification(
        {
          documentnumber: cpf.toString()
        })
      );
    }
  }

  subscribeToBorrowerVerification() {
    let documentNumber = JSON.parse(this.storageEncryptService.getSessionStore('documentNumber'));
    this.subscription.add(
      this.store$.select(fromConsult.selectors.selectBorrower).subscribe(state => {
        if (state.borrowerData) {
          this.storageEncryptService.setSessionStore('isFromSimulation', JSON.stringify(true));
          this.storageEncryptService.setSessionStore('consultProposal', JSON.stringify(true));
          this.storageEncryptService.setSessionStore('usuarioLogado', JSON.stringify({ cpf: documentNumber, nome: state.borrowerData.name, telefone: state.borrowerData.telephoneNumber }));
          return (window.location.href = `${environment.urlPortalCliente}/#/acompanhamento/lista-propostas`);
        }
      })
    );
  }
}
