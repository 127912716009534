<div class="offset-menu d-block d-sm-none" [ngClass]="{ active: isActive }">
  <ul>
    <li>
      <a (click)="logout(false)">
        <span>Sair</span>
      </a>
    </li>
    <li>
      <a (click)="logout(true)">
        <span>Nova simulação</span>
      </a>
    </li>
    <li>
      <a
        href="https://abrasuaconta.santander.com.br/#/cpf?utm_source=cross-sell-webcasas&utm_medium=lp&utm_campaign=cross-sell-webcasas-lp&cp=98&sp=1"
        (click)="toggleMenu()"
        target="_blank"
      >
        <span>Abra sua conta</span>
      </a>
    </li>
    <li>
      <a title="Atendimento ao cliente" class="item">
        <span>Atendimento ao cliente</span>
      </a>
    </li>
  </ul>
</div>
