import { ActionReducerMap } from '@ngrx/store';

import * as fromParticipants from './participants/participants.reducer';
import * as fromParameters from './parameters/parameters.reducer';
import * as fromProposal from './proposal/proposal.reducer';
import * as fromHelpers from './helpers/helpers.reducer';
import * as fromSimulation from './simulation/simulation.reducer';
import * as fromErrorHandling from './error-handling/error-handling.reducer';
import * as fromParametrosPerfil from './parametros-perfil';
import * as fromLogin from './login';
import * as fromConsult from './consult/consult.reducer';


export interface AppState {
  parametrosPerfil: fromParametrosPerfil.reducer.ParametrosPerfilState;
  login: fromLogin.state.LoginState;
  participants: fromParticipants.ParticipantsState;
  parameters: fromParameters.ParametersState;
  helpers: fromHelpers.HelpersState;
  proposal: fromProposal.ProposalState;
  errorHandling: fromErrorHandling.ErrorState;
  simulation: fromSimulation.ResumoPropostaState;
  consult: fromConsult.ConsultProposallState
}

export const reducers: ActionReducerMap<any> = {
  participants: fromParticipants.participantsReducer,
  parameters: fromParameters.parametersReducer,
  proposal: fromProposal.proposalReducer,
  helpers: fromHelpers.helpersReducer,
  errorHandling: fromErrorHandling.errorHandlingReducer,
  simulation: fromSimulation.simulationReducer,
  parametrosPerfil: fromParametrosPerfil.reducer.ParametrosPerfilReducer,
  login: fromLogin.reducer.loginReducer,
  consult: fromConsult.consultReducer
};

export const initialState = {
  participants: fromParticipants.initialState,
  parameters: fromParameters.initialState,
  proposal: fromProposal.initialState,
  helpers: fromHelpers.initialState,
  errorHandling: fromErrorHandling.initialState,
  simulation: fromSimulation.initialState,
  parametrosPerfil: fromParametrosPerfil.reducer.initialState,
  login: fromLogin.state.initialState,
  consult: fromConsult.initialState
};
