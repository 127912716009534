<div>
  <label [ngClass]="{ 'label-weight': !isLabelStrong }">{{ label }} </label>
  <app-helper-button appHelperTooltip *ngIf="helperName" [helperName]="helperName" (click)="clickedTooltip()"></app-helper-button>
  <a *ngIf="isEditable" (click)="onClickEdit()"class="edit-button"><fa-icon [icon]="faEdit"></fa-icon></a>

  <div class="input-wrapper">
    <ng-content></ng-content>
    <span *ngIf="hasSuccess() || (hasError() && input.touched)" class="help-block">
      <fa-icon [icon]="faCheck" *ngIf="hasSuccess()" class="text-success"></fa-icon>
      <fa-icon [icon]="faTimes" *ngIf="hasError()" class="text-danger"></fa-icon>
    </span>
  </div>

  <app-warning-min-max
    *ngIf="
      ((minValue !== undefined && minValue !== null) || (maxValue !== undefined && maxValue !== null)) &&
      (!touchedOnly || (touchedOnly && input.touched))
    "
    [isInvalidMin]="isInvalidMin()"
    [isInvalidMax]="isInvalidMax()"
    [minValue]="minValue"
    [maxValue]="maxValue"
    [labelMinValue]="labelMinValue"
    [labelMaxValue]="labelMaxValue"
    [formatMinMax]="formatMinMax"
    [isUseCasaMais]="isUseCasaMais"
  >
  </app-warning-min-max>

  <app-field-error-display
    *ngIf="
      this.input.touched &&
      this.input.errors &&
      this.input.errors.required &&
      (this.minValue === undefined || !this.maxValue === undefined)
    "
    [errorMsg]="this.validErrorMsg()"
    [isInvalid]="hasError()"
  >
  </app-field-error-display>

  <app-field-error-display
    *ngIf="input.errors && !input.errors.required"
    [errorMsg]="customErrorMessage"
    [isInvalid]="hasError()"
  >
  </app-field-error-display>
</div>
