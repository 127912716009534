import { ActionReducerMap } from '@ngrx/store';

import { LoadingState } from '@app/shared/widgets/loading/store/loading.state';
import { LoadingActionTypes, LoadingAction } from './loading.actions';

export function ongoingRequestsReducer(state = {}, action: LoadingAction) {
  switch (action.type) {
    case LoadingActionTypes.ADD_REQUEST:
      return {
        ...state,
        [action.payload.id]: action.payload.request
      };

    case LoadingActionTypes.REMOVE_REQUEST:
      const newState = { ...state };
      delete newState[action.payload.id];
      return newState;

    case LoadingActionTypes.SHOW:
      const randId = Math.floor(Math.random() * 100) + 1;
      return {
        ...state,
        [randId]: {}
      };

    case LoadingActionTypes.DISMISS:
      return {};

    default:
      return state;
  }
}

/**
 * Reducer Map
 */

export const reducerMap: ActionReducerMap<LoadingState> = {
  ongoingRequests: ongoingRequestsReducer
};
