import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrencyPipe } from './currency-pipe';
import { PercentPipe } from './percent-pipe';
import { InterestRatePipe } from './interest-rate-pipe';
import { BoldPipe } from './bold.pipe';
import { CepFormatPipe } from './cepFormat.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [CurrencyPipe, PercentPipe, InterestRatePipe, BoldPipe, CepFormatPipe],
  exports: [CurrencyPipe, PercentPipe, InterestRatePipe, BoldPipe, CepFormatPipe]
})
export class PipesModule {}
