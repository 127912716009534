<div class="formulario pb-5">
  <div class="container">
    <form action="" [formGroup]="formProponentData">
      <fieldset>
        <div class="form-row">
          <div class="form-group col text-uppercase">
            <!-- Name -->
            <app-input-text-container [label]="'common.name' | translate">
              <input class="text-uppercase" type="text" id="nome" formControlName="name" />
            </app-input-text-container>
          </div>
        </div>
      </fieldset>
      <fieldset>
        <div class="form-row">
          <div class="form-group col">
            <!-- Email -->
            <app-input-text-container
              [label]="'common.email' | translate"
              [customErrorMessage]="'common.invalid e-mail' | translate"
            >
              <input type="text" id="email" placeholder="nome@email.com" formControlName="email" />
            </app-input-text-container>
          </div>
        </div>
      </fieldset>
      <fieldset>
        <div class="form-row">
          <div class="form-group col">
            <!-- Phone -->
            <app-input-text-container
              [label]="'common.phone' | translate"
              [customErrorMessage]="'common.invalid phone number' | translate"
            >
              <input type="text" id="telefone" placeholder="( )" formControlName="phone" mask="(00) 00000-0009" />
            </app-input-text-container>
          </div>
        </div>
      </fieldset>
    </form>
  </div>
</div>
