import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./simulation/simulation.module').then(m => m.SimulationModule)
  },
  {
    path: 'session-expired',
    loadChildren: () => import('./session-expired/session-expired.module').then(m => m.SessionExpiredModule)
  },
  {
    path: 'consult-proposal',
    loadChildren: () => import('./consult-proposal/consult-proposal.module').then(m => m.ConsultProposalModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
