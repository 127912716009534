import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

declare const DLECC: any;

declare global {
  interface Window {
    dataLayer: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class DataLayerService {
  constructor() { }

  /**
   * Mapa de "customUrl" para enviar ao Google Analytics
   */
  pageViewNames = {
    home: '/funil/credito/webcasas/home',
    'dados-pessoais': '/funil/credito/webcasas/simulacao-dados-pessoais',
    'dados-proposta': '/funil/credito/webcasas/simulacao-dados-da-proposta',
    'resultado-simulacao': '/funil/credito/webcasas/simulacao-resultado',
    cadastro: '/funil/credito/negocios-imobiliarios/analise-tecnica/cadastro',
    'cadastro/imovel/endereco': '/funil/credito/negocios-imobiliarios/cadastro-imovel/endereco',
    'cadastro/imovel/vistoria': '/funil/credito/negocios-imobiliarios/cadastro-imovel/vistoria',
    'cadastro/imovel/resumo': '/funil/credito/negocios-imobiliarios/cadastro-imovel/resumo',
    'cadastro/comprador/dados-basicos': '/funil/credito/negocios-imobiliarios/cadastro-comprador/dados-basicos',
    'cadastro/comprador/estado-civil': '/funil/credito/negocios-imobiliarios/cadastro-comprador/estado-civil',
    'cadastro/comprador/documento-identificacao': '/funil/credito/negocios-imobiliarios/cadastro-comprador/documento',
    'cadastro/comprador/informacoes-adicionais':
      '/funil/credito/negocios-imobiliarios/cadastro-comprador/info-adicional',
    'cadastro/comprador/endereco': '/funil/credito/negocios-imobiliarios/cadastro-comprador/endereco',
    'cadastro/comprador/identificacao-contas': '/funil/credito/negocios-imobiliarios/cadastro-comprador/conta',
    'cadastro/comprador/resumo-comprador': '/funil/credito/negocios-imobiliarios/cadastro-comprador/resumo',
    'cadastro/vendedor/cpf-cnpj': '/funil/credito/negocios-imobiliarios/cadastro-vendedor/cpf-cnpj',
    'cadastro/vendedor/dados-basicos-pf': '/funil/credito/negocios-imobiliarios/cadastro-vendedor/dados-basicos-pf',
    'cadastro/vendedor/estado-civil-pf': '/funil/credito/negocios-imobiliarios/cadastro-vendedor/estado-civil-pf',
    'cadastro/vendedor/documento-identificacao-pf':
      '/funil/credito/negocios-imobiliarios/cadastro-vendedor/documento-pf',
    'cadastro/vendedor/informacoes-adicionais-pf':
      '/funil/credito/negocios-imobiliarios/cadastro-vendedor/info-adicional-pf',
    'cadastro/vendedor/endereco-pf': '/funil/credito/negocios-imobiliarios/cadastro-vendedor/endereco-pf',
    'cadastro/vendedor/dados-basicos-pj': '/funil/credito/negocios-imobiliarios/cadastro-vendedor/dados-basicos-pj',
    'cadastro/vendedor/endereco-pj': '/funil/credito/negocios-imobiliarios/cadastro-vendedor/endereco-pj',
    'cadastro/vendedor/resumo': '/funil/credito/negocios-imobiliarios/cadastro-vendedor/resumo',
    'cadastro/vendedor/dados-conta': '/funil/credito/negocios-imobiliarios/cadastro-vendedor/conta',
    'cadastro/vendedor/resumo-contas': '/funil/credito/negocios-imobiliarios/cadastro-vendedor/resumo-conta',
    'acompanhamento/detalhes/abra-sua-conta': '/funil/credito/negocios-imobiliarios/analise-tecnica/abertura-de-conta',
    'interveniente-quitante': '/funil/credito/negocios-imobiliarios/analise-tecnica/interveniente-quitante',
    seguradora: '/funil/credito/negocios-imobiliarios/analise-tecnica/seguradora',
    'acompanhamento/detalhes/envio-documentos': '/funil/credito/negocios-imobiliarios/envio-documentos',
    'acompanhamento/detalhes/envio-documentos-imovel': '/funil/credito/negocios-imobiliarios/envio-documentos/imovel',
    'acompanhamento/detalhes/envio-documentos-compradores':
      '/funil/credito/negocios-imobiliarios/envio-documentos/compradores',
    'acompanhamento/detalhes/envio-documentos-vendedores':
      '/funil/credito/negocios-imobiliarios/envio-documentos/vendedores',
    vistoria: '/funil/credito/negocios-imobiliarios/vistoria',
    'acompanhamento/detalhes/analise-documentos': '/funil/credito/negocios-imobiliarios/analise-documentos',
    'acompanhamento/detalhes/participantes':
      '/funil/credito/negocios-imobiliarios/analise-documentos/selecionar-vendedores',
    'acompanhamento/detalhes/confirma-valores': '/funil/credito/negocios-imobiliarios/contrato/conferir-valores',
    'contrato-envio-documentos-agrupadores': '/funil/credito/negocios-imobiliarios/contrato/ajuste-do-contrato',
    fgts: '/funil/credito/negocios-imobiliarios/contrato/fgts',
    assinaturas: '/funil/credito/negocios-imobiliarios/contrato/assinatura',
    'envio-via-assinada': '/funil/credito/negocios-imobiliarios/contrato/envio-via-assinada',
    'acompanhamento/lista-propostas': '/funil/credito/negocios-imobiliarios/acompanhamento/lista-proposta',
    'acompanhamento/proposta': '/funil/credito/negocios-imobiliarios/acompanhamento/etapas',
    'acompanhamento/detalhes/dados-simulacao': '/funil/credito/negocios-imobiliarios/acompanhamento/simulacao'
  };

  /**
   * Mapa de acentos
   */
  map = {
    â: 'a',
    Â: 'A',
    à: 'a',
    À: 'A',
    á: 'a',
    Á: 'A',
    ã: 'a',
    Ã: 'A',
    ê: 'e',
    Ê: 'E',
    è: 'e',
    È: 'E',
    é: 'e',
    É: 'E',
    î: 'i',
    Î: 'I',
    ì: 'i',
    Ì: 'I',
    í: 'i',
    Í: 'I',
    õ: 'o',
    Õ: 'O',
    ô: 'o',
    Ô: 'O',
    ò: 'o',
    Ò: 'O',
    ó: 'o',
    Ó: 'O',
    ü: 'u',
    Ü: 'U',
    û: 'u',
    Û: 'U',
    ú: 'u',
    Ú: 'U',
    ù: 'u',
    Ù: 'U',
    ç: 'c',
    Ç: 'C'
  };

  /**
   * Inclui dados que serão enviados para o dataLayer
   * @param {String} event
   * @param {String} category
   * @param {String} value
   * @param {String} label
   * @returns {Void}
   */
  sendToDataLayer(event, category, value, label) {
    window.dataLayer.push({
      event: event ? event : 'update',
      category: category ? category : null,
      action: value ? value : null,
      label: label ? this.generateInteractionPayload(label) : null
    });
  }

  /**
   * Inclui dados que serão enviados como pageView o dataLayer
   * @param {String} url
   * @param {String} goal
   * @param {String} customUrl
   * @param {String} title
   * @param {String} error
   * @param {Boolean} forceCustomUrl
   * @returns {Void}
   */
  sendPageViewToDataLayer(url, goal?, customUrl?, title?, error?, forceCustomUrl?) {
    const page = this.generatePagePayload(url, customUrl, title, error, goal, forceCustomUrl);

    if (!page.customPath) {
      return false;
    }

    const payload = { page, event: 'update' };

    window.dataLayer.push(payload);
  }

  /**
   * Inclui dados que serão enviados como Erro
   * @param {String} url
   * @param {String} customUrl
   * @returns {Void}
   */
  sendErrorToDataLayer = function (url, customUrl, error) {
    const payload = {
      page: this.dataLayerFactory.generatePagePayload(url, customUrl, null, error),
      event: 'interactions',
      category: 'erro',
      action: 'exibiu',
      label: this.dataLayerFactory.getEndpoint(url)
    };

    window.dataLayer.push(payload);
  };

  /**
   * Envia um objeto customizado para o DataLayer
   * @param {Object} prop
   */
  sendCustomToDataLayer(prop) {
    window.dataLayer.push(prop);
  }

  /**
   * função responsável por gerar o payload de page-view
   * @param {string} url
   * @param {string} customUrl
   * @param {string} title
   * @param {string} error
   * @param {string} goal
   * @param {boolean} forceCustomUrl
   * @returns {Object}
   */
  generatePagePayload(url, customUrl, title, error, goal, forceCustomUrl) {
    const matches = url.match(/^(?:https?:)?(?:\/\/)?([^\/\?]+)/i);
    const domain = matches && matches[1];

    /**
     * Verificação da flag forceCustomUrl é necessária pois tem algumas urls que exitem no objeto com
     * pageViewName porém são múltiplas telas na mesma url, o customPath enviado para o dataLayer tem
     * que ser diferente.
     */
    const payload = {
      title: '',
      error: '',
      originalPath: url.split('/')[1] || this.getEndpoint(url),
      customPath: forceCustomUrl ? customUrl : this.getPageViewName(url) || customUrl,
      homolog: this.isEndpointHomolog(url),
      domain,
      goal
    };

    /** Caso queira enviar um título customizado, utilizar esta flag */
    if (title && title !== null) {
      payload.title = title;
    } else {
      payload.title = 'Negócios Imobiliários';
    }

    /** Caso queira enviar um erro customizado, utilizar esta flag */
    if (error && error !== null) {
      payload.error = error;
    } else {
      payload.error = 'null';
    }

    return payload;
  }

  /**
   * Função responsável por obter o endPoint do serviço
   * @param {string} url
   */
  getEndpoint(url) {
    if (url.indexOf(environment.urlOrigClienteDef) !== -1) {
      return url.replace(environment.urlOrigClienteDef, '');
    }

    if (url.indexOf(environment.apiSantanderEndpoint) !== -1) {
      return url.replace(environment.apiSantanderEndpoint, '');
    }

    return url;
  }

  /**
   * Função responsável por obter o page-view name de acordo com o mapa declarado no início deste arquivo
   * e a rota onde a navegação ocorre.
   * @param {string} route
   * @returns {string}
   */
  getPageViewName(route) {
    const routeName = route.split('/')[1];

    if (this.pageViewNames.hasOwnProperty(routeName)) {
      return this.pageViewNames[routeName];
    }

    return '';
  }

  /**
   * Função responsável por gerar uma mensagem "slugfied"
   * @param {string} string
   * @returns {string}
   */
  generateInteractionPayload(value) {
    return value.toLowerCase().replace(/[\W\[\] ]/g, function (a) {
      return this.map[a] || a;
    });
  }

  /**
   * Função responsável por checar se o endpoint refere-se ao ambiente de homologação
   * @param {string} url
   * @returns {boolean}
   */
  isEndpointHomolog(url) {
    return url.indexOf('hml') !== -1;
  }
}
