import { Component, ViewChild, ElementRef, Renderer2, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from '@app/state';
import { Router, NavigationEnd } from '@angular/router';
import { filter, pairwise, takeWhile } from 'rxjs/operators';

import * as fromSimulation from '@app/state/simulation';
import * as fromHelpers from '@app/state/helpers';
import { AuthServices } from 'arch-onefinancial-auth.lib';

@Component({
  selector: 'app-simulation',
  templateUrl: './simulation.component.html',
  styleUrls: ['./simulation.component.scss']
})
export class SimulationComponent implements OnDestroy {
  @ViewChild('footer', { read: ElementRef, static: true }) footer: ElementRef;
  @ViewChild('chatFooter', { read: ElementRef, static: true }) chatFooter: ElementRef;

  title = 'cliente';
  isMenuActive = false;
  showMenuExit = false;

  private simulationInstance: boolean;
  private listener: any;

  constructor(
    private translateService: TranslateService,
    private store$: Store<AppState>,
    private renderer: Renderer2,
    private router: Router,
    private authServices: AuthServices
  ) {
    this.translateService.addLangs(['en', 'pt']);
    this.translateService.setDefaultLang('en');
    this.translateService.use('pt');

    this.showMenuExit = !window.location.href.includes('/home');
    this.simulationInstance = true;
    this.subscribeToRouteChanges();
    this.subscribeToScrollWindow();
  }

  ngOnDestroy() {
    this.listener();
    this.simulationInstance = false;
  }

  private subscribeToRouteChanges(): void {
    this.router.events
      .pipe(
        takeWhile(() => this.simulationInstance),
        filter(e => e instanceof NavigationEnd),
        pairwise()
      )
      .subscribe((e: any[]) => {
        if (!e || e.length < 1) return;

        const [previousTask, nextTask] = e;
        this.showMenuExit = !nextTask.url.includes('/home');
        this.store$.dispatch(new fromSimulation.actions.SetPreviousTask({ previousTask: previousTask.url }));
        this.store$.dispatch(new fromHelpers.actions.CloseHelper());
      });
  }

  private subscribeToScrollWindow(): void {
    this.listener = this.renderer.listen('window', 'scroll', () => {
      this.onWindowScroll();
    });
  }

  /* istanbul ignore next */
  onWindowScroll() {
    if (!this.chatFooter || !this.footer) return;

    const scrollH = window.pageYOffset;
    const windowH = window.innerHeight;
    const chat = this.getChatEl().getBoundingClientRect();
    const footer = this.getFooterEl().getBoundingClientRect();
    const visibleFooterH = Math.max(
      0,
      footer.top > 0 ? Math.min(footer.height, windowH - footer.top) : Math.min(footer.bottom, windowH)
    );

    if (scrollH >= footer.height - chat.height) {
      this.renderer.setStyle(this.getChatEl(), 'bottom', `${visibleFooterH}px`);
    } else {
      this.renderer.removeStyle(this.getChatEl(), 'bottom');
    }
  }

  /**
   * Get chat native element
   *
   * @private
   * @returns {*}
   * @memberof SimulationComponent
   */
  private getChatEl(): any {
    return this.chatFooter.nativeElement.firstElementChild;
  }

  /**
   * Get footer native element
   *
   * @private
   * @returns {*}
   * @memberof SimulationComponent
   */
  private getFooterEl(): any {
    return this.footer.nativeElement.firstElementChild;
  }

  onToggleMenu(): void {
    this.isMenuActive = !this.isMenuActive;
  }

  onLogout(newSimulation: boolean): void {
    // this.store$.dispatch(new fromLogin.actions.Logout());
    // this.loginService.logout().subscribe(() => {
    if (newSimulation) return;
    this.authServices.logout();
    window.location.href = environment.urlPortalCliente;
  }
}
