import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-template',
  templateUrl: './modal-template.component.html',
  styleUrls: ['./modal-template.component.scss']
})
export class ModalTemplateComponent {
  public data;
  private actionClosed: () => void;
  private actionBack: () => void;

  constructor(public activeModal: NgbActiveModal, public translate: TranslateService) {}

  public setData(data) {
    this.data = data;
  }

  public getData() {
    return this.data;
  }

  public setActionClosed(action: () => void) {
    this.actionClosed = action;
  }

  public setActionBack(action: () => void) {
    this.actionBack = action;
  }

  public closed() {
    this.activeModal.close();
    if (this.actionClosed) {
      this.actionClosed();
    }
  }

  public back() {
    this.activeModal.close();
    if (this.actionBack) {
      this.actionBack();
    }
  }

  
}
