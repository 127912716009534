/**
 * All user related data from login
 */
export interface UserData {
  access_token: string;
  refresh_token: string;
  user_name: string;
}

export const USER_DATA = 'userData';
