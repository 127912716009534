import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { UserData, USER_DATA } from './../models/user.model';
import { HISTORY } from './../models/integration.model';
import { environment } from 'src/environments/environment';
import { environment as environmentClient } from '../../../environments/environment';
import { StorageEncryptService } from '@shared/services/storageEncrypt.service';
import { AuthServices } from 'arch-onefinancial-auth.lib';
import * as AesJS from 'aes-js';

const httpOptions = {
  headers: new HttpHeaders({
    Authorization: `Basic ${environment.apiKeyApiSecret}`,
    'Content-Type': 'application/x-www-form-urlencoded'
  })
};

export interface TokenResponse {
  scope: string;
  token_type: string;
  expires_in: number;
  refresh_token: string;
  access_token: string;
}

const mockedResponse: TokenResponse = {
  scope: '',
  token_type: '',
  expires_in: 1,
  refresh_token: '',
  access_token: ''
};

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  readonly IV = this.convertToArray(environment.featureToggle.crypto.iv);
  readonly KEY = this.convertToArray(environment.featureToggle.crypto.key);
  urlCipher: string;
  useCrypto: boolean;

  constructor(
    private apollo: Apollo,
    private http: HttpClient,
    private storageEncryptService: StorageEncryptService,
    private authServices: AuthServices
  ) {}

  requestToken(body: HttpParams): Observable<TokenResponse> {
    const headers = new HttpHeaders({
      Authorization: `Basic ${environment.apiKeyApiSecret}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    return this.http.post<any>(environment.tokenApiRootUrl, body, { headers });
  }

  login({ name, password }): Observable<TokenResponse> {
    if (environment.tokenApiRootUrl != null && environment.tokenApiRootUrl.length > 0) {
      const body = new HttpParams()
        .set('grant_type', 'password')
        .set('username', name.toUpperCase())
        .set('password', password)
        .set('scope', 'ufs_view gruposeconomicos_view');

      return this.http.post<any>(environment.tokenApiRootUrl, body, httpOptions);
    } else {
      return of(mockedResponse);
    }
  }

  loginCipher({ name, password }): Observable<TokenResponse> {
    this.useCrypto = environment.featureToggle.crypto.active === 'true';
    this.urlCipher = `${environmentClient.baseUrl}cipher/token`;

    const body = new HttpParams()
      .set('grant_type', 'password')
      .set('username', this.useCrypto ? this.encrypt(name) : name)
      .set('password', this.useCrypto ? this.encrypt(password) : password)
      .set('scope', 'ufs_view gruposeconomicos_view');

    const headers = {
      headers: new HttpHeaders({
        Authorization: `Basic ${environment.apiKeyApiSecret}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };

    return this.http.post<any>(this.useCrypto ? this.urlCipher : environment.tokenApiRootUrl, body, headers);
  }

  loginPublic() {
    return this.authServices.loginPublicApis();
  }

  refresh(refreshToken: string): Observable<TokenResponse> {
    const body = new HttpParams().set('grant_type', 'refresh_token').set('refresh_token', refreshToken);
    return this.requestToken(body);
  }

  // logout() {
  //   return this.apollo
  //     .mutate({
  //       mutation: DELETA_SISTEMAS_CONTROLE_LOGOUT,
  //       errorPolicy: 'all',
  //       fetchPolicy: 'no-cache'
  //     })
  //     .pipe(
  //       map(r => {
  //         if (r.errors) throw r.errors[0];
  //         return r.data;
  //       }),
  //       catchError(err => throwError(err))
  //     );
  // }

  logoutRevoke() {
    const user = this.getUser();
    const body = new HttpParams().set('grant_type', 'password').set('token', user.access_token);

    return this.http.post<any>(environment.tokenRevoke, body, httpOptions);
  }

  getUser(): UserData {
    const userData: any = this.storageEncryptService.getSessionStore(USER_DATA);
    if (userData) {
      const parsed: any = JSON.parse(userData);
      return {
        access_token: parsed.access_token,
        refresh_token: parsed.refresh_token,
        user_name: parsed.user_name
      };
    }
  }

  getUserName(): string {
    const userData = this.getUser();
    if (userData) return userData.user_name;
  }

  setUser(userData): void {
    if (userData) this.storageEncryptService.setSessionStore('userData', JSON.stringify(userData));
  }

  setIntegrationData(tokenOriginacao: string): void {
    if (!tokenOriginacao) return;

    this.storageEncryptService.setSessionStore('token', tokenOriginacao);
    this.storageEncryptService.removeSession(USER_DATA);
  }

  clearUser(): void {
    this.storageEncryptService.removeSession(USER_DATA);
    this.storageEncryptService.removeSession(HISTORY);
  }

  private encrypt(text: string): string {
    const emBase64 = window.btoa(encodeURI(text));
    const aesOfb = new AesJS.ModeOfOperation.ofb(this.KEY, this.IV);
    const textBytes = AesJS.utils.utf8.toBytes(emBase64);
    const encryptedBytes = aesOfb.encrypt(textBytes);
    const encText = AesJS.utils.hex.fromBytes(encryptedBytes);

    return encText;
  }

  /**
   * Converte a string da váriavel de ambiente para um array de inteiros
   *
   * @private
   * @param {string} text
   * @returns
   * @memberof CryptoInterceptor
   */
  private convertToArray(text: string) {
    return text.split(',').map(value => parseInt(value.trim(), 10));
  }

  tokenNovaArquitetura(tokenBanco, cpfUsuario) {
    const body = new HttpParams()
      .set('userToken', tokenBanco)
      .set('grant_type', 'password')
      .set('cpf', cpfUsuario);

    const headers = new HttpHeaders({
      Authorization: `Bearer ${JSON.parse(this.storageEncryptService.getSessionStore('userData')).access_token}`,
      'Content-Type': 'application/json'
    });

    return this.http.post(`${environmentClient.apiRootUrl}/authvalidation/externalAuthCanais`, body, { headers });
  }
}
