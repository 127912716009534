import { Action } from '@ngrx/store';
import { InputSaveProposalData } from '@shared/graphql/types/mutation-types';
import {
  getSimulationDataVariables,
  GetUnitSimulationData_getUnitSimulationData,
  GetUnitSimulationDataVariables,
  listarEmpreendimentosSimulacao,
} from '@shared/graphql/types/query-types';

export enum ProposalActionTypes {
  SET_PROPOSAL_KEYS = '[Proposal] Set Proposal Keys',
  SET_CURRENT_TASK_KEY = '[Proposal] Set Current Task Key',
  SET_PROPOSAL_DATA = '[Proposal] Set Proposal Data',
  SET_PROPOSAL_ID = '[Proposal] Set Proposal ID',
  SET_SIMULATION_ID = '[Proposal] Set Simulation ID',

  RESET_SEND_CONTRACT = '[Proposal] Reset Send Contract',

  SEND = '[Proposal] Send',
  SEND_SUCCESS = '[Proposal] Send Success',
  SEND_ERROR = '[Proposal] Send Error',

  FIELD_CHANGED = '[Proposal] Field Changed',

  CALCULATE = '[Simulation Data] Calculate',
  CALCULATE_OFFERS = '[Simulation Data] Calculate Offers',
  CALCULATE_SUCCESS = '[Simulation Data] Calculate Success',
  CALCULATE_ERROR = '[Simulation Data] Calculate Error',

  GENERATE_PDF = '[Proposal] Generate PDF',
  GENERATE_PDF_SUCCESS = '[Proposal] Generate PDF Success',
  GENERATE_PDF_ERROR = '[Proposal] Generate PDF Error',

  SIMULATION_PROPOSAL_LOAD = '[Simulation Proposal] Simulation Proposal Load',
  SIMULATION_PROPOSAL_LOAD_SUCCESS = '[Simulation Proposal] Simulation Proposal Load Success',
  SIMULATION_PROPOSAL_LOAD_ERROR = '[Simulation Proposal] Simulation Proposal Load Error',

  GET_EMPREENDIMENTOS = '[Proposal] Get Empreendimentos',
  GET_EMPREENDIMENTOS_SUCESS = '[Proposal] Get Empreendimentos Success',

  SAVE_SIMULATION = '[Proposal] Save Simulation',
  SAVE_SIMULATION_SUCCESS = '[Proposal] Save Simulation Success',
  SAVE_SIMULATION_ERROR = '[Proposal] Save Simulation Error',
  SAVE_RENEWED_CONTRACT = '[Proposal] Save Renewed Contract',
  SAVE_RENEWED_CONTRACT_SUCESS = '[Proposal] Save Renewed Contract Sucess',

  BACK = '[Proposal] Back',
  BACK_ERROR = '[Proposal] Back Error',

  RESET_SELECTED_OPTIONS = '[Proposal] Reset Selected Options',
  RESET_SELECTED_OPTIONS_SUCCESS = '[Proposal] Reset Selected Options Success',
  RESET_CONTRACT_VALUE = '[Proposal] Reset Contract Value',

  REDIRECT = '[Proposal] Redirect',

  GET_BUILDINGS = '[Proposal] Listar Empreendimento',
  GET_BUILDINGS_SUCCESS = '[Proposal] Listar Empreendimento Success',

  SAVE_FINANCING_OBJECTIVE = '[Proposal] Save Financing Objective',

  GET_SIMULATION_CONTRACT_DATA = '[Proposal] Listar Contratos Emprestimo Simulação',
  GET_SIMULATION_CONTRACT_DATA_SUCCESS = '[Proposal] Listar Contratos Emprestimo Simulação Sucesso',

  GET_WARRANTY_SHARING_OFFERS = '[Proposal] Obter Ofertas Compartilhamento',
  GET_WARRANTY_SHARING_OFFERS_SUCCESS = '[Proposal] Obter Ofertas Compartilhamento Sucesso',

  SET_IS_LOGGED_SECOND_SCREEN = '[Proposal] Set is logged in second screen',

  GET_UNIT_SIMULATION_DATA = '[Parameters] Get Unit Simulation Data',
  GET_UNIT_SIMULATION_DATA_SUCESS = '[Parameters] Get Unit Simulation Data Sucess',
  GET_UNIT_SIMULATION_DATA_RESET = '[Parameters] Get Unit Simulation Data Reset',
}

export class ResetSendContract implements Action {
  readonly type = ProposalActionTypes.RESET_SEND_CONTRACT;
  constructor() {}
}

export class SetProposalKeys implements Action {
  readonly type = ProposalActionTypes.SET_PROPOSAL_KEYS;
  constructor(public payload: { businessKey: string; currentTaskKey: string }) {}
}

export class SetCurrentTaskKey implements Action {
  readonly type = ProposalActionTypes.SET_CURRENT_TASK_KEY;
  constructor(public payload: { currentTaskKey: string }) {}
}

export class SetProposalData implements Action {
  readonly type = ProposalActionTypes.SET_PROPOSAL_DATA;
  constructor(public payload: any) {}
}

export class SetProposalID implements Action {
  readonly type = ProposalActionTypes.SET_PROPOSAL_ID;
  constructor(public payload: { idProposta: string; channel: string }) {}
}

export class SetSimulationID implements Action {
  readonly type = ProposalActionTypes.SET_SIMULATION_ID;
  constructor(public payload: { simulationId: string }) {}
}

export class Send implements Action {
  readonly type = ProposalActionTypes.SEND;
  constructor(public payload: { input: InputSaveProposalData }) {}
}
export class SendOffers implements Action {
  readonly type = ProposalActionTypes.SEND;
  constructor(public payload: { input: InputSaveProposalData }) {}
}
export class SendSuccess implements Action {
  readonly type = ProposalActionTypes.SEND_SUCCESS;
  constructor(
    public payload: {
      input: {
        loanTypeKey?: string;
        propertyLocationKey?: string;
        buildingTypeKey?: string;
        quizResponseCode?: string;
        propertyValue?: number;
        loanAmountValue?: number;
        termInMonths?: number;
        isTransferTaxInClosingCostCredit?: boolean;
        isAppraisalfeesInClosingCostCredit?: boolean;
        isIOFIncludedInClosingCostCredit?: boolean;
        deferredPaymentKey?: string;
        expensesValue?: number;
        typeOfferWarrantyShared?: string;
        contractCodeRenovado?: string;
      };
    }
  ) {}
}

export class SendError implements Action {
  readonly type = ProposalActionTypes.SEND_ERROR;
  constructor(public payload: any) {}
}

export class FieldChanged implements Action {
  readonly type = ProposalActionTypes.FIELD_CHANGED;
  constructor(public payload: any) {}
}

export class Calculate implements Action {
  readonly type = ProposalActionTypes.CALCULATE;
  constructor() {}
}
export class CalculateOffers implements Action {
  readonly type = ProposalActionTypes.CALCULATE_OFFERS;
  constructor(public payload: any) {}
}

export class CalculateSuccess implements Action {
  readonly type = ProposalActionTypes.CALCULATE_SUCCESS;
  constructor(public payload: any) {}
}

export class CalculateError implements Action {
  readonly type = ProposalActionTypes.CALCULATE_ERROR;
  constructor(public payload: any) {}
}

export class GeneratePDF implements Action {
  readonly type = ProposalActionTypes.GENERATE_PDF;
  constructor() {}
}

export class GeneratePDFSuccess implements Action {
  readonly type = ProposalActionTypes.GENERATE_PDF_SUCCESS;
  constructor(public payload: { pdfHash: string }) {}
}

export class GeneratePDFError implements Action {
  readonly type = ProposalActionTypes.GENERATE_PDF_ERROR;
  constructor(public payload: any) {}
}

export class SimulationProposalLoad implements Action {
  readonly type = ProposalActionTypes.SIMULATION_PROPOSAL_LOAD;
  constructor(public payload: { simulationId: getSimulationDataVariables }) {}
}

export class SimulationProposalLoadSuccess implements Action {
  readonly type = ProposalActionTypes.SIMULATION_PROPOSAL_LOAD_SUCCESS;
  constructor(public payload: any) {}
}

export class SimulationProposalLoadError implements Action {
  readonly type = ProposalActionTypes.SIMULATION_PROPOSAL_LOAD_ERROR;
  constructor(public payload: any) {}
}

export class SaveSimulation implements Action {
  readonly type = ProposalActionTypes.SAVE_SIMULATION;
  constructor() {}
}

export class SaveSimulationSuccess implements Action {
  readonly type = ProposalActionTypes.SAVE_SIMULATION_SUCCESS;
  constructor() {}
}

export class SaveSimulationError implements Action {
  readonly type = ProposalActionTypes.SAVE_SIMULATION_ERROR;
  constructor(public payload: any) {}
}

export class GetEmpreendimentos implements Action {
  readonly type = ProposalActionTypes.GET_EMPREENDIMENTOS;
  constructor(public payload: { idEmpreendimento: string; tipoConsulta?: string; portal?: string }) {}
}

export class GetEmpreendimentosSuccess implements Action {
  readonly type = ProposalActionTypes.GET_EMPREENDIMENTOS_SUCESS;
  constructor(public payload: any) {}
}

export class Back implements Action {
  readonly type = ProposalActionTypes.BACK;
  constructor(public payload: { url: string }) {}
}

export class BackError implements Action {
  readonly type = ProposalActionTypes.BACK_ERROR;
  constructor(public payload: any) {}
}

export class ResetSelectedOptions implements Action {
  readonly type = ProposalActionTypes.RESET_SELECTED_OPTIONS;
  constructor() {}
}

export class ResetSelectedOptionsSuccess implements Action {
  readonly type = ProposalActionTypes.RESET_SELECTED_OPTIONS_SUCCESS;
  constructor() {}
}

export class ResetContractValue implements Action {
  readonly type = ProposalActionTypes.RESET_CONTRACT_VALUE;
  constructor() {}
}

export class Redirect implements Action {
  readonly type = ProposalActionTypes.REDIRECT;
  constructor(public payload: { url: string }) {}
}

export class GetBuildings implements Action {
  readonly type = ProposalActionTypes.GET_BUILDINGS;
  constructor(public payload: { idSimulacao?; tipoImovel: string; nomeEmpreendimento: string; loanTypeKey: string }) {}
}

export class GetBuildingsSuccess implements Action {
  readonly type = ProposalActionTypes.GET_BUILDINGS_SUCCESS;
  constructor(public payload: listarEmpreendimentosSimulacao) {}
}

export class SaveFinancingObjective implements Action {
  readonly type = ProposalActionTypes.SAVE_FINANCING_OBJECTIVE;
  constructor(public payload: { goal: string; type: string }) {}
}

export class SaveRenewedContract implements Action {
  readonly type = ProposalActionTypes.SAVE_RENEWED_CONTRACT;
  constructor(public payload: { cdContrato: string; simulationId: string }) {}
}

export class SaveRenewedContractSucess implements Action {
  readonly type = ProposalActionTypes.SAVE_RENEWED_CONTRACT_SUCESS;
  constructor(public payload: any) {}
}

export class GetSimulationContractData implements Action {
  readonly type = ProposalActionTypes.GET_SIMULATION_CONTRACT_DATA;
  constructor(public payload: { idSimulacao; loanTypeKey? }) {}
}

export class GetSimulationContractDataSuccess implements Action {
  readonly type = ProposalActionTypes.GET_SIMULATION_CONTRACT_DATA_SUCCESS;
  constructor(public payload: any) {}
}
export class GetWarrantySharingOffers implements Action {
  readonly type = ProposalActionTypes.GET_WARRANTY_SHARING_OFFERS;
  constructor(public payload: { nrCpfCnpj; cdContractInter }) {}
}

export class GetWarrantySharingOffersSucess implements Action {
  readonly type = ProposalActionTypes.GET_WARRANTY_SHARING_OFFERS_SUCCESS;
  constructor(public payload: any) {}
}

export class SetIsLoggedSecondScreen implements Action {
  readonly type = ProposalActionTypes.SET_IS_LOGGED_SECOND_SCREEN;
  constructor(public payload: boolean) {}
}

export class GetUnitSimulationData implements Action {
  readonly type = ProposalActionTypes.GET_UNIT_SIMULATION_DATA;
  constructor(public payload: GetUnitSimulationDataVariables) {}
}

export class GetUnitSimulationDataSucess implements Action {
  readonly type = ProposalActionTypes.GET_UNIT_SIMULATION_DATA_SUCESS;
  constructor(public payload: GetUnitSimulationData_getUnitSimulationData) {}
}

export class GetUnitSimulationDataReset implements Action {
  readonly type = ProposalActionTypes.GET_UNIT_SIMULATION_DATA_RESET;
  constructor() {}
}

export type ProposalActions =
  | SetProposalKeys
  | SetCurrentTaskKey
  | SetProposalData
  | SetProposalID
  | SetSimulationID
  | Send
  | SendSuccess
  | SendError
  | Calculate
  | CalculateSuccess
  | CalculateError
  | GeneratePDF
  | GeneratePDFSuccess
  | GeneratePDFError
  | SimulationProposalLoad
  | SimulationProposalLoadSuccess
  | SimulationProposalLoadError
  | SaveSimulation
  | SaveSimulationSuccess
  | SaveSimulationError
  | SaveRenewedContract
  | SaveRenewedContractSucess
  | GetEmpreendimentos
  | GetEmpreendimentosSuccess
  | Back
  | BackError
  | Redirect
  | ResetSelectedOptions
  | ResetSelectedOptionsSuccess
  | ResetContractValue
  | FieldChanged
  | GetBuildings
  | GetBuildingsSuccess
  | SaveFinancingObjective
  | GetSimulationContractData
  | GetSimulationContractDataSuccess
  | GetWarrantySharingOffers
  | GetWarrantySharingOffersSucess
  | SetIsLoggedSecondScreen
  | GetUnitSimulationData
  | GetUnitSimulationDataSucess
  | GetUnitSimulationDataReset
  | CalculateOffers
  | ResetSendContract;
