import { createSelector } from '@ngrx/store';
import { loginSelector } from './login.state';

import * as fromParametrosPerfil from '@app/state/parametros-perfil';

export const selectAlerts = createSelector(
  loginSelector,
  state => state.alerts
);

export const selectLoginSucess = createSelector(
  loginSelector,
  state => state
);

export const selectUrlRequest = createSelector(
  loginSelector,
  state => state.urlRequest
);

export const selectUrlOrigin = createSelector(
  loginSelector,
  state => state.urlOrigin
);

export const selectIsLogged = createSelector(
  loginSelector,
  state => state.isLoggedIn
);

export const selectUsername = createSelector(
  loginSelector,
  state => state.username
);

export const selectTokenPP = createSelector(
  loginSelector,
  state => state.tokenPP
);

export const selectIntegrationSucess = createSelector(
  loginSelector,
  state => state.integrationSuces
);

export const selectUrlRequestParametros = createSelector(
  loginSelector,
  selectUrlRequest,
  fromParametrosPerfil.selectors.selectParametros,
  (stateLogin, stateUrlRequest, stateParametros) => ({
    ...stateLogin,
    ...stateParametros,
    stateUrlRequest
  })
);
