import { ParticipantsActions, ParticipantsActionTypes } from './participants.actions';
import * as moment from 'moment';

export interface Participant {
  name: string | null;
  cpf: string | null;
  birthDate: string | null;
  email?: string | null;
  phone?: string | null;
  monthlyIncome: number | null;
  allowSms?: boolean | null;
  allowContact?: boolean | null;
  fgContact?: boolean | null;
  order: number | null;
  registrationStatus: string | null;
}

export interface PersonData {
  id: number | null;
  order: number | null;
  name: string | null;
  birthDate: string | null;
  documentNumber: string | null;
  email: string | null;
  telephoneNumber: string | null;
  monthlyIncome: number | null;
  authorizesEmailAndSms: boolean | null;
  isEmployee: boolean | null;
  isPrimaryBorrower?: boolean | null;
  isFromPersonas?: boolean | null;
  isRegistrationCompleted?: boolean | null;
  isRegistrationInconsistent?: boolean | null;
  hasDevelopments?: boolean | null;
}

export interface CompanyData {
  id: number | null;
  name: string | null;
  documentNumber: string | null;
  integrationCode: string | null;
  primarySegment: string | null;
  primarySegmentDescription: string | null;
}

export interface ParticipantsState {
  idProposta: string | null;
  otherParticipant: boolean | null;
  participants: PersonData[];
  company: CompanyData[];
  errors: any | null;
  personalDataChanged: boolean;
  send: boolean;
  validate: boolean;
}

export const initialState: ParticipantsState = {
  idProposta: null,
  otherParticipant: null,
  participants: null,
  company: null,
  errors: null,
  personalDataChanged: null,
  send: null,
  validate: null
};

export function participantsReducer(state = initialState, action: ParticipantsActions) {
  switch (action.type) {
    case ParticipantsActionTypes.SEND_SUCCESS: {
      return {
        ...state,
        otherParticipant: action.payload.otherParticipant,
        participants: action.payload.participants
      };
    }

    case ParticipantsActionTypes.SEND_PJ_SUCCESS: {
      return {
        ...state,
        company: action.payload.participants
      };
    }

    case ParticipantsActionTypes.SEND_ERROR: {
      let errorsToBeUsed;

      if (action.payload.extensions) {
        errorsToBeUsed =
          action.payload.extensions && action.payload.extensions.messages
            ? action.payload.extensions.messages
              .map(m => m.message)
              .reduce((curr, prev) => {
                return `${curr} ${prev}`;
              }, '')
            : action.payload.extensions.message;
      } else {
        errorsToBeUsed = action.payload.message;
      }

      return {
        ...state,
        errors: errorsToBeUsed
      };
    }

    case ParticipantsActionTypes.CLEAR_ERRORS: {
      return {
        ...state,
        errors: null
      };
    }

    case ParticipantsActionTypes.SET_PARTICIPANTS: {
      return {
        ...state,
        otherParticipant: action.payload.participants.filter(p => p !== null).length > 1 ? true : false,
        participants: action.payload.participants
          .filter(p => p !== null)
          .map(p => ({
            ...p,
            birthDate: p.birthDate ? moment(p.birthDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : null
          }))
      };
    }

    case ParticipantsActionTypes.GET_BORROWER_SIMULATION_SUCCESS: {
      return {
        ...state,
        otherParticipant: action.payload.participants.length > 1 ? true : false,
        participants: !action.payload.participants.length ? null : action.payload.participants
          .filter(p => p !== null)
          .map(p => ({
            order: p.order,
            id: p.id,
            name: p.name,
            birthDate: p.birthDate ? moment(p.birthDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : null,
            documentNumber: p.documentNumber,
            email: p.email,
            telephoneNumber: p.telephoneNumber,
            monthlyIncome: p.monthlyIncome,
            authorizesEmailAndSms: p.authorizesEmailAndSms,
            isEmployee: p.isEmployee,
            registrationStatus: p.registrationStatus,
            isFromPersonas: p.isFromPersonas,
            hasDevelopments: p.hasDevelopments || null
          }))
      };
    }

    case ParticipantsActionTypes.GET_BORROWER_SIMULATION_PJ_SUCCESS:
      return {
        ...state,
        company: action.payload.company
      }

    case ParticipantsActionTypes.GET_BORROWERS_LIST_SIMULATION_SUCCESS: {
      const participants = action.payload.participants
        .filter(p => p !== null)
        .map(p => ({
          ...p,
          birthDate: p.birthDate ? moment(p.birthDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : null
        }));

      return {
        ...state,
        participants
      };
    }

    case ParticipantsActionTypes.UPDATE_PERSONAS_STATUS_SUCCESS: {
      return {
        ...state,
        participants: state.participants.map(p => {
          const personasStatus = action.payload.personasStatus.find(s => s.documentNumber === p.documentNumber);

          if (personasStatus) {
            return { ...p, ...personasStatus };
          }

          return p;
        })
      };
    }

    case ParticipantsActionTypes.SET_PERSONAL_DATA_CHANGED: {
      return {
        ...state,
        personalDataChanged: action.payload.personalDataChanged
      };
    }

    case ParticipantsActionTypes.GENERATE_TOKEN_SMS_SUCCESS: {
      return {
        ...state,
        send: action.payload.send
      };
    }

    case ParticipantsActionTypes.VALIDATE_TOKEN_SMS_SUCCESS: {
      return {
        ...state,
        validate: action.payload.validate
      };
    }

    default:
      return state;
  }
}
