import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsultProposalComponent } from './consult-proposal.component';
import { ConsultProposalRoutingModule } from './consult-proposal-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoadingModule } from '@shared/widgets/loading/loading.module';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule as SharedModuleCliente } from './../shared/shared.module';
import { SimulationGuard } from '@app/core/simulation/simulation.guard';
import { ValidatedTokenSmsGuard } from '@app/core/simulation/validatedTokenSms.guard';
import { AuthGuard } from 'arch-onefinancial-auth.lib';
import { SharedModule } from '@shared/shared.module';


@NgModule({
  declarations: [ConsultProposalComponent],
  imports: [
    CommonModule,
    ConsultProposalRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgxMaskModule,
    SharedModuleCliente,
    NgxMaskModule.forRoot(),
    NgxCurrencyModule,
    NgSelectModule,
    LoadingModule
  ],
  providers: [AuthGuard, SimulationGuard, ValidatedTokenSmsGuard]
})
export class ConsultProposalModule { }
