import { Component, Input, ContentChild, AfterContentInit } from '@angular/core';
import { NgModel, FormControlName } from '@angular/forms';

@Component({
  selector: 'app-input-checkbox-container',
  templateUrl: './input-checkbox-container.component.html',
  styleUrls: ['./input-checkbox-container.component.scss']
})
export class InputCheckboxContainerComponent implements AfterContentInit {
  @Input() for: string;
  @Input() label: string;
  @Input() helperName: string;

  @ContentChild(NgModel) model: NgModel;
  @ContentChild(FormControlName) control: FormControlName;

  input: any;

  constructor() {}

  ngAfterContentInit() {
    this.input = this.control;

    if (this.input === undefined) {
      throw new Error('Esse componente precisa ser usado com uma diretiva ngModel ou FormControlName');
    }
  }

  
}
