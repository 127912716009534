import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { AppState } from '@app/state';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

import * as fromProposal from '@app/state/proposal';
import { CryptoService } from '../auth/services/crypto.service';
import { StorageEncryptService } from '@shared/services/storageEncrypt.service';

interface IntegrationData {
  proposalId?: string;
  proponentId?: string;
}

@Injectable()
export class SimulationGuard implements CanActivate {
  constructor(
    private store$: Store<AppState>,
    private router: Router,
    private cryptoService: CryptoService,
    private storageEncryptService: StorageEncryptService
  ) {}

  /**
   * Handle integration when coming back from Portal Cliente Personas
   *
   * @returns {IntegrationData}
   * @memberof SimulationGuard
   */
  handleIntegrationPersonas(): IntegrationData {
    const session = JSON.parse(this.storageEncryptService.getSessionStore('data'));

    if (session && session.idProposta && session.idProponente) {
      // this.storageEncryptService.removeSession('data');
      return { proposalId: session.idProposta, proponentId: session.idProponente };
    }

    return { proposalId: null, proponentId: null };
  }

  getValuesZap(state: RouterStateSnapshot) {
    const isCrypto = environment.featureToggle.crypto.active === 'true';
    return state.root.queryParams.idProposta && state.root.queryParams.utmSource
      ? {
          simulationId: isCrypto
            ? this.cryptoService.decrypt(state.root.queryParams.idProposta)
            : decodeURIComponent(state.root.queryParams.idProposta),
          utmSource: isCrypto
            ? this.cryptoService.decrypt(state.root.queryParams.utmSource)
            : state.root.queryParams.utmSource
        }
      : { simulationId: null, utmSource: null };
  }

  isLoggedSecondScreen(): Observable<boolean> {
    return this.store$.pipe(
      select(fromProposal.selectors.selectIsLoggedSecondScreen),
      map(isLoggedSecondScreen => {
        if(isLoggedSecondScreen) return true;
        return false;
      })
    );
  }

  /**
   * Allow access to pages other than Personal Data only if user has a Simulation ID
   *
   * @returns {Observable<boolean>}
   * @memberof SimulationGuard
   */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    let isLogged = false;
    this.isLoggedSecondScreen().subscribe(res => isLogged = res );
    let integration = this.handleIntegrationPersonas();
    const skin = environment.skin;
    let propostaZAP = null;
    let utmSource = null;

    if (skin === 'cliente' && environment.featureToggle.fgUtmSource) {
      const valueZap = this.getValuesZap(state);
      propostaZAP = valueZap.simulationId || integration.proposalId;
      utmSource = valueZap.utmSource || this.storageEncryptService.getSessionStore('utmSource');

      if (utmSource) {
        this.storageEncryptService.setSessionStore('data', JSON.stringify({ idProposta: propostaZAP }));
        this.storageEncryptService.setSessionStore('utmSource', utmSource);
      }
    }

    integration = this.handleIntegrationPersonas();
    const session = JSON.parse(this.storageEncryptService.getSessionStore('data'));

    if (skin !== 'cliente' && session && !session.storeId) {
      if (skin === 'parceiro') {
        window.location.href = `${environment.urlPortalParceiro}#/painel`;
        return;
      }
      window.location.href = `${environment.urlPortalGerente}#/painel`;
      return;
    }

    return this.store$.pipe(
      select(fromProposal.selectors.selectSimulationID),
      map(simulationId => {
        const prodruto = this.storageEncryptService.getSessionStore('selectedLoanObjective');

        if(isLogged && skin === 'cliente') return true;

        if (!simulationId && !integration.proposalId && (!propostaZAP || !utmSource)) {
          this.storageEncryptService.removeSession('usuarioLogado');
          this.storageEncryptService.removeSession('token_logged');
          this.router.navigate(['/dados-pessoais']);
          return false;
        }

        if ((!simulationId && integration.proposalId) || (propostaZAP && utmSource)) {
          const proposalId = propostaZAP && utmSource ? propostaZAP : integration.proposalId;
          this.store$.dispatch(new fromProposal.actions.SetSimulationID({ simulationId: proposalId }));
          this.store$.dispatch(
            new fromProposal.actions.SetProposalID({
              idProposta: proposalId,
              channel: 'PC'
            })
          );
        }

        return true;
      })
    );
  }
}
