import { Directive, HostListener, Inject, Optional } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[appNgSelectTab]'
})
export class NgSelectTabDirective {
  constructor(private host: NgSelectComponent, @Optional() @Inject(DOCUMENT) private document: any) {}

  @HostListener('keydown', ['$event'])
  handleKeyDown($event: KeyboardEvent) {
    if ($event.key === 'Tab' && this.host.selectOnTab) {
      this.handleTab($event);
    }
  }

  private handleTab(event: KeyboardEvent): void {
    if (!event || !event.target) return;

    const allElements = this.document.querySelectorAll('input');
    const currentIndex = Array.from(allElements).findIndex(e => e === event.target);

    setTimeout(() => {
      const nextElement = allElements[currentIndex + 1];

      if (nextElement) {
        nextElement.focus();
      }
    }, 0);
  }
}
