<div class="modal-header">
  <h4 class="modal-title d-flex justify-content-center">
    {{ data.title | translate }}
  </h4>
  <button type="button" class="close d-flex justify-content-center align-middle" aria-label="Close" (click)="closed()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>
    {{ data.message | translate }}
  </p>

  <ul *ngIf="data.list && data.list.length > 0">
    <li *ngFor="let item of data.list">
      {{ item | translate }}
    </li>
  </ul>

  <div
    *ngIf="data.buttons && data.buttons.length > 0"
    class="botoes"
    [ngClass]="{
      doisBotoesLarge: data.btnLarge,
      umBotao: data.buttons.length === 1,
      doisBotoes: data.buttons.length > 1
    }"
  >
    <button *ngFor="let item of data.buttons" (click)="buttonClick(item.action)">
      {{ item.label | translate }}
    </button>
  </div>
</div>
<!-- <div class="modal-footer"></div> -->
