import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Alert, VivereErrorSeverity, EmitterAlertInterface } from './../models/alert.model';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  constructor(private translateService: TranslateService) {}

  static getAlertError(message: string, type: string) {
    const errorEmptyAlert: EmitterAlertInterface = {
      error: {
        messages: [{ message: '' }, { message }]
      }
    };

    if (type === VivereErrorSeverity.WARN) {
      errorEmptyAlert.error.statusCode = 400;
      errorEmptyAlert.error.severity = type;
      errorEmptyAlert.error.type = false;
    }

    if (type === VivereErrorSeverity.ERRO) {
      errorEmptyAlert.error.statusCode = 500;
      errorEmptyAlert.error.severity = type;
      errorEmptyAlert.error.type = false;
    }

    return new Alert(null, errorEmptyAlert as HttpErrorResponse);
  }

  addAlertError(error) {
    return new Alert(null, error);
  }

  addEmptyAlert(message: string, type: string = 'ERROR') {
    return ErrorService.getAlertError(message, type);
  }

  addGraphQLAlert(errors) {
    let message;

    if (!errors.extensions) {
      message = errors;
    }

    if (errors.extensions && errors.extensions.message && !errors.extensions.messages) {
      message = errors.extensions.message;
    }

    if (errors.extensions && errors.extensions.message && errors.extensions.messages) {
      if (errors.extensions.messages[0]) {
        message = errors.extensions.messages[0].message;
      } else {
        message = errors.extensions.messages[0].message + ' ' + errors.extensions.messages[1].message;
      }
    }

    return ErrorService.getAlertError(message, 'ERROR');
  }

  handleError(rawError) {
    const payload = {
      key: `errorMessages.${this.getErrorCode(rawError)}`,
      params: this.getParametersFromRawError(rawError)
    };
    return this.getTranslation(payload);
  }

  getErrorCode(rawError: string): string {
    return rawError.split(' ')[0];
  }

  getParametersFromRawError(rawError: string) {
    return this.retrieveParams(this.getTranslatedParams(rawError.split(' ').slice(1)));
  }

  private getTranslation(error) {
    return this.translateService.instant(error.key, error.params);
  }

  private getTranslatedParams(params: Array<string>) {
    return params.map(p => {
      if (p.indexOf('$') === -1) return p;
      return this.translateService.instant(`params.${p}`);
    });
  }

  private retrieveParams(params: Array<string> | string) {
    const rv = {};

    for (let i = 0; i < params.length; ++i) {
      rv['param' + i] = params[i];
    }

    return rv;
  }
}
