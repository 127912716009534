import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageEncryptService } from '@shared/services/storageEncrypt.service';
import { environment } from 'src/environments/environment';
import { ProposalSummaryService } from '../proposal-summary/proposal-summary.service';
@Component({
  selector: 'app-middleware-proposal-sumary',
  templateUrl: './middleware-proposal-sumary.component.html',
  styleUrls: ['./middleware-proposal-sumary.component.scss']
})
export class MiddlewareProposalSumaryComponent {
  token: string;
  typeClient: string;
  loading = false;

  constructor(
    private route: ActivatedRoute,
    private proposalSummaryService: ProposalSummaryService,
    private router: Router,
    private storageEncryptService: StorageEncryptService
  ) {
    this.route.queryParams.subscribe(params => {
      this.loading = true;
      this.storageEncryptService.setSessionStore('isnewArch', 'true');

      if (environment.featureToggle.useMockLogin === 'true') {
        return (window.location.href = `${environment.urlPortalCliente}/#/v2/resumo-da-proposta/enviar`);
      }
      const token = 'token';
      const typeClient = 'typeClient';
      if (params[token]) {
        this.token = decodeURIComponent(params[token]).replace(/\s/g, '+');
        this.typeClient = params[typeClient];
        this.storageEncryptService.setSessionStore('tokenSantander', this.token);

        /* Obter client credencials */
        const cpf = JSON.parse(this.storageEncryptService.getSessionStore('usuarioLogado')).cpf;
        const userData = JSON.parse(this.storageEncryptService.getSessionStore('userData'));
        this.proposalSummaryService
          .tokenOpenFinancial(userData.access_token, this.token, cpf, this.typeClient, environment.clientID)
          .subscribe(response => {
            this.storageEncryptService.setSessionStore('userData', JSON.stringify(response));
            return (window.location.href = `${environment.urlPortalCliente}/#/v2/resumo-da-proposta/enviar`);
          });
      }
    });
  }

  
}
