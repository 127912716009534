import { Action } from '@ngrx/store';

export enum SimulationActionTypes {
  SAVE_SIMULATION = '[Simulation] Save Simulation',
  SAVE_SIMULATION_SUCCESS = '[Simulation] Save Simulation Success',
  SAVE_SIMULATION_ERROR = '[Simulation] Save Simulation Error',

  BACK = '[Simulation] Back',
  BACK_ERROR = '[Simulation] Back Error',

  GET_SUMMARY = '[Simulation Summary] Get Summary Simulation',
  GET_SUMMARY_SUCCESS = '[Simulation Summary] Get Summary Simulation Success',
  GET_SUMMARY_ERROR = '[Simulation Summary] Get Summary Simulation Error',

  CONFIRM_SUMMARY = '[Simulation Summary] Confirm Summary',
  CONFIRM_SUMMARY_SUCCESS = '[Simulation Summary] Confirm Summary Success',
  CONFIRM_SUMMARY_ERROR = '[Simulation Summary] Confirm Summary Error',

  BACK_SUMMARY = '[Simulation Summary] Back Summary',
  BACK_SUMMARY_ERROR = '[Simulation Summary] Back Summary Error',

  SAVE_CREDIT_SIMULATION = '[Simulation Summary] Save Credit Simulation',
  SAVE_OWNERS_SIMULATION = '[Simulation Summary] Save Owners Simulation',

  GET_PDF_SIMULATION = '[Simulation Summary] Get Pdf Simulation',
  GET_PDF_SIMULATION_SUCCESS = '[Simulation Summary] Get Pdf Simulation Success',

  SEND_PDF_EMAIL = '[Simulation Summary] Send Pdf Email',

  SET_PREVIOUS_TASK = '[Simulation] Set Previous Task',
  SET_SIMULATION_SUCCESSFUL = '[Simulation] Set Simulation Successful',

  REDIRECT_PERSONAS = '[Simulation] Redirect Personas',
  REDIRECT_RESULTADO_ANALISE_CREDITO = '[Simulation] Redirect Resultado Analise Credito',
  REDIRECT = '[Simulation] Redirect',
  REDIRECT_EXTERNAL = '[Simulation] Redirect External',

  GET_WARRANTY = '[Simulation] Get Warranty',
  GET_WARRANTY_SUCCESSFUL = '[Simulation] Get Warranty Successful',
}

export class SaveSimulation implements Action {
  readonly type = SimulationActionTypes.SAVE_SIMULATION;
  constructor(public payload: any) {}
}

export class SaveSimulationSuccess implements Action {
  readonly type = SimulationActionTypes.SAVE_SIMULATION_SUCCESS;
  constructor() {}
}

export class SaveSimulationError implements Action {
  readonly type = SimulationActionTypes.SAVE_SIMULATION_ERROR;
  constructor(public payload: any) {}
}

export class Back implements Action {
  readonly type = SimulationActionTypes.BACK;
  constructor(public payload: { url: string }) {}
}

export class BackError implements Action {
  readonly type = SimulationActionTypes.BACK_ERROR;
  constructor(public payload: any) {}
}

export class GetSummary implements Action {
  readonly type = SimulationActionTypes.GET_SUMMARY;
  constructor() {}
}

export class GetSummarySuccess implements Action {
  readonly type = SimulationActionTypes.GET_SUMMARY_SUCCESS;
  constructor(public payload: any) {}
}

export class GetSummaryError implements Action {
  readonly type = SimulationActionTypes.GET_SUMMARY_ERROR;
  constructor(public payload: any) {}
}

export class ConfirmSummary implements Action {
  readonly type = SimulationActionTypes.CONFIRM_SUMMARY;
  constructor(public payload: { modalAlert?: any; portal: string, unitId?: string, carSpaceUnitIdList?: Array<number> }) {}
}

export class ConfirmSummarySuccess implements Action {
  readonly type = SimulationActionTypes.CONFIRM_SUMMARY_SUCCESS;
  constructor() {}
}

export class ConfirmSummaryError implements Action {
  readonly type = SimulationActionTypes.CONFIRM_SUMMARY_ERROR;
  constructor(public payload: any) {}
}

export class BackSummary implements Action {
  readonly type = SimulationActionTypes.BACK_SUMMARY;
  constructor(public payload: { url: string }) {}
}

export class BackSummaryError implements Action {
  readonly type = SimulationActionTypes.BACK_SUMMARY_ERROR;
  constructor(public payload: any) {}
}

export class SaveCreditSimulation implements Action {
  readonly type = SimulationActionTypes.SAVE_CREDIT_SIMULATION;
  constructor(public payload: { simulationData: any; actionType: string }) {}
}

export class SaveOwnersSimulation implements Action {
  readonly type = SimulationActionTypes.SAVE_OWNERS_SIMULATION;
  constructor(public payload: { proposalId: string }) {}
}

export class GetPdfSimulation implements Action {
  readonly type = SimulationActionTypes.GET_PDF_SIMULATION;
  constructor(public payload?: { name: string }) {}
}

export class GetPdfSimulationSuccess implements Action {
  readonly type = SimulationActionTypes.GET_PDF_SIMULATION_SUCCESS;
  constructor(public payload: { pdfHash: string; name?: string }) {}
}

export class SendPdfEmail implements Action {
  readonly type = SimulationActionTypes.SEND_PDF_EMAIL;
  constructor(public payload: { modalAlert: any }) {}
}

export class SetPreviousTask implements Action {
  readonly type = SimulationActionTypes.SET_PREVIOUS_TASK;
  constructor(public payload: { previousTask: string }) {}
}

export class SetSimulationSuccessful implements Action {
  readonly type = SimulationActionTypes.SET_SIMULATION_SUCCESSFUL;
  constructor(public payload: { isSuccessful: boolean }) {}
}

export class RedirectPersonas implements Action {
  readonly type = SimulationActionTypes.REDIRECT_PERSONAS;
  constructor(public payload: { simulationId: string; borrowerId: string; urlPortal: string }) {}
}

export class RedirectResultadoAnaliseCredito implements Action {
  readonly type = SimulationActionTypes.REDIRECT_RESULTADO_ANALISE_CREDITO;
  constructor(public payload: { proposalId: string; borrowerId: string; url: string }) {}
}

export class Redirect implements Action {
  readonly type = SimulationActionTypes.REDIRECT;
  constructor(public payload: { url: string }) {}
}

export class RedirectExternal implements Action {
  readonly type = SimulationActionTypes.REDIRECT_EXTERNAL;
  constructor(public payload: { url: string }) {}
}


export class GetWarranty implements Action {
  readonly type = SimulationActionTypes.GET_WARRANTY;
  constructor(public payload: any) {}
}

export class GetWarrantySucess implements Action {
  readonly type = SimulationActionTypes.GET_WARRANTY_SUCCESSFUL;
  constructor(public payload: any) {}
}

export type SimulationActions =
  | GetSummary
  | GetSummarySuccess
  | GetSummaryError
  | SaveSimulation
  | SaveSimulationSuccess
  | SaveSimulationError
  | Back
  | BackError
  | Redirect
  | SaveCreditSimulation
  | SaveOwnersSimulation
  | GetPdfSimulation
  | GetPdfSimulationSuccess
  | SendPdfEmail
  | SetPreviousTask
  | SetSimulationSuccessful
  | RedirectPersonas
  | RedirectResultadoAnaliseCredito
  | RedirectExternal
  | GetWarranty
  | GetWarrantySucess;
