import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map, catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { getPdfSimulationProposalVariables } from '../graphql/types/query-types';

import * as mutations from '@app/shared/graphql/mutations/proposal-data.mutation';
import * as queries from '@app/shared/graphql/queries/proposal-data.query';
import * as mutationTypes from '../graphql/types/mutation-types';
import * as queryTypes from '../graphql/types/query-types';
import { GET_UNIT_SIMULATION_DATA } from '@shared/graphql/queries/get-unit-simulation-data.query';

@Injectable({
  providedIn: 'root'
})
export class ProposalDataService {
  constructor(private apollo: Apollo) { }

  getPropertyValueRange(businessKey: string, input: any) {
    return this.apollo
      .query({
        query: queries.CALCULATE_PROPERTY_VALUE_RANGE,
        variables: {
          input: {
            loanTypeKey: input.loanTypeKey || '',
            propertyLocationKey: input.propertyLocationKey || '',
            buildingTypeKey: input.buildingTypeKey || '',
            businessKey
          }
        } as queryTypes.calculatePropertyValueRangeVariables,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map(r => {
          if (r.errors) throw r.errors[0];
          return r.data as queryTypes.calculatePropertyValueRange;
        }),
        catchError(err => throwError(err))
      );
  }

  getPropertyValueRangeProposal(
    loanTypeKey: string,
    propertyLocationKey: string,
    buildingTypeKey: string,
    portabilityTypeKey: string
  ): Observable<queryTypes.calculatePropertyValueRange> {
    return this.apollo
      .query({
        query: queries.CALCULATE_PROPERTY_VALUE_RANGE,
        variables: {
          loanTypeKey,
          propertyLocationKey,
          buildingTypeKey,
          portabilityTypeKey
        } as queryTypes.calculatePropertyValueRangeVariables,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map(r => {
          if (r.errors) throw r.errors[0];
          return r.data as queryTypes.calculatePropertyValueRange;
        }),
        catchError(err => throwError(err))
      );
  }

  getProposalValueRange(input: any) {
    return this.apollo
      .query({
        query: queries.CALCULATE_PROPOSAL_VALUE_RANGE,
        variables: {
          input
        } as any,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map(r => {
          if (r.errors) throw r.errors[0];
          return r.data as any;
        }),
        catchError(err => throwError(err))
      );
  }

  generatePdf(simulationId: string) {
    return this.apollo
      .query({
        query: queries.GET_PDF_SIMULATION_DATA,
        variables: {
          simulationId
        } as getPdfSimulationProposalVariables,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map(r => {
          if (r.errors) throw r.errors[0];
          return r.data as queryTypes.getPdfSimulationProposal;
        }),
        catchError(err => throwError(err))
      );
  }

  calculateSimulation(proposalData: any) {
    return this.apollo
      .query({
        query: queries.CALCULATE_SIMULATION_DATA,
        variables: {
          input: this.createCalculateSimulationPayload(proposalData)
        } as queryTypes.calculateSimulationVariables,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map(r => {
          if (r.errors) throw r.errors[0];
          return r.data as queryTypes.calculateSimulation;
        }),
        catchError(err => throwError(err))
      );
  }
  calculateSimulationOffers(proposalData: any) {
    return this.apollo
      .query({
        query: queries.CALCULATE_SIMULATION_DATA,
        variables: {
          input: this.createSimulationDataPayloadOffers(proposalData)
        } as queryTypes.calculateSimulationVariables,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map(r => {
          if (r.errors) throw r.errors[0];
          return r.data as queryTypes.calculateSimulation;
        }),
        catchError(err => throwError(err))
      );
  }

  getEmpreendimento(idEmpreendimento, tipoConsulta?) {
    return this.apollo
      .query({
        query: queries.GET_EMPREENDIMENTOS,
        variables: {
          idEmpreendimento,
          tipoConsulta
        } as queryTypes.getEmpreendimentosVariables,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map(r => {
          if (r.errors) throw r.errors[0];
          return r.data as queryTypes.getEmpreendimentos;
        }),
        catchError(err => throwError(err))
      );
  }

  getBuildings(params: queryTypes.listarEmpreendimentosSimulacaoVariables) {
    return this.apollo
      .query({
        query: queries.GET_BUILDINGS,
        variables: {
          ...params
        } as queryTypes.listarEmpreendimentosSimulacaoVariables,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map(r => {
          if (r.errors) throw r.errors[0];
          return r.data as queryTypes.listarEmpreendimentosSimulacao;
        }),
        catchError(err => throwError(err))
      );
  }

  // getSimulationProposalData(input): Observable<getSimulationData> {
  //   return this.apollo
  //     .query({
  //       query: queries.GET_SIMULATION_DATA,
  //       variables: { input } as getSimulationDataVariables,
  //       errorPolicy: 'all',
  //       fetchPolicy: 'no-cache'
  //     })
  //     .pipe(
  //       map(r => {
  //         if (r.errors) throw r.errors[0];
  //         return r.data as getSimulationData;
  //       }),
  //       catchError(err => throwError(err))
  //     );
  // }

  saveRenewedContract(cdContrato, simulationId) {
    return this.apollo
      .mutate({
        mutation: mutations.SAVE_RENEWED_CONTRACT,
        variables: {
          input: {
            cdContrato,
            simulationId
          }
        } as mutationTypes.saveRenewedContractVariables,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map(r => {
          if (r.errors) throw r.errors[0];
          return r.data as mutationTypes.saveRenewedContract;
        }),
        catchError(err => throwError(err))
      );
  }

  save(input: any) {
    delete input.recaptcha;

    return this.apollo
      .mutate({
        mutation: mutations.SAVE_PROPOSAL,
        variables: {
          input
        } as mutationTypes.saveProposalDataSimulationVariables,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map(r => {
          if (r.errors) throw r.errors[0];
          return r.data as mutationTypes.saveProposalDataSimulation;
        }),
        catchError(err => throwError(err))
      );
  }

  // back(businessKey: string) {
  //   return this.apollo
  //     .query({
  //       query: queries.BACK_PROPOSAL,
  //       variables: {
  //         businessKey
  //       } as queryTypes.proposalDataBackVariables,
  //       errorPolicy: 'all',
  //       fetchPolicy: 'no-cache'
  //     })
  //     .pipe(
  //       map(r => {
  //         if (r.errors) throw r.errors[0];
  //         return r.data as queryTypes.proposalDataBack;
  //       }),
  //       catchError(err => throwError(err))
  //     );
  // }

  private createCalculateSimulationPayload(input) {
    return {
      simulationId: input.simulationId,
      selectedProposalOptions: {
        channel: input.channel,
        loanTypeKey: input.loanTypeKey,
        buildingTypeKey: input.buildingTypeKey,
        propertyLocationKey: input.propertyLocationKey,
        portabilityTypeKey: input.portabilityTypeKey
      },
      simulationEntryValues: {
        propertyValue: input.propertyValue,
        evaluationValue: input.evaluationValue,
        downPaymentAmount: input.downPaymentAmount,
        fgtsAmount: input.fgtsAmount,
        loanAmountValue: input.loanAmountValue,
        isPropertyNew: input.isPropertyNew,
        termInMonths: input.termInMonths,
        termInYears: input.termInYears,
        monthsDeferredPaymentQt: input.monthsDeferredPaymentQt
      },
      selectedOptions: {
        termInYearsKey: input.termInYearsKey || '',
        campaignKey: input.campaignKey || '',
        marketSegmentKey: input.marketSegmentKey || '',
        offerKey: input.offerKey || '',
        paymentTypeKey: input.paymentTypeKey || '',
        amortizationTypeKey: input.amortizationTypeKey || '',
        monthWithoutPaymentKey: input.monthWithoutPaymentKey || '',
        insurerKey: input.insurerKey || '',
        insurancePolicyKey: input.insurancePolicyKey || '',
        deferredPaymentKey: input.deferredPaymentKey || '',
        typeOfferWarrantyShared: input.typeOfferWarrantyShared || '',
      },
      isAppraisalfeesInClosingCostCredit: input.isAppraisalfeesInClosingCostCredit || false,
      isIOFIncludedInClosingCostCredit: input.isIOFIncludedInClosingCostCredit || false,
      isTransferTaxInClosingCostCredit: input.isTransferTaxInClosingCostCredit || false,
      estimatedTRRate: input.estimatedTRRate || 0,
      usesEstimatedTRrate: input.usesEstimatedTRrate || false,
      interestRatesToCalculate: [] // ???
    };
  }
  private createSimulationDataPayloadOffers(input) {
    return {
      simulationId: input.simulationId,
      selectedProposalOptions: {
        channel: input.channel,
        loanTypeKey: input.loanTypeKey,
        buildingTypeKey: input.buildingTypeKey,
        propertyLocationKey: input.propertyLocationKey,
        portabilityTypeKey: input.portabilityTypeKey
      },
      simulationEntryValues: {
        propertyValue: input.propertyValue,
        evaluationValue: input.evaluationValue,
        downPaymentAmount: input.downPaymentAmount,
        fgtsAmount: input.fgtsAmount,
        loanAmountValue: input.loanAmountValue,
        isPropertyNew: input.isPropertyNew,
        termInMonths: input.termInMonths,
        termInYears: input.termInYears,
        monthsDeferredPaymentQt: input.monthsDeferredPaymentQt
      },
      selectedOptions: {
        typeOfferWarrantyShared: input.typeOfferWarrantyShared || '',
        termInYearsKey: input.termInYearsKey || '',
        campaignKey: input.campaignKey || '',
        marketSegmentKey: input.marketSegmentKey || '',
        offerKey: input.offerKey || '',
        paymentTypeKey: input.paymentTypeKey || '',
        amortizationTypeKey: input.amortizationTypeKey || '',
        monthWithoutPaymentKey: input.monthWithoutPaymentKey || '',
        insurerKey: input.insurerKey || '',
        insurancePolicyKey: input.insurancePolicyKey || '',
        deferredPaymentKey: input.deferredPaymentKey || ''
      },
      isAppraisalfeesInClosingCostCredit: input.isAppraisalfeesInClosingCostCredit || false,
      isIOFIncludedInClosingCostCredit: input.isIOFIncludedInClosingCostCredit || false,
      isTransferTaxInClosingCostCredit: input.isTransferTaxInClosingCostCredit || false,
      estimatedTRRate: input.estimatedTRRate || 0,
      usesEstimatedTRrate: input.usesEstimatedTRrate || false,
      interestRatesToCalculate: [] // ???
    };
  }

  getSimulationContractList(idSimulacao, loanTypeKey) {
    return this.apollo
      .query({
        query: queries.LISTAR_CONTRATOS_EMPRESTIMO_SIMULACAO,
        variables: {
          idSimulacao,
          loanTypeKey
        } as queryTypes.listarContratosEmprestimoSimulacaoVariables,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map(r => {
          if (r.errors) throw r.errors[0];
          return r.data as queryTypes.listarContratosEmprestimoSimulacao;
        }),
        catchError(err => throwError(err))
      );
  }

  getWarrantySharingOffers(nrCpfCnpj , cdContractInter) {
    return this.apollo
      .query({
        query: queries.GET_WARRANTY_SHARING_OFFERS,
        variables: {
          inputOffersMarcoGuarantees: {
            nrCpfCnpj,
            cdContractInter
          }
        } as queryTypes.getWarrantySharingOffersVariables,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map(r => {
          if (r.errors) throw r.errors[0];
          return r.data as queryTypes.getWarrantySharingOffers;
        }),
        catchError(err => throwError(err))
      );
  }

  getUnitSimulationData(unitId: string, carSpaceUnitIdList: Array<string>) {
    return this.apollo
      .query({
        query: GET_UNIT_SIMULATION_DATA,
        variables: {
          unitId,
          carSpaceUnitIdList
        } as queryTypes.GetUnitSimulationDataVariables,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map(r => {
          if (r.errors) throw r.errors[0];
          return r.data as queryTypes.GetUnitSimulationData;
        }),
        catchError(err => throwError(err))
      );
  }
}
