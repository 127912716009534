import { Injectable, Input } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map, catchError } from 'rxjs/operators';
import { throwError, Observable, of } from 'rxjs';
import * as mutations from '@app/shared/graphql/mutations/simulation.mutation';
import * as queries from '@app/shared/graphql/queries/simulation.query';
import * as queriesProposal from '@app/shared/graphql/queries/proposal-data.query';
import * as mutationTypes from '../graphql/types/mutation-types';
import * as queryTypes from '../graphql/types/query-types';

@Injectable({
  providedIn: 'root'
})
export class SimulationService {
  constructor(private apollo: Apollo) { }

  save(simulationData: any, usecasa?) {
    return this.apollo
      .mutate({
        mutation: mutations.SAVE_SIMULATION,
        variables: {
          input: usecasa ? this.createSaveSimulationPayloadOffers(simulationData) : this.createSaveSimulationPayload(simulationData)
        } as mutationTypes.saveSimulationDataSimulationVariables,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map(r => {
          if (r.errors) throw r.errors[0];
          return r.data as mutationTypes.saveSimulationDataSimulation;
        }),
        catchError(err => throwError(err))
      );
  }

  confirmSummary(
    simulationId: string,
    portal,
    firstBorrower,
    secondBorrower,
    unitId = null,
    carSpaceUnitIdList = null
  ): Observable<mutationTypes.confirmSummaryDataSimulation> {
    delete firstBorrower.order;
    delete firstBorrower.isFromPersonas;
    delete firstBorrower.__typename;

    if (secondBorrower) {
      delete secondBorrower.order;
      delete secondBorrower.isFromPersonas;
      delete secondBorrower.__typename;
    }

    return this.apollo
      .mutate({
        mutation: mutations.CONFIRM_SUMMARY,
        variables: {
          input: {
            proposalId: simulationId,
            portal,
            firstBorrower,
            secondBorrower,
            unitId,
            carSpaceUnitIdList
          }
        } as mutationTypes.confirmSummaryDataSimulationVariables,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map(r => {
          if (r.errors) throw r.errors[0];
          return r.data as mutationTypes.confirmSummaryDataSimulation;
        }),
        catchError(err => throwError(err))
      );
  }

  getResumoProposta(simulationId: string): Observable<queryTypes.getSimulationSummary> {
    return this.apollo
      .query({
        query: queries.GET_SUMMARY,
        variables: {
          simulationId
        } as queryTypes.getSimulationSummaryVariables,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map(r => {
          if (r.errors) throw r.errors[0];
          return r.data as queryTypes.getSimulationSummary;
        }),
        catchError(err => throwError(err))
      );
  }

  getPdfSimulation(simulationId: string): Observable<queryTypes.getPdfSimulationProposal> {
    return this.apollo
      .query({
        query: queriesProposal.GET_PDF_SIMULATION_DATA,
        variables: {
          simulationId
        } as queryTypes.getPdfSimulationProposalVariables,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map(r => {
          if (r.errors) throw r.errors[0];
          return r.data as queryTypes.getPdfSimulationProposal;
        }),
        catchError(err => throwError(err))
      );
  }

  sendPdfEmail(simulationId: string): Observable<mutationTypes.sendPdfEmail> {
    return this.apollo
      .mutate({
        mutation: mutations.SEND_PDF_EMAIL,
        variables: {
          simulationId
        } as mutationTypes.sendPdfEmailVariables,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map(r => {
          if (r.errors) throw r.errors[0];
          return r.data as mutationTypes.sendPdfEmail;
        }),
        catchError(err => throwError(err))
      );
  }

  // back(businessKey: string) {
  //   return this.apollo
  //     .query({
  //       query: queries.BACK_SIMULATION_DATA,
  //       variables: {
  //         businessKey
  //       } as queryTypes.simulationDataBackVariables,
  //       errorPolicy: 'all',
  //       fetchPolicy: 'no-cache'
  //     })
  //     .pipe(
  //       map(r => {
  //         if (r.errors) throw r.errors[0];
  //         return r.data as queryTypes.simulationDataBack;
  //       }),
  //       catchError(err => throwError(err))
  //     );
  // }

  // backSummary(businessKey: string) {
  //   return this.apollo
  //     .query({
  //       query: queries.SUMMARY_DATA_BACK,
  //       variables: {
  //         businessKey
  //       } as queryTypes.summaryDataBackVariables,
  //       errorPolicy: 'all',
  //       fetchPolicy: 'no-cache'
  //     })
  //     .pipe(
  //       map(r => {
  //         if (r.errors) throw r.errors[0];
  //         return r.data as queryTypes.summaryDataBack;
  //       }),
  //       catchError(err => throwError(err))
  //     );
  // }

  // saveCreditSimulation(simulationData) {
  //   return this.apollo
  //     .query({
  //       query: mutations.SAVE_CREDIT_SIMULATION,
  //       variables: {
  //         input: {
  //           proposalId: simulationData.idProposta || '',
  //           isSimulation: simulationData.isSimulation || false,
  //           downPaymentAmount: simulationData.downPaymentAmount || 0,
  //           loanAmountValue: simulationData.loanAmountValue || 0,
  //           termInMonths: simulationData.termInMonths || 0,
  //           marketSegmentKey: simulationData.marketSegmentKey || 0,
  //           FgtsValue: simulationData.fgtsAmount || 0,
  //           insurancePolicyKey: simulationData.insurancePolicyKey || '',
  //           interestRate: simulationData.interestRate || 10,
  //           amortizationTypeKey: simulationData.amortizationTypeKey || '',
  //           insurerKey: simulationData.insurerKey || 0,
  //           monthWithoutPaymentKey: simulationData.monthWithoutPaymentKey || 0,
  //           deferredPaymentKey: simulationData.deferredPayment || '',
  //           qtdParcCarencia: simulationData.monthsDeferredPaymentQt || 0,
  //           cdPortal: 'PG',
  //           isIOFIncludedInClosingCostCredit: simulationData.isIOFIncludedInClosingCostCredit || false,
  //           offerKey: simulationData.offerKey
  //         }
  //       } as mutationTypes.saveCreditSimulationVariables,
  //       errorPolicy: 'all',
  //       fetchPolicy: 'no-cache'
  //     })
  //     .pipe(
  //       map(r => {
  //         if (r.errors) throw r.errors[0];
  //         return r.data as mutationTypes.saveCreditSimulation;
  //       }),
  //       catchError(err => throwError(err))
  //     );
  // }

  // saveOwnersSimulation(proposalId: string) {
  //   return this.apollo
  //     .query({
  //       query: mutations.SAVE_OWNERS_SIMULATION,
  //       variables: {
  //         input: {
  //           proposalId
  //         }
  //       } as mutationTypes.saveOwnersSimulationVariables,
  //       errorPolicy: 'all',
  //       fetchPolicy: 'no-cache'
  //     })
  //     .pipe(
  //       map(r => {
  //         if (r.errors) throw r.errors[0];
  //         return r.data as mutationTypes.saveOwnersSimulation;
  //       }),
  //       catchError(err => throwError(err))
  //     );
  // }

  private createSaveSimulationPayload(input) {
    return {
      simulationId: input.simulationId,
      selectedProposalOptions: {
        channel: input.channel || null,
        loanTypeKey: input.loanTypeKey || null,
        buildingTypeKey: input.buildingTypeKey || null,
        propertyLocationKey: input.propertyLocationKey || null,
        portabilityTypeKey: input.portabilityTypeKey || null,
      },
      simulationEntryValues: {
        propertyValue: input.propertyValue || 0,
        evaluationValue: input.evaluationValue || 0,
        downPaymentAmount: input.downPaymentAmount || null,
        fgtsAmount: input.fgtsAmount || 0,
        loanAmountValue: input.loanAmountValue || 0,
        isPropertyNew: input.isPropertyNew || null,
        termInMonths: input.termInMonths || null,
        termInYears: input.termInYears || null,
        monthsDeferredPaymentQt: input.monthsDeferredPaymentQt || null,
      },
      selectedOptions: {
        typeOfferWarrantyShared: input.typeOfferWarrantyShared || '',
        campaignKey: input.campaignKey || '',
        marketSegmentKey: input.marketSegmentKey || '',
        offerKey: input.offerKey || '',
        paymentTypeKey: input.paymentTypeKey || '',
        monthWithoutPaymentKey: input.monthWithoutPaymentKey || '',
        insurerKey: input.insurerKey || '',
        insurancePolicyKey: input.insurancePolicyKey || '',
        deferredPaymentKey: input.deferredPaymentKey || ''
      },
      isAppraisalfeesInClosingCostCredit: input.isAppraisalfeesInClosingCostCredit || false,
      isIOFIncludedInClosingCostCredit: input.isIOFIncludedInClosingCostCredit || false,
      isTransferTaxInClosingCostCredit: input.isTransferTaxInClosingCostCredit || false,
      estimatedTRRate: input.estimatedTRRate || 0,
      usesEstimatedTRrate: input.usesEstimatedTRrate || false,
      interestRatesToCalculate: [] // ???
    };
  }

  private createSaveSimulationPayloadOffers(input) {
    return {
      simulationId: input.simulationId,
      selectedProposalOptions: {
        channel: input.channel || null,
        loanTypeKey: input.loanTypeKey || null,
        buildingTypeKey: input.buildingTypeKey || null,
        propertyLocationKey: input.propertyLocationKey || null,
        portabilityTypeKey: input.portabilityTypeKey || null,
      },
      simulationEntryValues: {
        propertyValue: input.propertyValue || 0,
        evaluationValue: input.evaluationValue || 0,
        downPaymentAmount: input.downPaymentAmount || null,
        fgtsAmount: input.fgtsAmount || 0,
        loanAmountValue: input?.loanAmountValue || 0,
        isPropertyNew: input?.isPropertyNew || null,
        termInMonths: input?.termInMonths || null,
        termInYears: input?.termInYears || null,
        monthsDeferredPaymentQt: input?.monthsDeferredPaymentQt || null,
      },
      selectedOptions: {
        typeOfferWarrantyShared: input.typeOfferWarrantyShared || '',
        campaignKey: input.campaignKey || '',
        marketSegmentKey: input.marketSegmentKey || '',
        offerKey: input.offerKey || '',
        paymentTypeKey: input.paymentTypeKey || '',
        monthWithoutPaymentKey: input.monthWithoutPaymentKey || '',
        insurerKey: input.insurerKey || '',
        insurancePolicyKey: input.insurancePolicyKey || '',
        deferredPaymentKey: input.deferredPaymentKey || '',
      },
      isAppraisalfeesInClosingCostCredit: input.isAppraisalfeesInClosingCostCredit || false,
      isIOFIncludedInClosingCostCredit: input.isIOFIncludedInClosingCostCredit || false,
      isTransferTaxInClosingCostCredit: input.isTransferTaxInClosingCostCredit || false,
      estimatedTRRate: input.estimatedTRRate || 0,
      usesEstimatedTRrate: input.usesEstimatedTRrate || false,
      interestRatesToCalculate: [] // ???
    };
  }

  getWarranty(nslang1: String, nslang2: String, nslang3: String): Observable<any> {
    return this.apollo
      .query({
        query: queries.GET_WARRANTY_SHARING_SIMULATION,
        variables: {
          nslang1: nslang1,
          nslang2: nslang2,
          nslang3: nslang3
        } as any,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map(r => {
          if (r.errors) throw r.errors[0];
          return r.data as any;
        }),
        catchError(err => throwError(err))
      );
  }
}
