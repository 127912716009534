<footer>
  <!-- mobile-->
  <div class="mobile">
    <div class="container-fluid bottom">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-md-12 bottom-area">
            <ul>
              <li>ASSESSORIA DE CRÉDITO IMOBILIÁRIO</li>
              <li>Capitais e regiões metropolitanas:<span>4004 3535</span></li>
              <li>Demais localidades:<span>0800 702 3535</span></li>
            </ul>
          </div>
        </div>
        <div class="term ">
          <a href="{{term}}" target="_blank">
              <span class="term-icon">
                  <i class="material-icons">description</i>
                  <span class="term-text">{{'footer.terms and conditions of use of the platform' | translate}}</span>
              </span>
          </a>
      </div>
      </div>
    </div>
  </div>
  <!-- desktop -->
  <div class="desktop">
    <div class="container">
      <a href="https://www.santander.com.br/atendimento-para-voce/app-santander" target="_blank" (click)="handleClick($event)">
        <div class="row">
          <div class="col-md-1 logo-santander"></div>
          <div class="col-md-6 app-santander">
            <h3 id="footer-app-title">{{ 'footer.app' | translate: { value: clientName } }}</h3>
          </div>
        </div>
      </a>
      <hr />
      <div class="row">
        <div class="col-md-4">
          <div class="bottom-area">
            <h3>{{ 'footer.attendance' | translate }}</h3>
          </div>
        </div>
        <div class="col-md-8">
          <div class="bottom-area">
            <div class="tel-title" id="showAtendimento" (click)="showAccordion('showAtendimento')">
              <h4 class="h4-arrow" [ngClass]="arrow">
                {{ 'footer.central of attendance' | translate }}
              </h4>
              <div class="box-accordion" *ngIf="accordion.showAtendimento">
                <div class="content" [innerHTML]="'footer.central of attendence-text' | translate"></div>
              </div>
            </div>
          </div>

          <div class="bottom-area">
            <div class="tel-title" id="showTelSac" (click)="showAccordion('showTelSac')">
              <h4 class="h4-arrow" [ngClass]="arrow">
                {{ 'footer.sac' | translate }}
              </h4>
              <div class="box-accordion" *ngIf="accordion.showTelSac">
                <div class="content" [innerHTML]="'footer.sac-text' | translate"></div>
              </div>
            </div>
          </div>

          <div class="bottom-area">
            <div class="tel-title" id="showTelOuvidoria" (click)="showAccordion('showTelOuvidoria')">
              <h4 class="h4-arrow" [ngClass]="arrow">
                {{ 'footer.ouvidoria' | translate }}
              </h4>
              <div class="box-accordion" *ngIf="accordion.showTelOuvidoria">
                <div class="content" [innerHTML]="'footer.ouvidoria-text' | translate"></div>
              </div>
            </div>
          </div>

          <div class="bottom-area">
            <div class="tel-title" id="showCaixaEletronico" (click)="showAccordion('showCaixaEletronico')">
              <h4 class="h4-arrow" [ngClass]="arrow">
                {{ 'footer.cash mashines' | translate }}
              </h4>
              <div class="box-accordion" *ngIf="accordion.showCaixaEletronico">
                <div class="content" [innerHTML]="'footer.cash mashines-text' | translate"></div>
              </div>
            </div>
          </div>

          <div class="bottom-area">
            <div class="tel-title" id="showBuscaAgencia" (click)="showAccordion('showBuscaAgencia')">
              <h4 class="h4-arrow" [ngClass]="arrow">
                {{ 'footer.find agency' | translate }}
              </h4>
              <div class="box-accordion" *ngIf="accordion.showBuscaAgencia">
                <div class="content" [innerHTML]="'footer.find agency-text' | translate"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr/>
      <div class="term ">
          <a href="{{term}}" target="_blank">
              <span class="term-icon">
                  <i class="material-icons">description</i>
                  <span class="term-text">{{'footer.terms and conditions of use of the platform' | translate}}</span>
              </span>
          </a>
      </div>
    </div>
  </div>
</footer>
