import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

declare global {
  interface Navigator {
      msSaveBlob?: (blob: any, defaultName?: string) => boolean
  }
}

interface EcmDownload {
  fileContent?: string;
  returnCode?: string;
  returnDesc?: string;
  uuid?: string;
  mimeType?: string;
  name?: string;
  blob?: Blob;
}

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  private ios = false;

  constructor(private http: HttpClient) {}

  /**
   * If using Internet Explorer uses msSaveBlob method
   */
  private saveBlobIE(blob: Blob, filename: string): boolean {
    return window.navigator.msSaveBlob(blob, filename);
  }

  /**
   * Creates a link to force the file download
   */
  private saveBlobDefault(blob: Blob, filename: string, target = '_self'): boolean {
    const blobURL = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.download = filename;
    anchor.href = blobURL;
    anchor.target = target;
    anchor.click();
    anchor.remove();
    return true;
  }

  /**
   * Verify if Internet Explorer is the current browser
   */
  private isIE(): boolean {
    return window && window.navigator && window.navigator.msSaveBlob ? true : false;
  }

  /**
   * Verify if access comes from IOS device
   */
  private isIOS(): boolean {
    const iDevices = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'];

    if (!!navigator.platform) {
      while (iDevices.length) {
        if (navigator.platform === iDevices.pop()) {
          this.ios = true;
          return true;
        }
      }
    }
  }

  /**
   * Saves blob data as a file, triggering browser download
   */
  public saveBlob(blob: Blob, filename: string) {
    if (this.isIE()) return this.saveBlobIE(blob, filename);
    if (this.isIOS()) return this.saveBlobDefault(blob, filename, '_blank');
    return this.saveBlobDefault(blob, filename, '_blank');
  }

  /**
   * Loads data from url, creates a blob and downloads it
   */
  public downloadFile(url: string, filename = 'file', type = '', headers = new HttpHeaders()): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http.get(url, { headers, responseType: 'blob' }).subscribe(
        data => {
          const blob = new Blob([data], { type });
          this.saveBlob(blob, filename);
          resolve();
        },
        error => {
          reject(error);
        }
      );
    });
  }

  /**
   * Download shortcut for PDF files (enforces "application/pdf" mime-type)
   */
  public downloadPDF(url: string, filename = 'file.pdf', headers = new HttpHeaders()): Promise<any> {
    return this.downloadFile(url, filename, 'application/pdf', headers);
  }

  /**
   * Convert base64 to blob
   */
  public base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; sliceIndex++) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  /**
   * Download field from ECM endpoint
   */
  public downloadFileECM(url: string): Observable<EcmDownload> {
    return this.http.get<EcmDownload>(url).pipe(
      map(data => {
        const blob = this.base64toBlob(data.fileContent, data.mimeType);
        return { blob, name: data.name, mimeType: data.mimeType };
      })
    );
  }
}
