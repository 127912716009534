import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';

export interface VivereErrorMessage {
  message: string;
}

export interface VivereError {
  messages: VivereErrorMessage[];
  statusCode: number;
  timestamp: string;
  path: string;
  errorId: string;
  exception: string;
  severity: VivereErrorSeverity;
  type: VivereErrorType;
}

export enum VivereErrorSeverity {
  ERRO = 'ERROR',
  WARN = 'WARN'
}

export enum VivereErrorType {
  BUSINESS = 'BUSINESS',
  SYSTEM = 'SYSTEM'
}

class ERROR {
  messages?: Array<any>;
  statusCode?: any;
  severity?: string;
  type?: any;
}
export class EmitterAlertInterface {
  error: ERROR;
}

export class Alert {
  type: string;
  message: string;
  error: VivereError;

  constructor(message: string, error: HttpErrorResponse) {
    if (error == null) {
      this.type = 'success';
      this.message = message;
    } else {
      this.type = 'danger';

      this.error = error.error as VivereError;
      if (this.error && this.error.statusCode) {
        if (this.error.severity === VivereErrorSeverity.WARN) {
          this.type = 'warning';
        }

        this.message = this.error.messages[0].message + this.error.messages[1].message;

        if (this.error.type === VivereErrorType.SYSTEM) {
          const headers: HttpHeaders = error.headers;
          if (headers.get('X-TRID')) {
            this.message = this.message + ' (ID Transacao: ' + headers.get('X-TRID') + ')';
          }
        }
      } else {
        if (error.status === 400 && message != null) {
          this.message = message;
        } else {
          this.message = 'System is unavailable. Please try it later.';
        }
      }
    }
  }
}
