import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from '@app/state';

import * as fromSimulation from '@app/state/simulation';
import { StorageEncryptService } from '@shared/services/storageEncrypt.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.container.html',
  styleUrls: ['./home.container.scss']
})
export class HomeContainerComponent implements OnInit {
  disablePortal = environment.featureToggle.disablePortal === 'true';

  private baseUrl = `${environment.urlPortalCliente}`.replace(/\/\/$/gm, '/');

  constructor(private router: Router, private store$: Store<AppState>, private storageEncryptService: StorageEncryptService) { }

  ngOnInit() {
    this.storageEncryptService.removeSession('type');
    this.storageEncryptService.removeSession('goal');
  }

  goToOldSimulation() {
    this.store$.dispatch(
      new fromSimulation.actions.RedirectExternal({
        url: `${this.baseUrl}/#/dados-pessoais`
      })
    );
  }

  goToProposal() {
    if (environment.featureToggle.newConsultProposal === 'true') {
      this.router.navigate(['../consult-proposal']);
    } else {
      this.store$.dispatch(
        new fromSimulation.actions.RedirectExternal({
          url: `${this.baseUrl}/#/acompanhesuaproposta`
        })
      );
    }
  }

  goToSimulation() {
    this.router.navigate(['/dados-pessoais']);
  }
}
