import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-offset-menu',
  templateUrl: './offset-menu.component.html',
  styleUrls: ['./offset-menu.component.scss']
})
export class OffsetMenuComponent {
  @Input() isActive: boolean;
  @Output() isLogout = new EventEmitter();

  constructor() {}

  toggleMenu() {
    this.isActive = !this.isActive;
  }

  logout(newSimulaion: boolean): void {
    this.isLogout.emit(newSimulaion);
  }

  
}
