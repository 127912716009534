import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './widgets/footer/footer.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgSelectConfig } from '@ng-select/ng-select';

@NgModule({
  declarations: [FooterComponent],
  imports: [CommonModule, TranslateModule],
  exports: [FooterComponent],
  providers: []
})
export class SharedModule {
  constructor(private translateConfig: NgSelectConfig, private translateService: TranslateService) {
    this.translateConfig.notFoundText = this.translateService.instant('No items found');
    this.translateConfig.placeholder = this.translateService.instant('Selecione');
  }
}
