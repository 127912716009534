import { ErrorHandlingActions, ErrorHandlingActionTypes } from './error-handling.actions';

export interface ErrorState {
  message: null | string;
}

export const initialState = {
  message: ''
};

export function errorHandlingReducer(state = initialState, action: ErrorHandlingActions) {
  switch (action.type) {
    case ErrorHandlingActionTypes.ADD_ERROR: {
      return {
        message: action.payload.message
      };
    }

    case ErrorHandlingActionTypes.CLEAR_ERROR: {
      return {
        message: ''
      };
    }

    default:
      return initialState;
  }
}
