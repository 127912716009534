import { RouterStateSnapshot, Params } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import { Injectable } from "@angular/core";

export interface RouterStoreState {
  url: string;
  params: Params;
  queryParams: Params;
}

@Injectable()
export class CustomSerializer implements RouterStateSerializer<RouterStoreState> {
  serialize(routerState: RouterStateSnapshot): RouterStoreState {
    let route = routerState.root;

    while (route.firstChild) {
      route = route.firstChild;
    }

    const {
      url,
      root: { queryParams }
    } = routerState;
    const { params } = route;

    // Only return an object including the URL, params and query params
    // instead of the entire snapshot
    return {
      url,
      params,
      queryParams
    };
  }
}

export const CustomSerializerProvider = {
  provide: RouterStateSerializer,
  useClass: CustomSerializer
};
