import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';

import * as fromHelpers from '@app/state/helpers';
import { DataLayerSantanderService } from '@projects/cliente/src/app/shared/service/dataLayer/novo-data-layer.service';

@Component({
  selector: 'app-helper-button',
  templateUrl: './helper-button.component.html',
  styleUrls: ['./helper-button.component.scss']
})
export class HelperButtonComponent implements OnInit, OnDestroy {
  @Input() helperName: string;

  show = false;
  faInfoCircle = faInfoCircle;

  private questionName: string;
  private subscription = new Subscription();

  constructor(private store: Store<any> , private dataLayerSantander: DataLayerSantanderService) {}

  ngOnInit() {
    this.subscribeToHelpersFields();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private subscribeToHelpersFields(): void {
    this.subscription.add(
      this.store.select(fromHelpers.selectors.selectHelperFields).subscribe(state => {
        if (!state) return;

        const helper = Object.keys(state).find(key => key === this.helperName);
        this.show = !!helper;

        // If question is changing for same helper name
        if (this.helperName && this.questionName && this.questionName !== state[helper]) {
          this.store.dispatch(
            new fromHelpers.actions.UpdateHelperQuestions({
              prev: this.questionName,
              curr: state[helper]
            })
          );
        }

        this.questionName = state[helper];
      })
    );
  }

  onToggleHelper() {
    this.store.dispatch(new fromHelpers.actions.ToggleHelperQuestions(this.questionName));
    const helperName = this.translateField(this.helperName);
    const questionName = this.translateField(this.questionName);
    if(helperName) this.sendToClicEvent(false, 'clicou' , 'tooltip:informacoes:' + helperName);
    if(questionName) this.sendToClicEvent(false, 'clicou' , 'tooltip:informacoes:' + questionName);
  }

  sendToClicEvent(nonInterection, eventAction, eventLabel) {
    this.dataLayerSantander.sendCustomToDataLayer(
      nonInterection,
      eventAction,
      eventLabel
    );
  }

  translateField(fieldName: string) {
    let translatedFieldName: string;
    switch (fieldName) {
      case 'birthDate':
        translatedFieldName = 'data-nascimento';
        break;
      case 'phone':
        translatedFieldName = 'celular';
        break;
      case 'monthlyIncome':
        translatedFieldName = 'renda-mensal';
        break;
      case 'otherParticipant':
        translatedFieldName = 'outro-participante';
        break;
      case 'CD_TTP_USCGI':
        translatedFieldName = 'emprestimo-usecasa';
        break;
      case 'CD_TTP_AQIMV':
        translatedFieldName = 'financiamento-imovel';
        break;
      case 'CD_TTP_PORTA':
        translatedFieldName = 'portabilidade';
        break;
      case 'loanAmountValue':
        translatedFieldName = 'valor-financiamento';
        break;
      case 'financingMonthPeriod':
        translatedFieldName = 'prazo-anos';
        break;
        case 'INSTALLMENT_INITIAL_VALUE':
        translatedFieldName = 'valor-parcela';
        break;
      case 'INSURER_KEY':
        translatedFieldName = 'seguradora';
        break;
      case 'PAYMENT_TYPE_FIXED':
        translatedFieldName = 'tipo-parcela';
        break;
      case 'CET':
        translatedFieldName = 'cet';
        break;
      default:
        translatedFieldName = null;

      }
      return translatedFieldName
  }
}
