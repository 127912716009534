import { environment } from 'src/environments/environment';

export const cryptoConfiguration = {
  enable: environment.featureToggle.crypto.active,
  requestExceptions: [
    'esbapi.santander',
    'esbapihi.santander',
    'auth.santander',
    'cryptographic-security/v1/key/public/js?gw-app-key=',
    'cipher/token'
  ],
  key: environment.featureToggle.asymmetricKey,
  apiRootUrl: environment.apiRootUrl
};
