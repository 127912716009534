import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { personalDataConstant } from '@shared/constants/personal-data.constant';

@Component({
  selector: 'app-form-other-participant',
  templateUrl: './form-other-participant.component.html',
  styleUrls: ['./form-other-participant.component.scss']
})
export class FormOtherParticipantComponent {
  @Input() formOtherParticipant: FormGroup;
  @Input() visibleFields: Array<string>;
  @Input() telephoneNumberMask: string;
  @Input() editableFields: Array<string>;
  @Input() isSimulationInProgress: boolean;

  constructor() {}

  

  /**
   * Check visible fields
   */
  isVisible(fieldName: string): boolean {
    if (!this.visibleFields) return;

    const exceptions = [];
    const field = this.formOtherParticipant.get(fieldName);
    const isFieldVisible = this.visibleFields.find(f => f === personalDataConstant[fieldName]);
    const isFieldEditable = this.editableFields.find(f => f === personalDataConstant[fieldName]);
    const fieldState = isFieldEditable && this.isSimulationInProgress ? 'enable' : 'disable';

    if (field && !isFieldVisible) {
      field.disable();
      return false;
    }

    if (field && !exceptions.includes(fieldName)) {
      field[fieldState]();
    }

    return true;
  }
}
