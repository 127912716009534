import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DataLayerSantanderService } from '@projects/cliente/src/app/shared/service/dataLayer/novo-data-layer.service';
import { StorageEncryptService } from '@shared/services/storageEncrypt.service';

@Component({
  selector: 'app-header-proposal-summary',
  templateUrl: './header-proposal-summary.component.html',
  styleUrls: ['./header-proposal-summary.component.scss']
})
export class HeaderProposalSummaryComponent {
  @Input() pathImageClient: string;
  @Input() hasInconsistencies: boolean;
  @Input() isFinalized: boolean;
  @Input() borrowers: any[];
  @Input() nameExibition: any;
  @Input() formProponentData: FormGroup;
  @Input() utmSource: boolean;

  @Output() fillProponentData = new EventEmitter();
  @Output() redirectTo = new EventEmitter();

  openProponentData: boolean;

  constructor(private dataLayerSantander: DataLayerSantanderService,     private storageEncryptService: StorageEncryptService,
  ) {
    this.sendPageView(undefined, '/simuladorcliente/resumo-da-proposta');
  }



  onRedirectOrToggle(p, i) {
    let productName = this.storageEncryptService.getSessionStore('productName');
    this.sendToClickEvent(false , 'clicou' , 'botao:iniciar', productName);

    if (!p.isRegistrationCompleted) {
      this.redirectTo.emit(i);
      return;
    }

    if (p.isRegistrationCompleted) {
      this.openProponentData = !this.openProponentData;
      this.fillProponentData.emit(p);
    }
  }

  sendToClickEvent(nonInterection, eventAction, eventLabel, productName) {
    this.dataLayerSantander.sendCustomToDataLayer(
      nonInterection,
      eventAction,
      eventLabel,
      productName
    );
  }
  sendPageView(url, customUrl) {
    this.dataLayerSantander.sendPageViewToDataLayer(
      url,
      customUrl
    );
  }
}
