<!-- Inconsistent -->
<div class="container--inconsistencies" *ngIf="hasInconsistencies">
  <div class="bg-gray">
    <div class="container py-5">
      <div class="row align-items-center justify-content-center">
        <div class="col-4 col-md-auto text-right">
          <img src="assets/{{ pathImageClient }}/images/icon-summary.svg" alt="Resumo" class="icon icon--summary" />
        </div>

        <div class="col-8 col-md-9">
          <h2 class="font-weight-bold">
            Olá {{ nameExibition }}, nessa etapa você solicitará análise da sua proposta, sem compromisso, logo após
            você poderá iniciar a etapa de contratação, se houver interesse.
          </h2>
        </div>
      </div>
    </div>
  </div>

  <div class="container" *ngIf="!utmSource">
    <h2 class="text-center">
      Olá <strong>{{ borrowers[0]?.name }}</strong
      >, há informações que divergem de nosso cadastro.
    </h2>

    <h2 class="text-center">
      Por favor, confira as informações e refaça a simulação ou entre em contato com nossa central de atendimento.
    </h2>
  </div>

  <div class="container" *ngIf="utmSource">
    <h2 class="text-center">
      Olá <strong>{{ borrowers[0]?.name }}</strong
      >, ocorreu um erro ao processar sua solicitação.
    </h2>

    <h2 class="text-center">
        Por favor, tente novamente mais tarde.
    </h2>
  </div>
</div>

<!-- Consistent -->
<div class="bg-gray" *ngIf="!hasInconsistencies">
  <div class="container py-5">
    <div class="row align-items-center justify-content-center">
      <div class="col-4 col-md-auto text-right">
        <img src="assets/{{ pathImageClient }}/images/icon-summary.svg" alt="Resumo" class="icon icon--summary" />
      </div>

      <div class="col-8 col-md-9">
        <h2 class="font-weight-bold">
          Olá {{ nameExibition }},

          <span *ngIf="!isFinalized"
            >para seguirmos com a sua solicitação precisamos que complete as informações do seu cadastro.</span
          >
          <span *ngIf="isFinalized"
            >nessa etapa você solicitará análise da sua proposta, sem compromisso, logo após você poderá iniciar a etapa
            de contratação, se houver interesse.</span
          >
        </h2>
      </div>
    </div>
  </div>

  <div class="container pb-5">
    <div class="row justify-content-center">
      <button
        class="col-12 col-md-10 no-bg-desk button button--proponent"
        *ngFor="let borrower of borrowers; let i = index"
        (click)="onRedirectOrToggle(borrower, i)"
      >
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex align-items-center">
            <span
              class="material-icons pr-2"
              [ngClass]="{
                'text-danger': !borrower?.isRegistrationCompleted && !borrower?.isRegistrationInconsistent,
                'text-success': borrower?.isRegistrationCompleted && !borrower?.isRegistrationInconsistent
              }"
              >{{
                !borrower?.isRegistrationCompleted && !borrower?.isRegistrationInconsistent
                  ? 'error_outline'
                  : 'check_circle'
              }}</span
            >

            <span class="text-uppercase button__name">{{ borrower.name }}</span>
          </div>

          <span
            *ngIf="!borrower?.isRegistrationCompleted && !borrower?.isRegistrationInconsistent"
            class="button__title text-danger"
          >
            Iniciar
            <img
              src="assets/{{ pathImageClient }}/images/icon-arrow-circle-right.svg"
              alt="Iniciar"
              class="icon icon--arrow-circle"
            />
          </span>

          <span
            class="button__title text-success"
            *ngIf="borrower?.isRegistrationCompleted && !borrower?.isRegistrationInconsistent"
          >
            Alterar
            <img
              src="assets/{{ pathImageClient }}/images/icon-arrow-circle-right-green.svg"
              alt="Alterar"
              class="icon icon--arrow-circle"
            />
          </span>
        </div>
      </button>

      <app-form-dados-proponente
        *ngIf="openProponentData"
        [formProponentData]="formProponentData"
      ></app-form-dados-proponente>
    </div>
  </div>
</div>
