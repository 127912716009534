import { Action } from '@ngrx/store';

export enum ParametrosPerfilActionTypes {
  // Reset
  RESET = '[Perfil] Reset',
  RESET_SUCESSO = '[Perfil] Reset sucesso',

  // Token PP
  SALVAR_TOKEN_PP = '[Perfil] Salvar Token PP',
  RECUPERAR_TOKEN_PP = '[Perfil] Recuperar Token PP'
}

export class Reset implements Action {
  readonly type = ParametrosPerfilActionTypes.RESET;
  constructor() {}
}

export class ResetSucesso implements Action {
  readonly type = ParametrosPerfilActionTypes.RESET_SUCESSO;
  constructor() {}
}

export class SalvarTokenPP implements Action {
  readonly type = ParametrosPerfilActionTypes.SALVAR_TOKEN_PP;
  constructor(public payload: any) {}
}

export class RecuperarTokenPP implements Action {
  readonly type = ParametrosPerfilActionTypes.RECUPERAR_TOKEN_PP;
  constructor() {}
}
export type ParametrosPerfilActions = Reset | ResetSucesso | SalvarTokenPP | RecuperarTokenPP;
