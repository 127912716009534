import { environment } from 'src/environments/environment';
import { configuration } from 'arch-onefinancial-auth.lib';

export const authConfiguration: configuration = {
  storageCrypto: {
    enabled: environment.featureToggle.cryptoStorageActive,
    iv: environment.featureToggle.crypto.iv,
    key: environment.featureToggle.crypto.key
  },
  authExcept: [environment.tokenRevoke, environment.tokenApiRootUrl, 'totp'],
  apiRootBase: environment.apiRootBase,
  baseEndpointIdentity:  environment.featureToggle.crypto.active === 'true' ? 'cipherasym/lgn' : 'lgn',
  clientID: environment.clientID,
  environment: environment.namespace,
  expiredSessionUrl: environment.apiRootBase + '/simuladorpfpc/not-found',
  apiRootUrl: environment.apiRootUrl,
  loginSSO:
    environment.apiRootBase +
    '/lgn/realms/' +
    environment.namespace +
    '/protocol/openid-connect/auth?response_type=code&client_id=' +
    environment.clientID,
  logoutSSO:
    environment.apiRootBase +
    '/' + environment.cont,
  useSSO: 'false',
  clientIDPublicApi: environment.client_id_simulacao,
  clientSecretPublicApi: environment.clientSecretSimulacao
};
