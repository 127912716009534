import { Component, Input, ContentChild, AfterContentInit, Output, EventEmitter } from '@angular/core';
import { NgModel, FormControlName } from '@angular/forms';

import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Skins } from '@shared/models/skins.model';
import { environment } from 'src/environments/environment';
import { faCheck, faTimes } from '@fortawesome/pro-light-svg-icons';
import { faEdit } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-input-text-container',
  templateUrl: './input-text-container.component.html',
  styleUrls: ['./input-text-container.component.scss'],
})
export class InputTextContainerComponent implements AfterContentInit {
  @Input() label: string;
  @Input() helperName: string;
  @Input() customErrorMessage: string;
  @Input() minValue?: number;
  @Input() maxValue?: number;
  @Input() acceptZero?: boolean;
  @Input() labelMinValue?: number;
  @Input() labelMaxValue?: number;
  @Input() formatMinMax?: string;
  @Input() touchedOnly = false;
  @Input() isLabelStrong = true;
  @Input() isEditable = false;
  @Input() codeInvalid = false;
  @Input() isUseCasaMais = false;

  @Output() toggleHelper = new EventEmitter();
  @Output() clickEdit = new EventEmitter();

  @ContentChild(NgModel) model: NgModel;
  @ContentChild(FormControlName) control: FormControlName;

  input: any;
  faCheck = faCheck;
  faTimes = faTimes;
  faEdit = faEdit;

  constructor(private store: Store<any>, private translateService: TranslateService) { }



  ngAfterContentInit() {
    this.input = this.control || this.model;

    if (this.input === undefined) {
      throw new Error('Esse componente precisa ser usado com uma diretiva ngModel ou FormControlName');
    }
  }

  validErrorMsg() {
    if (
      this.input.touched &&
      this.input.errors &&
      this.input.errors.required &&
      (this.minValue === undefined || !this.maxValue === undefined) &&
      this.input.name === 'email' &&
      environment.skin === Skins.CLIENTE
    ) {
      return this.translateService.instant(
        'common.Mandatory field - Important: fill in correctly. We will send your simulation and letter of credit by email.'
      );
    } else if (
      this.input.touched &&
      this.input.errors &&
      this.input.errors.required &&
      this.input.name === 'code' &&
      this.codeInvalid &&
      environment.skin === Skins.CLIENTE
    ) {
      return this.translateService.instant('errorMessages.Invalid Code');
    } else {
      return this.translateService.instant('common.mandatory field');
    }
  }

  isInvalidMin(): boolean {
    /**
     * Check if field is zero
     */
    if (this.acceptZero && this.input.control.value === 0) {
      return false;
    }
    if (!this.input.control.value) return true;
    return this.input.invalid && (this.input.dirty || this.input.touched) && this.input.errors.invalidLowerThanMin;
  }

  isInvalidMax(): boolean {
    /**
     * Check if field is zero
     */
    if (this.acceptZero && this.input.control.value === 0) {
      return false;
    }
    return this.input.invalid && (this.input.dirty || this.input.touched) && this.input.errors.invalidGreaterThanMax;
  }

  /**
   * Check if field is valid
   */
  hasSuccess(): boolean {
    return this.input.valid && (this.input.dirty || this.input.touched);
  }

  /**
   * Check if field has error
   */
  hasError(): boolean {
    return this.input.invalid && (this.input.dirty || this.input.touched);
  }

  /**
   * Emit onClick edit event
   */
  onClickEdit() {
    return this.clickEdit.emit();
  }
}
