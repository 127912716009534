import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, withLatestFrom, switchMap, tap, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { of, BehaviorSubject, EMPTY } from 'rxjs';
import { ModalTemplateComponent } from '@app/shared/widgets/modal-template/modal-template.component';
import { SimulationService } from '@app/shared/services/simulation.service';
import { AppState } from '..';
import { ErrorService } from '@app/shared/services/error.service';
import { DownloadService } from '@shared/services/download.service';
import { actions } from './';
import { environment } from 'src/environments/environment';
import { LoginService } from '@projects/cliente/src/app/login/services/login.service';

import * as fromProposal from './../proposal';
import * as fromParticipants from './../participants';
import * as fromErrorHandling from './../error-handling';
import * as fromSimulation from './../simulation';
import { StorageEncryptService } from '@shared/services/storageEncrypt.service';

@Injectable()
export class SimulationEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private simulationService: SimulationService,
    private errorService: ErrorService,
    private router: Router,
    private downloadService: DownloadService,
    private loginService: LoginService,
    private storageEncryptService: StorageEncryptService
  ) {}

  modal = new BehaviorSubject({
    show: false,
    template: ModalTemplateComponent,
    content: Object,
    closed: () => {
      return;
    }
  });

  /**
   * Save Simulation Data
   */
  @Effect()
  sendSimulation = this.actions$.pipe(
    ofType<actions.SaveSimulation>(actions.SimulationActionTypes.SAVE_SIMULATION),
    switchMap(action => {
     let usecasa =  this.storageEncryptService.getSessionStore('usecasa');
      return this.simulationService.save(action.payload, usecasa).pipe(
        map(response => {
          if (response.saveSimulationDataSimulation && response.saveSimulationDataSimulation.isSuccessful) {
            return new actions.SaveSimulationSuccess();
          }

          return new fromErrorHandling.actions.AddError(this.errorService.addEmptyAlert('Erro ao salvar simulação'));
        }),
        catchError(err => {
          if (err.extensions) {
            return of(new fromErrorHandling.actions.AddError(this.errorService.addGraphQLAlert(err)));
          }

          return of(new fromErrorHandling.actions.AddError(this.errorService.addAlertError(err)));
        })
      );
    })
  );

  @Effect({ dispatch: false })
  saveSimulationSuccess = this.actions$.pipe(
    ofType<actions.SaveSimulationSuccess>(actions.SimulationActionTypes.SAVE_SIMULATION_SUCCESS),
    tap(() => this.router.navigate(['/resumo-da-proposta']))
  );

  /**
   * Get simulation summary
   */
  @Effect()
  getSummary = this.actions$.pipe(
    ofType<actions.GetSummary>(actions.SimulationActionTypes.GET_SUMMARY),
    withLatestFrom(this.store$.pipe(select(fromProposal.selectors.selectSimulationID))),
    switchMap(([_, simulationId]) => {

      let idSimulacao = simulationId;
      if (!idSimulacao) { 
        const session = JSON.parse(this.storageEncryptService.getSessionStore('data'));
        idSimulacao = session.idProposta;   
      }

      return this.simulationService.getResumoProposta(idSimulacao).pipe(
        switchMap(response => {
          return [
            new fromParticipants.actions.SetParticipants({
              participants: [response.getSimulationSummary.firstBorrower, response.getSimulationSummary.secondBorrower]
            }),
            new fromSimulation.actions.GetSummarySuccess(response.getSimulationSummary)
          ];
        }),
        catchError(err => {
          if (err.extensions) {
            return of(new fromErrorHandling.actions.AddError(this.errorService.addGraphQLAlert(err)));
          }

          return of(new fromErrorHandling.actions.AddError(this.errorService.addAlertError(err)));
        })
      );
    })
  );

  @Effect()
  confirmSummary = this.actions$.pipe(
    ofType<actions.ConfirmSummary>(actions.SimulationActionTypes.CONFIRM_SUMMARY),
    withLatestFrom(
      this.store$.pipe(select(fromSimulation.selectors.selectIdProposta)),
      this.store$.pipe(select(fromSimulation.selectors.selectResumoProposta))
    ),
    switchMap(([action, simulationId, proponents]) => {
      return this.simulationService
        .confirmSummary(simulationId, action.payload.portal, proponents.firstBorrower, proponents.secondBorrower, action.payload.unitId, action.payload.carSpaceUnitIdList)
        .pipe(
          map(response => {
            if (!response.confirmSummaryDataSimulation.isSuccessful) {
              return action.payload.modalAlert();
            }

            return new actions.ConfirmSummarySuccess();
          }),
          catchError(err => {
            if (err.extensions) {
              return of(new fromErrorHandling.actions.AddError(this.errorService.addGraphQLAlert(err)));
            }

            return of(new fromErrorHandling.actions.AddError(this.errorService.addAlertError(err)));
          })
        );
    })
  );

  @Effect({ dispatch: false })
  confirmSummarySuccess$ = this.actions$.pipe(
    ofType<actions.ConfirmSummarySuccess>(actions.SimulationActionTypes.CONFIRM_SUMMARY_SUCCESS),
    withLatestFrom(
      this.store$.pipe(select(fromSimulation.selectors.selectIdProposta)),
      this.store$.pipe(select(fromParticipants.selectors.selectCpfMainProponent)),
      this.store$.pipe(select(fromSimulation.selectors.selectIsQfgts))
    ),
    tap(([_, simulationId, documentNumber, isQfgts]) => {
      this.storageEncryptService.setSessionStore('data', JSON.stringify({ idProposta: simulationId, nrCpf: documentNumber }));
      if (isQfgts) {
        this.storageEncryptService.setSessionStore('isQfgtsFromSimulacao', 'true');
      }

      if (environment.skin === 'gerente') {
        const retorno = !isQfgts ? 'retorno-analise-credito' : 'subfase'
        window.location.href = `${environment.urlPortalGerente}#/v2/acompanhamento/detalhes/${retorno}`;
      } else {
        window.location.href = `${environment.urlPortalParceiro}#/v2/acompanhamento/detalhes/retorno-analise-credito`;
      }
    })
  );

  /**
   * Get PDF Simulation
   */
  @Effect()
  getPdfSimulation$ = this.actions$.pipe(
    ofType<actions.GetPdfSimulation>(actions.SimulationActionTypes.GET_PDF_SIMULATION),
    withLatestFrom(this.store$.pipe(select(fromProposal.selectors.selectSimulationID))),
    switchMap(([action, simulationId]) =>
      this.simulationService.getPdfSimulation(simulationId).pipe(
        map(response => {
          let payload = { pdfHash: response.getPdfSimulation.pdfHash };

          if (action.payload && action.payload.name) {
            payload = Object.assign(Object.create(null), payload, { name: action.payload.name });
          }

          return new actions.GetPdfSimulationSuccess(payload);
        }),
        catchError(err => {
          if (err.extensions) {
            return of(new fromErrorHandling.actions.AddError(this.errorService.addGraphQLAlert(err)));
          }

          return of(new fromErrorHandling.actions.AddError(this.errorService.addAlertError(err)));
        })
      )
    )
  );

  @Effect()
  getWarranty$ = this.actions$.pipe(
    ofType<actions.GetWarranty>(actions.SimulationActionTypes.GET_WARRANTY),
    switchMap((action) =>
      this.simulationService.getWarranty(action.payload.nslang1,action.payload.nslang2, action.payload.nslang3 ).pipe(
        map(response => {
          return new actions.GetWarrantySucess(response.getWarrantySharingSimulation);
        }),
        catchError(err => {
          if (err.extensions) {
            return of(new fromErrorHandling.actions.AddError(this.errorService.addGraphQLAlert(err)));
          }

          return of(new fromErrorHandling.actions.AddError(this.errorService.addAlertError(err)));
        })
      )
    )
  );

  /**
   * Download PDF from blob created by ECM return
   */
  @Effect({ dispatch: false })
  getPdfSimulationSuccess$ = this.actions$.pipe(
    ofType<actions.GetPdfSimulationSuccess>(actions.SimulationActionTypes.GET_PDF_SIMULATION_SUCCESS),
    switchMap(action => {
      const url = `${environment.apiRootUrl}/ecm/ecm/downloadDocument/${action.payload.pdfHash}?download=false`;

      return this.downloadService.downloadFileECM(url).pipe(
        tap(response => {
          const fileName = action.payload.name || response.name;
          this.downloadService.saveBlob(response.blob, fileName);
        }),
        catchError(() => EMPTY)
      );
    })
  );

  /**
   * Send PDF email
   */
  @Effect({ dispatch: false })
  sendPdfEmail$ = this.actions$.pipe(
    ofType<actions.SendPdfEmail>(actions.SimulationActionTypes.SEND_PDF_EMAIL),
    withLatestFrom(this.store$.pipe(select(fromProposal.selectors.selectSimulationID))),
    switchMap(([_, simulationId]) => {
      return this.simulationService.sendPdfEmail(simulationId).pipe(
        tap(response => {
          if (response.sendPdfEmail.pdfHash) {
            _.payload.modalAlert();
          }
        }),
        catchError(err => {
          if (err.extensions) {
            return of(new fromErrorHandling.actions.AddError(this.errorService.addGraphQLAlert(err)));
          }

          return of(new fromErrorHandling.actions.AddError(this.errorService.addAlertError(err)));
        })
      );
    })
  );

  /**
   * Redirect to Personas
   */
  @Effect({ dispatch: false })
  redirectPersonas$ = this.actions$.pipe(
    ofType<actions.RedirectPersonas>(actions.SimulationActionTypes.REDIRECT_PERSONAS),
    tap(action => {
      window.location.href = `${action.payload.urlPortal}/#/cadastro-nao-correntista/${action.payload.simulationId}/${
        action.payload.borrowerId
      }`;
    })
  );

  /**
   * Redirect to Resultado Analise Credito
   */
  @Effect({ dispatch: false })
  redirectResultadoAnaliseCredito$ = this.actions$.pipe(
    ofType<actions.RedirectResultadoAnaliseCredito>(actions.SimulationActionTypes.REDIRECT_RESULTADO_ANALISE_CREDITO),
    tap(action => {
      const data = {
        idProposta: action.payload.proposalId,
        idProponente: action.payload.borrowerId
      };

      this.storageEncryptService.setSessionStore('data', JSON.stringify(data));

      window.location.href = action.payload.url;
    })
  );

  /**
   * Redirect user to URL
   */
  @Effect({ dispatch: false })
  redirect = this.actions$.pipe(
    ofType<actions.Redirect>(actions.SimulationActionTypes.REDIRECT),
    tap(action => this.router.navigate([action.payload.url]))
  );

  /**
   * Redirect user to an external URL
   */
  @Effect({ dispatch: false })
  redirectExternal$ = this.actions$.pipe(
    ofType<actions.RedirectExternal>(actions.SimulationActionTypes.REDIRECT_EXTERNAL),
    tap(action => {
      window.location.href = action.payload.url;
    })
  );
}
