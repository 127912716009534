import { createSelector } from '@ngrx/store';

export const selectParameters = (state: any) => state.parameters;

export const selectPropertyValueRange = createSelector(
  selectParameters,
  state => ({
    minPropertyValue: state.minPropertyValue,
    maxPropertyValue: state.maxPropertyValue
  })
);

export const selectProposalValueRange = createSelector(
  selectParameters,
  state => ({
    minTermInMonths: state.minTermInMonths,
    maxTermInMonths: state.maxTermInMonths,
    minTermInYears: state.minTermInYears,
    maxTermInYears: state.maxTermInYears,
    minLoanAmountValue: state.minLoanAmountValue,
    maxLoanAmountValue: state.maxLoanAmountValue,
    deferredPaymentGroup: state.deferredPaymentGroup,
    isFree: state.isFree,
    allowCarencia: state.allowCarencia
  })
);

export const selectParametersminDateOfBirth = createSelector(
  selectParameters,
  state => state.minDateOfBirth
);

export const selectOfferList = createSelector(
  selectParameters,
  state => state.offersList
);

export const selectParametersmaxDateOfBirth = createSelector(
  selectParameters,
  state => state.maxDateOfBirth
);

export const selectParametersMinIncome = createSelector(
  selectParameters,
  state => state.minMonthlyIncome
);

export const selectParametersPropertyTypes = createSelector(
  selectParameters,
  state => state.propertyTypes
);

export const selectParametersFinancialObjectives = createSelector(
  selectParameters,
  state => state.financialObjectives
);

export const selectPropertyDomains = createSelector(
  selectParameters,
  state => state.propertyDomains
);

export const selectVisibleFields = createSelector(
  selectParameters,
  state => state.visibleFields
);

export const selectMonths = createSelector(
  selectParameters,
  state => state.months
);

export const selectProposalData = createSelector(
  selectParameters,
  state => ({
    visibleFields: state.visibleFields,
    codigoDistrito: state.codigoDistrito,
    objetivoFinanciamento: state.objetivoFinanciamento,
    tipoUtilizacaoImovel: state.tipoUtilizacaoImovel
  })
);

export const selectSimulationDomains = createSelector(
  selectParameters,
  state => ({
    showFlexibleInterestRateBlock: state.showFlexibleInterestRateBlock,
    showFGTSBlock: state.showFGTSBlock,
    showDownPaymentAmountBlock: state.showDownPaymentAmountBlock,
    showExpensesValueBlock: state.showExpensesValueBlock,
    showLoanAmountValueWithExpensesBlock: state.showLoanAmountValueWithExpensesBlock,
    showLoanAmountValueBlock: state.showLoanAmountValueBlock,
    campaigns: state.campaigns,
    marketSegments: state.marketSegments,
    offers: state.offers,
    paymentType: state.paymentType,
    amortizationTypes: state.amortizationTypes,
    insurers: state.insurers,
    insurancePolicies: state.insurancePolicies,
    monthWithoutPayment: state.monthWithoutPayment,
    deferredPayment: state.deferredPayment,
    termInYearsList: state.termInYearsList
  })
);

export const selectIsSimulationInProgress = createSelector(
  selectParameters,
  state => state.isSimulationInProgress
);

export const selectMenuProfile = createSelector(
  selectParameters,
  state => state.menuPerfil
);
export const selectClosingCostsCredit = createSelector(
  selectParameters,
  state => state.isAppraisalFeeFree
);

export const selectOriginalContractValues = createSelector(
  selectParameters,
  state => ({
    balanceValue: state.balanceValue,
    remainingPaymentTermInMonths: state.remainingPaymentTermInMonths
  })
);

export const selectGetDevelopments = createSelector(
  selectParameters,
  state => state.developments
);
