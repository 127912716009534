<div *ngIf="isChatBot && !fgSdk">
  <div *ngIf="isOpenIframe" class="chat-iframe">
    <button type="button" class="chat-iframe__close" aria-label="Close" (click)="toggleIframe()">
        <img src="assets/images/close-float-chat-v2.svg" alt="Fechar chat" />
    </button>

    <iframe class="chat-iframe__iframe" name="chat" scrolling="no" [src]="urlChatbot"></iframe>
  </div>

  <a *ngIf="!isOpenIframe" class="chat-footer chat-footer--chatbot" (click)="toggleIframe()" target="chat">
    <img srcset="assets/images/ic-communication-chat.png,
            assets/images/ic-communication-chat@2x.png 2x,
            assets/images/ic-communication-chat@3x.png 3x"
    src="assets/images/ic-communication-chat@3x.png"
    alt="Chat">
  </a>
</div>

<div *ngIf="!isChatBot && !fgSdk">
  <a class="chat-footer" href="https://chat.santander.com.br/web/?queue=321e" target="chat">
    <img src="assets/{{ pathImageClient }}/images/icon-chat.svg" alt="Chat" />
  </a>
</div>

<div class="fab-container" *ngIf="isChatBot && fgSdk">
  <div class="chat-box" id="chat-box">
    <div class="chat-box-header" id="chat-box-header"></div>
    <div class="chat-box-body" id="chat-box-body"></div>
    <div class="chat-box-input">
      <input class="chatbot-inputext" type="text" id="text-message" name="text-message" required minlength="4"
        placeholder="Digite aqui sua mensagem" style="width: 85%;">
      <a id="send-message" onclick="send_message()">
        <svg xmlns:xlink="http://www.w3.org/1999/xlink" style="margin-bottom: -8px;" height="24px" version="1.1"
          viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.006855,11.4935258 C11.58555,11.5705626 11.58555,12.4077443 11.006855,12.4847811 L3.86208703,13.4359044 L2.8741222,19.4325323 C2.85890314,19.5270619 2.87114572,19.6239735 2.90939482,19.7117486 C3.0197086,19.9648997 3.31435511,20.0806922 3.56750621,19.9703784 L20.8314861,12.4473816 C20.9472657,12.3969292 21.0396635,12.3045314 21.0901159,12.1887518 C21.2004297,11.9356007 21.0846372,11.6409542 20.8314861,11.5306404 L3.61707139,4.02924226 C3.52550202,3.98933975 3.4240801,3.97777799 3.32587933,3.99604735 C3.05439519,4.0465545 2.87525794,4.30758002 2.92811628,4.59275755 L3.86483353,10.542768 L11.006855,11.4935258 Z M3.36297151,11.4847811 C3.14355478,11.455572 2.96945738,11.2855706 2.93503359,11.0669113 L1.94263405,4.76196673 C1.7911126,3.94751431 2.32852433,3.16443776 3.14297676,3.01291631 C3.43757905,2.95810824 3.74184483,2.9927935 4.01655294,3.11250102 L21.2309676,10.6138992 C21.9904209,10.9448405 22.3377985,11.82878 22.0068571,12.5882333 C21.8554999,12.9355722 21.5783064,13.2127656 21.2309676,13.3641229 L3.96698775,20.8871197 C3.20753445,21.218061 2.32359493,20.8706835 1.99265358,20.1112301 C1.87790629,19.8479049 1.84117855,19.5571699 1.88712975,19.2717761 L2.93560107,12.9078727 C2.97136988,12.6907669 3.14486299,12.5225607 3.36297151,12.4935258 L7.151779,11.9891534 L3.36297151,11.4847811 Z"
            id="path-1" fill="#cc1717"></path>
        </svg>
      </a>
    </div>
  </div>
  <a onclick="openConversation()" (click)="sendEventClickChat()" class="fab-buttons fab-avatar" tooltip="Admin Options"><span><span
        class="fab-rotate level"></span></span></a>
</div>

