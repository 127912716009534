<div class="timeline" [class.timeline--fixed]="fixed">
  <div class="container">
    <h2 class="timeline__mobile-title">{{ nomeFase }}</h2>
    <div class="timeline__line" [ngClass]="'fase' + fase">
      <div class="timeline__steps" [class.timeline__steps--active]="+fase === 1">
        <span>{{ 'timeline.personal data' | translate }}</span>
      </div>
      <div class="timeline__steps" [class.timeline__steps--active]="+fase === 2">
        <span>{{ 'timeline.proposal data' | translate }}</span>
      </div>
      <div class="timeline__steps" [class.timeline__steps--active]="+fase === 3">
        <span>{{ 'timeline.simulation' | translate }}</span>
      </div>
      <div class="timeline__steps" [class.timeline__steps--active]="+fase === 4">
        <div [ngClass]=" (+objective === 56)  ? 'd-none' : 'd-block' " >
           <span  >{{ 'Crédito'}}</span>
        </div>
        <div [ngClass]=" (+objective === 56 )  ? 'd-block' : 'd-none' " >
          <span>{{ 'Enviar Proposta'}}</span>
       </div>
      </div>

    </div>
  </div>
</div>
