import { createSelector } from '@ngrx/store';
import { AppState } from '..';

export const selectSimulation = (state: AppState) => state.simulation;

export const selectResumoProposta = createSelector(
  selectSimulation,
  state => state
);

export const selectIdProposta = createSelector(
  selectSimulation,
  state => state.proposalId
);

export const selectPreviousTask = createSelector(
  selectSimulation,
  state => state.previousTask
);

export const selectSimulationSuccessful = createSelector(
  selectSimulation,
  state => state.isSimulationSuccessful
);

export const selectIsQfgts =  createSelector(
  selectSimulation,
  state =>
    state.loanData
    && state.loanData.loanTypeKey
    && state.loanData.loanTypeKey === '56'
);

export const selectGetWarranty = createSelector(
  selectSimulation,
  state => state.getWarrantySharingSimulation
);