import { ParametrosPerfilActions, ParametrosPerfilActionTypes } from './parametros-perfil.actions';

export interface ParametrosPerfilState {
  urlOrigin: string;
  tokenPP: string;
}

export const initialState: ParametrosPerfilState = {
  urlOrigin: '',
  tokenPP: ''
};

export function ParametrosPerfilReducer(state = initialState, action: ParametrosPerfilActions) {
  switch (action.type) {
    case ParametrosPerfilActionTypes.SALVAR_TOKEN_PP:
      return { ...state, tokenPP: action.payload.tokenPP, urlOrigin: action.payload.urlOrigin };

    case ParametrosPerfilActionTypes.RESET_SUCESSO:
      return initialState;

    default:
      return state;
  }
}
