import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cepFormat'
})
export class CepFormatPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return '';
    }

    // Remove any non-numeric characters
    const numericValue = value.replace(/\D/g, '');

    // Format the numeric string to the CEP format 00000-000
    if (numericValue.length === 8) {
      return `${numericValue.substring(0, 5)}-${numericValue.substring(5, 8)}`;
    }

    // Return the original value if it does not have 8 digits
    return value;
  }
}
