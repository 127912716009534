import { listarEmpreendimentosSimulacao_listarEmpreendimentosSimulacao } from '@shared/graphql/types/query-types';
import { ProposalActions, ProposalActionTypes } from './proposal.actions';

export interface ProposalState {
  warrantySharingOffers: any | null;
  simulationId: string | null;
  businessKey: string | null;
  currentTaskKey: string | null;
  selectedBuilding: string | null;
  idProposta: string | null;
  channel: string | null;
  loanTypeKey: string | null;
  loanTypeName: string | null;
  customerPortfolioName: string | null;
  buildingTypeKey: string | null;
  isOccupied: any | null;
  portabilityTypeKey: any | null;
  isHomeEquity: boolean | null;
  trIndexerKey: string | null;
  isNegotiatedRate: boolean | null;
  isEmployee: boolean | null;
  quizResponseCode: string | null;
  propertyLocationKey: string | null;
  propertyValue: number | null;
  evaluationValue: number | null;
  downPaymentAmount: number | null;
  expensesValue: number | null;
  fgtsAmount: number | null;
  loanAmountValue: number | null;
  termInYears: number | null;
  termInMonths: number | null;
  amountFinanced: number | null;
  totalMonthlyIncome: number | null;
  calculatedProposalValuesRanges: any;
  calculatedSimulationResults: any;
  amortizationTypeKey: string | null;
  campaignKey: string | null;
  insurancePolicyKey: string | null;
  insurerKey: string | null;
  marketSegmentKey: string | null;
  monthWithoutPaymentKey: string | null;
  offerKey: string | null;
  paymentTypeKey: string | null;
  termInYearsKey: string | null;
  appraisalFeesValue: number | null;
  transferTaxValue: number | null;
  iofValue: number | null;
  objetivoFinanciamento: number | null;
  codigoDistrito: string | null;
  tipoUtilizacaoImovel: string | null;
  deferredPaymentKey: string | null;
  monthsDeferredPaymentQt: number | null;
  evaluationPrice: number | null;
  isTransferTaxInClosingCostCredit: boolean | null;
  isAppraisalfeesInClosingCostCredit: boolean | null;
  isIOFIncludedInClosingCostCredit: boolean | null;
  isBlockedSettlementStatement: boolean | null;
  pdfHash: string | null;
  disableRepasse: boolean | null;
  BuildingList: listarEmpreendimentosSimulacao_listarEmpreendimentosSimulacao[];
  monthsToCompleteWork: number | null;
  saveFinancingObjective: {
    goal: string | null;
    type: string | null;
  };
  endereco: any | null;
  codigoContrato: any | null;
  codigoContratoTombamento: any | null;
  descricaoObjetivoFinanciamento: any | null;
  simulationContractList: any | null;
  isLoggedSecondScreen: boolean;
  typeOfferWarrantyShared: any | null;
  isSuccessful: null;
  isAppraisalFeeFree: null;
}

export const initialState = {
  warrantySharingOffers: null,
  simulationId: null,
  businessKey: null,
  currentTaskKey: null,
  idProposta: null,
  selectedBuilding: null,
  channel: null,
  loanTypeKey: null,
  loanTypeName: null,
  customerPortfolioName: null,
  buildingTypeKey: null,
  isOccupied: null,
  portabilityTypeKey: null,
  isHomeEquity: null,
  trIndexerKey: null,
  isNegotiatedRate: null,
  isEmployee: null,
  quizResponseCode: null,
  propertyLocationKey: null,
  propertyValue: null,
  evaluationValue: null,
  downPaymentAmount: null,
  expensesValue: null,
  fgtsAmount: null,
  loanAmountValue: null,
  termInYears: null,
  termInMonths: null,
  amountFinanced: null,
  totalMonthlyIncome: null,
  amortizationTypeKey: null,
  campaignKey: null,
  insurancePolicyKey: null,
  insurerKey: null,
  marketSegmentKey: null,
  monthWithoutPaymentKey: null,
  offerKey: null,
  paymentTypeKey: null,
  termInYearsKey: null,
  deferredPaymentKey: null,
  appraisalFeesValue: null,
  transferTaxValue: null,
  iofValue: null,
  objetivoFinanciamento: null,
  codigoDistrito: null,
  tipoUtilizacaoImovel: null,
  evaluationPrice: null,
  monthsDeferredPaymentQt: null,
  isTransferTaxInClosingCostCredit: null,
  isAppraisalfeesInClosingCostCredit: null,
  isIOFIncludedInClosingCostCredit: null,
  calculatedSimulationResults: null,
  calculatedProposalValuesRanges: null,
  pdfHash: null,
  disableRepasse: false,
  BuildingList: null,
  monthsToCompleteWork: null,
  saveFinancingObjective: null,
  endereco: null,
  codigoContrato: null,
  codigoContratoTombamento: null,
  descricaoObjetivoFinanciamento: null,
  simulationContractList: null,
  isLoggedSecondScreen: null,
  typeOfferWarrantyShared: null,
  isSuccessful: null,
  isAppraisalFeeFree: null,
};

export function proposalReducer(state = initialState, action: ProposalActions) {
  switch (action.type) {
    case ProposalActionTypes.SET_PROPOSAL_KEYS: {
      return {
        ...state,
        businessKey: action.payload.businessKey,
        currentTaskKey: action.payload.currentTaskKey,
      };
    }

    case ProposalActionTypes.SET_CURRENT_TASK_KEY: {
      return {
        ...state,
        currentTaskKey: action.payload.currentTaskKey,
      };
    }

    case ProposalActionTypes.SET_PROPOSAL_ID: {
      return {
        ...state,
        idProposta: action.payload.idProposta,
        channel: action.payload.channel,
      };
    }

    case ProposalActionTypes.SET_SIMULATION_ID: {
      return {
        ...state,
        simulationId: action.payload.simulationId,
      };
    }

    case ProposalActionTypes.SET_IS_LOGGED_SECOND_SCREEN: {
      return {
        ...state,
        isLoggedSecondScreen: action.payload,
      };
    }

    case ProposalActionTypes.SIMULATION_PROPOSAL_LOAD_SUCCESS: {
      return {
        ...state,
        objetivoFinanciamento: 47,
        tipoUtilizacaoImovel: action.payload.proposal.idUtilImovel,
        codigoDistrito: action.payload.proposal.idUf,
        propertyValue: action.payload.proposal.valorImovel,
        evaluationPrice: action.payload.proposal.vlAvaliacao,
        loanAmountValue: action.payload.proposal.valorFinanciamento,
        termInYears: action.payload.proposal.prazoPretendidoMeses,
        isTransferTaxInClosingCostCredit: action.payload.proposal.fgFinanciarDespesas,
        isIOFIncludedInClosingCostCredit: action.payload.proposal.fgFinanciarIof,
        isAppraisalfeesInClosingCostCredit: action.payload.proposal.fgFinanciarTarifa,
        downPaymentAmount: action.payload.proposal.valorEntrada,
        expensesValue: action.payload.proposal.valorDespesa,
      };
    }

    case ProposalActionTypes.CALCULATE_SUCCESS:
    case ProposalActionTypes.SET_PROPOSAL_DATA: {
      return {
        ...state,
        ...(action.payload.selectedProposalOptions && {
          channel: action.payload.selectedProposalOptions.channel,
          propertyLocationKey: action.payload.selectedProposalOptions?.propertyLocationKey,
          quizResponseCode: action.payload.selectedProposalOptions.quizResponseCode,
          typeOfferWarrantyShared: action.payload.selectedProposalOptions.typeOfferWarrantyShared,
        }),
        ...(action.payload.simulationId && {
          simulationId: action.payload.simulationId,
        }),
        loanTypeKey:
          action.payload?.simulationFeaturesValues?.loanTypeKey ??
          action.payload?.selectedProposalOptions.loanTypeKey ??
          '',
        loanTypeName: action.payload?.simulationFeaturesValues?.loanTypeName || '',
        customerPortfolioName: action.payload?.simulationFeaturesValues?.customerPortfolioName || '',
        buildingTypeKey:
          action.payload?.simulationFeaturesValues?.buildingTypeKey ??
          action.payload?.selectedProposalOptions.buildingTypeKey ??
          '',
        isHomeEquity: action.payload?.simulationFeaturesValues?.isHomeEquity || false,
        trIndexerKey: action.payload?.simulationFeaturesValues?.trIndexerKey || '',
        isNegotiatedRate: action.payload?.simulationFeaturesValues?.isNegotiatedRate || false,
        isEmployee: action.payload?.simulationFeaturesValues?.isEmployee || false,
        propertyValue: action.payload?.simulationEntryValues?.propertyValue || 0,
        evaluationValue: action.payload?.simulationEntryValues?.evaluationValue || 0,
        downPaymentAmount: action.payload?.simulationEntryValues?.downPaymentAmount || 0,
        fgtsAmount: action.payload?.simulationEntryValues?.fgtsAmount || 0,
        loanAmountValue: action.payload?.simulationEntryValues?.loanAmountValue || 0,
        termInYears: action.payload?.simulationEntryValues?.termInYears || 0,
        termInMonths: action.payload?.simulationEntryValues?.termInMonths || 0,
        amountFinanced: action.payload?.simulationCalculatedValues?.loanAmountValueWithExpenses || 0,
        expensesValue: action.payload?.simulationCalculatedValues?.expensesValue || 0,
        totalMonthlyIncome: action.payload?.simulationCalculatedValues?.totalMonthlyIncome || 0,
        calculatedSimulationResults: action.payload?.calculatedSimulationResults || [],
        calculatedProposalValuesRanges: action.payload?.calculatedProposalValuesRange || [],
        amortizationTypeKey: action.payload?.selectedOptions?.amortizationTypeKey,
        campaignKey: action.payload?.selectedOptions?.campaignKey,
        insurancePolicyKey: action.payload?.selectedOptions?.insurancePolicyKey,
        insurerKey: action.payload?.selectedOptions?.insurerKey,
        marketSegmentKey: action.payload?.selectedOptions?.marketSegmentKey,
        monthWithoutPaymentKey: action.payload?.selectedOptions?.monthWithoutPaymentKey,
        offerKey: action.payload?.selectedOptions?.offerKey,
        paymentTypeKey: action.payload?.selectedOptions?.paymentTypeKey,
        termInYearsKey: action.payload?.selectedOptions?.termInYearsKey,
        deferredPaymentKey: action.payload?.selectedOptions?.deferredPaymentKey,
        typeOfferWarrantyShared: action.payload?.selectedOptions?.typeOfferWarrantyShared,
        appraisalFeesValue: action.payload?.closingCostsCredit?.appraisalFeesValue || 0,
        isAppraisalFeeFree: action.payload?.closingCostsCredit?.isAppraisalFeeFree || false,
        transferTaxValue: action.payload?.closingCostsCredit?.transferTaxValue || 0,
        iofValue: action.payload?.closingCostsCredit?.iofValue || 0,
        isTransferTaxInClosingCostCredit: action.payload?.closingCostsCredit?.isTransferTaxInClosingCostCredit || false,
        isAppraisalfeesInClosingCostCredit:
          action.payload?.closingCostsCredit?.isAppraisalfeesInClosingCostCredit || false,
        isIOFIncludedInClosingCostCredit: action.payload?.closingCostsCredit?.isIOFIncludedInClosingCostCredit || false,
      };
    }

    case ProposalActionTypes.SEND_SUCCESS: {
      return {
        ...state,
        ...action.payload.input,
        disableRepasse: true,
      };
    }

    case ProposalActionTypes.FIELD_CHANGED: {
      const { field, value, isUsecasaMais } = action.payload;
      let conditionalUpdatedFields = {};
      let clearSelectedBuilding = false;

      if (field === 'termInYears' && !isUsecasaMais) {
        conditionalUpdatedFields = { termInMonths: parseInt(value, 10) * 12 };
      } else if (field === 'termInMonths' && !isUsecasaMais) {
        // tslint:disable-next-line: no-bitwise
        conditionalUpdatedFields = { termInYears: ~~(parseInt(value, 10) / 12) };
      } else if (field === 'termInMonths' && isUsecasaMais) {
        // tslint:disable-next-line: no-bitwise
        clearSelectedBuilding = true;
      }

      if (field === 'loanTypeKey') {
        clearSelectedBuilding = true;
      }

      return {
        ...state,
        ...conditionalUpdatedFields,
        selectedBuilding: clearSelectedBuilding ? null : state.selectedBuilding,
        [field]: value,
      };
    }

    case ProposalActionTypes.GENERATE_PDF_SUCCESS: {
      return {
        ...state,
        pdfHash: action.payload.pdfHash,
      };
    }

    case ProposalActionTypes.GET_EMPREENDIMENTOS_SUCESS: {
      return {
        ...state,
        loanTypeKey: action.payload.portal === 'PP' ? state.loanTypeKey : 2,
        buildingTypeKey: action.payload.cdUtilImovel,
        propertyLocationKey: action.payload.idUf,
        disableRepasse: true,
      };
    }

    case ProposalActionTypes.RESET_SELECTED_OPTIONS_SUCCESS: {
      return {
        ...state,
        termInYearsKey: null,
        campaignKey: null,
        marketSegmentKey: null,
        offerKey: null,
        paymentTypeKey: null,
        amortizationTypeKey: null,
        monthWithoutPaymentKey: null,
        insurerKey: null,
        insurancePolicyKey: null,
      };
    }

    case ProposalActionTypes.RESET_CONTRACT_VALUE: {
      return {
        ...state,
        loanTypeKey: null,
        endereco: null,
        codigoContrato: null,
        descricaoObjetivoFinanciamento: null,
        buildingTypeKey: null,
        simulationContractList: null,
        loanAmountValue: null,
      };
    }

    case ProposalActionTypes.RESET_SEND_CONTRACT: {
      return {
        ...state,
        isSuccessful: null
      };
    }
    case ProposalActionTypes.GET_BUILDINGS_SUCCESS: {
      return {
        ...state,
        BuildingList: action.payload.listarEmpreendimentosSimulacao,
      };
    }

    case ProposalActionTypes.SAVE_FINANCING_OBJECTIVE: {
      return {
        ...state,
        saveFinancingObjective: {
          goal: Number(action.payload.goal),
          type: action.payload.type,
        },
      };
    }
    case ProposalActionTypes.SAVE_RENEWED_CONTRACT_SUCESS: {
      return {
        ...state,
        isSuccessful: action.payload,
      };
    }
    case ProposalActionTypes.GET_SIMULATION_CONTRACT_DATA_SUCCESS: {
      return {
        ...state,
        simulationContractList: action.payload,
      };
    }
    case ProposalActionTypes.GET_WARRANTY_SHARING_OFFERS_SUCCESS: {
      console.log('p', action.payload);
      return {
        ...state,
        warrantySharingOffers: action.payload,
      };
    }

    case ProposalActionTypes.GET_UNIT_SIMULATION_DATA_SUCESS: {
      return {
        ...state,
        loanTypeKey: action.payload.loanObjectiveId,
        buildingTypeKey: action.payload.buildingTypeKey,
        propertyLocationKey: action.payload.federalState,
        propertyValue: action.payload.propertyValue,
        evaluationPrice: action.payload.appraisalValue,
        loanAmountValue: action.payload.loanAmountValue,
        //disableRepasse: true
      };
    }

    default:
      return state;
  }
}
