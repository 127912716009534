<ng-template #contentModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title d-flex justify-content-center">
        {{ title | translate }}
    </h4>
    <button type="button" class="close d-flex justify-content-center align-middle" aria-label="Close" (click)="modalClose()" *ngIf="closable">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <ng-content select="[body]"></ng-content>
  </div>

  <div class="modal-footer" *ngIf="footer">
    <ng-content select="[footer]"></ng-content>
  </div>
</ng-template>
