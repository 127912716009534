export enum LoanTypeKey {
  AQUISICAO = 3,
  USECASA = 4,
  USECASAMAIS= 59,
  PORTABILIDADE = 46,
  USECASA_PJ = 50,
  QFGTS = 56
}

export enum PortabilityTypeKey {
  FINANCIAMENTO = 'PF',
  EMPRESTIMO = 'PE'
}

export enum PaymentTypeKey {
  PRICE = 'PREF_P'
}

export enum OfferKey {
  SEM_RELACIONAMENTO = '0'
}

export enum CustomerPortfolioName {
  TAXA_DE_MERCADO = 'TAXA DE MERCADO'
}

export enum BuildingTypeKey {
  RES = 'R',
  COM = 'C'
}
