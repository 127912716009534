import gql from 'graphql-tag';

export const GET_UNIT_BORROWER_SIMULATION = gql`
  query getUnitBorrowerSimulation($unitId: ID) {
    getUnitBorrowerSimulation(unitId: $unitId) {
      id
      order
      name
      birthDate
      documentNumber
      email
      telephoneNumber
      monthlyIncome
      authorizesEmailAndSms
      isEmployee
      isPrimaryBorrower
      isFromPersonas
      hasDevelopments
      typePerson
      annualBillingAmount
      cdIntegration
      dsSegmentoPad
      nrSeqHierarq
      tpPessoa
      fgCorrentista
    }
  }
`;
