import { createSelector } from '@ngrx/store';
import { AppState } from '..';

export const selectParametrosPerfil = (state: AppState) => state.parametrosPerfil;

export const selectTokenPP = createSelector(
  selectParametrosPerfil,
  state => state.tokenPP
);

export const selectUrlOrigin = createSelector(
  selectParametrosPerfil,
  state => state.urlOrigin
);

export const selectParametros = createSelector(
  selectTokenPP,
  selectUrlOrigin,
  (stateTokenPP, stateUrlOrigin) => ({
    stateTokenPP,
    stateUrlOrigin
  })
);
