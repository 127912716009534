import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Subscription, BehaviorSubject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { distinctUntilChanged } from 'rxjs/operators';
import { FormValidationService } from '@app/shared/services/form-validation.service';
import { ModalTemplateComponent } from '@app/shared/widgets/modal-template/modal-template.component';
import { ModalAlertComponent } from '@app/shared/widgets/modal-alert/modal-alert.component';
import { ErrorService } from '@app/shared/services/error.service';
import { AppState } from '@app/state';
import { ActivatedRoute, Router } from '@angular/router';

import * as fromLogin from '@app/state/login'
import * as fromParticipants from '@app/state/participants';
import * as fromProposal from '@app/state/proposal';
import * as fromParameters from '@app/state/parameters';
import * as fromErrorHandling from '@app/state/error-handling';
import { DataLayerService } from '../../shared/service/dataLayer/dataLayer.service';
import { StorageEncryptService } from '@shared/services/storageEncrypt.service';
import { DataLayerSantanderService } from '../../shared/service/dataLayer/novo-data-layer.service';

@Component({
  selector: 'app-personal-data',
  templateUrl: './personal-data.container.html',
  styleUrls: ['./personal-data.container.scss']
})
export class PersonalDataContainerComponent implements OnInit, OnDestroy {
  readonly CELLPHONE_MASK = '(00) 00000-0000';
  readonly TELEPHONE_MASK = '(00) 0000-00009';

  telephoneNumberMask = this.CELLPHONE_MASK;
  telephoneNumberPreviousLength: number;

  formPersonalData: FormGroup;
  formOtherParticipant: FormGroup;
  firstProponentCpf: string;

  borrowers: Array<any>;
  simulationId: string;
  utmSource: string;

  minDateOfBirth: number;
  maxDateOfBirth: number;
  minMonthlyIncome: number;
  hasOtherParticipant: boolean;
  oldMonthlyIncome: number;
  isFromPersonas: boolean;
  visibleFields: Array<string>;
  editableFields: Array<string>;
  isSimulationInProgress: boolean;
  isRenovado: boolean;
  loanTypeKey: any;

  data = JSON.parse(this.storageEncryptService.getSessionStore('data'));

  modal = new BehaviorSubject({
    show: false,
    template: ModalTemplateComponent,
    content: Object,
    closed: () => {
      return;
    },
    back: () => {
      return;
    }
  });

  private subscription: Subscription = new Subscription();

  constructor(
    private store: Store<AppState>,
    private formBuilder: FormBuilder,
    private formValidationsService: FormValidationService,
    private errorService: ErrorService,
    private route: ActivatedRoute,
    private dataLayerService: DataLayerService,
    private router: Router,
    private storageEncryptService: StorageEncryptService,
    private dataLayerSantander: DataLayerSantanderService,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this.storageEncryptService.removeSession('utmSource');
    this.getGoalAndType();
    this.createForms();
    this.createSubscriptions();
    this.integration();
    // this.monitorFieldChanges();
    this.sendPageView(undefined, '/simuladorcliente/dados-pessoais');
  }

  getGoalAndType() {
    const goal = this.route.snapshot.queryParams.goal ? this.route.snapshot.queryParams.goal : false;
    const type = this.route.snapshot.queryParams.type ? this.route.snapshot.queryParams.type.toUpperCase() : false;

    if (goal) {
      this.store.dispatch(new fromProposal.actions.SaveFinancingObjective({ goal, type }));
      // this.clearPosParam();

      this.storageEncryptService.setSessionStore('goal', goal);
    }

    if (type) {
      this.storageEncryptService.setSessionStore('type', type);
    }
  }

  integration() {
    if(this.storageEncryptService.getSessionStore('userData') === null){
      return this.store.dispatch(new fromLogin.actions.IntegrationPublic());
    }
    this.getInitialParameters();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private createSubscriptions(): void {
    this.subscribeToIdProposal();
    this.subscribeToGetBorrowerSimulation();
    this.subscribeToPersonalData();
    this.subscribeToParameters();
    this.subscribeToErrors();
    this.subscribeLoanTypeKey();
    this.subscriptionToPrimaryCpf();
    this.subscribeToCpfMainProponent();
    this.subscribeToUtmSource();
    this.subscribeToPCSimulationInProgress();
  }

  clearPosParam() {
    this.router.navigate(['.'], { relativeTo: this.route, queryParams: null });
  }

  subscribeToParameters() {
    this.subscription.add(
      this.store.select(fromParameters.selectors.selectParameters).subscribe(state => {
        this.minDateOfBirth = state.minDateOfBirth;
        this.maxDateOfBirth = state.maxDateOfBirth;
        this.visibleFields = state.visibleFields;
        this.editableFields = state.editableFields;
      })
    );
  }

  subscribeToPersonalData() {
    this.subscription.add(
      this.store.select(fromParticipants.selectors.selectParticipants).subscribe(state => {
        if (!state) return;
        const participants = state.participants;
      })
    );
  }

  subscribeToIdProposal() {
    this.subscription.add(
      this.store.pipe(select(fromProposal.selectors.selectSimulationID)).subscribe(state => {
        if (!state) return;
        this.simulationId = state;
      })
    );
  }

  subscribeToIsLogged() {
    this.subscription.add(
      this.store.pipe(select(fromLogin.selectors.selectIsLogged)).subscribe(state => {
        if (!state) return;
        this.getInitialParameters();
      })
    );
  }

  subscribeToErrors() {
    this.subscription.add(
      this.store.pipe(select(fromErrorHandling.selectors.selectErrorMessage)).subscribe(state => {
        if (!state || state === '') return;

        this.modal.next({
          show: true,
          template: ModalAlertComponent,
          content: Object({
            title: 'common.attention',
            message: this.errorService.handleError(state)
          }),
          closed: () => this.store.dispatch(new fromErrorHandling.actions.ClearError()),
          back: () => ''
        });
      })
    );
  }

  subscribeToGetBorrowerSimulation() {
    this.subscription.add(
      this.store.pipe(select(fromParticipants.selectors.selectBorrowers)).subscribe(state => {
        if (!state) return;

        if (state.length > 1) {
          this.loadFullForm(state);
          return;
        }

        this.borrowers = state.map(b => ({ ...b, id: null }));

        /**
         * if borrowers value is null
         */
        if (this.borrowers.length > 0) {
          this.oldMonthlyIncome = this.borrowers[0].monthlyIncome;
          this.isFromPersonas = this.borrowers[0].isFromPersonas;

          if (this.borrowers[0] && (this.borrowers[0].order === null || this.borrowers[0].order === 0)) {
            this.formPersonalData.patchValue({ ...this.borrowers[0], id: null });
            return;
          }

          if (this.borrowers[1] && this.borrowers[1].order === 1) {
            this.formOtherParticipant.patchValue({ ...this.borrowers[1], id: null });
            return;
          }
        }
      })
    );
  }

  private subscribeToUtmSource(): void {
    this.subscription.add(
      this.route.queryParams.subscribe(params => {
        this.utmSource = params.utm_source;
      })
    );
  }

  private subscribeToPCSimulationInProgress(): void {
    this.subscription.add(
      this.store.select(fromParameters.selectors.selectIsSimulationInProgress).subscribe(state => {
        this.isSimulationInProgress = state;

        if (this.isSimulationInProgress === false) {
          this.formPersonalData.disable();
          this.formOtherParticipant.disable();
        }
      })
    );
  }

  subscribeLoanTypeKey(): void {
    this.subscription.add(
      this.store.select(fromProposal.selectors.selectLoanTypeKey).subscribe(state => {
        if(!state) return;
        this.loanTypeKey = state;
        this.isRenovado = parseInt(state, 10) === 55 ? true : false
      })
    )
  }

  subscribeToCpfMainProponent() {
    this.subscription.add(
      this.store.pipe(select(fromParticipants.selectors.selectCpfMainProponent)).subscribe(state => {
        if (!state) return;
        this.firstProponentCpf = state;
      })
    );
  }

  /**
   * Get initial parameters
   */
  getInitialParameters() {
    this.store.dispatch(new fromParameters.actions.ParticipantsLoad());
  }

  /**
   * Load Value of first and second Form
   * but only load if has value of first and second proponent
   */
  loadFullForm(data) {
    this.formPersonalData.patchValue({
      ...data[0],
      otherParticipant: true,
      id: null
    });

    this.formOtherParticipant.patchValue({
      ...data[1],
      id: null
    });
  }

  /**
   * Create forms
   */
  createForms(): void {
    this.createFormPersonalData();
    this.createFormOtherParticipant();
  }

  /**
   * Creates Personal Data Form
   */
  createFormPersonalData(): void {
    this.formPersonalData = this.formBuilder.group({
      otherParticipant: [null],
      id: [null],
      order: [0],
      name: [null, [Validators.required, this.formValidationsService.isValidFullName]],
      documentNumber: [null, [Validators.required, this.formValidationsService.isCpfValid]],
      birthDate: [
        null,
        [Validators.required, this.formValidationsService.isValidDate(this.minDateOfBirth, this.maxDateOfBirth)]
      ],
      email: [null, [Validators.required, Validators.pattern(this.formValidationsService.getPatternEmail())]],
      telephoneNumber: [null, [Validators.required, Validators.pattern(this.formValidationsService.getPatternPhone())]],
      monthlyIncome: [null, [Validators.required, this.formValidationsService.isLowerThanMin(0)]],
      authorizesEmailAndSms: new FormControl(true, {
        updateOn: 'change',
        validators: Validators.required
      }),
      fgContact: [null]
    });

    this.subscribeToTelephoneNumber();
    this.fgOtherParticipantChanged();
  }

  monitorFieldChanges(): void {
    Object.keys(this.formPersonalData.controls).forEach(controlName => {
      let fieldName;
      const element = document.querySelector(`[formControlName="${controlName}"]`);
      if (element) {
        this.renderer.listen(element, 'blur', () => {
          fieldName = this.translateField(controlName);
          if(fieldName === 'incluir-renda-outro-participante') return this.sendToClicEvent(false, 'selecionou', 'checkbox:' + fieldName);
          this.sendToClicEvent(false, 'preencheu', 'input:' + fieldName);
        });
      }
    });
  }

  clickBtnSubmit(event){
    this.sendSelectedFields();
    if(event === true) this.sendToClicEvent(false, 'clicou', 'botao:proximo');
  }

  translateField(fieldName: string) {
    let translatedFieldName: string;
    switch (fieldName) {
      case 'name':
        translatedFieldName = 'nome';
        break;
      case 'documentNumber':
        translatedFieldName = 'cpf';
        break;
      case 'birthDate':
        translatedFieldName = 'data-nascimento';
        break;
      case 'telephoneNumber':
        translatedFieldName = 'celular';
        break;
      case 'monthlyIncome':
        translatedFieldName = 'renda-mensal';
        break;
      case 'otherParticipant':
        translatedFieldName = 'incluir-renda-outro-participante';
        break;
      default:
        translatedFieldName = fieldName;

      }
      return translatedFieldName
  }

  private subscribeToTelephoneNumber(): void {
    this.subscription.add(
      this.formPersonalData
        .get('telephoneNumber')
        .valueChanges.pipe(distinctUntilChanged((prev, curr) => prev === curr))
        .subscribe(value => {
          if (!value) return;

          if (value.length <= 10 && this.telephoneNumberMask === this.CELLPHONE_MASK) {
            this.telephoneNumberMask = this.TELEPHONE_MASK;
          } else if (
            value.length === 11 &&
            this.telephoneNumberMask === this.TELEPHONE_MASK &&
            this.telephoneNumberPreviousLength === 10
          ) {
            this.telephoneNumberMask = this.CELLPHONE_MASK;
          }

          this.telephoneNumberPreviousLength = value.length;
        })
    );
  }

  /**
   * Validate other participant based on hasOtherParticipant flag
   */
   fgOtherParticipantChanged(): void {
    this.formPersonalData
      .get('otherParticipant')
      .valueChanges.subscribe(hasOtherParticipant => {
        this.hasOtherParticipant = hasOtherParticipant

        if (this.isRenovado) {
          this.store.dispatch(new fromProposal.actions.ResetContractValue());
          this.store.dispatch(new fromProposal.actions.SetIsLoggedSecondScreen(false));
        }
      });
  }

  /**
   * Create Other Participant Form
   */
  createFormOtherParticipant(): void {
    this.formOtherParticipant = this.formBuilder.group({
      id: [null],
      order: [1],
      isPrimaryBorrower: new FormControl(null, {
        updateOn: 'change'
      }),
      name: [null, [Validators.required, this.formValidationsService.isValidFullName]],
      documentNumber: [
        null,
        [
          Validators.required,
          this.formValidationsService.isCpfValid,
          this.formValidationsService.isSameCpf(this.formPersonalData.get('documentNumber'))
        ]
      ],
      birthDate: [
        null,
        [Validators.required, this.formValidationsService.isValidDate(this.minDateOfBirth, this.maxDateOfBirth)]
      ],
      telephoneNumber: [null, [Validators.required, Validators.pattern(this.formValidationsService.getPatternPhone())]],
      monthlyIncome: [null, [Validators.required, this.formValidationsService.isLowerThanMin(0)]]
    });

    this.subscribeToOtherTelephone();
  }

  private subscribeToOtherTelephone(): void {
    this.subscription.add(
      this.formOtherParticipant
        .get('telephoneNumber')
        .valueChanges.pipe(distinctUntilChanged((prev, curr) => prev === curr))
        .subscribe(value => {
          if (!value) return;

          if (value.length <= 10 && this.telephoneNumberMask === this.CELLPHONE_MASK) {
            this.telephoneNumberMask = this.TELEPHONE_MASK;
          } else if (
            value.length === 11 &&
            this.telephoneNumberMask === this.TELEPHONE_MASK &&
            this.telephoneNumberPreviousLength === 10
          ) {
            this.telephoneNumberMask = this.CELLPHONE_MASK;
          }

          this.telephoneNumberPreviousLength = value.length;
        })
    );
  }

  subscriptionToPrimaryCpf() {
    this.subscription.add(
      this.formPersonalData.get('documentNumber').valueChanges.subscribe(value => {
        if (this.hasOtherParticipant && this.formOtherParticipant) {
          this.formOtherParticipant.get('documentNumber').updateValueAndValidity();
        }
      })
    );
  }

  onFocusOutCpf(data) {
    if (data.documentNumber === null) {
      return;
    }

    if (this.isRenovado) {
      this.store.dispatch(new fromProposal.actions.ResetContractValue());
      this.store.dispatch(new fromProposal.actions.SetIsLoggedSecondScreen(false));
    }
  }

  /**
   * Dispatch Send Personal Data action
   */
  onSubmitFormPersonalData() {
    const isOtherParticipantValid = this.hasOtherParticipant ? this.formOtherParticipant.valid : true;
    let invalidFields = [];

    // Envia o cpf hasheado para o DataLayer
    this.dataLayerService.sendCustomToDataLayer({ cpf_hasheado: 'null' });

    if (this.formPersonalData.valid && isOtherParticipantValid) {
      delete this.formPersonalData.value.otherParticipant;
      let participants = [this.formPersonalData.value];

      if (this.hasOtherParticipant) {
        participants = [...participants, this.formOtherParticipant.value];
      }

      const participantsPayload = {
        otherParticipant: this.hasOtherParticipant || false,
        participants
      };

      if (this.isValidMonthlyIncome()) {
        this.store.dispatch(
          new fromParticipants.actions.SendParticipants('PC', participantsPayload, this.simulationId, this.utmSource)
        );
      }
    }

    if (this.hasOtherParticipant) {
      this.formValidationsService.validateAllFormFields(this.formOtherParticipant);
      invalidFields = [...invalidFields, ...this.formValidationsService.getInvalidFields(this.formOtherParticipant)];
    }

    this.formValidationsService.validateAllFormFields(this.formPersonalData);
    invalidFields = [...invalidFields, ...this.formValidationsService.getInvalidFields(this.formPersonalData)];
    this.showInvalidFieldsModal(invalidFields);
  }

  /**
   * Check if monthly income is greater than 2500
   *
   * @private
   * @returns {boolean}
   * @memberof PersonalDataContainerComponent
   */
  private isValidMonthlyIncome(): boolean {
    const primaryBorrowerIncome = this.formPersonalData.get('monthlyIncome').value;
    const secondBorrowerIncome = this.formOtherParticipant.get('monthlyIncome').value;
    const currentIncome = this.hasOtherParticipant
      ? primaryBorrowerIncome + secondBorrowerIncome
      : primaryBorrowerIncome;

    if (currentIncome < 2500) {
      this.modal.next({
        show: true,
        template: ModalAlertComponent,
        content: Object({
          title: 'common.attention',
          message: this.errorService.handleError('msgErrorSIFSIMB01_E0010')
        }),
        closed: () => '',
        back: () => ''
      });

      return false;
    }

    return true;
  }

  openModalmonthlyIncome(): void {
    if (
      this.isFromPersonas &&
      this.oldMonthlyIncome > 0 &&
      this.oldMonthlyIncome < this.formPersonalData.value.monthlyIncome
    ) {
      this.modal.next({
        show: true,
        template: ModalAlertComponent,
        content: Object({
          title: 'common.attention',
          message: this.errorService.handleError('msgErrorSIFSIMB01_E0009')
        }),
        closed: () => '',
        back: () => ''
      });
    }
  }

  /**
   * Show modal when no authorizes Email And Sms
   */
  showNoAuthorizesEmailAndSmsModal(): void {
    this.modal.next({
      show: true,
      template: ModalAlertComponent,
      content: Object({
        title: 'common.confirm',
        message: 'common.modalNoAuthorizesEmailAndSms',
        buttons: [{ label: 'common.yes-unsubscribe', action: 's' }, { label: 'common.no-wantStay', action: 'n' }],
        btnLarge: true
      }),
      closed: () => '',
      back: () => {
        this.actionNoAuthorizesEmailAndSmsConfirm();
      }
    });
  }

  actionNoAuthorizesEmailAndSmsConfirm() {
    this.formPersonalData.patchValue({
      authorizesEmailAndSms: true
    });
  }

  /**
   * Show invalid fields modal if it exists
   */
  showInvalidFieldsModal(invalidFields): void {
    if (!invalidFields.length) {
      return;
    } else {
      this.modal.next({
        show: true,
        template: ModalAlertComponent,
        content: Object({
          title: 'common.attention',
          message: this.errorService.handleError('msgErrorSIFSIMB01_E0005'),
          list: invalidFields.map(f => `common.${f}`)
        }),
        closed: () => '',
        back: () => ''
      });
    };
  }

  sendToClicEvent(nonInterection, eventAction, eventLabel) {
    this.dataLayerSantander.sendCustomToDataLayer(
      nonInterection,
      eventAction,
      eventLabel
    );
  }
  sendPageView(url, customUrl) {
    this.dataLayerSantander.sendPageViewToDataLayer(
      url,
      customUrl
    );
  }

    sendSelectedFields() {
    const fieldsToSend = ['name', 'cpf','birthDate','email','telephoneNumber','monthlyIncome','otherParticipant'];

    fieldsToSend.forEach(field => {
      const fieldTranslate = this.translateField(field);
      const otherParticipantValue = this.formPersonalData.get('otherParticipant');
      if(fieldTranslate === 'incluir-renda-outro-participante') {
        if (otherParticipantValue.value === true) {
          this.sendToClicEvent(false, 'selecionou', 'checkbox:' + fieldTranslate);
        }
      }else{
        this.sendToClicEvent(false, 'preencheu', 'input:' + fieldTranslate);
      }
    });
  }

}
