import { SimulationActions, SimulationActionTypes } from './simulation.actions';

export interface DadosFinanciamento {
  customerPortfolioName;
  deferredPaymentDescription;
  loanTypeKey;
  loanTypeName;
  isBlockedSettlementStatement;
  isChangeablePayment;
  isPrimeLendingRate;
  termInMonths;
  contractedAmortizationPeriodInMonths;
  monthsDeferredPaymentPeriod;
  monthsDeferredPaymentQt;
  ceshRate;
  primeCeshRate;
  cetRate;
  primeCetRate;
  cemRate;
  primeCemRate;
  yearInterestRate;
  primeYearInterestRate;
  deferredPaymentKey;
  paymentTypeDescription;
  amountFinanced;
  amountFinancedWithExpenses;
  downPaymentAmount;
  fgtsAmount;
  evaluationValue;
  PMTfirstPaymentAmount;
  primePMTfirstPaymentAmount;
  firstPaymentAmount;
  primefirstPaymentAmount;
  finalPaymentAmount;
  primefinalPaymentAmount;
  isUseCasa;
  isLoanPortability;
  amortizationTermInMonths;
  isUseImovel;
  CD_TTP_PORTA;
  CD_TTP_AQIMV;
  CD_TTP_USCGI;
  CD_TTP_DFLT;
}

export interface PersonData {
  id;
  order;
  name;
  birthDate;
  documentNumber;
  email;
  telephoneNumber;
  monthlyIncome;
  authorizesEmailAndSms;
  isEmployee;
  isPrimaryBorrower;
  isFromPersonas;
}
export interface Proponentes {
  cpf;
  fgParticipantePrincipal;
  name;
  fgCadastro;
  fgCadastroConcluido;
  fgCadastroInconsistente;
}
export interface ResumoPropostaState {
  proposalId: string;
  hasSecondBorrower: boolean;
  loanData: DadosFinanciamento;
  firstBorrower: PersonData;
  secondBorrower: PersonData;
  proponentes: [Proponentes];
  previousTask: string;
  isSimulationSuccessful: boolean;
  getWarrantySharingSimulation: any;
}

export const initialState: ResumoPropostaState = {
  proposalId: null,
  hasSecondBorrower: null,
  loanData: null,
  firstBorrower: null,
  secondBorrower: null,
  proponentes: [
    {
      cpf: null,
      fgParticipantePrincipal: null,
      name: null,
      fgCadastro: null,
      fgCadastroConcluido: null,
      fgCadastroInconsistente: null
    }
  ],
  previousTask: null,
  isSimulationSuccessful: null,
  getWarrantySharingSimulation: null
};

export function simulationReducer(state = initialState, action: SimulationActions) {
  switch (action.type) {
    case SimulationActionTypes.SET_SIMULATION_SUCCESSFUL: {
      return {
        ...state,
        isSimulationSuccessful: action.payload.isSuccessful
      };
    }

    case SimulationActionTypes.GET_SUMMARY_SUCCESS: {
      return {
        ...state,
        proposalId: action.payload.proposalId,
        hasSecondBorrower: action.payload.hasSecondBorrower,
        loanData: action.payload.loanData,
        firstBorrower: action.payload.firstBorrower,
        secondBorrower: action.payload.secondBorrower
      };
    }

    case SimulationActionTypes.SET_PREVIOUS_TASK: {
      return {
        ...state,
        previousTask: action.payload.previousTask
      };
    }

    case SimulationActionTypes.GET_WARRANTY_SUCCESSFUL: {
      return {
        ...state,
        getWarrantySharingSimulation: action.payload
      };
    }

    default:
      return state;
  }
}
