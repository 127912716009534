import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-input-radio-container',
  templateUrl: './input-radio-container.component.html',
  styleUrls: ['./input-radio-container.component.scss']
})
export class InputRadioContainerComponent {
  @Input() label: string;
  @Input() helperName: string;

  constructor() {}

  
}
