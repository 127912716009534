import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import * as AesJS from 'aes-js';

@Injectable({
    providedIn: 'root'
})
export class CryptoService {
    readonly IV = this.convertToArray(environment.featureToggle.crypto.iv);
    readonly KEY = this.convertToArray(environment.featureToggle.crypto.key);
    constructor() { }

   /**
    * Converte a string da váriavel de ambiente para um array de inteiros
    *
    * @private
    * @param {string} text
    * @returns
    * @memberof CryptoInterceptor
    */
    private convertToArray(text: string) {
        return text.split(',').map(value => parseInt(value.trim(), 10));
    }

    public decrypt(encText: string): any {
        const aesOfb = new AesJS.ModeOfOperation.ofb(this.KEY, this.IV);
        const encryptedBytes = AesJS.utils.hex.toBytes(encText);
        const decryptedBytes = aesOfb.decrypt(encryptedBytes);
        const decryptedString = AesJS.utils.utf8.fromBytes(decryptedBytes);
        const decText = decodeURI(window.atob(decryptedString));

        return decText;
    }

    public encrypt(text: string): string {
        const emBase64 = window.btoa(encodeURI(text));
        const aesOfb = new AesJS.ModeOfOperation.ofb(this.KEY, this.IV);
        const textBytes = AesJS.utils.utf8.toBytes(emBase64);
        const encryptedBytes = aesOfb.encrypt(textBytes);
        const encText = AesJS.utils.hex.fromBytes(encryptedBytes);

        return encText;
    }
}
