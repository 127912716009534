import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, switchMap, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { ErrorService } from '@app/shared/services/error.service';
import { AppState } from '..';

import * as fromErrorHandling from '../error-handling';
import * as actions from './consult.actions';
import { ConsultProposalService } from '@projects/cliente/src/app/consult-proposal/consult-proposal.service';

@Injectable()
export class ConsultProposalEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private consultProposalService: ConsultProposalService,
    private errorService: ErrorService,
    private router: Router
  ) {}

  /**
   * Save Proposal Data
   */
  @Effect()
  borrowerVerification = this.actions$.pipe(
    ofType<actions.BorrowerVerification>(actions.ConsultActionTypes.BORROWER_VERIFICATION),
    switchMap(action => {
      let cpf = action.payload.documentnumber;

      if (!cpf) return;
      return this.consultProposalService.borrowerVerification(cpf).pipe(
        map(response => new actions.BorrowerVerificationSucess({
            borrowerData: response.borrowerVerification
          }),
        ),
        catchError(err => {
          if (err.extensions) {
            return of(new fromErrorHandling.actions.AddError(this.errorService.addGraphQLAlert(err)));
          }

          return of(new fromErrorHandling.actions.AddError(this.errorService.addAlertError(err)));
        })
      );
    })
  );
}
