import gql from 'graphql-tag';

export const BUSCAR_TABELAS_GERAL = gql`
  query buscarTabelasGeral($id: ID!, $page: Int!, $per_page: Int!, $tipoOrdenacao: String) {
    tabelaGeralConnections(id: $id, page: $page, per_page: $per_page, tipoOrdenacao: $tipoOrdenacao) {
      edges {
        node {
          id
          codigo
          descricao
        }
      }
    }
  }
`;
