<div class="formulario">
  <div class="container">
    <div class="row">
      <div class="col margin-title">
        <h2>{{ 'modules.simulation.personal-data.hello' | translate }}</h2>
        <h2 class="sub">{{ 'modules.simulation.personal-data.lets begin a simulation' | translate }}</h2>
      </div>
    </div>

    <form [formGroup]="formPersonalData">
      <fieldset>
        <div class="form-row">
          <div class="form-group col">
            <!-- Name -->
            <app-input-text-container
              [label]="'modules.simulation.personal-data.what is your name' | translate"
              [customErrorMessage]="'common.please type the full name' | translate"
            >
              <input
                class="text-uppercase"
                type="text"
                id="nome"
                autocomplete="off"
                [placeholder]="'common.full name' | translate"
                formControlName="name"
                maxlength="50"
              />
            </app-input-text-container>
          </div>
        </div>
      </fieldset>

      <fieldset>
        <div class="form-row">
          <div class="form-group col">
            <!-- CPF -->
            <app-input-text-container
              [label]="'modules.simulation.personal-data.what is your cpf number' | translate"
              [customErrorMessage]="'common.invalid CPF' | translate"
            >
              <input
                type="tel"
                id="cpf"
                autocomplete="off"
                formControlName="documentNumber"
                mask="000.000.000-00"
                [placeholder]="'common.type' | translate"
                (focusout)="onFocusOutCpf()"
              />
            </app-input-text-container>
          </div>
        </div>
      </fieldset>

      <fieldset>
        <div class="form-row">
          <div class="form-group col">
            <!-- Birth Date -->
            <app-input-text-container
              helperName="birthDate"
              [label]="'modules.simulation.personal-data.when were you born' | translate"
              [customErrorMessage]="'common.age must be greater than 16 and less than 80 yers old' | translate"
            >
              <input
                type="tel"
                id="data"
                autocomplete="off"
                placeholder="dd/mm/aaaa"
                formControlName="birthDate"
                mask="00/00/0000"
                [dropSpecialCharacters]="false"
              />
            </app-input-text-container>
          </div>
        </div>
      </fieldset>

      <fieldset>
        <div class="form-row">
          <div class="form-group col">
            <!-- Email -->
            <app-input-text-container
              [label]="'modules.simulation.personal-data.what is your preferred e-mail address' | translate"
              [customErrorMessage]="'common.invalid e-mail' | translate"
            >
              <input
                type="text"
                autocomplete="off"
                id="email"
                placeholder="nome@email.com"
                formControlName="email"
                maxlength="60"
                pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$"
              />
            </app-input-text-container>
          </div>
        </div>
      </fieldset>

      <fieldset>
        <div class="form-row">
          <div class="form-group col">
            <!-- Phone -->
            <app-input-text-container
              helperName="phone"
              [label]="'modules.simulation.personal-data.what is your phone number' | translate"
              [customErrorMessage]="'common.invalid phone number' | translate"
            >
              <input
                type="tel"
                id="telefone"
                placeholder="( )"
                formControlName="telephoneNumber"
                autocomplete="off"
                mask="(00) 00000-0000"
              />
            </app-input-text-container>
          </div>
        </div>
      </fieldset>

      <fieldset>
        <div class="form-row">
          <div class="form-group col">
            <!-- Monthly Income -->
            <app-input-text-container
              helperName="monthlyIncome"
              [label]="'modules.simulation.personal-data.what is your monthly income' | translate"
              [customErrorMessage]="'common.value less than minimum' | translate"
            >
              <input
                maxlength="16"
                type="tel"
                id="rendaMensalBruta"
                autocomplete="off"
                placeholder="R$"
                formControlName="monthlyIncome"
                (blur)="onBlurMonthlyIncome()"
                currencyMask
              />
            </app-input-text-container>
          </div>
        </div>
      </fieldset>

      <fieldset *ngIf="isVisible('authorizesEmailAndSms')">
        <div class="form-row">
          <div class="form-group col">
            <!--SMS E e-mail radio button-->
            <app-input-radio-container
              [label]="
                'modules.simulation.personal-data.does the customer authorize Santander to contact him/her through calls, SMS and e-mail'
                  | translate
              "
            >
              <div class="form-row">
                <div class="form-group form-group-radio col">
                  <input
                    [value]="true"
                    type="radio"
                    id="notificarParticipanteSim"
                    formControlName="authorizesEmailAndSms"
                  />
                  <label for="notificarParticipanteSim" class="label-radio">{{ 'common.yes' | translate }}</label>
                </div>
                <div class="form-group form-group-radio col">
                  <input
                    [value]="false"
                    type="radio"
                    id="notificarParticipanteNao"
                    (change)="onShowNoAuthorizesEmailAndSmsModal()"
                    formControlName="authorizesEmailAndSms"
                  />
                  <label for="notificarParticipanteNao" class="label-radio">{{ 'common.no' | translate }}</label>
                </div>
              </div>
            </app-input-radio-container>
          </div>
        </div>
      </fieldset>

      <!-- <fieldset>
        <div class="form-row">
          <div class="form-group col">
            <app-input-radio-container
              [label]="'Para efeito de obter condições diferenciadas, o cliente é funcionário do Grupo Santander?'"
            >
              <div class="form-row">
                <div class="form-group form-group-radio col">
                  <input [value]="true" type="radio" id="participanteGrupoSantanderSim" formControlName="isEmployee" />
                  <label for="participanteGrupoSantanderSim" class="label-radio">{{ 'common.yes' | translate }}</label>
                </div>
                <div class="form-group form-group-radio col">
                  <input [value]="false" type="radio" id="participanteGrupoSantanderNao" formControlName="isEmployee" />
                  <label for="participanteGrupoSantanderNao" class="label-radio">{{ 'common.no' | translate }}</label>
                </div>
              </div>
            </app-input-radio-container>
          </div>
        </div>
      </fieldset> -->

      <fieldset>
        <div class="form-row">
          <div class="form-group col">
            <!-- second participant -->
            <app-input-checkbox-container
              helperName="otherParticipant"
              [label]="
                'modules.simulation.personal-data.would you like to include the income of another participant'
                  | translate
              "
              for="incluirRendaParticipante"
            >
              <input
                type="checkbox"
                id="incluirRendaParticipante"
                formControlName="otherParticipant"
                name="otherParticipant"
              />
            </app-input-checkbox-container>
          </div>
        </div>
      </fieldset>

      <!-- Other Participant Form -->
      <ng-content></ng-content>

      <!-- Button -->
      <div class="group-buttons">
        <button type="button" id="btnSubmitPreAnalise" class="button-default" (click)="onSubmit()">
          {{ 'button.next' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
