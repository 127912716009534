import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import {
  getSimulationDataParametersVariables,
  getSimulationDataParameters,
  getPersonalDataParametersSimulation,
  getProposalDataParametersSimulationVariables,
  getProposalDataParametersSimulation,
  recuperaParametrosPerfil,
  recuperaParametrosPerfilVariables,
  getDevelopmentsVariables,
  getDevelopments
} from '../graphql/types/query-types';
import {
  GET_DEVELOPMENTS,
  GET_PERSONAL_DATA_PARAMETERS,
  RECUPERAR_MENU_PERFIL
} from '../graphql/queries/parameters.query';
import { GET_PROPOSAL_DATA_PARAMETERS } from '../graphql/queries/proposal-data.query';
import { GET_SIMULATION_DATA_PARAMETERS } from '../graphql/queries/proposal-data.query';

@Injectable()
export class ParameterService {
  constructor(private apollo: Apollo) { }

  getPersonalData(simulationId: string) {
    let variables = {};

    if (simulationId) {
      variables = { ...variables, simulationId };
    }

    return this.apollo
      .query({
        query: GET_PERSONAL_DATA_PARAMETERS,
        variables,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map(r => {
          if (r.errors) throw r.errors[0];
          return r.data as getPersonalDataParametersSimulation;
        }),
        catchError(err => throwError(err))
      );
  }

  getProposalData(channel: string, simulationId, tpPessoa: string, loanTypeKey?) {
    return this.apollo
      .query({
        query: GET_PROPOSAL_DATA_PARAMETERS,
        variables: {
          channel,
          simulationId,
          tpPessoa,
          loanTypeKey
        } as getProposalDataParametersSimulationVariables,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map(r => {
          if (r.errors) throw r.errors[0];
          return r.data as getProposalDataParametersSimulation;
        }),
        catchError(err => throwError(err))
      );
  }

  getSimulationData(proposalData?) {
    return this.apollo
      .query({
        query: GET_SIMULATION_DATA_PARAMETERS,
        variables: {
          input: this.createSimulationDataPayload(proposalData)
        } as getSimulationDataParametersVariables,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map(r => {
          if (r.errors) throw r.errors[0];
          return r.data as getSimulationDataParameters;
        }),
        catchError(err => throwError(err))
      );
  }

  recoverProfile(cdsMenu, usuario) {
    return this.apollo
      .query({
        query: RECUPERAR_MENU_PERFIL,
        variables: {
          cdsMenu,
          usuario
        } as recuperaParametrosPerfilVariables,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map(r => {
          if (r.errors) throw r.errors[0];
          return r.data as recuperaParametrosPerfil;
        }),
        catchError(err => throwError(err))
      );
  }

  getDevelopments(documentNumber) {
    return this.apollo
      .query({
        query: GET_DEVELOPMENTS,
        variables: {
          documentNumber
        } as getDevelopmentsVariables,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map(r => {
          if (r.errors) throw r.errors[0];
          return r.data as getDevelopments;
        }),
        catchError(err => throwError(err))
      );
  }

  private createSimulationDataPayload(input) {
    return {
      simulationId: input.simulationId,
      selectedProposalOptions: {
        channel: input.channel,
        loanTypeKey: input.loanTypeKey,
        buildingTypeKey: input.buildingTypeKey,
        propertyLocationKey: input.propertyLocationKey,
        portabilityTypeKey: input.portabilityTypeKey
      },
      simulationEntryValues: {
        propertyValue: input.propertyValue,
        evaluationValue: input.evaluationValue,
        downPaymentAmount: input.downPaymentAmount,
        fgtsAmount: input.fgtsAmount,
        loanAmountValue: input.loanAmountValue,
        isPropertyNew: input.isPropertyNew,
        termInMonths: input.termInMonths,
        termInYears: input.termInYears,
        monthsDeferredPaymentQt: input.monthsDeferredPaymentQt
      },
      selectedOptions: {
        termInYearsKey: input.termInYearsKey || '',
        campaignKey: input.campaignKey || '',
        marketSegmentKey: input.marketSegmentKey || '',
        offerKey: input.offerKey || '',
        paymentTypeKey: input.paymentTypeKey || '',
        amortizationTypeKey: input.amortizationTypeKey || '',
        monthWithoutPaymentKey: input.monthWithoutPaymentKey || '',
        insurerKey: input.insurerKey || '',
        insurancePolicyKey: input.insurancePolicyKey || '',
        deferredPaymentKey: input.deferredPaymentKey || ''
      },
      isAppraisalfeesInClosingCostCredit: input.isAppraisalfeesInClosingCostCredit || false,
      isIOFIncludedInClosingCostCredit: input.isIOFIncludedInClosingCostCredit || false,
      isTransferTaxInClosingCostCredit: input.isTransferTaxInClosingCostCredit || false,
      estimatedTRRate: input.estimatedTRRate || 0,
      usesEstimatedTRrate: input.usesEstimatedTRrate || false,
      interestRatesToCalculate: [] // ???
    };
  }
}
