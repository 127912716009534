<div class="container text-center mt-5" *ngIf="valuesWithRelationship">
  <h2>
    {{ 'modules.simulation.result.If the proposal values ​​informed at the time of the simulation are changed, with the credit approval and/or valuation of the property, the financing conditions will be updated and may change.' | translate }}
  </h2>
</div>
<section class="position-relative mt-5 mb-4">
  <div class="container">
    <div class="row">
      <div class="col-md-6 mb-5 mb-md-0 mt-0" *ngIf="valuesWithRelationship">
        <h4 class="check d-inline-block">
          {{ 'modules.simulation.result.values with relationship' | translate | uppercase }}
        </h4>

        <div class="row form-row mt-md-4">
          <div class="col-12 form-group mt-md-4">
            <label for="">{{ 'modules.simulation.result.effective interest rate subsidized' | translate }}</label>
            <span class="info mr-2 taxa-aa currency-brl">
              {{ valuesWithRelationship.interestRates.yearInterestRate | percentPipe: null:false }}
            </span>
            <span
              class="info taxa-am currency-brl"
              [ngClass]="{ 'taxa-am-tr': valuesWithRelationship?.usesEstimatedTRrate }"
            >
              {{ valuesWithRelationship.interestRates.monthInterestRate | percentPipe: null:false }}
            </span>
          </div>
          <div class="col-12 form-group" *ngIf="hasCarencia">
            <label for="">{{
              'modules.simulation.result.payments during deferred period with bonus' | translate
            }}</label>
            <span class="info currency-brl">{{
              valuesWithRelationship.paymentAmountOnDeferredPeriod | currencyPipe
            }}</span>
          </div>
          <div class="col-12 form-group">
            <label for="" *ngIf="hasCarencia">{{
              'modules.simulation.result.installment after period of deferred with bonus' | translate
            }}</label>
            <label for="" *ngIf="!hasCarencia">{{
              'modules.simulation.result.entry installment subsidized value' | translate
            }}</label>
            <span class="info currency-brl">{{ valuesWithRelationship.firstPaymentAmount | currencyPipe }}</span>
          </div>
          <div class="col-12 form-group">
            <span class="description">{{ 'modules.simulation.result.total effective cost (TEC) with bonus' | translate }}</span>
            <label for="">
              <app-helper-button appHelperTooltip helperName="cetRateBonus"></app-helper-button>
            </label>
            <span class="info taxa-aa">{{ valuesWithRelationship.cetRate | percentPipe: null:false }}</span>
          </div>
          <div class="col-12 form-group" *ngIf="valuesWithRelationship.ceshRate > 0">
            <span class="description-cesh">{{ 'modules.simulation.result.effective cost of housing insurance (ECHI)' | translate }}</span>
            <label for="">
              <app-helper-button appHelperTooltip helperName="ceshRate"></app-helper-button>
            </label>
            <span class="info">{{ valuesWithRelationship.ceshRate | percentPipe: 4 }}</span>
          </div>
        </div>
      </div>

      <div class="col-md-6 mt-5 mt-md-0" *ngIf="valuesNoRelationship && !fgOferta">
        <!--  -->
        <h4>{{ 'modules.simulation.result.values no relationship' | translate | uppercase }}</h4>

        <div class="row form-row mt-md-4">
          <div class="col-12 form-group mt-md-4">
            <label for="">{{ 'modules.simulation.result.effective interest rate without bonus' | translate }}</label>
            <span class="info mr-2 taxa-aa">
              {{ valuesNoRelationship.interestRates.yearInterestRate | percentPipe: null:false }}
            </span>
            <span class="info taxa-am" [ngClass]="{ 'taxa-am-tr': valuesNoRelationship?.usesEstimatedTRrate }">
              {{ valuesNoRelationship.interestRates.monthInterestRate | percentPipe: null:false }}
            </span>
          </div>
          <div class="col-12 form-group" *ngIf="hasCarencia">
            <label for="">{{
              'modules.simulation.result.payments during deferred period without bonus' | translate
            }}</label>
            <span class="info">{{ valuesNoRelationship.paymentAmountOnDeferredPeriod | currencyPipe }}</span>
          </div>
          <div class="col-12 form-group">
            <label for="" class="no-break-word" *ngIf="hasCarencia">{{
              'modules.simulation.result.installment after period of deferred without bonus' | translate
            }}</label>
            <label for="" *ngIf="!hasCarencia">{{
              'modules.simulation.result.entry installment value without bonus' | translate
            }}</label>
            <span class="info">{{ valuesNoRelationship.firstPaymentAmount | currencyPipe }}</span>
          </div>
          <div class="col-12 form-group">
            <span class="description">{{ 'modules.simulation.result.total effective cost (TEC) without bonus' | translate }}</span>
            <label for="">
              <app-helper-button appHelperTooltip helperName="cetRate"></app-helper-button>
            </label>
            <span class="info taxa-aa">{{ valuesNoRelationship.cetRate | percentPipe: null:false }}</span>
          </div>
          <div class="col-12 form-group" *ngIf="valuesNoRelationship.ceshRate > 0">
            <!--  -->
            <span class="description-cesh">{{ 'modules.simulation.result.effective cost of housing insurance (ECHI)' | translate }}</span>
            <label for="">
              <app-helper-button appHelperTooltip helperName="ceshRate"></app-helper-button>
            </label>
            <span class="info">{{ valuesNoRelationship.ceshRate | percentPipe: 4 }}</span>
          </div>
        </div>
      </div>

    <div class="col-md-6 mt-5 mt-md-0" *ngIf="valuesNoRelationship && fgOferta">
    <h4>{{ 'common.offer' | translate | uppercase }}</h4>

    <div class="row form-row mt-md-4">
      <div class="col-12 form-group mt-md-4">
        <label for="">{{ 'modules.simulation.result.effective interest rate' | translate }}</label>
        <span class="info mr-2 taxa-aa">
          {{ valuesNoRelationship.interestRates.yearInterestRate | percentPipe: null:false }}
        </span>
        <span class="info taxa-am" [ngClass]="{ 'taxa-am-tr': valuesNoRelationship?.usesEstimatedTRrate }">
          {{ valuesNoRelationship.interestRates.monthInterestRate | percentPipe: null:false }}
        </span>
      </div>
      <div class="col-12 form-group" *ngIf="hasCarencia">
        <label for="">{{
          'modules.simulation.result.payments during deferred period' | translate
        }}</label>
        <span class="info">{{ valuesNoRelationship.paymentAmountOnDeferredPeriod | currencyPipe }}</span>
      </div>
      <div class="col-12 form-group">
        <label for="" class="no-break-word" *ngIf="hasCarencia">{{
          'modules.simulation.result.installment after period of deferred' | translate
        }}</label>
        <label for="" *ngIf="!hasCarencia">{{
          'modules.simulation.result.entry installment' | translate
        }}</label>
        <span class="info">{{ valuesNoRelationship.firstPaymentAmount | currencyPipe }}</span>
      </div>
      <div class="col-8 form-group">
        <span class="description">{{ 'modules.simulation.result.total effective cost (TEC)' | translate }}</span>
        <label for="">
          <app-helper-button appHelperTooltip helperName="cetRate"></app-helper-button>
        </label>
        <span class="info taxa-aa">{{ valuesNoRelationship.cetRate | percentPipe: null:false }}</span>
      </div>
      <div class="col-12 form-group" *ngIf="valuesNoRelationship.ceshRate > 0">
        <span class="description-cesh">{{ 'modules.simulation.result.effective cost of housing insurance (ECHI)' | translate }}</span>
        <label for="">
          <app-helper-button appHelperTooltip helperName="ceshRate"></app-helper-button>
        </label>
        <span class="info">{{ valuesNoRelationship.ceshRate | percentPipe: 4 }}</span>
      </div>
    </div>
    </div>

      <div class="container mt-5">
        <p class="black-text-center">{{ 'modules.simulation.result.these are values ​​from your simulation' | translate }}</p>
        <button type="button" class="button-default" (click)="onSave()" id="btnAcompanhamentoResumoProposta" [ngClass]="{ 'btn-disabled': !formConditionResult.valid }">
          {{ 'button.click here for credit analysis' | translate | uppercase }}
        </button>
        <!-- <p class="red-text-center mt-4">{{ 'modules.simulation.result.+ than 100 thousand people with approved credit' | translate }}</p> -->
        <app-btn-extract-simulation
          class="extract-simulation"
          [allowPrint]="false"
          [allowEmail]="true"
          (extractSimulation)="extractSimulation($event)"
        ></app-btn-extract-simulation>

        <div class="group-buttons" *ngIf="!isUseCasaMais">
          <button type="button" type="button" class="button-back" (click)="onBack()" id="buttonVoltar">
            <span class="material-icons">keyboard_arrow_left</span>
            <strong>{{ 'button.back' | translate | uppercase }}</strong>
          </button>
        </div>
      </div>
    </div>
  </div>
</section>