<div class="help-footer" [class.active]="show">
  <div class="container">
    <div class="row title">
      <div class="col-12">
        <span class="text">Como podemos ajudar?</span>
        <span class="material-icons" (click)="closeHelper()" *ngIf="show">close</span>
      </div>
    </div>
    <div class="row content-holder">
      <div class="content active" *ngIf="questionActive">
        <div class="col-12 question">
          <span class="text">{{ questionActive.question }}</span>
        </div>
        <div class="col-12 answer">
          <div>
            <span class="text" [innerHTML]="questionActive.answer"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
