import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpRequest } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private pendingRequests: Subject<any>;
  private nextRequest: HttpRequest<any>;

  constructor() {}

  resolvePendingRequests(): void {
    if (!this.pendingRequests) return;
    this.pendingRequests.next(this.nextRequest);
    this.pendingRequests = null;
  }

  setPendingRequests(pendingRequests: Subject<any>, nextRequest: HttpRequest<any>): void {
    this.pendingRequests = pendingRequests;
    this.nextRequest = nextRequest;
  }
}
