import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input() show: Observable<{
    show: boolean;
    template: any;
    content: any;
    options?: any;
    closed?: () => void;
    back?: () => void;
  }>;

  constructor(private changeDetectorRef: ChangeDetectorRef, private modalService: NgbModal) {}

  ngOnInit() {
    this.show.subscribe(value => {
      if (value.show && !this.modalService.hasOpenModals()) {
        const options: NgbModalOptions = { backdrop: 'static' };
        let modalRef: NgbModalRef;

        if (value.options) {
          modalRef = this.modalService.open(value.template, { ...options, ...value.options });
        } else {
          modalRef = this.modalService.open(value.template, options);
        }

        modalRef.componentInstance.setData(value.content);

        if (value.closed) {
          modalRef.componentInstance.setActionClosed(value.closed);
        }

        if (value.back) {
          modalRef.componentInstance.setActionBack(value.back);
        }
      }
      this.changeDetectorRef.markForCheck();
    });
  }
}
