import { Component } from '@angular/core';
import { ModalTemplateComponent } from '../modal-template/modal-template.component';

@Component({
  selector: 'app-modal-alert',
  templateUrl: './modal-alert.component.html',
  styleUrls: ['./modal-alert.component.scss']
})
export class ModalAlertComponent extends ModalTemplateComponent {
  

  buttonClick(action) {
    if (action === 'n') return this.back();
    this.closed();
  }
}
