/**
 * sessionStorage names
 */
export const HISTORY = 'history';
export const USER = 'user';
export const TOKEN_SIM = 'token_sim';
export const URL_REQUEST = 'urlRequest';

/**
 * Integration parameters from the old core application
 */
export interface IntegrationParameters {
  username: string;
  url: string;
  errorMessage: string;
  token: any;
}

/**
 * All possible status for integration with the old core application
 */
export enum IntegrationStatus {
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE',
  EXPIRED = 'EXPIRED'
}

/**
 * If integration is unavailable, returns the parameters (even if none is found)
 */
export interface IntegrationUnavailable {
  status: IntegrationStatus.UNAVAILABLE;
  parameters: IntegrationParameters;
}

/**
 * If the integration is available returns the refresh token (and the parameters)
 */
export interface IntegrationAvailable {
  status: IntegrationStatus.AVAILABLE;
  refreshToken: string;
  parameters: IntegrationParameters;
}

/**
 * If the integration is expired returns the error message (and the parameters)
 */
export interface IntegrationExpired {
  status: IntegrationStatus.EXPIRED;
  errorMessage: string;
  parameters: IntegrationParameters;
}

/**
 * All response types combined
 */
export type IntegrationStatusResponse = IntegrationUnavailable | IntegrationAvailable | IntegrationExpired;
