<div>
    <app-timeline [fase]="2" [nomeFase]="'Dados da Proposta'" [scrollPage]="85"></app-timeline>

    <app-form-proposal-data
      *ngIf="!showAuthentication"
      [formProposalData]="formProposalData"
      [showPropertyLocationKey]="showPropertyLocationKey"
      [loanObjectives]="loanObjectives"
      [federalStates]="federalStates"
      [buildingTypes]="buildingTypes"
      [deferredPayment]="deferredPayment"
      [estimatedOperatingPeriods]="estimatedOperatingPeriods"
      [visibleFields]="visibleFields"
      [editableFields]="editableFields"
      [portabilityTypes]="portabilityTypes"
      [arrCarencia]="arrCarencia"
      [allowCarencia]="allowCarencia"
      [minPropertyValue]="minPropertyValue"
      [minEvaluationValue]="minEvaluationValue"
      [minTermInYears]="minTermInYears"
      [maxTermInYears]="maxTermInYears"
      [minLoanAmountValue]="minLoanAmountValue"
      [maxLoanAmountValue]="maxLoanAmountValue"
      [selectedLoanTypeKey]="selectedLoanTypeKey"
      [loanAmountLabel]="loanAmountLabel"
      [termInMonthsLabel]="termInMonthsLabel"
      [flagPortability]="flagPortability"
      [tipoImovelState]="tipoImovelState"
      [loanTypes]="loanTypes"
      [label]="label"
      [singleContract]="singleContract"
      [isSimulationInProgress]="isSimulationInProgress"
      [submitedContractList]="submitedContractList"
      [isencaoTarifaUseCasa]="isencaoTarifaUseCasa"
      (fieldChanged)="onFieldChanged($event)"
      (submitFormProposalData)="submitFormProposalData()"
      (focusOutEvaluationValue)="focusOutEvaluationValue()"
      (back)="back()"
      (clickEdit)="onClickEdit()"
    >
    </app-form-proposal-data>

    <!-- <app-header-proposal-summary
      *ngIf="showAuthentication"
      [borrowers]="borrowers"
      [hasInconsistencies]="hasInconsistencies"
      [isFinalized]="isFinalized"
      [pathImageClient]="pathImageClient"
      [nameExibition]="nameExibition"
      [formProponentData]="formProponentData"
      [utmSource]="utmSource"
      (fillProponentData)="fillProponentData($event)"
      (redirectTo)="redirectTo($event)"
    >
    </app-header-proposal-summary> -->

    <div *ngIf="showAuthentication">
      <div *ngIf="!useMockLogin" class="container">
        <iframe scrolling="auto" [src]="urlLoginIframe" frameborder="0" id="iframe-resumo" class="iframe-resumo"></iframe>
      </div>

      <div class="container">
        <div class="row d-flex justify-content-center">
          <form *ngIf="useMockLogin" [formGroup]="formLoginRenovado">
            <fieldset>
              <div class="form-row">
                <div class="form-group col-xs-4">
                    <app-input-text-container [label]="'Login'">
                      <input
                        type="text"
                        placeholder="Digite"
                        formControlName="name"
                        class="ajust-width text-uppercase"
                        id="login"
                      />
                    </app-input-text-container>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <div class="form-row">
                <div class="form-group col-xs-4">
                    <app-input-text-container [label]="'Password'">
                      <input
                        type="password"
                        placeholder="Digite"
                        formControlName="password"
                        class="ajust-width"
                        id="password"
                      />
                    </app-input-text-container>
                </div>
              </div>
            </fieldset>
            <button type="button" class="mt-5 mb-5 button-default" (click)="loginUnico()">{{ 'button.login' | translate }}</button>
          </form>
        </div>
      </div>
    </div>

    <app-modal [show]="modal"></app-modal>

    <app-modal-contract-list title="Lista de Contratos" [form]="formContractList"
      [list]="contractListPC" [formProposalData]="formProposalData" channel="PC" (advance)="selectContract()">
    </app-modal-contract-list>

  </div>
