import gql from 'graphql-tag';

export const SAVE_SIMULATION = gql`
  mutation saveSimulationDataSimulation($input: InputCalculateSimulation!) {
    saveSimulationDataSimulation(input: $input) {
      isSuccessful
    }
  }
`;

export const CONFIRM_SUMMARY = gql`
  mutation confirmSummaryDataSimulation($input: InputConfirmSummaryData!) {
    confirmSummaryDataSimulation(input: $input) {
      isSuccessful
    }
  }
`;

export const SEND_PDF_EMAIL = gql`
  mutation sendPdfEmail($simulationId: ID!) {
    sendPdfEmail(simulationId: $simulationId) {
      pdfHash
    }
  }
`;

// export const SAVE_CREDIT_SIMULATION = gql`
//   mutation saveCreditSimulation($input: InputCreditSimulation) {
//     saveCreditSimulation(input: $input) {
//       amortizacao {
//         dtAmortizacao
//       }
//       taxas {
//         idTaxaDoc
//         vlTaxaDoc
//         tpTaxaPara
//       }
//       seguros {
//         idSeguro
//         idSeguroApolice
//         idSeguradora
//         vlSeguro
//         tpBase
//       }
//       taxaTemp {
//         nmTaxaDoc
//         vlDocBanco
//       }
//       cobol {
//         rdm {
//           vlRdm
//         }
//         amortizacao {
//           dtAmortizacao
//         }
//         loopParcelasV6 {
//           vlPrincipal
//           vlJurosV6
//         }
//         extrato {
//           vlImovel
//           vlFinanciamento
//           vlTaxaJurosNom
//           dsCarteira
//           idIndexador
//           tpAmortizacao
//           qtParcelas
//           pcCesh
//           vlCetAno
//           pzIsencao
//           hasIsencao
//           hasCarencia
//           dsCarencia
//           pzCarencia
//           pzAmortCnt
//           transaction {
//             xtrid
//           }
//         }
//       }
//       proposta {
//         dsDocumento
//         tipo
//       }
//     }
//   }
// `;

// export const SAVE_OWNERS_SIMULATION = gql`
//   mutation saveOwnersSimulation($input: InputSaveOwnersSimulation) {
//     saveOwnersSimulation(input: $input) {
//       isSuccessful
//     }
//   }
// `;
