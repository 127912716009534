import { Injectable } from '@angular/core';
import * as AesJS from 'aes-js';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class StorageEncryptService {
  readonly IV = this.convertToArray(environment.featureToggle.crypto.iv);
  readonly KEY = this.convertToArray(environment.featureToggle.crypto.key);
  readonly cryptoStorageActive = environment.featureToggle.cryptoStorageActive === 'true' ? true : false;

  constructor() {}

  private encrypt(text: string): string {
      const emBase64 = window.btoa(encodeURI(text));
      const aesOfb = new AesJS.ModeOfOperation.ofb(this.KEY, this.IV);
      const textBytes = AesJS.utils.utf8.toBytes(emBase64);
      const encryptedBytes = aesOfb.encrypt(textBytes);
      const encText = AesJS.utils.hex.fromBytes(encryptedBytes);

      return encText;
  }

  private decrypt(encText: string): any {
    const aesOfb = new AesJS.ModeOfOperation.ofb(this.KEY, this.IV);
    const encryptedBytes = AesJS.utils.hex.toBytes(encText);
    const decryptedBytes = aesOfb.decrypt(encryptedBytes);
    const decryptedString = AesJS.utils.utf8.fromBytes(decryptedBytes);
    const decText = decodeURI(window.atob(decryptedString));

    return decText;
  }

  private convertToArray(text: string) {
    return text.split(',').map(value => parseInt(value.trim(), 10));
  }

  public setSessionStore(key: string, value: string) {
    if (this.cryptoStorageActive) {
      const encKey = this.encrypt(key);
      const encValue = this.encrypt(value);
      sessionStorage.setItem(encKey, encValue);
      return;
    }
    sessionStorage.setItem(key, value);
  }

  public getSessionStore(key: string) {
    if (this.cryptoStorageActive) {
      const encKey = this.encrypt(key);
      const encValue = sessionStorage.getItem(encKey);
      const decValue = encValue ? this.decrypt(encValue) : null;
      return decValue;
    }
    const value = sessionStorage.getItem(key);
    return value ? value : null;
  }

  public setLocalStore( key: string, value: string) {
    if (this.cryptoStorageActive) {
      const encKey = this.encrypt(key);
      const encValue = this.encrypt(value);
      localStorage.setItem(encKey, encValue);
      return;
    }
    localStorage.setItem(key, value);
  }

  public getLocalStore(key: string) {
    if (this.cryptoStorageActive) {
      const encKey = this.encrypt(key);
      const encValue = localStorage.getItem(encKey);
      const decValue = encValue ? this.decrypt(encValue) : null;
      return decValue;
    }
    const value = localStorage.getItem(key);
    return value ? value : null;
  }

  public removeSession(key: string) {
    if (this.cryptoStorageActive) {
      const encKey = this.encrypt(key);
      sessionStorage.removeItem(encKey);
    } else {
      sessionStorage.removeItem(key);
    }
  }

  public removeLocal(key: string) {
    if (this.cryptoStorageActive) {
      const encKey = this.encrypt(key);
      localStorage.removeItem(encKey);
    } else {
      localStorage.removeItem(key);
    }
  }
}
