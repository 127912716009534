import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, switchMap, tap, withLatestFrom, map } from 'rxjs/operators';
import { PersonalDataService } from '@app/shared/services/personal-data.service';
import { ErrorService } from '@app/shared/services/error.service';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '..';
import { ModalService } from '@shared/ui/modal/services/modal.service';
import { environment } from 'src/environments/environment';

import * as fromProposal from './../proposal';
import * as fromParameters from './../parameters';
import * as fromErrorHandling from './../error-handling';
import * as actions from './participants.actions';
import * as selectors from './participants.selectors';
import { StorageEncryptService } from '@shared/services/storageEncrypt.service';

@Injectable()
export class ParticipantsEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private personalDataService: PersonalDataService,
    private errorService: ErrorService,
    private router: Router,
    private modalService: ModalService,
    private storageEncryptService: StorageEncryptService

  ) { }

  /**
   * Send Personal Data
   *
   * Updates Current Task Key state
   * Updates Personal Data state
   */
  @Effect()
  sendPersonalData$ = this.actions$.pipe(
    ofType<actions.SendParticipants>(actions.ParticipantsActionTypes.SEND),
    withLatestFrom(this.store$.select(selectors.selectBorrowers)),
    switchMap(([action, participants]) => {
      return this.personalDataService
        .save(action.channel, action.payload.participants, action.simulationId, action.utmSource)
        .pipe(
          switchMap(response => {
            // this.storageEncryptService.setSessionStore('idSimulation', response.savePersonalDataSimulation.simulationId);
            /** Checks if Personal Data has changed after coming back to personal-data page */
            const personalDataChanged =
              response.savePersonalDataSimulation.isSuccessful &&
              participants &&
              (hasChangedMonthlyIncome || participants.length !== action.payload.participants.length);

            /** Check if Monthly Income has changed */
            function hasChangedMonthlyIncome() {
              return participants.find((participant, i) => {
                return participant.monthlyIncome !== action.payload.participants[i].monthlyIncome;
              });
            }

            this.modalService.clearStack();

            const url = environment.skin === 'cliente' ? '/confirmacao-sms' : '/dados-proposta';

            return [
              new fromProposal.actions.SetSimulationID({
                simulationId: response.savePersonalDataSimulation.simulationId
              }),
              new actions.SetPersonalDataChanged({ personalDataChanged: !!personalDataChanged }),
              new actions.SendParticipantsSuccess({ ...action.payload }),
              new fromParameters.actions.Redirect({ url })
            ];
          }),
          catchError(err => {
            if (err.extensions) {
              return of(new fromErrorHandling.actions.AddError(this.errorService.addGraphQLAlert(err)));
            }

            return of(new fromErrorHandling.actions.AddError(this.errorService.addAlertError(err)));
          })
        );
    })
  );

  /**
   * Send Personal Data
   *
   * Updates Current Task Key state
   * Updates Personal Data state
   */
  @Effect()
  sendPersonalDataPJ$ = this.actions$.pipe(
    ofType<actions.SendParticipantsPJ>(actions.ParticipantsActionTypes.SEND_PJ),
    withLatestFrom(this.store$.select(selectors.selectBorrowers)),
    switchMap(([action, participants]) => {
      return this.personalDataService
        .savePJ(action.channel, action.payload.participants, action.simulationId, action.utmSource)
        .pipe(
          switchMap(response => {
            this.modalService.clearStack();

            return [
              new fromProposal.actions.SetSimulationID({
                simulationId: response.savePersonalDataSimulationPJ.simulationId
              }),
              new actions.SendParticipantsPJSuccess({ ...action.payload }),
              new fromParameters.actions.Redirect({ url: '/dados-proposta' })
            ];
          }),
          catchError(err => {
            if (err.extensions) {
              return of(new fromErrorHandling.actions.AddError(this.errorService.addGraphQLAlert(err)));
            }

            return of(new fromErrorHandling.actions.AddError(this.errorService.addAlertError(err)));
          })
        );
    })
  );


  /**
   * Personal Data
   *
   * Updates Current Task Key state
   * Updates Personal Data state
   */
  @Effect()
  getBorrower$ = this.actions$.pipe(
    ofType<actions.GetBorrower>(actions.ParticipantsActionTypes.GET_BORROWER_SIMULATION),
    switchMap(action => {
      return this.personalDataService.getBorrowerSimulation(action.payload.documentNumber).pipe(
        switchMap(response => {
          return [new actions.GetBorrowerSuccess({ participants: [response.getBorrower] })];
        }),
        catchError(err => {
          if (err.extensions) {
            return of(new fromErrorHandling.actions.AddError(this.errorService.addGraphQLAlert(err)));
          }

          return of(new fromErrorHandling.actions.AddError(this.errorService.addAlertError(err)));
        })
      );
    })
  );

  @Effect()
  getBorrowerPJ$ = this.actions$.pipe(
    ofType<actions.GetBorrowerPJ>(actions.ParticipantsActionTypes.GET_BORROWER_SIMULATION_PJ),
    switchMap(action => {
      return this.personalDataService.getBorrowerSimulationPJ(action.payload.documentNumber).pipe(
        switchMap(response => {
          return [new actions.GetBorrowerSuccessPJ({ company: [response.getBorrowerPJ] })];
        }),
        catchError(err => {
          if (err.extensions) {
            return of(new fromErrorHandling.actions.AddError(this.errorService.addGraphQLAlert(err)));
          }

          return of(new fromErrorHandling.actions.AddError(this.errorService.addAlertError(err)));
        })
      );
    })
  );

  /**
   * Personal Data
   *
   * Updates Current Task Key state
   * Updates Personal Data state
   */
  @Effect()
  getBorrowersListSimulation$ = this.actions$.pipe(
    ofType<actions.GetBorrowersListSimulation>(actions.ParticipantsActionTypes.GET_BORROWERS_LIST_SIMULATION),
    switchMap(action => {
      return this.personalDataService.getBorrowersListSimulation(action.simulationId).pipe(
        switchMap(response => {
          return [
            new actions.GetBorrowersListSimulationSuccess({
              participants: response.getBorrowersListSimulation
            })
          ];
        }),
        catchError(err => {
          if (err.extensions) {
            return of(new fromErrorHandling.actions.AddError(this.errorService.addGraphQLAlert(err)));
          }

          return of(new fromErrorHandling.actions.AddError(this.errorService.addAlertError(err)));
        })
      );
    })
  );

  /**
   * Update Personas Status
   */
  @Effect()
  updatePersonassStatus$ = this.actions$.pipe(
    ofType<actions.UpdatePersonasStatus>(actions.ParticipantsActionTypes.UPDATE_PERSONAS_STATUS),
    withLatestFrom(this.store$.select(fromProposal.selectors.selectSimulationID)),
    switchMap(([_, simulationId]) => {

      
      let idSimulacao = simulationId;
      if (!idSimulacao) { 
        const session = JSON.parse(this.storageEncryptService.getSessionStore('data'));
        idSimulacao = session.idProposta;   
      }

      return this.personalDataService.updatePersonasStatus(idSimulacao).pipe(
        map(response => {
          return new actions.UpdatePersonasStatusSuccess({ personasStatus: response.updatePersonasStatus });
        }),
        catchError(err => {
          if (err.extensions) {
            return of(new fromErrorHandling.actions.AddError(this.errorService.addGraphQLAlert(err)));
          }

          return of(new fromErrorHandling.actions.AddError(this.errorService.addAlertError(err)));
        })
      );
    })
  );

  @Effect({ dispatch: false })
  redirect$ = this.actions$.pipe(
    ofType<fromParameters.actions.Redirect>(fromParameters.actions.ParametersActionTypes.REDIRECT),
    tap(action => this.router.navigate([action.payload.url]))
  );

  @Effect()
  generateTokenSms$ = this.actions$.pipe(
    ofType<actions.GenerateTokenSms>(actions.ParticipantsActionTypes.GENERATE_TOKEN_SMS),
    switchMap(action => {
      return this.personalDataService.generateTokenSms(action.payload).pipe(
        switchMap(response => {
          return [
            new actions.GenerateTokenSmsSucess({
              send: response
            })
          ];
        }),
        catchError(err => {
          if (err.extensions) {
            return of(new fromErrorHandling.actions.AddError(this.errorService.addGraphQLAlert(err)));
          }

          return of(new fromErrorHandling.actions.AddError(this.errorService.addAlertError(err)));
        })
      );
    })
  );

  /**
   * Update Personas Status
   */
  @Effect()
  validateTokenSms$ = this.actions$.pipe(
    ofType<actions.ValidateTokenSms>(actions.ParticipantsActionTypes.VALIDATE_TOKEN_SMS),
    switchMap(action => {
      return this.personalDataService.validateTokenSms(action.payload).pipe(
        switchMap(response => {
          return [
            new actions.ValidateTokenSmsSucess({
              validate: response
            }),
            new fromParameters.actions.Redirect({ url: '/dados-proposta' })
          ];
        }),
        catchError(err => {
          if (err.extensions) {
            return [
              new fromErrorHandling.actions.AddError(this.errorService.addGraphQLAlert(err)),
              new actions.ValidateTokenSmsSucess({
                validate: null
              }),
              new actions.ValidateTokenSmsSucess({
                validate: false
              }),
            ] ;
          }

          return of(new fromErrorHandling.actions.AddError(this.errorService.addAlertError(err)));
        })
      );
    })
  );

  /**
   * Get unit borrower
   */
  @Effect()
  getUnitBorrower = this.actions$.pipe(
    ofType<actions.GetUnitBorrower>(actions.ParticipantsActionTypes.GET_UNIT_BORROWER_SIMULATION),
    switchMap((action) =>
      this.personalDataService.getUnitBorrower(action.unitId).pipe(
        map(response => new actions.GetBorrowerSuccess({ participants: response.getUnitBorrowerSimulation })
        ),
        catchError(err => {
          if (err.extensions) {
            return of(new fromErrorHandling.actions.AddError(this.errorService.addGraphQLAlert(err)));
          }

          return of(new fromErrorHandling.actions.AddError(this.errorService.addAlertError(err)));
        })
      )
    )
  );
}
