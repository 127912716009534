import { HelpersActionTypes, HelpersActions } from './helpers.actions';

export interface HelpersState {
  show: boolean;
  questions: Array<any>;
  fields: any;
}

export const initialState: HelpersState = {
  show: false,
  questions: [],
  fields: null
};

export function helpersReducer(state = initialState, action: HelpersActions) {
  switch (action.type) {
    case HelpersActionTypes.GET_HELPERS_SUCCESS: {
      return {
        ...state,
        fields: action.payload.fields
      };
    }

    case HelpersActionTypes.CLOSE_HELPER: {
      return {
        ...state,
        show: false,
        questions: state.questions.map(q => ({ ...q, active: false }))
      };
    }

    case HelpersActionTypes.UPDATE_HELPER_QUESTIONS: {
      const oldQuestion = state.questions.find(q => q.field === action.payload.prev);
      const oldActiveStatus = oldQuestion ? oldQuestion.active : false;

      const updatedQuestions = state.questions.map(q => {
        q.active = q.field === action.payload.curr && oldActiveStatus ? true : false;
        return q;
      });

      const updatedShow = updatedQuestions.some(q => q.active);

      return {
        ...state,
        show: updatedShow,
        questions: updatedQuestions
      };
    }

    case HelpersActionTypes.TOGGLE_HELPER_QUESTIONS: {
      let activeQuestion = state.questions.find(q => q.active);
      activeQuestion = activeQuestion ? activeQuestion.field : undefined;

      const updatedShow = action.payload === activeQuestion ? !state.show : true;
      const updatedQuestions = state.questions.map(q => {
        q.active = q.field === action.payload ? true : false;
        return q;
      });

      return {
        ...state,
        show: updatedShow,
        questions: updatedQuestions
      };
    }

    case HelpersActionTypes.CLEAR_HELPER_QUESTIONS: {
      return {
        ...state,
        questions: []
      };
    }

    case HelpersActionTypes.SET_HELPER_QUESTIONS: {
      return {
        ...state,
        questions: action.payload
      };
    }

    default:
      return state;
  }
}
