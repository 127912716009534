import gql from 'graphql-tag';

export const SAVE_PROPOSAL = gql`
  mutation saveProposalDataSimulation($input: InputSaveProposalData!) {
    saveProposalDataSimulation(input: $input) {
      isSuccessful
    }
  }
`;

export const SAVE_RENEWED_CONTRACT = gql`
  mutation saveRenewedContract($input: InputRenewedContract!) {
    saveRenewedContract(input: $input) {
      isSuccessful
    }
  }
`
