import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map, catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import {
  MUTATE_GENERATE_TOKEN_SMS,
  MUTATE_VALIDATE_TOKEN_SMS,
  SAVE_PERSONAL,
  SAVE_PERSONAL_PJ,
  UPDATE_PERSONAS_STATUS
} from '../graphql/mutations/personal-data.mutation';
import {
  savePersonalDataSimulationVariables,
  savePersonalDataSimulation,
  savePersonalDataSimulationPJVariables,
  savePersonalDataSimulationPJ,
  updatePersonasStatus,
  updatePersonasStatusVariables,
  generateTokenSmsSimulation,
  generateTokenSmsSimulationVariables,
  InputGenerateTokenSmsSimulation,
  InputValidateTokenSmsSimulation,
  validateTokenSmsSimulation,
  validateTokenSmsSimulationVariables
} from '../graphql/types/mutation-types';
import {
  getBorrowersListSimulationVariables,
  getBorrowersListSimulation,
  getBorrowerVariables,
  getBorrower,
  getBorrowerPJ,
  getUnitBorrowerSimulationVariables,
  getUnitBorrowerSimulation
} from '../graphql/types/query-types';
import {
  GET_BORROWERS_LIST_SIMULATION,
  GET_BORROWER_SIMULATION,
  GET_BORROWER_SIMULATION_PJ
} from '@shared/graphql/queries/personal-data.query';

import * as moment from 'moment';
import { StorageEncryptService } from './storageEncrypt.service';

import { environment } from 'src/environments/environment';
import { ModalAlertComponent } from '@shared/widgets/modal-alert/modal-alert.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CryptoService } from '@app/core/auth/services/crypto.service';
import { GET_UNIT_BORROWER_SIMULATION } from '@shared/graphql/queries/get-unit-borrower.query';

interface Signature {
  storeId?: string;
  outSourceId?: string;
  partnerId?: string;
  userCode?: string;
  isGeneratedFromCanais?: boolean;
  partnerOrigin?: string;
}

@Injectable({
  providedIn: 'root'
})
export class PersonalDataService {
  private repasseEnterpriseId: string; // Enterprise for "REPASSE"

  constructor(
    private apollo: Apollo,
    private storageEncryptService: StorageEncryptService,
    private ngbModalService: NgbModal,
    private CryptoService: CryptoService) { }

  save(channel: string, input: any, simulationId: any, utmSource: string) {
    return this.apollo
      .mutate({
        mutation: SAVE_PERSONAL,
        variables: {
          input: this.generateSavePayload({
            channel,
            input,
            simulationId,
            utmSource
          })
        } as savePersonalDataSimulationVariables,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map(r => {
          if (r.errors) throw r.errors[0];
          return r.data as savePersonalDataSimulation;
        }),
        catchError(err => throwError(err))
      );
  }

  savePJ(channel: string, input: any, simulationId: any, utmSource: string) {
    return this.apollo
      .mutate({
        mutation: SAVE_PERSONAL_PJ,
        variables: {
          input: this.generateSavePayloadPJ({
            channel,
            input,
            simulationId,
            utmSource
          })
        } as savePersonalDataSimulationPJVariables,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map(r => {
          if (r.errors) throw r.errors[0];
          return r.data as savePersonalDataSimulationPJ;
        }),
        catchError(err => throwError(err))
      );
  }

  private findUtmSources(utmSource) {
    const utmSourceList = ['grupozap', 'zapimoveis', 'vivareal'];
    return utmSourceList.filter(el => el === utmSource).length;
  }

  /**
   * Generate input payload for savePersonalData
   */
  private generateSavePayload(payload, signature = this.getProposalSignature()): any {
    const channelOriginCd = this.storageEncryptService.getSessionStore('codCanal');

    return {
      channel: payload.channel,
      simulationId: payload.simulationId || null,
      proposalSignature: {
        storeId: signature.storeId || null,
        outSourceId: signature.outSourceId || null,
        partnerId: signature.partnerId || null,
        userCode: signature.userCode || null,
        regionalCode: payload.regionalCode || '164',
        regionCode: payload.regionCode || '36',
        providerId: payload.providerId || '1',
        partnerCode: payload.utmSource,
        channelCd: '1',
        channelOriginCd: channelOriginCd || null,
        isGeneratedFromCanais: payload.channel === 'PP' ? false : signature.isGeneratedFromCanais,
        partnerOrigin: payload.channel === 'PC' && this.findUtmSources(payload.utmSource) ?
          'DI' : signature.partnerOrigin || null
      },
      firstBorrower: this.generateBorrowers(payload.input[0]),
      secondBorrower: payload.input[1] !== undefined ? this.generateBorrowers(payload.input[1]) : {}

    };

  }

  /**
   * Generate input payload for savePersonalData
   */
  private generateSavePayloadPJ(payload, signature = this.getProposalSignature()): any {
    return {
      channel: payload.channel,
      simulationId: payload.simulationId || null,
      proposalSignature: {
        storeId: signature.storeId || null,
        outSourceId: signature.outSourceId || null,
        partnerId: signature.partnerId || null,
        userCode: signature.userCode || null,
        regionalCode: payload.regionalCode || '164',
        regionCode: payload.regionCode || '36',
        providerId: payload.providerId || '1',
        channelCd: '1',
        isGeneratedFromCanais: signature.isGeneratedFromCanais,
        partnerOrigin: payload.channel === 'PC' && this.findUtmSources(payload.utmSource) ?
          'DI' : signature.partnerOrigin || null
      },
      borrower: {
        id: null,
        name: payload.input[0].companyName || null,
        documentNumber: payload.input[0].companyDocumentNumber || null,
        email: payload.input[0].email || null,
        telephoneNumber: payload.input[0].telephoneNumber || null,
        annualBilling: payload.input[0].annualBilling || null,
        contactName: payload.input[0].contactName || null,
        primarySegment: payload.input[0].primarySegment || null
      }
    };
  }

  /**
   * Get Proposal Signature data
   *
   * @private
   * @returns {Signature}
   * @memberof PersonalDataService
   */
  private getProposalSignature(): Signature {
    const signature = JSON.parse(this.storageEncryptService.getSessionStore('data'));
    const username = this.storageEncryptService.getSessionStore('username');

    if (signature && username) {
      return {
        ...signature,
        userCode: username.toUpperCase()
      };
    } else if (environment.skin === 'parceiro' && environment.validaCarimbo === 'true') {
      const modal = this.ngbModalService.open(ModalAlertComponent);
      modal.componentInstance.data = {
        title: 'Atenção',
        message: 'Parâmetros inválidos para simulação'
      }
      return { storeId: '22336', outSourceId: null, partnerId: null, userCode: null, isGeneratedFromCanais: true, partnerOrigin: null };
    } else {
      return { storeId: '22336', outSourceId: null, partnerId: null, userCode: null, isGeneratedFromCanais: true, partnerOrigin: null };
    }
  }

  private generateBorrowers(input) {
    return {
      id: null,
      name: input.name || null,
      birthDate: moment(input.birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD') || null,
      // this.translateService.currentLang === 'pt'
      //   ? this.convertDateToUS(input.birthDate)
      //   : moment(input.birthDate).unix(),
      documentNumber: input.documentNumber || null,
      email: input.email || null,
      telephoneNumber: input.telephoneNumber || null,
      monthlyIncome: input.monthlyIncome || null,
      authorizesEmailAndSms: input.authorizesEmailAndSms,
      isEmployee: input.isEmployee,
      isPrimaryBorrower: input.isPrimaryBorrower || null
    };
  }

  /**
   * Must have because the API only accepts US format for dates
   */
  private convertDateToUS(date) {
    return moment(moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD')).unix();
  }

  getBorrowerSimulation(documentNumber: string): Observable<getBorrower> {
    return this.apollo
      .query({
        query: GET_BORROWER_SIMULATION,
        variables: {
          documentNumber: documentNumber || ''
        } as getBorrowerVariables,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map(r => {
          if (r.errors) throw r.errors[0];
          return r.data as getBorrower;
        }),
        catchError(err => throwError(err))
      );
  }

  getBorrowerSimulationPJ(documentNumber: string): Observable<getBorrowerPJ> {
    return this.apollo
      .query({
        query: GET_BORROWER_SIMULATION_PJ,
        variables: {
          documentNumber: documentNumber || ''
        } as getBorrowerVariables,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map(r => {
          if (r.errors) throw r.errors[0];
          return r.data as getBorrowerPJ;
        }),
        catchError(err => throwError(err))
      );
  }

  getBorrowersListSimulation(simulationId?: string) {
    return this.apollo
      .query({
        query: GET_BORROWERS_LIST_SIMULATION,
        variables: {
          simulationId
        } as getBorrowersListSimulationVariables,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map(r => {
          if (r.errors) throw r.errors[0];
          return r.data as getBorrowersListSimulation;
        }),
        catchError(err => throwError(err))
      );
  }

  updatePersonasStatus(simulationId: string): Observable<updatePersonasStatus> {
    return this.apollo
      .mutate({
        mutation: UPDATE_PERSONAS_STATUS,
        variables: {
          simulationId
        } as updatePersonasStatusVariables,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map(r => {
          if (r.errors) throw r.errors[0];
          return r.data as updatePersonasStatus;
        }),
        catchError(err => throwError(err))
      );
  }

  /**
   * Set Enterprise id when proposal for "REPASSE"
   *
   * @param {string} enterpriseId
   * @memberof PersonalDataService
   */
  setRepasseEnterpriseId(enterpriseId: string) {
    this.repasseEnterpriseId = enterpriseId;
  }

  removeRepasseEnterpriseId() {
    return this.repasseEnterpriseId = null;
  }

  /**
   * Get private property Enterprise for "REPASSE"
   *
   * @returns
   * @memberof PersonalDataService
   */
  getRepasseEnterpriseId() {
    return this.repasseEnterpriseId;
  }

  generateTokenSms(inputGenerate: InputGenerateTokenSmsSimulation) {
    return this.apollo
      .mutate({
        mutation: MUTATE_GENERATE_TOKEN_SMS,
        variables: {
          input: inputGenerate
        } as generateTokenSmsSimulationVariables,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map(r => {
          if (r.errors) throw r.errors[0];
          return r.data as generateTokenSmsSimulation;
        }),
        catchError(err => throwError(err))
      );
  }

  validateTokenSms(inputValidate: InputValidateTokenSmsSimulation): Observable<validateTokenSmsSimulation> {
    return this.apollo
      .mutate({
        mutation: MUTATE_VALIDATE_TOKEN_SMS,
        variables: {
          input: inputValidate
        } as validateTokenSmsSimulationVariables,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map(r => {
          if (r.errors) throw r.errors[0];
          return r.data as validateTokenSmsSimulation;
        }),
        catchError(err => throwError(err))
      );
  }

  getUnitBorrower(unitId: string) {
    return this.apollo
      .query({
        query: GET_UNIT_BORROWER_SIMULATION,
        variables: {
          unitId
        } as getUnitBorrowerSimulationVariables,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map(r => {
          if (r.errors) throw r.errors[0];
          return r.data as getUnitBorrowerSimulation;
        }),
        catchError(err => throwError(err))
      );
  }
}

