<header>
  <div class="container">
    <div class="row flex-row-reverse flex-sm-row justify-content-between">
      <!--Logo-->
      <div class="col col-md-3 d-flex justify-content-end justify-content-sm-start align-items-center">
        <img class="logo-full" src="assets/{{ pathImageClient }}/images/logo-header.svg" alt="logo" />
        <img class="logo-mini" src="assets/{{ pathImageClient }}/images/logo-header-mini.svg" alt="logo" />
      </div>

      <!-- Sair -->
      <div
        class="col col-md-2 d-flex justify-content-start justify-content-sm-end align-items-center"
        *ngIf="showMenuExit"
      >
        <a class="header__button d-none d-sm-flex" href="javascript:void(0)" (click)="onExit()">
          Sair
        </a>

        <a class="toggle-menu d-block d-sm-none" (click)="onToggleMenu()">
          <span class="material-icons" ngClass="{ 'menu-closed' : !menuOpen, 'menu-opened' : menuOpen }">
            {{ menuOpen ? 'arrow_back' : 'menu' }}
          </span>
        </a>
      </div>
    </div>
  </div>
</header>
