import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProposalState } from '@app/state/proposal/proposal.reducer';
import {
  BuildingTypeKey,
  CustomerPortfolioName,
  LoanTypeKey,
  OfferKey,
  PaymentTypeKey,
  PortabilityTypeKey,
} from '@shared/constants/loan-type-key.enum';
import { Skins } from '@shared/models/skins.model';

import { StorageEncryptService } from './storageEncrypt.service';

@Injectable()
export class HelperService {
  constructor(private http: HttpClient, private storageEncryptService: StorageEncryptService) {}

  getQuestions() {
    return this.http.get('assets/helpers/questions.json');
  }

  /**
   * Get helpers for Cliente
   *
   * @param {ProposalState} data
   * @returns {Observable<any>}
   * @memberof HelperService
   */
  getHelpersCliente(data: ProposalState): Observable<any> {
    let response = {
      birthDate: 'birthDate',
      phone: 'phone',
      monthlyIncome: 'monthlyIncome',
      monthlyIncomeOtherParticipant: 'monthlyIncomeOtherParticipant',
      otherParticipant: 'otherParticipant',
      CD_TTP_PORTA: 'CD_TTP_PORTA',
      CD_TTP_AQIMV: 'CD_TTP_AQIMV',
      CD_TTP_USCGI: 'CD_TTP_USCGI',
      CD_TTP_DFLT: 'CD_TTP_DFLT',
    } as any;

    // OFFER KEY
    if (data.offerKey && data.offerKey === OfferKey.SEM_RELACIONAMENTO) {
      response = {
        ...response,
        offerKey: 'OFFER_KEY_WITHOUT_RELATIONSHIP',
      };
    } else {
      response = {
        ...response,
        offerKey: 'OFFER_KEY',
      };
    }

    // PAYMENT TYPE
    if (data.paymentTypeKey && data.paymentTypeKey === PaymentTypeKey.PRICE) {
      response = {
        ...response,
        paymentType: 'PAYMENT_TYPE_FIXED',
      };
    } else {
      response = {
        ...response,
        paymentType: 'PAYMENT_TYPE',
      };
    }

    // PORTABILIDADE
    if (data.loanTypeKey && parseInt(data.loanTypeKey, 10) === LoanTypeKey.PORTABILIDADE) {
      response = {
        ...response,
        loanTypeKey: 'LOAN_TYPE_KEY',
        installmentInitialValue: 'INSTALLMENT_INITIAL_VALUE',
        insurerKey: 'INSURER_KEY',
        cetRateBonus: 'CET_BONUS',
        cetRate: 'CET',
      };

      if (data.portabilityTypeKey === PortabilityTypeKey.FINANCIAMENTO) {
        response = {
          ...response,
          loanAmountValue:
            data.buildingTypeKey === BuildingTypeKey.RES ? 'EVALUATION_PRICE_RES' : 'EVALUATION_PRICE_COM',
          financingMonthPeriod: 'FINANCING_MONTH_PERIOD',
          fgtsAmount: 'FGTS_AMOUNT_NOT_ALLOWED',
          backgroundOperation: 'BACKGROUND_OPERATION',
          ceshRate: 'CESH',
        };
      }

      if (data.portabilityTypeKey === PortabilityTypeKey.EMPRESTIMO) {
        response = {
          ...response,
          loanAmountValue: 'LOAN_AMOUNT_VALUE',
          financingMonthPeriod: 'FINANCING_MONTH_PERIOD',
        };
      }
    }

    // AQUISICAO
    if (data.loanTypeKey && parseInt(data.loanTypeKey, 10) === LoanTypeKey.AQUISICAO) {
      response = {
        ...response,
        loanAmountValue: data.buildingTypeKey === BuildingTypeKey.RES ? 'EVALUATION_PRICE_RES' : 'EVALUATION_PRICE_COM',
        financingMonthPeriod: 'FINANCING_MONTH_PERIOD',
        isTransferTaxInClosingCostCredit: 'TRANSFER_TAX_IN_CLOSING_COST_CREDIT',
        isAppraisalfeesInClosingCostCredit: 'APPRAISAL_FEES_IN_CLOSING_COST_CREDIT',
        installmentInitialValue: 'INSTALLMENT_INITIAL_VALUE',
        backgroundOperation: 'BACKGROUND_OPERATION',
        insurerKey: 'INSURER_KEY',
        cetRateBonus: 'CET_BONUS',
        cetRate: 'CET',
        ceshRate: 'CESH',
      };
    }

    // USECASA
    if ((data.loanTypeKey && parseInt(data.loanTypeKey, 10) === LoanTypeKey.USECASA) || data.loanTypeKey && parseInt(data.loanTypeKey, 10) === LoanTypeKey.USECASAMAIS) {
      response = {
        ...response,
        loanAmountValue: 'LOAN_AMOUNT_VALUE',
        financingMonthPeriod: 'FINANCING_MONTH_PERIOD',
        isAppraisalfeesInClosingCostCredit: 'APPRAISAL_FEES_IN_CLOSING_COST_CREDIT_USECASA',
        isIOFIncludedInClosingCostCredit: 'IOF_INCLUDED_IN_CLOSING_COST_CREDIT',
        installmentInitialValue: 'INSTALLMENT_INITIAL_VALUE',
        insurerKey: 'INSURER_KEY',
        cetRateBonus: 'CET_BONUS',
        cetRate: 'CET',
        ceshRate: 'CESH',
      };
    }

    // CUSTOMER PORTFOLIO NAME
    if (response.hasOwnProperty('backgroundOperation') && data.customerPortfolioName) {
      if (data.customerPortfolioName === CustomerPortfolioName.TAXA_DE_MERCADO) {
        response = { ...response, backgroundOperation: 'BACKGROUND_OPERATION_FEES' };
      } else {
        response = { ...response, backgroundOperation: 'BACKGROUND_OPERATION' };
      }
    }

    return of(response);
  }

  /**
   * Get helpers for Gerente
   *
   * @param {ProposalState} data
   * @returns {Observable<any>}
   * @memberof HelperService
   */
  getHelpersGerente(data: ProposalState): Observable<any> {
    let response = {
      firstProponent: 'firstProponent',
      birthDate: 'birthDate',
      email: 'email',
      phone: 'phone',
      monthlyIncome: 'monthlyIncome',
      monthlyIncomeOtherParticipant: 'monthlyIncomeOtherParticipant',
      otherParticipant: 'otherParticipant',
      campaign: 'campaign',
      apoliceType: 'apoliceType',
      updatedEmail: 'updatedEmail',
      celphoneToContact: 'celphoneToContact',
      companyRepresentative: 'companyRepresentative',
      annualBilling: 'annualBilling',
      evaluationValue: 'evaluationValue',
    } as any;

    // Dashboard Repasse
    const channel = this.storageEncryptService.getSessionStore('channel')
      ? this.storageEncryptService.getSessionStore('channel')
      : 'PG';
    if (channel === 'DR') {
      response = {
        ...response,
        valorAvaliacao: 'valorAvaliacao',
        financiamentCom: 'financiamentCom',
        financiamentRes: 'financiamentRes',
        financingMonthPeriod: 'financingMonthPeriod',
        isFinancingExpenses: 'isFinancingExpenses',
        installmentInitialValue: 'installmentInitialValue',
        backgroundOperation: 'backgroundOperation',
        insurerKey: 'insurerKey',
        cetRateBonus: 'CET-BONIFICADO',
        cetRate: 'CET',
        ceshRate: 'CESH',
        offerKey: 'offerKey',
        paymentType: 'paymentType'
      };

      return of(response);
    }

    // OFFER KEY
    if (data.offerKey && data.offerKey === OfferKey.SEM_RELACIONAMENTO) {
      response = {
        ...response,
        offerKey: 'OFFER_KEY_WITHOUT_RELATIONSHIP',
      };
    } else {
      response = {
        ...response,
        offerKey: 'OFFER_KEY',
      };
    }

    // PAYMENT TYPE
    if (data.paymentTypeKey && data.paymentTypeKey === PaymentTypeKey.PRICE) {
      response = {
        ...response,
        paymentType: 'PAYMENT_TYPE_FIXED',
      };
    } else {
      response = {
        ...response,
        paymentType: 'PAYMENT_TYPE',
      };
    }

    // PORTABILIDADE
    if (data.loanTypeKey && parseInt(data.loanTypeKey, 10) === LoanTypeKey.PORTABILIDADE) {
      response = {
        ...response,
        loanTypeKey: 'LOAN_TYPE_KEY',
        installmentInitialValue: 'INSTALLMENT_INITIAL_VALUE',
        insurerKey: 'INSURER_KEY',
        cetRateBonus: 'CET_BONUS',
        cetRate: 'CET',
        ceshRate: 'CESH',
      };

      if (data.portabilityTypeKey === PortabilityTypeKey.FINANCIAMENTO) {
        response = {
          ...response,
          loanAmountValue:
            data.buildingTypeKey === BuildingTypeKey.RES ? 'EVALUATION_PRICE_RES' : 'EVALUATION_PRICE_COM',
          financingMonthPeriod: 'FINANCING_MONTH_PERIOD',
          fgtsAmount: 'FGTS_AMOUNT_NOT_ALLOWED',
          backgroundOperation: 'BACKGROUND_OPERATION',
        };
      }

      if (data.portabilityTypeKey === PortabilityTypeKey.EMPRESTIMO) {
        response = {
          ...response,
          loanAmountValue:
            data.buildingTypeKey === BuildingTypeKey.RES ? 'EVALUATION_PRICE_RES' : 'EVALUATION_PRICE_COM',
          financingMonthPeriod: 'FINANCING_MONTH_PERIOD',
        };
      }
    }

    if (data.loanTypeKey && parseInt(data.loanTypeKey, 10) === LoanTypeKey.QFGTS) {
      response = {
        ...response,
        loanTypeQfgtsHelper: 'QFGTS',
      };
    }

    // AQUISICAO
    if (data.loanTypeKey && parseInt(data.loanTypeKey, 10) === LoanTypeKey.AQUISICAO) {
      response = {
        ...response,
        loanAmountValue: data.buildingTypeKey === BuildingTypeKey.RES ? 'EVALUATION_PRICE_RES' : 'EVALUATION_PRICE_COM',
        financingMonthPeriod: 'FINANCING_MONTH_PERIOD',
        isTransferTaxInClosingCostCredit: 'TRANSFER_TAX_IN_CLOSING_COST_CREDIT',
        isAppraisalfeesInClosingCostCredit: 'APPRAISAL_FEES_IN_CLOSING_COST_CREDIT',
        installmentInitialValue: 'INSTALLMENT_INITIAL_VALUE',
        backgroundOperation: 'BACKGROUND_OPERATION',
        insurerKey: 'INSURER_KEY',
        cetRateBonus: 'CET_BONUS',
        cetRate: 'CET',
        ceshRate: 'CESH',
      };
    }

    // USECASA
    if (this.isUseCasa(data.loanTypeKey)) {
      response = {
        ...response,
        loanAmountValue: 'LOAN_AMOUNT_USECASA_VALUE',
        financingMonthPeriod: 'FINANCING_MONTH_PERIOD',
        isAppraisalfeesInClosingCostCredit: 'APPRAISAL_FEES_IN_CLOSING_COST_CREDIT_USECASA',
        isIOFIncludedInClosingCostCredit: 'IOF_INCLUDED_IN_CLOSING_COST_CREDIT',
        installmentInitialValue: 'INSTALLMENT_INITIAL_VALUE',
        insurerKey: 'INSURER_KEY',
        cetRateBonus: 'CET_BONUS',
        cetRate: 'CET',
        ceshRate: 'CESH',
      };
    }

    // USECASAPJ
    if (this.isUseCasaPj(data.loanTypeKey)) {
      response = {
        ...response,
        loanAmountValue: 'LOAN_AMOUNT_USECASAPJ_VALUE',
        financingMonthPeriod: 'FINANCING_MONTH_PERIOD_PJ',
        isAppraisalfeesInClosingCostCredit: 'APPRAISAL_FEES_IN_CLOSING_COST_CREDIT_USECASA',
        isIOFIncludedInClosingCostCredit: 'IOF_INCLUDED_IN_CLOSING_COST_CREDIT',
        installmentInitialValue: 'INSTALLMENT_INITIAL_VALUE',
        insurerKey: 'INSURER_KEY_PJ',
        cetRateBonus: 'CET_BONUS',
        cetRate: 'CET',
        ceshRate: 'CESH',
        campaign: 'CAMPAIGN_PJ',
      };
    }

    // IF USECASA OR POTABILITY PE
    if (
      this.isUseCasa(data.loanTypeKey) ||
      (this.isPortabilidade(data.loanTypeKey) && data.portabilityTypeKey === PortabilityTypeKey.EMPRESTIMO)
    ) {
      response = { ...response, backgroundOperation: 'BACKGROUND_OPERATION_FEES' };
    } else {
      // OR IF CUSTOMER PORTFOLIO NAME
      if (response.hasOwnProperty('backgroundOperation') && data.customerPortfolioName) {
        if (data.customerPortfolioName === CustomerPortfolioName.TAXA_DE_MERCADO) {
          response = { ...response, backgroundOperation: 'BACKGROUND_OPERATION_FEES' };
        } else {
          response = { ...response, backgroundOperation: 'BACKGROUND_OPERATION' };
        }
      }
    }

    return of(response);
  }

  /**
   * Get helpers for Parceiro
   *
   * @param {ProposalState} data
   * @returns {Observable<any>}
   * @memberof HelperService
   */
  getHelpersParceiro(data: ProposalState): Observable<any> {
    let response = {
      firstProponent: 'firstProponent',
      birthDate: 'birthDate',
      email: 'email',
      phone: 'phone',
      monthlyIncome: 'monthlyIncome',
      monthlyIncomeOtherParticipant: 'monthlyIncomeOtherParticipant',
      otherParticipant: 'otherParticipant',
      campaign: 'campaign',
    } as any;

    // OFFER KEY
    if (data.offerKey && data.offerKey === OfferKey.SEM_RELACIONAMENTO) {
      response = {
        ...response,
        offerKey: 'OFFER_KEY_WITHOUT_RELATIONSHIP',
      };
    } else {
      response = {
        ...response,
        offerKey: 'OFFER_KEY',
      };
    }

    // PAYMENT TYPE
    if (data.paymentTypeKey && data.paymentTypeKey === PaymentTypeKey.PRICE) {
      response = {
        ...response,
        paymentType: 'PAYMENT_TYPE_FIXED',
      };
    } else {
      response = {
        ...response,
        paymentType: 'PAYMENT_TYPE',
      };
    }

    // PORTABILIDADE
    if (data.loanTypeKey && parseInt(data.loanTypeKey, 10) === LoanTypeKey.PORTABILIDADE) {
      response = {
        ...response,
        loanTypeKey: 'LOAN_TYPE_KEY',
        installmentInitialValue: 'INSTALLMENT_INITIAL_VALUE',
        insurerKey: 'INSURER_KEY',
        cetRateBonus: 'CET_BONUS',
        cetRate: 'CET',
        ceshRate: 'CESH',
      };

      if (data.portabilityTypeKey === PortabilityTypeKey.FINANCIAMENTO) {
        response = {
          ...response,
          loanAmountValue:
            data.buildingTypeKey === BuildingTypeKey.RES ? 'EVALUATION_PRICE_RES' : 'EVALUATION_PRICE_COM',
          financingMonthPeriod: 'FINANCING_MONTH_PERIOD',
          fgtsAmount: 'FGTS_AMOUNT_NOT_ALLOWED',
          backgroundOperation: 'BACKGROUND_OPERATION',
        };
      }

      if (data.portabilityTypeKey === PortabilityTypeKey.EMPRESTIMO) {
        response = {
          ...response,
          loanAmountValue: 'LOAN_AMOUNT_VALUE',
          financingMonthPeriod: 'FINANCING_MONTH_PERIOD',
        };
      }
    }

    // AQUISICAO
    if (data.loanTypeKey && parseInt(data.loanTypeKey, 10) === LoanTypeKey.AQUISICAO) {
      response = {
        ...response,
        loanAmountValue: data.buildingTypeKey === BuildingTypeKey.RES ? 'EVALUATION_PRICE_RES' : 'EVALUATION_PRICE_COM',
        financingMonthPeriod: 'FINANCING_MONTH_PERIOD',
        isTransferTaxInClosingCostCredit: 'TRANSFER_TAX_IN_CLOSING_COST_CREDIT',
        isAppraisalfeesInClosingCostCredit: 'APPRAISAL_FEES_IN_CLOSING_COST_CREDIT',
        installmentInitialValue: 'INSTALLMENT_INITIAL_VALUE',
        backgroundOperation: 'BACKGROUND_OPERATION',
        insurerKey: 'INSURER_KEY',
        cetRateBonus: 'CET_BONUS',
        cetRate: 'CET',
        ceshRate: 'CESH',
      };
    }

    // USECASA
    if (this.isUseCasa(data.loanTypeKey)) {
      response = {
        ...response,
        loanAmountValue: 'LOAN_AMOUNT_USECASA_VALUE',
        financingMonthPeriod: 'FINANCING_MONTH_PERIOD',
        isAppraisalfeesInClosingCostCredit: 'APPRAISAL_FEES_IN_CLOSING_COST_CREDIT_USECASA',
        isIOFIncludedInClosingCostCredit: 'IOF_INCLUDED_IN_CLOSING_COST_CREDIT',
        installmentInitialValue: 'INSTALLMENT_INITIAL_VALUE',
        insurerKey: 'INSURER_KEY',
        cetRateBonus: 'CET_BONUS',
        cetRate: 'CET',
        ceshRate: 'CESH',
      };
    }

    // IF USECASA OR POTABILITY PE
    if (
      this.isUseCasa(data.loanTypeKey) ||
      (this.isPortabilidade(data.loanTypeKey) && data.portabilityTypeKey === PortabilityTypeKey.EMPRESTIMO)
    ) {
      response = { ...response, backgroundOperation: 'BACKGROUND_OPERATION_FEES' };
    } else {
      // OR IF CUSTOMER PORTFOLIO NAME
      if (response.hasOwnProperty('backgroundOperation') && data.customerPortfolioName) {
        if (data.customerPortfolioName === CustomerPortfolioName.TAXA_DE_MERCADO) {
          response = { ...response, backgroundOperation: 'BACKGROUND_OPERATION_FEES' };
        } else {
          response = { ...response, backgroundOperation: 'BACKGROUND_OPERATION' };
        }
      }
    }

    return of(response);
  }

  /**
   * Get simulation "field" based on helperName according to loanTypeKey & portabilityTypeKey
   *
   * @param {ProposalState} data
   * @returns {Observable<any>}
   * @memberof HelperService
   */
  simulationHelpers(data): Observable<any> {
    if (environment.skin === Skins.CLIENTE) {
      return this.getHelpersCliente(data);
    }

    if (environment.skin === Skins.GERENTE) {
      return this.getHelpersGerente(data);
    }

    return this.getHelpersParceiro(data);
  }

  /**
   * Check if Loan Type Key is PORTABILIDADE
   *
   * @param {string} loanTypeKey
   * @returns {boolean}
   * @memberof HelperService
   */
  private isPortabilidade(loanTypeKey: string): boolean {
    return loanTypeKey && parseInt(loanTypeKey, 10) === LoanTypeKey.PORTABILIDADE;
  }

  /**
   * Check if Loan Type Key is USECASA
   *
   * @param {string} loanTypeKey
   * @returns {boolean}
   * @memberof HelperService
   */
  private isUseCasa(loanTypeKey: string): boolean {
    return loanTypeKey && parseInt(loanTypeKey, 10) === LoanTypeKey.USECASA;
  }

  /**
   * Check if Loan Type Key is USECASA
   *
   * @param {string} loanTypeKey
   * @returns {boolean}
   * @memberof HelperService
   */
  private isUseCasaPj(loanTypeKey: string): boolean {
    return loanTypeKey && parseInt(loanTypeKey, 10) === LoanTypeKey.USECASA_PJ;
  }
}
