import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { simulationDomainsConstant } from '@app/shared/constants/simulation-domains.constant';
import { distinctUntilChanged, debounceTime, pairwise } from 'rxjs/operators';
import { ModalTemplateComponent } from '@app/shared/widgets/modal-template/modal-template.component';
import { ModalAlertComponent } from '@app/shared/widgets/modal-alert/modal-alert.component';
import { AppState } from '@app/state';
import { ErrorService } from '@app/shared/services/error.service';
import { FormValidationService } from '@shared/services/form-validation.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as fromProposal from '@app/state/proposal';
import * as fromSimulation from '@app/state/simulation';
import * as fromParameters from '@app/state/parameters';
import * as fromErrorHandling from '@app/state/error-handling';
import * as fromLogin from '@app/state/login';
import { StorageEncryptService } from '@shared/services/storageEncrypt.service';
import { ProposalState } from '@app/state/proposal/proposal.reducer';
import { environment } from 'src/environments/environment';
import { DataLayerSantanderService } from '../../shared/service/dataLayer/novo-data-layer.service';

@Component({
  selector: 'app-simulation-result',
  templateUrl: './simulation-result.container.html',
  styleUrls: ['./simulation-result.container.scss']
})
export class SimulationResultContainerComponent implements OnInit, OnDestroy {
  simulationData: any;
  simulationDomains: any;
  months: Array<any>;
  calculatedSimulationResult: any;
  valuesWithRelationship: any;
  valuesNoRelationship: any;
  offerChosen: string;
  pdfUrl: string;
  pdfHash: string;
  minTermInMonths: number;
  maxTermInMonths: number;
  minTermInYears: number;
  maxTermInYears: number;
  minLoanAmountValue: number;
  maxLoanAmountValue: number;
  arrCarencia: Array<any>;
  deferredPayment: any;
  isSimulationInProgress: boolean;

  proposalId: string;
  oldFgtsAmount: number;
  oldDownPaymentAmount: number;
  termInYearsList: any;

  formSimulationResult: FormGroup;
  formConditionResult: FormGroup;
  visibleFields: Array<any>;

  fgModalFGTSAmount = false;
  hasCarencia = false;

  hasUmaOferta

  offerList;

  nslang1;
  nslang2;
  nslang3;

  isUseCasaMais = false;
  isencaoTarifaUseCasa = false;
  receivedChangesArray: { controlName: string, value: string }[] = [];
  receivedChangesArraySimulation:  { controlName: string, value: string, eventType: string }[] = [];
  private subscription: Subscription = new Subscription();

  modal = new BehaviorSubject({
    show: false,
    template: ModalTemplateComponent,
    content: Object,
    closed: () => {
      return;
    }
  });

  private inputsRadio = [
    'isTransferTaxInClosingCostCredit',
    'isIOFIncludedInClosingCostCredit',
    'isAppraisalfeesInClosingCostCredit'
  ];

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private errorService: ErrorService,
    private formValidationService: FormValidationService,
    private router: Router,
    private route: ActivatedRoute,
    private storageEncryptService: StorageEncryptService,
    private dataLayerSantander: DataLayerSantanderService

  ) { }

  ngOnInit() {
    this.checkRouter()
    this.createConditionResultFormPC();
    this.createSimulationResultFormPC();
    this.createSubscriptionsPC();
    this.onChangeDeferredPaymentPC();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  checkRouter() {
    this.route.queryParams.subscribe(params => {
      if (params.hasUmaOferta) this.hasUmaOferta = true;

      if (params.nslang1 && environment.featureToggle.ENABLE_USECASAMAIS_PC === 'true') {

        this.nslang1 = decodeURIComponent(params.nslang1).replace(/\s/g, '+');
        this.nslang2 = decodeURIComponent(params.nslang2).replace(/\s/g, '+');;
        this.nslang3 = decodeURIComponent(params.nslang3).replace(/\s/g, '+');;
        this.isUseCasaMais = true;


        let scope = JSON.parse(this.storageEncryptService.getSessionStore('userData'));
        if (!this.storageEncryptService.getSessionStore('userData')) {
          this.store.dispatch(new fromLogin.actions.IntegrationPublic());
        } else if (!scope.scope.includes('simulacao')) {
          this.storageEncryptService.removeSession('userData');
          this.storageEncryptService.removeLocal('userData');
          this.store.dispatch(new fromLogin.actions.IntegrationPublic());
        } else {
          this.callSimulate(this.nslang1, this.nslang2, this.nslang3);
        }

      } else {
        this.load();
      }
    });
  }


  callSimulate(nsLang1, nsLang2, nsLang3) {
    setTimeout(() => {
      this.store.dispatch(new fromSimulation.actions.GetWarranty({ nslang1: nsLang1, nslang2: nsLang2, nslang3: nsLang3 }));
    }, 500);
  }
  /**
   * Create all the subscriptions for Simulation Data
   */
  createSubscriptionsPC(): void {
    this.subscribeToFormSimulationChanges();
    this.subscribeToSimulationDomainsPC();
    this.subscribeToVisibleFieldsPC();
    this.subscribeToQtParcCarenciaPC();
    this.subscribeToMonthsPC();
    this.subscribeToSimulationInProgressPC();
    this.subscribeToErrorsPC();
    this.subscribeToListOffers();
    this.subscribeToProposalPC();
    this.subscribeToGetWarranty();
    this.subscribeToIntegration();
    this.subscribeToFormChangesPC();
    this.subscribeClosingCostsCredit();
  }

  subscribeClosingCostsCredit(): void {
    this.subscription.add(
      this.store.pipe(select(fromParameters.selectors.selectClosingCostsCredit)).subscribe(state => {
        if (!state) return;
        this.isencaoTarifaUseCasa = state;
      })
    )
  }

  subscribeToIntegration(): void {
      this.subscription.add(
        this.store.pipe(select(fromLogin.selectors.selectIntegrationSucess)).subscribe(state => {
          if (state && this.isUseCasaMais) {
            this.callSimulate(this.nslang1, this.nslang2, this.nslang3);
          }
          return;
        })
      );
    }

  subscribeToGetWarranty() {
      this.subscription.add(
        this.store.pipe(select(fromSimulation.selectors.selectGetWarranty)).subscribe(state => {
          if (!state) return;

          if (this.isUseCasaMais) {
            this.simulationData = state;

            this.store.dispatch(new fromProposal.actions.SetProposalData(state));
            this.store.dispatch(new fromParameters.actions.OffersList({ id: '603' }));
            this.load();
          }
        })
      );
    }


  subscribeToQtParcCarenciaPC(): void {
      this.subscription.add(
        this.store.pipe(select(fromProposal.selectors.selectQtParcCarencia)).subscribe(state => {
          if (!state) return;
          this.formConditionResult.get('monthsDeferredPaymentQt').patchValue(state);
        })
      );
    }

  /**
   * Subscribe to API Errors
   */
  subscribeToErrorsPC() {
      this.subscription.add(
        this.store.pipe(select(fromErrorHandling.selectors.selectErrorMessage)).subscribe(state => {
          if (!state || state === '') return;

          this.modal.next({
            show: true,
            template: ModalAlertComponent,
            content: Object({
              title: 'common.attention',
              message: this.errorService.handleError(state)
            }),
            closed: () => this.store.dispatch(new fromErrorHandling.actions.ClearError())
          });
        })
      );
    }



  subscribeToVisibleFieldsPC(): void {
      this.subscription.add(
        this.store.pipe(select(fromParameters.selectors.selectVisibleFields)).subscribe(state => {
          if (!state) return;
          this.visibleFields = state;
        })
      );
    }

  /**
   * Subscribe to Simulation Domains
   */
  subscribeToSimulationDomainsPC(): void {
      this.subscription.add(
        this.store.pipe(select(fromParameters.selectors.selectSimulationDomains)).subscribe(state => {
          if (Object.values(state).some(data => data === undefined)) return;

          const changes = {};
          this.simulationDomains = state;

          Object.keys(this.simulationDomains).forEach(res => {
            /** Doesn't update to default value if field already has a value filled by the user */
            // const field = this.formConditionResult.get(simulationDomainsConstant[res]);
            if (
              typeof this.simulationDomains[res] === 'boolean' ||
              this.simulationDomains[res] === null ||
              (!this.simulationDomains[res].options && !this.simulationDomains[res].deferredPaymentOptions)
              // (field && field.value !== null)
            ) {
              return false;
            }

            if (res === 'termInYearsList') {
              this.termInYearsList = this.simulationDomains[res];
            }

            changes[simulationDomainsConstant[res]] = this.getDomainDefaultOption(res);
          });
          this.formConditionResult.patchValue(changes);
        })
      );
    }

  /**
   * Get default option for a specific domain
   *
   * @private
   * @param {string} domain
   * @returns {string}
   * @memberof SimulationResultContainerComponent
   */
  private getDomainDefaultOption(domain: string): string {
      let defaultOption;
      if(domain === 'deferredPayment') {
      this.deferredPayment = this.simulationDomains[domain].deferredPaymentOptions;
      defaultOption = this.simulationDomains[domain].deferredPaymentOptions.find(data => data.defaultOption);
    } else {
      defaultOption = this.simulationDomains[domain].options.find(data => data.defaultOption);
    }

    return defaultOption ? defaultOption.key : '';
  }

  /**
   * Subscribe to Proposal Data
   */
  subscribeToProposalPC(): void {
    this.subscription.add(
      this.store.pipe(select(fromProposal.selectors.selectProposal)).subscribe(state => {
        if (Object.values(state).every(d => !d)) return;
        if (!this.isUseCasaMais || !this.simulationData) {
          this.simulationData = state;
        } else {
          this.simulationData = this.mergeObjects(this.simulationData, state);
        }

        this.proposalId = state?.idProposta;

        if (this.simulationData.calculatedProposalValuesRanges !== null) {
          this.minTermInMonths = this.simulationData.calculatedProposalValuesRanges.minTermInMonths;
          this.maxTermInMonths = this.simulationData.calculatedProposalValuesRanges.maxTermInMonths;
          this.minTermInYears = this.simulationData.calculatedProposalValuesRanges.minTermInYears;
          this.maxTermInYears = this.simulationData.calculatedProposalValuesRanges.maxTermInYears;
          this.minLoanAmountValue = this.simulationData.calculatedProposalValuesRanges.minLoanAmountValue;
          this.maxLoanAmountValue = this.simulationData.calculatedProposalValuesRanges.maxLoanAmountValue;

          this.onValuesReceived(this.minLoanAmountValue, this.maxLoanAmountValue, this.minTermInYears, this.maxTermInYears, this.minTermInMonths, this.maxTermInMonths);
        }

        this.formSimulationResult.patchValue({
          ...this.simulationData,
          downPayment: state.downPaymentAmount,
          termInYears: state.termInYears.toString()
        });

        this.formConditionResult.patchValue({
          isIOFIncludedInClosingCostCredit: state.isIOFIncludedInClosingCostCredit,
          isAppraisalfeesInClosingCostCredit: state.isAppraisalfeesInClosingCostCredit,
          expensesValue: state.expensesValue
        });

        this.formSimulationResult.patchValue({
          offerType: this.simulationData?.typeOfferWarrantyShared
        });


        this.isencaoTarifaUseCasa = state?.isAppraisalFeeFree;
        this.updateProposalValidation();
        this.getRelationshipValues();
        this.setOfferChosen();
        this.checkForDivergences();

      })
    );
  }

  mergeObjects(obj1: ProposalState, obj2: ProposalState): ProposalState {
    const result: ProposalState = { ...obj1 };

    for (const key in obj2) {
      if (obj2.hasOwnProperty(key) && obj2[key] !== undefined && obj2[key] !== null) {
        result[key] = obj2[key];
      }
    }

    return result;
  }

  private subscribeToSimulationInProgressPC(): void {
    this.subscription.add(
      this.store.select(fromParameters.selectors.selectIsSimulationInProgress).subscribe(state => {
        this.isSimulationInProgress = state;
      })
    );
  }

  /**
   * Subscribe to months list
   */
  subscribeToMonthsPC(): void {
    this.subscription.add(
      this.store.pipe(select(fromParameters.selectors.selectMonths)).subscribe(state => {
        this.months = state;
      })
    );
  }

  private subscribeToFormSimulationChanges(): void {
    this.subscription.add(
      this.formSimulationResult.valueChanges
        .pipe(
          debounceTime(0),
          distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
          pairwise()
        )
        .subscribe(([previousState, currentState]) => {
          const changes = this.getFormStateDiff(previousState, currentState);
          Object.keys(changes).forEach(c => this.fieldChanged({ field: c, value: changes[c] }));
        })
    );
  }


  /**
   * Subscribe to form changes to dispatch Field Change action and recalculate values
   */
  subscribeToFormChangesPC(): void {
    this.subscribeToFormConditionResultChanges();
    this.subscribeToInsurerKeyChanges();
  }


  /**
   * Subscribe to Insurer Key changes to update insurancePolicyKey to ''
   *
   * @private
   * @memberof SimulationResultContainerComponent
   */
  private subscribeToInsurerKeyChanges(): void {
    this.subscription.add(
      this.formConditionResult
        .get('insurerKey')
        .valueChanges.pipe(
          debounceTime(0),
          distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr))
        )
        .subscribe(value => {
          if (!value) return;
          this.fieldChanged({ field: 'insurancePolicyKey', value: '' });
        })
    );
  }

  private subscribeToFormConditionResultChanges(): void {
    this.subscription.add(
      this.formConditionResult.valueChanges
        .pipe(
          debounceTime(0),
          distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
          pairwise()
        )
        .subscribe(([previousState, currentState]) => {
          const changes = this.getFormStateDiff(previousState, currentState);
          Object.keys(changes).forEach(c => this.fieldChanged({ field: c, value: changes[c] }));
        })
    );
  }

  /**
   * Get initial parameters
   */
  private load(): void {
    this.store.dispatch(new fromParameters.actions.SimulationLoad());
  }


  subscribeToListOffers() {
    this.subscription.add(
      this.store.pipe(select(fromParameters.selectors.selectOfferList)).subscribe((state) => {
        if (!state) return;
        this.offerList = state;
      })
    );
  }

  /**
   * Check for divergences with min/max allowed values for Term and Loan Amount Value
   *
   * @private
   * @memberof SimulationResultContainerComponent
   */
  private checkForDivergences(): void {
    const formData = this.formSimulationResult.value;

    if (
      ((formData.termInYears !== null && formData.termInYears < this.minTermInYears) ||
        formData.termInYears > this.maxTermInYears) ||
      ((formData.termInMonths !== null && formData.termInMonths < this.minTermInMonths) ||
        formData.termInMonths > this.maxTermInMonths) ||
      ((formData.loanAmountValue !== null && formData.loanAmountValue < this.minLoanAmountValue) ||
        formData.loanAmountValue > this.maxLoanAmountValue)
    ) {
      this.modal.next({
        show: true,
        template: ModalAlertComponent,
        content: Object({
          title: 'common.attention',
          message: 'errorMessages.ALERT@SIMULACAO_NOVASCONDFINANC'
        }),
        closed: () => ''
      });
    }
  }

  /**
   * Creates Simulation Result form
   */
  private createSimulationResultFormPC(): void {
    this.formSimulationResult = this.formBuilder.group(
      {
        fgtsAmount: [null],
        loanAmountValue: [null, Validators.required],
        termInYears: [null, Validators.required],
        termInMonths: [null, Validators.required],
        downPayment: [{ value: null, disabled: true }],
        amountFinanced: [{ value: null, disabled: true }],
        expensesValue: [{ value: null, disabled: true }],
        offerType: null
      },
      { updateOn: 'blur' }
    );
  }


  /**
   * Creates Condition Result form
   */
  private createConditionResultFormPC(): void {
    this.formConditionResult = this.formBuilder.group({
      marketSegmentKey: [null],
      insurerKey: [null],
      campaignKey: [null],
      offerKey: [null],
      paymentTypeKey: [null],
      fgSimulatedTR: [null],
      insurancePolicyKey: [null],
      monthWithoutPaymentKey: [null],
      deferredPaymentKey: [null],
      monthsDeferredPaymentQt: [null, [Validators.required]],
      isIOFIncludedInClosingCostCredit: new FormControl(null, {
        updateOn: 'change',
        validators: Validators.required
      }),
      isAppraisalfeesInClosingCostCredit: new FormControl(null, {
        updateOn: 'change'
      }),
    });
  }

  private onValuesReceived(minLoanAmountValue: number, maxLoanAmountValue: number, minTermInYears: number, maxTermInYears: number, minTermInMonths: number, maxTermInMonths: number): void {
    this.minLoanAmountValue = minLoanAmountValue;
    this.maxLoanAmountValue = maxLoanAmountValue;
    this.minTermInYears = minTermInYears;
    this.maxTermInYears = maxTermInYears;
    this.minTermInMonths = minTermInMonths;
    this.maxTermInMonths = maxTermInMonths;

    this.updateProposalValidation();
  }


  /**
   * Update proposal validation based on flags
   */
  private updateProposalValidation(): void {
    const group = this.formSimulationResult;

    const minLoanAmountValue = this.minLoanAmountValue;
    const maxLoanAmountValue = this.maxLoanAmountValue;
    const minTermInYears = this.minTermInYears;
    const maxTermInYears = this.maxTermInYears;
    const minTermInMonths = this.minTermInMonths;
    const maxTermInMonths = this.maxTermInMonths;

    group.get('loanAmountValue').setValidators([
      Validators.required,
      this.formValidationService.isLowerThanMin(minLoanAmountValue),
      this.formValidationService.isGreaterThanMax(maxLoanAmountValue)
    ]);

    group.get('termInYears').setValidators([
      Validators.required,
      this.formValidationService.isLowerThanMin(minTermInYears),
      this.formValidationService.isGreaterThanMax(maxTermInYears)
    ]);

    group.get('termInMonths').setValidators([
      Validators.required,
      this.formValidationService.isLowerThanMin(minTermInMonths),
      this.formValidationService.isGreaterThanMax(maxTermInMonths)
    ]);

    Object.keys(group.controls).forEach(key => {
      group.get(key).updateValueAndValidity();
    });
  }

  onChangeDeferredPaymentPC(): void {
    this.subscription.add(
      this.formConditionResult
        .get('deferredPaymentKey')
        .valueChanges.pipe(distinctUntilChanged((prev, curr) => prev === curr))
        .subscribe(d => {
          this.hasCarencia = d !== null && d !== 'N';
          if (this.deferredPayment && this.deferredPayment.length > 0) {
            const auxDeferredPayment = this.deferredPayment.find(p => p.deferredPaymentType === d);
            this.arrCarencia = [];
            for (
              let i = auxDeferredPayment.minMonthsDeferredPayment;
              i <= auxDeferredPayment.maxMonthsDeferredPayment;
              i++
            ) {
              this.arrCarencia.push(i);
            }
          }
        })
    );
  }

  /**
   * Set offer chosen
   */
  setOfferChosen(): void {
    this.calculatedSimulationResult = this.valuesWithRelationship || this.valuesNoRelationship;
  }

  /**
   * Calculates simulation based on fields changes
   */
  calculateSimulation(): void {
    this.store.dispatch(new fromProposal.actions.Calculate());
  }

  /**
 * Calculates simulation based on fields changes
 */
  calculateSimulationOffers(state): void {
    this.store.dispatch(new fromProposal.actions.CalculateOffers({ state }));
  }


  /**
   * Detects changes on form fields and update store
   */
  private fieldChanged({ field, value }): void {
    // Prevent running when field is not specified
    if (!field) return;

    // When fgtsAmount + loanAmountValue is greater than propertyValue it should show a modal and return fgtsAmount to it's previous state
    if (field === 'fgtsAmount' && value + this.simulationData.loanAmountValue > this.simulationData.propertyValue) {
      this.modal.next({
        show: true,
        template: ModalAlertComponent,
        content: Object({
          title: 'common.attention',
          message: 'common.fgts value can not be more than the down payment amount'
        }),
        closed: () => ''
      });
      return this.formSimulationResult.get('fgtsAmount').patchValue(this.simulationData.fgtsAmount);
    }

    if (this.isUseCasaMais) {
      switch (field) {
        case 'offerType':
          this.simulationData = {
            ...this.simulationData,
            typeOfferWarrantyShared: value

          }
          break;
        case 'isAppraisalfeesInClosingCostCredit':
          this.simulationData = {
            ...this.simulationData,
            isAppraisalfeesInClosingCostCredit: value
          }
          break;
        case 'isIOFIncludedInClosingCostCredit':
          this.simulationData = {
            ...this.simulationData,
            isIOFIncludedInClosingCostCredit: value
          }
          break;
        case 'loanAmountValue':
          this.simulationData = {
            ...this.simulationData,
            loanAmountValue: value

          }
          break;
        case 'termInMonths':
          this.simulationData = {
            ...this.simulationData,
            termInMonths: value

          }
          break;
        case 'marketSegmentKey':
          this.simulationData = {
            ...this.simulationData,
            marketSegmentKey: value

          }
          break;
        case 'paymentTypeKey':
          this.simulationData = {
            ...this.simulationData,
            paymentTypeKey: value

          }
        case 'monthWithoutPaymentKey':
          this.simulationData = {
            ...this.simulationData,
            monthWithoutPaymentKey: value

          }
          break;
        default:
          break;
      }


      this.store.dispatch(new fromProposal.actions.FieldChanged({ field, value, isUsecasaMais: this.isUseCasaMais }));
      this.calculateSimulationOffers(this.simulationData);

      return;
    }
    
    this.store.dispatch(new fromProposal.actions.FieldChanged({ field, value }));
    this.calculateSimulation();
  }

  extractPdfEmailSimulation(type: string): void {
    if (type === 'print') {
      return this.store.dispatch(new fromSimulation.actions.GetPdfSimulation({ name: 'Simulação de Proposta.pdf' }));
    }

    if (type === 'email') {
      return this.store.dispatch(
        new fromSimulation.actions.SendPdfEmail({ modalAlert: this.openModalAlertEmail.bind(this) })
      );
    }
  }

  /**
   * Get relationship values depending on type
   */
  getRelationshipValues(): void {
    if (this.simulationData.calculatedSimulationResults) {
      this.valuesWithRelationship = this.simulationData.calculatedSimulationResults.find(
        c => c.calculatedSimulationType === 'prime'
      );

      this.valuesNoRelationship = this.simulationData.calculatedSimulationResults.find(
        c => c.calculatedSimulationType === 'average'
      );
    }
  }

  handleChangesEmitted(changesArray: { controlName: string, value: string }[]) {
    this.receivedChangesArray = changesArray;
  }

  triggerEvents() {
    let productName = this.storageEncryptService.getSessionStore('productName');

    this.receivedChangesArray.forEach(change => {
      const controlNameTranslate = this.translateSelect(change.controlName);
      this.sendToClickEvent(false, 'selecionou', 'dropdown:' + controlNameTranslate + ':' + change.value, productName);
    });

    // Limpar o array após disparar os eventos
    this.receivedChangesArray = [];
  }

  handleChangesEmittedSimulation(changesArray: { controlName: string, value: string, eventType: string }[]) {
    this.receivedChangesArraySimulation = changesArray;
  }

  triggerEventsSimulation() {
    let productName = this.storageEncryptService.getSessionStore('productName');

    this.receivedChangesArraySimulation.forEach(change => {
      const controlNameTranslate = this.translateField(change.controlName);
      this.sendToClickEvent(false, change.eventType, 'dropdown:' + controlNameTranslate + ':' + change.value, productName);
    });

    // Limpar o array após disparar os eventos
    this.receivedChangesArraySimulation = [];
  }

  translateField(fieldName: string) {
    let translatedFieldName: string;
    switch (fieldName) {
      case 'loanAmountValue':
        translatedFieldName = 'valor:emprestimo';
        break;
      case 'expensesValue':
        translatedFieldName = 'valor:despesas-financiadas';
        break;
      case 'amountFinanced':
        translatedFieldName = 'valor:financiamento';
        break;
      case 'termInYears':
        translatedFieldName = 'dropdown:prazo';
        break;
      }
      return translatedFieldName
  }



  sendToClickEvent(nonInterection, eventAction, eventLabel, productName) {
    this.dataLayerSantander.sendCustomToDataLayer(
      nonInterection,
      eventAction,
      eventLabel,
      productName
    );
  }

    translateSelect(fieldName) {
    let translatedFieldName;
    switch (fieldName) {
      case 'marketSegmentKey':
        translatedFieldName = 'segmento';
        break;
      case 'insurerKey':
        translatedFieldName = 'seguradora';
        break;
      case 'paymentTypeKey':
        translatedFieldName = 'tipo-parcelas';
        break;
      case 'campaignKey':
        translatedFieldName = 'campanha';
        break;
      case 'monthWithoutPaymentKey':
        translatedFieldName = 'mes-sem-pagar';
        break;
      }
      return translatedFieldName
  }

  /**
   * Dispatch Send Simulation Data action
   */
  save(): void {
    if (this.formConditionResult.invalid || this.formSimulationResult.invalid) return;
    if (this.isUseCasaMais) {
      this.storageEncryptService.setSessionStore('usecasa', 'true');
    }

    this.triggerEvents();
    this.triggerEventsSimulation();
    this.store.dispatch(new fromProposal.actions.SetSimulationID({ simulationId: this.simulationData.simulationId }));
    return this.store.dispatch(
      new fromSimulation.actions.SaveSimulation({ ...this.simulationData, ...this.formConditionResult.getRawValue(), offerType: this.formSimulationResult.get('offerType').value })
    );
  }

  openModalAlertEmail(): void {
    this.modal.next({
      show: true,
      template: ModalAlertComponent,
      content: Object({
        title: 'common.sent sucess',
        message: 'common.sent pdf email'
      }),
      closed: () => {
        return;
      }
    });
  }

  /**
   * Dispatch action to go to previous page
   */
  back() {
    // this.store.dispatch(new fromSimulation.actions.Back({ url: 'dados-proposta' }));
    this.router.navigate(['/dados-proposta']);
  }

  /**
   * Get form state difference between an old state object and a new state object
   */
  private getFormStateDiff(oldState, newState) {
    const changes = {};

    for (const key in newState) {
      if (oldState[key] !== newState[key]) {
        changes[key] = newState[key];
      }
    }

    return changes;
  }
}
