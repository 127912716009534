import { Component, Input, SimpleChanges, OnChanges, EventEmitter, Output, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DataLayerSantanderService } from '@projects/cliente/src/app/shared/service/dataLayer/novo-data-layer.service';
import { environment } from '@projects/cliente/src/environments/environment';
import { StorageEncryptService } from '@shared/services/storageEncrypt.service';

@Component({
  selector: 'app-form-condition-result',
  templateUrl: './form-condition-result.component.html',
  styleUrls: ['./form-condition-result.component.scss']
})
export class FormConditionResultComponent implements OnChanges, OnInit {
  @Input() formConditionResult: FormGroup;
  @Input() simulationDomains: any;
  @Input() loanTypeName: string;
  @Input() customPortfolioName: string;
  @Input() arrCarencia: Array<any>;
  @Input() hasCarencia: boolean;
  @Input() isSimulationInProgress: boolean;
  @Input() isUseCasaMais: boolean;
  @Input() isencaoTarifaUseCasa: boolean;
  @Output() fieldChanged = new EventEmitter();

  changesArray: { controlName: string, value: string }[] = [];
  @Output() changesEmitted = new EventEmitter<{ controlName: string, value: string }[]>();
  featureOferta = environment.featureToggle.fgOferta === 'true' ? true : false;
  listaOfertas;

  constructor() {}

  ngOnInit(): void {
    this.setListaOfertas();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isSimulationInProgress && changes.isSimulationInProgress.currentValue === false) {
      this.formConditionResult.disable();
    }
  }

  setListaOfertas(){
    this.listaOfertas = this.simulationDomains?.offers?.options;

    if(this.featureOferta) {
      return this.listaOfertas = this.listaOfertas.filter(oferta => oferta.key === "0");
    }
    return this.listaOfertas;
  }

  isDeferredPaymentValue() {
    if (this.formConditionResult && this.hasCarencia) {
      this.formConditionResult.get('monthsDeferredPaymentQt').enable();
      return true;
    } else {
      this.formConditionResult.get('monthsDeferredPaymentQt').disable();
      return false;
    }
  }

  setParcCarenciaNull(event) {
    this.formConditionResult.get('monthsDeferredPaymentQt').patchValue(null);
    this.onSelectBlur('deferredPaymentKey', event);
  }

  onFieldChanged(controlName: string): void {
    this.fieldChanged.emit({ value: this.formConditionResult.controls[controlName].value, controlName });
    if (controlName === 'loanTypeKey') {
      this.formConditionResult.get('isAppraisalfeesInClosingCostCredit').setValue(null);
      this.fieldChanged.emit({
        value: this.formConditionResult.controls[`isAppraisalfeesInClosingCostCredit`].value,
        controlName: 'isAppraisalfeesInClosingCostCredit'
      });
      this.formConditionResult.get('isIOFIncludedInClosingCostCredit').setValue(null);
      this.fieldChanged.emit({
        value: this.formConditionResult.controls[`isIOFIncludedInClosingCostCredit`].value,
        controlName: 'isIOFIncludedInClosingCostCredit'
      });
    }
  }

  onSelectBlur(controlName, event){
    const controlNameValue = event.description.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s+/g, '-').replace(/\//g, '-');

    const index = this.changesArray.findIndex(item => item.controlName === controlName);

    if (index !== -1) {
      // Substituir pelo novo valor se já existe
      this.changesArray[index].value = controlNameValue;
    } else {
      // Adicionar novo item ao array
      this.changesArray.push({ controlName, value: controlNameValue });
    }

     // Emitir o array de mudanças para o componente pai
     this.changesEmitted.emit(this.changesArray);
  }
}
