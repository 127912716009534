<div class="btnExtractSimulation">
  <div [ngClass]="{ marginDefault: allowPrint && allowEmail }" *ngIf="allowEmail">
    <button class="btnEmail" (click)="onExtractSimulation('email')">
      <span>Enviar simulação <br />por e-mail</span>
    </button>
  </div>
  <div *ngIf="allowPrint">
    <button class="btnPrint" (click)="onExtractSimulation('print')">
      <span>Baixar Simulação Completa</span>
    </button>
  </div>
</div>
