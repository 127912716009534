import { Action } from '@ngrx/store';
import { InputSaveProposalData } from '@shared/graphql/types/query-types';

export enum ParametersActionTypes {
  PARTICIPANTS_LOAD = '[Parameters] Participants Load',
  PARTICIPANTS_LOAD_SUCCESS = '[Parameters] Participants Load Success',
  PARTICIPANTS_LOAD_ERROR = '[Parameters] Participants Load Error',

  PROPOSAL_LOAD = '[Parameters] Proposal Load',
  PROPOSAL_LOAD_SUCCESS = '[Parameters] Proposal Load Success',
  PROPOSAL_LOAD_ERROR = '[Parameters] Proposal Load Error',

  SIMULATION_LOAD = '[Simulation] Simulation Load',
  SIMULATION_LOAD_SUCCESS = '[Simulation] Simulation Load Success',
  SIMULATION_LOAD_ERROR = '[Simulation] Simulation Load Error',

  GET_PROPOSAL_RANGE = '[Parameters] Get Proposal Range',
  GET_PROPOSAL_RANGE_SUCCESS = '[Parameters] Get Proposal Range Success',
  GET_PROPOSAL_RANGE_ERROR = '[Parameters] Get Proposal Range Error',

  GET_DEVELOPMENTS = '[Parameters] Get Developments',
  GET_DEVELOPMENTS_SUCESS = '[Parameters] Get Developments Sucess',

  GET_PROPERTY_RANGE = '[Parameters] Get Property Range',
  GET_PROPERTY_RANGE_SUCCESS = '[Parameters] Get Property Range Success',
  GET_PROPERTY_RANGE_ERROR = '[Parameters] Get Property Range Error',

  GET_PROPERTY_RANGE_PROPOSAL = '[Parameters] Get Property Range Proposal',
  GET_PROPERTY_RANGE_PROPOSAL_SUCCESS = '[Parameters] Get Property Range Success Proposal',
  GET_PROPERTY_RANGE_PROPOSAL_ERROR = '[Parameters] Get Property Range Error Proposal',

  GET_PROPERTY_DOMAINS = '[Parameters] Get Property Domain',
  GET_PROPERTY_DOMAINS_SUCCESS = '[Parameters] Get Property Domain Success',
  GET_PROPERTY_DOMAINS_ERROR = '[Parameters] Get Property Domain Error',

  SET_MIN_PROPERTY_VALUE = '[Parameters] Set Minimum Property Value',
  SET_FINANCE_LOAN_VALUE = '[Parameters] Set Finance Value',
  SET_PROPOSAL_DOMAINS = '[Parameters] Set Proposal Domains',

  RECOVER_MENU_PROFILE = '[Parameters] Recover Menu Profile',
  RECOVER_MENU_PROFILE_SUCESS = '[Parameters] Recover Menu Profile Sucess',

  REDIRECT = '[Parameters] Redirect',

  OFFERS_LIST = '[General Table] List Available Offers',
  OFFERS_LIST_SUCESS = '[General Table] List Available Offers Sucess',
}

export class OffersList implements Action {
  readonly type = ParametersActionTypes.OFFERS_LIST;
  constructor(public payload: { id: string; tipoOrdenacao?: string }) { }
}

export class OffersListSucess implements Action {
  readonly type = ParametersActionTypes.OFFERS_LIST_SUCESS;
  constructor(public payload: any) { }
}


export class ParticipantsLoad implements Action {
  readonly type = ParametersActionTypes.PARTICIPANTS_LOAD;
  constructor() { }
}

export class ParticipantsLoadSuccess implements Action {
  readonly type = ParametersActionTypes.PARTICIPANTS_LOAD_SUCCESS;
  constructor(public payload: any) { }
}

export class ParticipantsLoadError implements Action {
  readonly type = ParametersActionTypes.PARTICIPANTS_LOAD_ERROR;
  constructor(public payload: any) { }
}

export class ProposalLoad implements Action {
  readonly type = ParametersActionTypes.PROPOSAL_LOAD;
  constructor(public payload: { channel: string, tpPessoa: string, loanTypeKey?, showModalcontractList?}) { }
}
export class ProposalLoadSuccess implements Action {
  readonly type = ParametersActionTypes.PROPOSAL_LOAD_SUCCESS;
  constructor(public payload: any) { }
}

export class ProposalLoadError implements Action {
  readonly type = ParametersActionTypes.PROPOSAL_LOAD_ERROR;
  constructor(public payload: any) { }
}

export class SimulationLoad implements Action {
  readonly type = ParametersActionTypes.SIMULATION_LOAD;
  constructor() { }
}

export class SimulationLoadSuccess implements Action {
  readonly type = ParametersActionTypes.SIMULATION_LOAD_SUCCESS;
  constructor(public payload: any) { }
}

export class SimulationLoadError implements Action {
  readonly type = ParametersActionTypes.SIMULATION_LOAD_ERROR;
  constructor(public payload: any) { }
}

export class GetProposalRange implements Action {
  readonly type = ParametersActionTypes.GET_PROPOSAL_RANGE;
  constructor(public payload: { input: InputSaveProposalData }) { }
}

export class GetProposalRangeSuccess implements Action {
  readonly type = ParametersActionTypes.GET_PROPOSAL_RANGE_SUCCESS;
  constructor(public payload: any) { }
}

export class GetProposalRangeError implements Action {
  readonly type = ParametersActionTypes.GET_PROPOSAL_RANGE_ERROR;
  constructor(public payload: any) { }
}

export class GetPropertyRange implements Action {
  readonly type = ParametersActionTypes.GET_PROPERTY_RANGE;
  constructor(
    public payload: {
      input: {
        loanTypeKey: string;
        propertyLocationKey: string;
        buildingTypeKey: string;
      };
    }
  ) { }
}

export class GetPropertyRangeSuccess implements Action {
  readonly type = ParametersActionTypes.GET_PROPERTY_RANGE_SUCCESS;
  constructor(public payload: any) { }
}

export class GetPropertyRangeError implements Action {
  readonly type = ParametersActionTypes.GET_PROPERTY_RANGE_ERROR;
  constructor(public payload: any) { }
}

export class GetPropertyRangeProposal implements Action {
  readonly type = ParametersActionTypes.GET_PROPERTY_RANGE_PROPOSAL;
  constructor(
    public payload: {
      objetivoFinanciamento: string;
      tipoUtilizacaoImovel: string;
      codigoDistrito: string;
      portabilityTypeKey: string;
    }
  ) { }
}

export class GetPropertyRangeProposalSuccess implements Action {
  readonly type = ParametersActionTypes.GET_PROPERTY_RANGE_PROPOSAL_SUCCESS;
  constructor(public payload: any) { }
}

export class GetPropertyRangeProposalError implements Action {
  readonly type = ParametersActionTypes.GET_PROPERTY_RANGE_PROPOSAL_ERROR;
  constructor(public payload: any) { }
}

export class GetDevelopments implements Action {
  readonly type = ParametersActionTypes.GET_DEVELOPMENTS;
  constructor(public payload: { documentNumber: string }) { }
}

export class GetDevelopmentsSucess implements Action {
  readonly type = ParametersActionTypes.GET_DEVELOPMENTS_SUCESS;
  constructor(public payload: any) { }
}

export class SetMinPropertyValue implements Action {
  readonly type = ParametersActionTypes.SET_MIN_PROPERTY_VALUE;
  constructor(public payload: number) { }
}

export class SetFinanceLoanValue implements Action {
  readonly type = ParametersActionTypes.SET_FINANCE_LOAN_VALUE;
  constructor(
    public payload: {
      minLoanAmountValue: number;
      maxLoanAmountValue: number;
    }
  ) { }
}

export class SetProposalDomains implements Action {
  readonly type = ParametersActionTypes.SET_PROPOSAL_DOMAINS;
  constructor(public payload: { simulationDomains: any }) { }
}

export class RecoverMenuProfile implements Action {
  readonly type = ParametersActionTypes.RECOVER_MENU_PROFILE;
  constructor(public payload: { username: any }) { }
}

export class RecoverMenuProfileSucess implements Action {
  readonly type = ParametersActionTypes.RECOVER_MENU_PROFILE_SUCESS;
  constructor(public payload: any) { }
}
export class Redirect implements Action {
  readonly type = ParametersActionTypes.REDIRECT;
  constructor(public payload: { url: string }) { }
}

export type ParametersActions =
  | Redirect
  | ParticipantsLoad
  | ParticipantsLoadSuccess
  | ParticipantsLoadError
  | ProposalLoad
  | ProposalLoadSuccess
  | ProposalLoadError
  | SimulationLoad
  | SimulationLoadSuccess
  | SimulationLoadError
  | GetProposalRange
  | GetProposalRangeSuccess
  | GetProposalRangeError
  | GetPropertyRange
  | GetPropertyRangeSuccess
  | GetPropertyRangeError
  | GetPropertyRangeProposal
  | GetPropertyRangeProposalSuccess
  | GetPropertyRangeProposalError
  | GetDevelopments
  | GetDevelopmentsSucess
  | SetMinPropertyValue
  | SetFinanceLoanValue
  | SetProposalDomains
  | RecoverMenuProfile
  | RecoverMenuProfileSucess
  | OffersList
  | OffersListSucess
  ;
