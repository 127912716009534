import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
export class CustomTaggingEvent {
    event: string;
    nonInteraction: boolean;
    category: string;
    action: string;
    label: string;
    productName?: any;
    proposalID?: any;
}

declare global {
    interface Window {
      dataLayer: any;
    }
  }
  
  @Injectable({
    providedIn: 'root'
  })

export class DataLayerSantanderService {
    constructor() { }

    /**
     * Mapa de "customUrl" para enviar ao Google Analytics
     */
    pageViewNames = {
        dadosPessoais: '/simuladorcliente/dados-pessoais',
        confirmacaoSms: '/simuladorcliente/confirmacao-sms',
        dadosProposta: '/simuladorcliente/dados-proposta',
        resultadoSimulacao: '/simuladorcliente/resultado-simulacao',
        resumoProposta: '/simuladorcliente/resumo-da-proposta',
    };

    /**
     * Mapa de acentos
     */
    map = {
        â: 'a',
        Â: 'A',
        à: 'a',
        À: 'A',
        á: 'a',
        Á: 'A',
        ã: 'a',
        Ã: 'A',
        ê: 'e',
        Ê: 'E',
        è: 'e',
        È: 'E',
        é: 'e',
        É: 'E',
        î: 'i',
        Î: 'I',
        ì: 'i',
        Ì: 'I',
        í: 'i',
        Í: 'I',
        õ: 'o',
        Õ: 'O',
        ô: 'o',
        Ô: 'O',
        ò: 'o',
        Ò: 'O',
        ó: 'o',
        Ó: 'O',
        ü: 'u',
        Ü: 'U',
        û: 'u',
        Û: 'U',
        ú: 'u',
        Ú: 'U',
        ù: 'u',
        Ù: 'U',
        ç: 'c',
        Ç: 'C'
    };

    /**
     * Inclui dados que serão enviados como pageView o dataLayer
     * @param {String} url
     * @param {String} customUrl

     * @returns {Void}
     */
    sendPageViewToDataLayer(url, customUrl?, productName?, proposalID?) {
        let payload: any = {
            'event': 'pageviewGA',
            'page': customUrl,
            'title': 'Jornada Única - Crédito Imobiliário',
        };

        if (productName) {
            payload = {
                ...payload,
                productName
            };
        }
        if (proposalID) {
            payload = {
                ...payload,
                proposalID
            };
        }

        window.dataLayer.push(payload);
    }


    /**
     * Inclui dados que serão enviados como Erro
     * @param {String} url
     * @param {String} customUrl
     * @returns {Void}
     */
    sendErrorToDataLayer = function (nonInteraction, action, label) {
        const payload = {
            event: 'errorGA',
            category: 'jornadaunica:credito:imobiliario',
            action,
            label,
            nonInteraction
        };

        window.dataLayer.push(payload);
    };

    /**
     * Envia um objeto customizado para o DataLayer santander
     * @param {Object} payload
     */
    sendCustomToDataLayer(nonInteraction: boolean, action: string, label: string, productName?, proposalID?) {

        let payload: CustomTaggingEvent = {
            event: 'eventGA',
            nonInteraction,
            category: 'jornadaunica:credito:imobiliario',
            action,
            label
        };

        if (productName) {
            payload = {
                ...payload,
                productName
                
            };
        }

        if (proposalID) {
            payload = {
                ...payload,
                proposalID
            };
        }

        if (action && label) {
            window.dataLayer.push(payload);
        }
    }


    /**
     * Método responsável por gerar o payload de page-view
     * @param {string} url
     * @param {string} customUrl
     * @param {string} title
     * @param {string} error
     * @param {boolean} forceCustomUrl
     * @returns {Object}
     */

    /**
     * Método responsável por obter o endPoint do serviço
     * @param {string} url
     */
    getEndpoint(url) {
        if (url.indexOf(environment.urlNovaSimulacaoCliente) !== -1) {
            return url.replace(environment.urlNovaSimulacaoCliente, '');
        }

        return url;
    }

    /**
     * Método responsável por obter o page-view name de acordo com o mapa declarado no início deste arquivo
     * e a rota onde a navegação ocorre.
     * @param {string} route
     * @returns {string}
     */
    getPageViewName(route) {
        const routeName = route.split('/')[1];

        if (this.pageViewNames.hasOwnProperty(routeName)) {
            return this.pageViewNames[routeName];
        }

        return '';
    }

    /**
     * Método responsável por gerar uma mensagem "slugfied"
     * @param {string} string
     * @returns {string}
     */
    generateInteractionPayload(value) {
        return value.toLowerCase().replace(/[\W\[\] ]/g, function (a) {
            return this.map[a] || a;
        });
    }

    /**
     * Método responsável por checar se o endpoint refere-se ao ambiente de homologação
     * @param {string} url
     * @returns {boolean}
     */
    isEndpointHomolog(url) {
        return url.indexOf('hml') !== -1;
    }
}
