import gql from 'graphql-tag';

export const GET_BORROWER_VERIFICATION = gql`
  query borrowerVerification($documentNumber: String!) {
    borrowerVerification(documentNumber: $documentNumber) {
        name
        telephoneNumber
        documentNumber
    }
  }
`;