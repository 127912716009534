export const environment = {
  production: false,
  pathClientAssets: 'santander',
  cont: "simuladorcliente",
  version: '2.25.0-SNAPSHOT (16/07/2024 10:04:03)',
  recaptcha: '6Leh-hMUAAAAAKWHAG10U8wl4kFmuH2twTbmDxQM',

  /* PRO apigw */
  apiRootUrl: 'https://imobpfpro.onefinancial.com.br/apigw',
  tokenApiRootUrl: 'https://imobpfpro.onefinancial.com.br/apigw/token',
  tokenRevoke: 'https://imobpfpro.onefinancial.com.br/apigw/revoke',
  apiKeyApiSecret: 'TDF2SVkwZUoxX1lSOGRFQ0dTUk9DdnNuX3FzYTpFcllLMWtObENYZkU2aGs0a1ZtUGNHcGU3RHdh',
  pathApi: 'apigw',
  /* HML*/
  // apiRootUrl: 'https://imobpfhml.onefinancial.com.br/cipherv2',
  // tokenApiRootUrl: 'https://imobpfhml.onefinancial.com.br/cipherv2/token',
  // tokenRevoke: 'https://imobpfhml.onefinancial.com.br/cipherv2/revoke',
  // apiKeyApiSecret: 'alA1MGNTc2RZNDVFOHRJOFUzNVJMRnZvN3FBYTo2TEFrbzdnWFd1M3RtQVVtdjlnZjVUd2hrMndh',

  /* PRO */
  // apiRootUrl: 'https://imobpfpro.onefinancial.com.br/cipherv2',
  // tokenApiRootUrl: 'https://imobpfpro.onefinancial.com.br/cipherv2/token',
  // tokenRevoke: 'https://imobpfpro.onefinancial.com.br/cipherv2/revoke',
  // apiKeyApiSecret: 'TDF2SVkwZUoxX1lSOGRFQ0dTUk9DdnNuX3FzYTpFcllLMWtObENYZkU2aGs0a1ZtUGNHcGU3RHdh',
  urlCoreAntigo: 'https://dev.onefinancial.com.br/santanderpfpro',
  urlTermCondicoes:
    'https://www.santander.com.br/institucional-santander/seguranca/politica-de-privacidade?ic=homepf-footer-politica',
  baseUrl: 'https://imobpfhml.onefinancial.com.br/',

  /* ESP */
  // apiRootUrl: 'https://imobpfesp.onefinancial.com.br/apigw',
  // tokenApiRootUrl: 'https://imobpfesp.onefinancial.com.br/apigw/token',
  // tokenRevoke: 'https://imobpfesp.onefinancial.com.br/apigw/revoke',
  // apiKeyApiSecret: 'Q2tuS2VuSFZ3WkljTTJydXJpT1ZqMHNhZW9jYTpaOVJJRmtyRTBRMTZmckRMSnlPWnRVTmFqdmth',
  // urlCoreAntigo: 'https://dev.onefinancial.com.br/santanderpfesp',

  /** IFRAME SANTANDER */
  gwAppKey: 'c1238870f89101341bb6005056906329',
  loginSantanderPartner: 'imobiliario',
  authUrl: 'https://hom.auth.santander.com.br',
  apiSantanderEndpoint: 'https://esbapihi.santander.com.br/',

  urlCoreAntigoMenu: '/login/iframePrincipal.html?funcao=index',
  urlPortalParceiro: 'https://dev.onefinancial.com.br/santanderportalparceiropro',
  urlPortalCliente: 'https://imobpfpro.onefinancial.com.br/santanderpfpc',
  urlPortalGerente: 'https://dev.onefinancial.com.br/santanderportalimobiliariopro',
  urlMortgage: 'https://dev.onefinancial.com.br/mortgage-platformpfpro',
  urlOrigClienteDef: 'https://dev.onefinancial.com.br/simuladororiginacaopro',
  urlNovaSimulacaoCliente: 'https://imobpfpro.onefinancial.com.br/simuladorcliente/',
  apiKeyExternal: '1111',

  skin: 'cliente',
  validaCarimbo: 'false',
  clientIDchatBot:'chatBot',
  clientSecretChatbot: 'Bf1Bu5GwgDLFGZLfHXrsBcT6Yeid2cC4',
  urlChatBot: 'https://avi.santander.com.br/?botid=92d290b0-3bb3-11eb-b262-a76c17cee208&header=true',
  apiRootBase: 'https://imobpfpro.onefinancial.com.br',
  namespace: 'imobpfpro',
  clientID: 'portalCliente',
  client_id_simulacao: 'simulacao',
  clientSecretSimulacao: 'pwJCQ80e0jGD09uHXW1NDahOrOLfSecy',
  /** FEATURE TOGGLE */
  featureToggle: {
    newRedirect: 'true',
    newConsultProposal: 'true',
    ENABLE_USECASAMAIS_PG: 'false',
    ENABLE_USECASAMAIS_PC: 'true',
    cryptoStorageActive: 'false',
    recaptchaOn: 'true',
    objetivoDefault: false,
    fgUtmSource: false,
    fgOferta: 'true',
    fgSdk: true,
    useMockLogin: 'true',
    disablePortal: 'false',
    fgRepasse: { active: false },
    isencaoTarifaAvaliacao: true,
    asymmetricKey:
      'LS0tLS1CRUdJTiBQVUJMSUMgS0VZLS0tLS0NCk1JR2ZNQTBHQ1NxR1NJYjNEUUVCQVFVQUE0R05BRENCaVFLQmdRQ2oyclBTNFZFZWMzUks0a2dwS2t0bVdidGcNCjEvQTdnS2dnZDdpT1VPYUhpamk2Q2xVV0lVenpObzB6MWplM2ErcHYxanB3RThtejFlbVlnL1dYZUZXN2phaVcNCnhCU1VPUE01TEtPa0hzYWViSXN5OTZ1TEw4VHpjR1RPaTFMS3JOQ0NyOFZKbmhRZUZ4SitGb1VVNXJiL1pMT1QNCnEwMGIzSTZnSEpZd3dBVTJ5d0lEQVFBQg0KLS0tLS1FTkQgUFVCTElDIEtFWS0tLS0tDQo=',
    crypto: {
      active: 'false',
      key: '3, 1, 4, 1, 5, 9, 2, 6, 5, 3, 5, 8, 9, 7, 9, 3',
      iv: '21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36'
    }
  }
};

if (environment.featureToggle.useMockLogin === 'true') {
  environment.apiSantanderEndpoint = 'http://localhost:8090/';
  environment.authUrl = 'http://localhost:8090';
}
