import { Action } from '@ngrx/store';
import { ProposalState } from '../proposal/proposal.reducer';

export enum HelpersActionTypes {
  GET_HELPERS = '[Helpers] Get Helpers',
  GET_HELPERS_SUCCESS = '[Helpers] Get Helpers Success',

  CLOSE_HELPER = '[Helpers] Close Helper',
  SET_HELPER_QUESTIONS = '[Helpers] Set Helper Questions',
  CLEAR_HELPER_QUESTIONS = '[Helpers] Clear Helper Questions',
  TOGGLE_HELPER_QUESTIONS = '[Helpers] Toggle Helper Questions',
  UPDATE_HELPER_QUESTIONS = '[Helpers] Update Helper Questions'
}

export class GetHelpers implements Action {
  readonly type = HelpersActionTypes.GET_HELPERS;
  constructor(
    public payload?: {
      loanTypeKey?: number;
      portabilityTypeKey?: string;
      offerKey?: string;
      paymentTypeKey?: string;
      customerPortfolioName?: string;
    }
  ) {}
}
export class GetHelpersSuccess implements Action {
  readonly type = HelpersActionTypes.GET_HELPERS_SUCCESS;
  constructor(public payload: { fields: any }) {}
}

export class CloseHelper implements Action {
  readonly type = HelpersActionTypes.CLOSE_HELPER;
  constructor() {}
}

export class ToggleHelperQuestions implements Action {
  readonly type = HelpersActionTypes.TOGGLE_HELPER_QUESTIONS;
  constructor(public payload: any) {}
}

export class ClearHelperQuestions implements Action {
  readonly type = HelpersActionTypes.CLEAR_HELPER_QUESTIONS;
  constructor() {}
}

export class SetHelperQuestions implements Action {
  readonly type = HelpersActionTypes.SET_HELPER_QUESTIONS;
  constructor(public payload: any) {}
}

export class UpdateHelperQuestions implements Action {
  readonly type = HelpersActionTypes.UPDATE_HELPER_QUESTIONS;
  constructor(public payload: any) {}
}

export type HelpersActions =
  | GetHelpers
  | GetHelpersSuccess
  | CloseHelper
  | ToggleHelperQuestions
  | ClearHelperQuestions
  | SetHelperQuestions
  | UpdateHelperQuestions;
