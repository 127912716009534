import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PersonalDataContainerComponent } from './personal-data/personal-data.container';
import { SimulationResultContainerComponent } from './simulation-result/simulation-result.container';
import { ProposalDataContainerComponent } from './proposal-data/proposal-data.container';
import { ProposalSummaryContainerComponent } from './proposal-summary/proposal-summary.container';
import { AuthGuard } from '@app/core/auth/auth.guard';
import { SimulationComponent } from './simulation.component';
import { SimulationGuard } from '@app/core/simulation/simulation.guard';
import { HomeContainerComponent } from './home/home.container';
import { MiddlewareTokenOriginationComponent } from './middleware-token-origination/middleware-token-origination.component';
import { MiddlewareHomeComponent } from './middleware-home/middleware-home.component';
import { ConfirmacaoSmsContainerComponent } from './confirmacao-sms/confirmacao-sms.container';
import { ValidatedTokenSmsGuard } from '@app/core/simulation/validatedTokenSms.guard';
import { MiddlewareProposalSumaryComponent } from './middleware-proposal-sumary/middleware-proposal-sumary.component';

const routes: Routes = [
  {
    path: '',
    component: SimulationComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        component: HomeContainerComponent
      },
      {
        path: 'dados-pessoais',
        component: PersonalDataContainerComponent
      },
      {
        path: 'dados-proposta',
        component: ProposalDataContainerComponent,
        canActivate: [AuthGuard, SimulationGuard, ValidatedTokenSmsGuard]
      },
      {
        path: 'resultado-simulacao',
        component: SimulationResultContainerComponent,
        canActivate: [AuthGuard, SimulationGuard, ValidatedTokenSmsGuard]
      },
      {
        path: 'resultado-simulacao-nova-oferta',
        component: SimulationResultContainerComponent
      },
      {
        path: 'resumo-da-proposta',
        component: ProposalSummaryContainerComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'middleware-token-origination',
        component: MiddlewareTokenOriginationComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'acompanhesuaproposta',
        component: MiddlewareHomeComponent
      },
      {
        path: 'confirmacao-sms',
        component: ConfirmacaoSmsContainerComponent,
        canActivate: [AuthGuard, SimulationGuard]
      },
      {
        path: 'middleware-proposal-sumary',
        component: MiddlewareProposalSumaryComponent,
        canActivate: [AuthGuard]
      }

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SimulationRoutingModule { }
