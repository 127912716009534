import { Action } from '@ngrx/store';

export enum ConsultActionTypes {
  BORROWER_VERIFICATION = '[Consult] Consult Borrower Proposal',
  BORROWER_VERIFICATION_SUCESS = '[Consult] Consult Borrower Proposal'
}

export class BorrowerVerification implements Action {
  readonly type = ConsultActionTypes.BORROWER_VERIFICATION;
  constructor(public payload: { documentnumber: string; }) { }
}


export class BorrowerVerificationSucess implements Action {
  readonly type = ConsultActionTypes.BORROWER_VERIFICATION_SUCESS;
  constructor(public payload: any) { }
}


export type ConsultActions =
  | BorrowerVerification
  | BorrowerVerificationSucess