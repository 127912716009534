export const simulationDomainsConstant = {
  campaigns: 'campaignKey',
  insurers: 'insurerKey',
  marketSegments: 'marketSegmentKey',
  offers: 'offerKey',
  paymentType: 'paymentTypeKey',
  amortizationTypes: 'amortizationTypeKey',
  insurancePolicies: 'insurancePolicyKey',
  monthWithoutPayment: 'monthWithoutPaymentKey',
  deferredPayment: 'deferredPaymentKey'
};
