import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AppState } from '@app/state';
import { Store } from '@ngrx/store';
import { ModalService } from '../modal/services/modal.service';
import * as fromProposal from '@app/state/proposal';


@Component({
  selector: 'app-modal-contract-list',
  templateUrl: './modal-contract-list.component.html',
  styleUrls: ['./modal-contract-list.component.scss']
})
export class ModalContractListComponent {

  @Input() list: any[];
  @Input() title: string;
  @Input() subtitle: string;
  @Input() channel: string;
  @Input() form: FormGroup;
  @Input() formProposalData: FormGroup;

  @Output() advance = new EventEmitter();
  @Output() back = new EventEmitter();

  constructor(
    public modalService: ModalService,     
    private store$: Store<AppState>,
  ) { }

  

  onAdvance() {
    this.advance.emit();
  }

  onBack(isClose?) {
    if (!isClose) {
      this.modalService.closeModal('modalContractList');
    }
    this.formProposalData.get('loanTypeKey').setValue(null);
    this.store$.dispatch(new fromProposal.actions.ResetContractValue());
  }

}
