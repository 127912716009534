import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { simulationDomainsConstant } from '@app/shared/constants/simulation-domains.constant';
import { ErrorService } from '@app/shared/services/error.service';
import { ProposalDataService } from '@app/shared/services/proposal-data.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import { AppState } from '../';
import * as fromErrorHandling from '../error-handling';
import * as fromHelpers from '../helpers';
import * as fromParameters from '../parameters';
import * as fromSimulation from '../simulation';
import * as actions from './proposal.actions';
import * as selectors from './proposal.selectors';

@Injectable()
export class ProposalEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private proposalDataService: ProposalDataService,
    private errorService: ErrorService,
    private router: Router
  ) { }

  /**
   * Save Proposal Data
   */
  @Effect()
  sendProposal = this.actions$.pipe(
    ofType<actions.Send>(actions.ProposalActionTypes.SEND),
    withLatestFrom(this.store$.select(selectors.selectSimulationID)),
    switchMap(([action, simulationId]) =>
      this.proposalDataService.save({ ...action.payload.input, simulationId }).pipe(
        switchMap((response) => {
          const { selectedProposalOptions, simulationEntryValues } = action.payload.input;

          return [
            new actions.SendSuccess({
              input: {
                ...selectedProposalOptions,
                ...simulationEntryValues,
                isAppraisalfeesInClosingCostCredit: action.payload.input.isAppraisalfeesInClosingCostCredit,
                isIOFIncludedInClosingCostCredit: action.payload.input.isIOFIncludedInClosingCostCredit,
                isTransferTaxInClosingCostCredit: action.payload.input.isTransferTaxInClosingCostCredit,
                deferredPaymentKey: action.payload.input.deferredPaymentKey,
                quizResponseCode: action.payload.input.quizResponseCode,
                expensesValue: action.payload.input.expensesValue,
              },
            }),
            new fromSimulation.actions.SetSimulationSuccessful({
              isSuccessful: response.saveProposalDataSimulation.isSuccessful,
            }),
            new actions.Redirect({ url: '/resultado-simulacao' }),
          ];
        }),
        catchError((err) => {
          if (err.extensions) {
            return of(new fromErrorHandling.actions.AddError(this.errorService.addGraphQLAlert(err)));
          }

          return of(new fromErrorHandling.actions.AddError(this.errorService.addAlertError(err)));
        })
      )
    )
  );

  /**
   * Generate PDF Hash por proposal
   */
  @Effect()
  generatePdf = this.actions$.pipe(
    ofType<actions.GeneratePDF>(actions.ProposalActionTypes.GENERATE_PDF),
    withLatestFrom(this.store$.pipe(select(selectors.selectBusinessKey))),
    switchMap(([action, businessKey]) => {
      return this.proposalDataService.generatePdf(businessKey).pipe(
        map((response) => new actions.GeneratePDFSuccess({ pdfHash: response.getPdfSimulation.pdfHash })),
        catchError((err) => {
          if (err.extensions) {
            return of(new fromErrorHandling.actions.AddError(this.errorService.addGraphQLAlert(err)));
          }

          return of(new fromErrorHandling.actions.AddError(this.errorService.addAlertError(err)));
        })
      );
    })
  );

  /**
   * Save Renewed Contract
   */
  @Effect()
  saveRenewedContract = this.actions$.pipe(
    ofType<actions.SaveRenewedContract>(actions.ProposalActionTypes.SAVE_RENEWED_CONTRACT),
    switchMap((action) => {
      return this.proposalDataService.saveRenewedContract(action.payload.cdContrato, action.payload.simulationId).pipe(
        map(response => new actions.SaveRenewedContractSucess({
          isSuccessful: response.saveRenewedContract.isSuccessful
        }),
        ),
        catchError((err) => {
          if (err.extensions) {
            return of(new fromErrorHandling.actions.AddError(this.errorService.addGraphQLAlert(err)));
          }

          return of(new fromErrorHandling.actions.AddError(this.errorService.addAlertError(err)));
        })
      );
    })
  );

  /**
   * Calculate Simulation Data
   */
  @Effect()
  calculate = this.actions$.pipe(
    ofType<actions.Calculate>(actions.ProposalActionTypes.CALCULATE),
    withLatestFrom(this.store$.pipe(select(selectors.selectProposal))),
    switchMap(([action, proposalData]) => {
      if (!proposalData.simulationId) return;
      return this.proposalDataService.calculateSimulation(proposalData).pipe(
        switchMap(response => {
          const selectedOpts = this.setDefaultDomainKeys(response.calculateSimulation);
          const selectedOptsWithtypeOfferWarrantyShared = {
            ...selectedOpts,
            typeOfferWarrantyShared: proposalData.typeOfferWarrantyShared
          };
          const simulationData = { ...response.calculateSimulation.simulationValues, selectedOptions: selectedOptsWithtypeOfferWarrantyShared };

          return [
            new fromParameters.actions.SetProposalDomains({
              simulationDomains: response.calculateSimulation.simulationDomains,
            }),
            new actions.CalculateSuccess(simulationData),
            new fromHelpers.actions.GetHelpers(),
          ];
        }),
        catchError((err) => {
          if (err.extensions) {
            return of(new fromErrorHandling.actions.AddError(this.errorService.addGraphQLAlert(err)));
          }

          return of(new fromErrorHandling.actions.AddError(this.errorService.addAlertError(err)));
        })
      );
    })
  );
  @Effect()
  calculateOffers = this.actions$.pipe(
    ofType<actions.CalculateOffers>(actions.ProposalActionTypes.CALCULATE_OFFERS),
    switchMap((action) => {
      return this.proposalDataService.calculateSimulationOffers(action.payload.state).pipe(
        switchMap(response => {
          const selectedOpts = this.setDefaultDomainKeys(response.calculateSimulation);
          const simulationData = { ...response.calculateSimulation.simulationValues, selectedOptions: selectedOpts };

          return [
            new fromParameters.actions.SetProposalDomains({
              simulationDomains: response.calculateSimulation.simulationDomains,
            }),
            new actions.CalculateSuccess(simulationData),
            new fromHelpers.actions.GetHelpers(),
          ];
        }),
        catchError((err) => {
          if (err.extensions) {
            return of(new fromErrorHandling.actions.AddError(this.errorService.addGraphQLAlert(err)));
          }

          return of(new fromErrorHandling.actions.AddError(this.errorService.addAlertError(err)));
        })
      );
    })
  );

  @Effect()
  getEmpreendimentos$ = this.actions$.pipe(
    ofType<actions.GetEmpreendimentos>(actions.ProposalActionTypes.GET_EMPREENDIMENTOS),
    switchMap((action) => {
      return this.proposalDataService
        .getEmpreendimento(action.payload.idEmpreendimento, action.payload.tipoConsulta)
        .pipe(
          map(
            (response) =>
              new actions.GetEmpreendimentosSuccess({ ...response.getEmpreendimentos, portal: action.payload.portal })
          ),
          catchError((err) => {
            if (err.extensions) {
              return of(new fromErrorHandling.actions.AddError(this.errorService.addGraphQLAlert(err)));
            }

            return of(new fromErrorHandling.actions.AddError(this.errorService.addAlertError(err)));
          })
        );
    })
  );

  @Effect()
  getBuildings$ = this.actions$.pipe(
    ofType<actions.GetBuildings>(actions.ProposalActionTypes.GET_BUILDINGS),
    switchMap((action) => {
      return this.proposalDataService.getBuildings(action.payload).pipe(
        map((response) => new actions.GetBuildingsSuccess(response)),
        catchError((err) => {
          if (err.extensions) {
            return of(new fromErrorHandling.actions.AddError(this.errorService.addGraphQLAlert(err)));
          }

          return of(new fromErrorHandling.actions.AddError(this.errorService.addAlertError(err)));
        })
      );
    })
  );

  @Effect()
  resetSelectedOptions$ = this.actions$.pipe(
    ofType<actions.ResetSelectedOptions>(actions.ProposalActionTypes.RESET_SELECTED_OPTIONS),
    withLatestFrom(this.store$.select(selectors.selectSelectedOptionsResettable)),
    filter(([_, state]) => !Object.values(state).every((val) => val === null)),
    map(() => {
      return new actions.ResetSelectedOptionsSuccess();
    })
  );

  /**
   * Redirect user to URL
   */
  @Effect({ dispatch: false })
  redirect = this.actions$.pipe(
    ofType<actions.Redirect>(actions.ProposalActionTypes.REDIRECT),
    tap((action) => this.router.navigate([action.payload.url]))
  );

  @Effect()
  getSimulationContractList = this.actions$.pipe(
    ofType<actions.GetSimulationContractData>(actions.ProposalActionTypes.GET_SIMULATION_CONTRACT_DATA),
    switchMap((action) => {
      return this.proposalDataService.getSimulationContractList(action.payload.idSimulacao, action.payload.loanTypeKey).pipe(
        map((response) => new actions.GetSimulationContractDataSuccess(response.listarContratosEmprestimoSimulacao)),
        catchError((err) => {
          if (err.extensions) {
            return of(new fromErrorHandling.actions.AddError(this.errorService.addGraphQLAlert(err)));
          }

          return of(new fromErrorHandling.actions.AddError(this.errorService.addAlertError(err)));
        })
      );
    })
  );

  @Effect()
  getWarrantySharingOffers = this.actions$.pipe(
    ofType<actions.GetWarrantySharingOffers>(actions.ProposalActionTypes.GET_WARRANTY_SHARING_OFFERS),
    switchMap((action) => {
      return this.proposalDataService.getWarrantySharingOffers(action.payload.nrCpfCnpj, action.payload.cdContractInter).pipe(
        map((response) => new actions.GetWarrantySharingOffersSucess(response.getWarrantySharingOffers.offers)),
        catchError((err) => {
          if (err.extensions) {
            return of(new fromErrorHandling.actions.AddError(this.errorService.addGraphQLAlert(err)));
          }

          return of(new fromErrorHandling.actions.AddError(this.errorService.addAlertError(err)));
        })
      );
    })
  );

  @Effect()
  getUnitSimulationData = this.actions$.pipe(
    ofType<actions.GetUnitSimulationData>(actions.ProposalActionTypes.GET_UNIT_SIMULATION_DATA),
    switchMap((action) =>
      this.proposalDataService.getUnitSimulationData(action.payload.unitId, action.payload.carSpaceUnitIdList).pipe(
        map((response) => new actions.GetUnitSimulationDataSucess(response.getUnitSimulationData)),
        catchError((err) => {
          if (err.extensions) {
            return of(new fromErrorHandling.actions.AddError(this.errorService.addGraphQLAlert(err)));
          }

          return of(new fromErrorHandling.actions.AddError(this.errorService.addAlertError(err)));
        })
      )
    )
  );

  private setDefaultDomainKeys({ simulationValues, simulationDomains }) {
    const selectedOptions = {};

    Object.keys(simulationDomainsConstant).forEach((o) => {
      if (!simulationValues.selectedOptions[simulationDomainsConstant[o]]) return;

      if (o === 'deferredPayment') {
        selectedOptions[simulationDomainsConstant[o]] =
          simulationDomains[o] &&
            simulationDomains[o].deferredPaymentOptions &&
            simulationDomains[o].deferredPaymentOptions.length
            ? this.getDefaultOptionKey(simulationDomains[o].deferredPaymentOptions.find(d => d.defaultOption))
            : '';
        return;
      }

      selectedOptions[simulationDomainsConstant[o]] =
        simulationDomains[o] && simulationDomains[o].options && simulationDomains[o].options.length
          ? this.getDefaultOptionKey(simulationDomains[o].options.find((d) => d.defaultOption))
          : '';
    });

    return selectedOptions;
  }

  private getDefaultOptionKey(defaultOption): string {
    if (defaultOption) {
      return defaultOption.key;
    }

    return '';
  }
}
