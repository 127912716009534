<section class="main section-white simulation-result">
  <div class="container">
    <h2>{{ 'modules.simulation.result.here is the result of your simulation' | translate }}</h2>
    <!-- <h2 class="sub">
      {{ 'modules.simulation.result.if you prefer you can change the values and conditions' | translate }}
    </h2> -->

    <div class="slider" *ngIf="!isUseCasaMais">
      <div class="valor-total">
        <span class="valor-total__titulo pr-2">{{ 'common.propertyValue' | translate }}:</span>
        <span class="valor-total__valor">{{ simulationData?.propertyValue | currencyPipe }}</span>
      </div>
    </div>
    <form action="" [formGroup]="formSimulationResult">
      <div class="form-row" *ngIf="isUseCasaMais">
        <div *ngIf="!showOferta" class="form-group col-md-6 col-lg-4 mb-4">
          <app-input-text-container [label]="'Oferta Selecionada' | translate" helperName="">
            <ng-select [placeholder]="'Select' | translate" formControlName="offerType" id="OfferType"
              [clearable]="false" [selectOnTab]="false" [items]="offerList" bindLabel="descricao" bindValue="codigo"
              (change)="onChangeOfferType($event)"></ng-select>
          </app-input-text-container>
        </div>

        <div class="mt-4" *ngIf="showText === 'EX'">
          <p class="mt-4 font-siza-default"><strong> Com essa opção você conta com:</strong></p>
          <p class="mt-4 font-siza-default"><strong> - Empréstimos com prazo e valor máximos que não poderão exceder os
              estipulados no contrato original.</strong></p>
          <p class="mt-4 font-siza-default"><strong> - Menor custo para constituição da garantia a favor do Santander.
            </strong></p>
        </div>

        <div class="mt-4" *ngIf="showText === 'SP'">
          <p class="mt-4 font-siza-default"><strong> Com essa opção você conta com:</strong></p>
          <p class="mt-4 font-siza-default"><strong> - Empréstimos com valor de até 60% do valor do imóvel, a depender
              do saldo devedor do seu contrato original, e até 240 meses para pagar.</strong></p>
        </div>
      </div>

      <div class="form-row">
        <!-- FGTS -->
        <div class="form-group col-md-6 col-lg-4" *ngIf="simulationDomains?.showFGTSBlock">
          <app-input-text-container [label]="'common.fgtsValue' | translate" [helperName]="'fgtsAmount'">
            <input type="tel" placeholder="R$" class="bg-beige" formControlName="fgtsAmount" id="fgts" maxlength="16"
              currencyMask/>
          </app-input-text-container>
        </div>

        <!-- Loan Amount Value -->
        <div class="form-group col-md-6 col-lg-4" *ngIf="simulationDomains?.showLoanAmountValueBlock && !isUseCasaMais">
          <app-input-text-container [label]="loanAmountValueLabel | translate">
            <input type="tel" placeholder="R$" class="bg-beige" formControlName="loanAmountValue" id="valorFinanciado"
              maxlength="16" currencyMask (blur)="sendSelectedFieldsSelectedsField()" />
          </app-input-text-container>
        </div>

        <!-- Loan Amount Value -->
        <div class="form-group col-md-6 col-lg-4" *ngIf="simulationDomains?.showLoanAmountValueBlock && isUseCasaMais">
          <app-input-text-container [label]="loanAmountValueLabel | translate" formatMinMax="currency"
            [labelMaxValue]="'common.maximum value' | translate">
            <input type="tel" placeholder="R$" class="bg-beige" formControlName="loanAmountValue" id="valorFinanciado"
              maxlength="16" currencyMask />
          </app-input-text-container>
          <div class="warning-msg">
            <p>Valor mínimo: {{minLoanAmountValue | currencyPipe }}</p>
            <p>Valor máximo: {{maxLoanAmountValue | currencyPipe }}</p>
          </div>
        </div>
        <!-- Term -->
        <div class="form-group col-md-6 col-lg-4" *ngIf="termInYearsList?.showThisBlock">
          <app-input-text-container [label]="'common.monthDeadline' | translate" [minValue]="minTermInMonths"
            [maxValue]="maxTermInMonths" [labelMinValue]="'common.label minimum value' | translate"
            [labelMaxValue]="'common.label maximum value' | translate">
            <ng-select *ngIf="!isUseCasaMais" appNgSelectTab [items]="termInYearsList?.options" [selectOnTab]="true"
              [clearable]="false" [class]="'ng-select bg-beige'" bindLabel="description" bindValue="key"
              placeholder="Selecione" formControlName="termInYears" (blur)="sendSelectedFieldsSelecteds()" id="prazo">
              <ng-template ng-label-tmp let-item="item">
                <span class="ng-option-label" [innerHTML]="item.description"></span>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <span class="ng-option-label" [innerHTML]="item.description"></span>
              </ng-template>
            </ng-select>

            <input *ngIf="isUseCasaMais" type="text" placeholder="Digite" class="bg-beige"
              formControlName="termInMonths" id="prazo" maxlength="3" mask="0*" />
          </app-input-text-container>
        </div>

        <!-- Payment -->
        <div class="form-group col-md-6 col-lg-4" *ngIf="simulationDomains?.showDownPaymentAmountBlock">
          <app-input-text-container [label]="'common.downPayment' | translate">
            <input type="tel" placeholder="R$" formControlName="downPayment" id="entrada" maxlength="16" currencyMask />
          </app-input-text-container>
        </div>

        <!-- Expenses Loan -->
        <div class="form-group col-md-6 col-lg-4" *ngIf="simulationDomains?.showExpensesValueBlock">
          <app-input-text-container [label]="'common.expensesValue' | translate" class="no-break-word">
            <input type="tel" placeholder="R$" formControlName="expensesValue" id="valorDespesasFinanciadas"
              maxlength="16" currencyMask />
          </app-input-text-container>
        </div>

        <!-- Total financed value -->
        <div class="form-group col-md-6 col-lg-4">
          <app-input-text-container [label]="amountFinancedLabel | translate">
            <input type="tel" placeholder="R$" formControlName="amountFinanced" id="amountFinanced" maxlength="16"
              currencyMask />
          </app-input-text-container>
        </div>
      </div>

      <div class="info-simulation">
        <div class="info-installment">
          <label for="" class="simulation-result__label simulation-result__label--info pr-2 check check--left">{{
            'modules.simulation.result.entry installment value' | translate }}:</label>
          <span class="info currency-brl">{{ calculatedSimulationResult?.firstPaymentAmount | currencyPipe }}</span>
          <app-helper-button appHelperTooltip helperName="installmentInitialValue"></app-helper-button>
        </div>
        <div class="info-installment">
          <label for="" class="simulation-result__label simulation-result__label--info pr-2"
            *ngIf="calculatedSimulationResult?.calculatedSimulationType === 'prime'">
            {{ 'modules.simulation.result.effective interest rate with bonus' | translate }}:
          </label>
          <label for="" class="simulation-result__label simulation-result__label--info pr-2 interestRates"
            *ngIf="calculatedSimulationResult?.calculatedSimulationType === 'average'">
            {{ 'modules.simulation.result.effective interest rate' | translate }}:
          </label>
          <span class="info currency-brl taxa-aa">
            {{ calculatedSimulationResult?.interestRates.yearInterestRate | percentPipe: null:false }}
          </span>&nbsp;&nbsp;<span class="info currency-brl taxa-am"
            [ngClass]="{ 'taxa-am-tr': calculatedSimulationResult?.usesEstimatedTRrate }">
            {{ calculatedSimulationResult?.interestRates.monthInterestRate | percentPipe: null:false }}
          </span>
        </div>
      </div>

      <div class="row mt-5 mb-3 text-align">
        <div class="col-12 mt-0">
          <span class="custom-span">{{ 'modules.simulation.result.these are values ​​from your simulation' | translate
            }}
          </span>
        </div>
      </div>

      <div class="mt-2 pb-4">
        <button type="button" class="button-default" (click)="onSave()" id="btnAcompanhamentoResumoProposta"
          [ngClass]="{ 'btn-disabled': !formConditionResult.valid }">
          {{ 'button.click here for credit analysis' | translate | uppercase }}
        </button>
      </div>
      <!-- <div class="d-flex justify-content-center pb-4 custom-h3">
        <h3 class="font-weight-bold mt-1">{{ 'modules.simulation.result.+ than 100 thousand people with approved credit' | translate }}</h3>
      </div> -->
    </form>
  </div>
</section>