import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { AppState } from '@app/state';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import * as fromParticipants from '@app/state/participants';
import { StorageEncryptService } from '@shared/services/storageEncrypt.service';

interface IntegrationData {
  proposalId?: string;
  proponentId?: string;
}

@Injectable()
export class ValidatedTokenSmsGuard implements CanActivate {
  constructor(
    private store$: Store<AppState>,
    private router: Router,
    private storageEncryptService: StorageEncryptService
  ) {}

  /**
   * Handle integration when coming back from Portal Cliente Personas
   *  @returns {IntegrationData}
   * @memberof SimulationGuard
   */
  handleIntegrationPersonas(): IntegrationData {
    const session = JSON.parse(this.storageEncryptService.getSessionStore('data'));
    const utmSource = this.storageEncryptService.getSessionStore('utmSource');
    const renovado  = this.storageEncryptService.getSessionStore('renovado');

    if (session && session.idProposta && utmSource) {
      return { proposalId: session.idProposta };
    }

    if (session && session.idProposta && renovado) {
      return { proposalId: session.idProposta };
    }

    if (session && session.idProposta && session.idProponente) {
      // this.storageEncryptService.removeSession('data');
      return { proposalId: session.idProposta, proponentId: session.idProponente };
    }

    return { proposalId: null, proponentId: null };
  }

  /**
   * Allow access to pages other than personal data only if the user has a validated sms token
   *
   * @returns {Observable<boolean>}
   * @memberof SimulationGuard
   */

  canActivate(): Observable<boolean> {
    const integration = this.handleIntegrationPersonas();
    const userData  = this.storageEncryptService.getSessionStore('userData');

    return this.store$.pipe(
      select(fromParticipants.selectors.selectValidateToken),
      map(validated => {
        if (!validated && !integration.proposalId && !userData) {
          this.router.navigate(['/dados-pessoais']);
          return false;
        }
        return true;
      })
    );
  }
}
