import { Component, ViewEncapsulation } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DataLayerSantanderService } from '../../service/dataLayer/novo-data-layer.service';
import { StorageEncryptService } from '@shared/services/storageEncrypt.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent {
  clientName: string = environment.pathClientAssets;

  arrow = 'arrow-down';

  term = environment.urlTermCondicoes;

  accordion = {
    showAtendimento: false,
    showTelSac: false,
    showTelOuvidoria: false,
    showCaixaEletronico: false,
    showBuscaAgencia: false
  };

  constructor(private dataLayerSantander: DataLayerSantanderService, private storageEncryptService: StorageEncryptService) {}

  showAccordion(item) {
    let nameAccordion;
    if (this.accordion[item] === false) {
      this.accordion[item] = true;
      this.arrow = 'arrow-up';
      setTimeout(() => {
        window.scrollTo(0, 2500);
      }, 50);
    } else {
      this.accordion[item] = false;
      this.arrow = 'arrow-down';
    }
    nameAccordion = this.translateField(item)
    let productName = this.storageEncryptService.getSessionStore('productName');
    this.sendToClickEvent(false, 'selecionou', 'dropdown:' + nameAccordion, productName)
  }

  handleClick(event){
    event.preventDefault();
    const txtContent = document.querySelector('#footer-app-title').textContent;
    const txt = this.translateField(txtContent);
    let productName = this.storageEncryptService.getSessionStore('productName');
    this.sendToClickEvent(false, 'clicou' , 'botao:' + txt, productName);
  }

  sendToClickEvent(nonInterection, eventAction, eventLabel, productName) {
    this.dataLayerSantander.sendCustomToDataLayer(
      nonInterection,
      eventAction,
      eventLabel,
      productName
    );
  }

  translateField(fieldName: string) {
    let translatedFieldName: string;
    switch (fieldName) {
      case 'showAtendimento':
        translatedFieldName = 'central-atendimento';
        break;
      case 'showTelSac':
        translatedFieldName = 'sac';
        break;
      case 'showTelOuvidoria':
        translatedFieldName = 'ouvidoria';
        break;
      case 'showCaixaEletronico':
        translatedFieldName = 'caixa-eletronico';
        break;
      case 'showBuscaAgencia':
        translatedFieldName = 'agencia';
        break;
      case 'App santander':
        translatedFieldName = 'app-santander';
        break;
      default:
        translatedFieldName = fieldName;

      }
      return translatedFieldName
  }


}
