import { Action } from '@ngrx/store';
import { getUnitBorrowerSimulation_getUnitBorrowerSimulation } from '@shared/graphql/types/query-types';

export enum ParticipantsActionTypes {
  SET_PARTICIPANTS = '[Participants] Set Participants',

  LOAD = '[Participants] Load',
  LOAD_SUCCESS = '[Participants] Load Success',
  LOAD_ERROR = '[Participants] Load Error',

  GET_BORROWER_SIMULATION = '[Participants] Get Borrower Simulation',
  GET_BORROWER_SIMULATION_SUCCESS = '[Participants] Get Borrower Simulation Success',

  GET_BORROWER_SIMULATION_PJ = '[Participants] Get Borrower Simulation PJ',
  GET_BORROWER_SIMULATION_PJ_SUCCESS = '[Participants] Get Borrower Simulation PJ Success',

  GET_BORROWERS_LIST_SIMULATION = '[Participants] Get Borrowers List Simulation',
  GET_BORROWERS_LIST_SIMULATION_SUCCESS = '[Participants] Get Borrowers List Simulation Success',

  UPDATE_PERSONAS_STATUS = '[Participants] Update Personas Status',
  UPDATE_PERSONAS_STATUS_SUCCESS = '[Participants] Update Personas Status Success',

  SEND = '[Participants] Send',
  SEND_SUCCESS = '[Participants] Send Success',
  SEND_PJ = '[Participants] Send PJ',
  SEND_PJ_SUCCESS = '[Participants] Send PJ Success',
  SEND_ERROR = '[Participants] Send Error',

  SET_PERSONAL_DATA_CHANGED = '[Participants] Set Personal Data Changed',

  GENERATE_TOKEN_SMS = '[Participants] Generate Sms',
  GENERATE_TOKEN_SMS_SUCCESS = '[Participants] Generate Sms Success',

  VALIDATE_TOKEN_SMS = '[Participants] Validate Sms',
  VALIDATE_TOKEN_SMS_SUCCESS = '[Participants] Validade Sms Success',


  CLEAR_ERRORS = '[Participants] Clear Errors',

  GET_UNIT_BORROWER_SIMULATION = '[Simulation] Get Unit Simulation',
  GET_UNIT_BORROWER_SIMULATION_SUCCESS = '[Simulation] Get Unit Simulation Success',
  GET_UNIT_BORROWER_SIMULATION_RESET = '[Simulation] Get Unit Simulation Reset'
}

export class SetParticipants implements Action {
  readonly type = ParticipantsActionTypes.SET_PARTICIPANTS;
  constructor(public payload: { participants: Array<any> }) {}
}

export class LoadParticipants implements Action {
  readonly type = ParticipantsActionTypes.LOAD;
  constructor(public payload: string) {}
}

export class LoadParticipantsSuccess implements Action {
  readonly type = ParticipantsActionTypes.LOAD_SUCCESS;
  constructor(public payload: any) {}
}

export class LoadParticipantsError implements Action {
  readonly type = ParticipantsActionTypes.LOAD_ERROR;
  constructor(public payload: any) {}
}

export class SendParticipants implements Action {
  readonly type = ParticipantsActionTypes.SEND;
  constructor(public channel: string, public payload: any, public simulationId: any, public utmSource?: string) {}
}

export class SendParticipantsSuccess implements Action {
  readonly type = ParticipantsActionTypes.SEND_SUCCESS;
  constructor(public payload: any) {}
}

export class SendParticipantsPJ implements Action {
  readonly type = ParticipantsActionTypes.SEND_PJ;
  constructor(public channel: string, public payload: any, public simulationId: any, public utmSource?: string) {}
}

export class SendParticipantsPJSuccess implements Action {
  readonly type = ParticipantsActionTypes.SEND_PJ_SUCCESS;
  constructor(public payload: any) {}
}

export class SendParticipantsError implements Action {
  readonly type = ParticipantsActionTypes.SEND_ERROR;
  constructor(public payload: any) {}
}

export class ClearErrors implements Action {
  readonly type = ParticipantsActionTypes.CLEAR_ERRORS;
  constructor() {}
}

export class GetBorrower implements Action {
  readonly type = ParticipantsActionTypes.GET_BORROWER_SIMULATION;
  constructor(public payload: { documentNumber: string }) {}
}

export class GetBorrowerSuccess implements Action {
  readonly type = ParticipantsActionTypes.GET_BORROWER_SIMULATION_SUCCESS;
  constructor(public payload: any) {}
}

export class GetBorrowerPJ implements Action {
  readonly type = ParticipantsActionTypes.GET_BORROWER_SIMULATION_PJ;
  constructor(public payload: { documentNumber: string }) {}
}

export class GetBorrowerSuccessPJ implements Action {
  readonly type = ParticipantsActionTypes.GET_BORROWER_SIMULATION_PJ_SUCCESS;
  constructor(public payload: any) {}
}

export class GetBorrowersListSimulation implements Action {
  readonly type = ParticipantsActionTypes.GET_BORROWERS_LIST_SIMULATION;
  constructor(public simulationId?: string) {}
}

export class GetBorrowersListSimulationSuccess implements Action {
  readonly type = ParticipantsActionTypes.GET_BORROWERS_LIST_SIMULATION_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdatePersonasStatus implements Action {
  readonly type = ParticipantsActionTypes.UPDATE_PERSONAS_STATUS;
  constructor() {}
}

export class UpdatePersonasStatusSuccess implements Action {
  readonly type = ParticipantsActionTypes.UPDATE_PERSONAS_STATUS_SUCCESS;
  constructor(public payload: any) {}
}

export class SetPersonalDataChanged implements Action {
  readonly type = ParticipantsActionTypes.SET_PERSONAL_DATA_CHANGED;
  constructor(public payload: { personalDataChanged: boolean }) {}
}

export class GenerateTokenSms implements Action {
  readonly type = ParticipantsActionTypes.GENERATE_TOKEN_SMS;
  constructor(public payload: any) { }
}

export class GenerateTokenSmsSucess implements Action {
  readonly type = ParticipantsActionTypes.GENERATE_TOKEN_SMS_SUCCESS;
  constructor(public payload: any) { }
}
export class ValidateTokenSms implements Action {
  readonly type = ParticipantsActionTypes.VALIDATE_TOKEN_SMS;
  constructor(public payload: any) { }
}

export class ValidateTokenSmsSucess implements Action {
  readonly type = ParticipantsActionTypes.VALIDATE_TOKEN_SMS_SUCCESS;
  constructor(public payload: any) { }
}

export class GetUnitBorrower implements Action {
  readonly type = ParticipantsActionTypes.GET_UNIT_BORROWER_SIMULATION;
  constructor(public unitId: string) {}
}

export class GetUnitBorrowerSuccess implements Action {
  readonly type = ParticipantsActionTypes.GET_UNIT_BORROWER_SIMULATION_SUCCESS;
  constructor(public payload: Array<getUnitBorrowerSimulation_getUnitBorrowerSimulation>) {}
}

export class GetUnitBorrowerReset implements Action {
  readonly type = ParticipantsActionTypes.GET_UNIT_BORROWER_SIMULATION_RESET;
  constructor() {}
}

export type ParticipantsActions =
  | SetParticipants
  | LoadParticipants
  | LoadParticipantsSuccess
  | LoadParticipantsError
  | SendParticipants
  | SendParticipantsSuccess
  | SendParticipantsPJ
  | SendParticipantsPJSuccess
  | SendParticipantsError
  | ClearErrors
  | GetBorrower
  | GetBorrowerSuccess
  | GetBorrowerPJ
  | GetBorrowerSuccessPJ
  | GetBorrowersListSimulation
  | GetBorrowersListSimulationSuccess
  | UpdatePersonasStatus
  | UpdatePersonasStatusSuccess
  | SetPersonalDataChanged
  | SetPersonalDataChanged
  | GenerateTokenSms
  | GenerateTokenSmsSucess
  | ValidateTokenSms
  | ValidateTokenSmsSucess
  | GetUnitBorrower
  | GetUnitBorrowerSuccess
  | GetUnitBorrowerReset;

