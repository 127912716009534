import gql from 'graphql-tag';

export const GET_UNIT_SIMULATION_DATA = gql`
  query GetUnitSimulationData($unitId: ID, $carSpaceUnitIdList: [ID]) {
    getUnitSimulationData(unitId: $unitId, carSpaceUnitIdList: $carSpaceUnitIdList) {
      appraisalValue
      buildingTypeKey
      federalState
      loanAmountValue
      loanObjectiveId
      propertyValue
    }
  }
`;
