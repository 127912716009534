<app-modal-new [title]="title" identifier="modalContractList" (closeModal)="onBack(true)" size="lg">
  <ng-container body>
    <form name="form" [formGroup]="form">
      <div class="detalhes-mensagem">
        <fieldset>
          <h3 class="pb-3 text-center" *ngIf="channel === 'PP'">
            {{ subtitle }}
          </h3>
          <div class="col-sm-12" *ngFor="let item of list; index as i">
            <div class="form-row form-row-radio">
              <div class="form-group-radio col-12 col-sm-2">
                <input type="radio" [id]="item.codigoContrato" [value]="item"
                  formControlName="contrato" />
                <label [for]="item.codigoContrato" class="label-radio"></label>
              </div>

              <div class="col-12 col-sm-10">
                <div class="break pb-2" *ngIf="channel !== 'PP'">
                  <span class="text-color-black h4 pd-block">Contrato: {{ item.codigoContratoTombamento ? item.codigoContratoTombamento : item.codigoContrato }}</span>
                </div>
                <div class="break pb-2" *ngIf="channel !== 'PP'">
                  <strong class="text-color-black h4 pd-block font-weight-600">Tipo de Operação:</strong>
                </div>
                <div class="break pb-2" *ngIf="channel !== 'PP'">
                  <span class="text-color-black h4 pd-block">{{ item.descricaoObjetivoFinanciamento }}</span>
                </div>
                <div class="break pb-2">
                  <strong class="text-color-black h4 pd-block font-weight-600">Endereço: </strong>
                  <span class="h4">
                    {{ item.tipoLogradouro }}
                    {{ item.descricaoLogradouro }},
                    {{ item.numeroLogradouro }} -
                    {{ item.descricaoComplemento }}
                    {{ item.descricaoBairro }} -
                    {{ item.descricaoCidade }} /
                    {{ item.idUf }} - CEP: 
                    {{ item.cep | cepFormat }}          
                  </span>
                </div>
              </div>
            </div>
          </div>
          
          <div class="text-center pb-4">
            <button class="button-default text-center center-block" type="button"
              [disabled]="form.invalid" (click)="onAdvance()">
              Avançar
            </button>
          </div>

          <div class="text-center">
            <button class="button-default text-center center-block" type="button" (click)="onBack()">
              Voltar
            </button>
          </div>
        </fieldset>
      </div>
    </form>
  </ng-container>
</app-modal-new>