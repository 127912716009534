<div>
  <app-timeline [fase]="4" [nomeFase]="'Crédito'" [scrollPage]="85"></app-timeline>

  <app-header-proposal-summary *ngIf="borrowers" [borrowers]="borrowers" [hasInconsistencies]="hasInconsistencies"
    [isFinalized]="isFinalized" [pathImageClient]="pathImageClient" [nameExibition]="nameExibition"
    [formProponentData]="formProponentData" [utmSource]="utmSource" (fillProponentData)="fillProponentData($event)"
    (redirectTo)="redirectTo($event)">
  </app-header-proposal-summary>


  <div *ngIf="useMockLogin && !isRenovado && !hasInconsistencies && isFinalized" >
    <div class="container">
      <form [formGroup]="formLogin">
        <div class="form-group">
          <div class="div">
            <label for="Login">Login </label>
            <input type="login" class="form-control" id="login" aria-describedby="login" placeholder="Login"
              formControlName="nome">
          </div>
          <div class="div">
            <label class="pt-2" for="Senha">Senha </label>
            <input type="password" class="form-control" id="Senha" aria-describedby="Senha" placeholder="Senha"
              formControlName="senha">
          </div>
        </div>
        <div class="pt-2">
          <button type="submit" class="btn btn-primary" (click)="login()">Login</button>
        </div>
      </form>
    </div>
  </div>

  <div *ngIf="exibeLogin && isFinalized && !hasInconsistencies && !isRenovado && !useMockLogin">
    <div class="container">
      <iframe scrolling="auto" [src]="urlLoginIframe" frameborder="0" id="iframe-resumo" class="iframe-resumo"></iframe>
    </div>
  </div>

  <div *ngIf="isRenovado && !hasInconsistencies" class="group-buttons">
    <button type="button" class="button-default" (click)="onRedirectCredito()" id="btnSubmitPreAnalise">
      {{ 'button.continue' | translate | uppercase }}
    </button>
  </div>

  <div class="container" *ngIf="!utmSource">
    <div class="group-buttons">
      <button type="button" type="button" (click)="back()" class="button-back" id="buttonVoltar">
        <span class="material-icons">keyboard_arrow_left</span>
        <strong>{{ 'button.back' | translate | uppercase }}</strong>
      </button>
    </div>
  </div>

  <app-modal [show]="modal"></app-modal>
</div>
