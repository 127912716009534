import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { BUSCAR_TABELAS_GERAL } from '@shared/graphql/queries/tabela-geral.query';


@Injectable({
  providedIn: 'root'
})
export class TabelaGeralService {
  constructor(private apollo: Apollo) {}

  buscarTabelasGeral(id, tipoOrdenacao = 'ASC') {
    return this.apollo
      .query({
        query: BUSCAR_TABELAS_GERAL,
        variables: {
          id,
          tipoOrdenacao,
          page: 1,
          per_page: 200
        } as any,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map(r => {
          if (r.errors) throw r.errors[0];
          return r.data as any;
        }),
        catchError(err => throwError(err))
      );
  }
}
