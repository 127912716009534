import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from '@app/state';

import * as fromSimulation from '@app/state/simulation';

@Component({
  selector: 'app-middleware-home',
  templateUrl: './middleware-home.component.html',
  styleUrls: ['./middleware-home.component.scss']
})
export class MiddlewareHomeComponent implements OnInit {

  constructor( private store$: Store<AppState>) { }

  private baseUrl = `${environment.urlPortalCliente}`.replace(/\/\/$/gm, '/');

  goToProposal() {
    this.store$.dispatch(
      new fromSimulation.actions.RedirectExternal({
        url: `${this.baseUrl}/#/acompanhesuaproposta`
      })
    );
  }

  ngOnInit() {
    this.goToProposal();
  }

}
