import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxMaskModule } from 'ngx-mask';

import { NgSelectModule } from '@ng-select/ng-select';
import { NgxCurrencyModule } from 'ngx-currency';

import { HeaderComponent } from './widgets/header/header.component';
import { TimelineComponent } from './widgets/timeline/timeline.component';
import { InputTextContainerComponent } from './ui/input-text-container/input-text-container.component';
import { FieldErrorDisplayComponent } from './ui/field-error-display/field-error-display.component';
import { InputCheckboxContainerComponent } from './ui/input-checkbox-container/input-checkbox-container.component';
import { HelperComponent } from './widgets/helper/helper.component';
import { HelperService } from './services/helper.service';
import { FormValidationService } from './services/form-validation.service';
import { InputRadioContainerComponent } from './ui/input-radio-container/input-radio-container.component';
import { HelperButtonComponent } from './ui/helper-button/helper-button.component';
import { ModalComponent } from './widgets/modal/modal.component';
import { ModalTemplateComponent } from './widgets/modal-template/modal-template.component';
import { ModalAlertComponent } from './widgets/modal-alert/modal-alert.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WarningMinMaxComponent } from './ui/warning-min-max/warning-min-max.component';
import { ChatFooterComponent } from './ui/chat-footer/chat-footer.component';
import { ModalComponent as ModalButtonComponent } from './ui/modal/ui/modal.component';

import { RecaptchaModule, RECAPTCHA_LANGUAGE, RecaptchaFormsModule} from 'ng-recaptcha';
import { GraphQLModule } from './graphql.module';
import { ProposalDataService } from './services/proposal-data.service';
import { ParameterService } from './services/parameter.service';
import { PersonalDataService } from './services/personal-data.service';
import { VisibleFieldDirective } from './directives/visible-field/visible-field.directive';
import { PipesModule } from './pipes/pipes.module';
import { SimulationService } from './services/simulation.service';
import { OffsetMenuComponent } from './ui/offset-menu/offset-menu.component';
import { DownloadService } from './services/download.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HelperTooltipDirective } from './directives/helper-tooltip/helper-tooltip.directive';
import { NgSelectTabDirective } from './directives/ng-select-tab/ng-select-tab.directive';
import { MenuPerfilDirective } from './directives/parametros-perfil/parametros-perfil.directive';
import { HeaderSessionExpiredComponent } from './ui/header-session-expired/header-session-expired.component';
import { AppSessionExpiredComponent } from './ui/app-session-expired/app-session-expired.component';
import { ModalContractListComponent } from './ui/modal-contract-list/modal-contract-list.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const customCurrencyMaskConfig = {
  align: 'left',
  allowNegative: true,
  allowZero: true,
  decimal: ',',
  precision: 2,
  prefix: 'R$ ',
  suffix: '',
  thousands: '.',
  nullable: true
};

@NgModule({
  declarations: [
    HeaderComponent,
    TimelineComponent,
    InputTextContainerComponent,
    FieldErrorDisplayComponent,
    InputCheckboxContainerComponent,
    HelperComponent,
    InputRadioContainerComponent,
    HelperButtonComponent,
    ModalComponent,
    ModalButtonComponent,
    ModalTemplateComponent,
    ModalAlertComponent,
    WarningMinMaxComponent,
    ChatFooterComponent,
    VisibleFieldDirective,
    HelperTooltipDirective,
    OffsetMenuComponent,
    NgSelectTabDirective,
    MenuPerfilDirective,
    HeaderSessionExpiredComponent,
    AppSessionExpiredComponent,
    ModalContractListComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FontAwesomeModule,
    NgSelectModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NgxMaskModule.forRoot(),
    NgbModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    GraphQLModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    TranslateModule,
    PipesModule,
    HeaderComponent,
    TimelineComponent,
    InputTextContainerComponent,
    InputCheckboxContainerComponent,
    HelperComponent,
    TranslateModule,
    InputRadioContainerComponent,
    HelperButtonComponent,
    RecaptchaModule,
    RecaptchaFormsModule,
    ModalComponent,
    ModalButtonComponent,
    ModalTemplateComponent,
    ModalAlertComponent,
    WarningMinMaxComponent,
    ChatFooterComponent,
    VisibleFieldDirective,
    HelperTooltipDirective,
    OffsetMenuComponent,
    FormsModule,
    ReactiveFormsModule,
    NgSelectTabDirective,
    MenuPerfilDirective,
    HeaderSessionExpiredComponent,
    AppSessionExpiredComponent,
    ModalContractListComponent ],
  entryComponents: [ModalAlertComponent],
  providers: [
    HelperService,
    FormValidationService,
    ProposalDataService,
    ParameterService,
    PersonalDataService,
    SimulationService,
    DownloadService,
    { provide: RECAPTCHA_LANGUAGE, useValue: 'pt' }
  ]
})
export class SharedModule { }
