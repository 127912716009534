import { createFeatureSelector } from '@ngrx/store';

export interface LoginState {
  username: string | null;
  urlRequest: any | null;
  urlOrigin: string | null;
  isLoggedIn: boolean | null;
  tokenPP: string | null;
  alerts: any;
  isLogged: boolean | null;
  integrationSuces: boolean | null;
}

export const initialState: LoginState = {
  username: null,
  urlRequest: null,
  urlOrigin: null,
  isLoggedIn: null,
  tokenPP: null,
  alerts: [],
  isLogged: null,
  integrationSuces: null
};

export const loginSelector = createFeatureSelector<any>('login');
