import gql from 'graphql-tag';

export const GET_BORROWERS_LIST_SIMULATION = gql`
  query getBorrowersListSimulation($simulationId: ID!) {
    getBorrowersListSimulation(simulationId: $simulationId) {
      id
      order
      name
      birthDate
      documentNumber
      email
      telephoneNumber
      monthlyIncome
      authorizesEmailAndSms
      isEmployee
      isPrimaryBorrower
      isFromPersonas
    }
  }
`;

export const GET_BORROWER_SIMULATION = gql`
  query getBorrower($documentNumber: String!) {
    getBorrower(documentNumber: $documentNumber) {
      id
      order
      name
      birthDate
      documentNumber
      email
      telephoneNumber
      monthlyIncome
      authorizesEmailAndSms
      isEmployee
      isPrimaryBorrower
      isFromPersonas
      hasDevelopments
    }
  }
`;

export const GET_BORROWER_SIMULATION_PJ = gql`
query getBorrowerPJ ($documentNumber: String!){
  getBorrowerPJ(documentNumber: $documentNumber){
    id
    name
    documentNumber
    integrationCode
    primarySegment
    primarySegmentDescription
  }
}
`;
