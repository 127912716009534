<div class="elemento-tela-cheia">
  <div>
    <!-- Logo -->
    <div class="col-sm-12 d-flex">
      <div class="col-sm-6 d-flex justify-content-start align-items-end">
        <img class="logo-full logo-red" src="assets/{{ pathImageClient }}/images/logo-header-red.svg" alt="logo" />
      </div>

      <div class="row col-sm-6 col-md-6 intro text-center d-flex justify-content-end pr-2" *ngIf="isLogin">
        <div class="buttons button-main button-splash">
          <button class="button-default-logout botao-troca-usuario" (click)="resetScreen()">
            Trocar usuário
            <img class="logo-sair" src="assets/{{ pathImageClient }}/images/sign-out.svg" alt="sair" />
          </button>
        </div>
      </div>
    </div>

    <!-- Linha horizontal -->
    <div class="linha-horizontal"></div>

    <div class="container-fliud margin-container" *ngIf="!isLogin && !isNotCliente" [formGroup]="formConsultProposal">
      <!-- Texto de proposta -->

      <p class="text-proposal col-sm-10">Acompanhe o andamento da sua proposta. Digite seu CPF e Celular:</p>

      <!-- Formulário -->
      <div class="col-sm-12 col-md-12 margin-form">
        <div class="row">
          <div class="col-sm-6 col-md-5 form-group">
            <app-input-text-container
              [label]="'modules.simulation.personal-data.what is the cpf' | translate"
              [customErrorMessage]="'common.invalid CPF' | translate"
            >
              <input
                type="tel"
                id="cpfOutroParticipante"
                formControlName="documentNumber"
                mask="000.000.000-00"
                autocomplete="off"
                class="campo-cpf form-control"
              />
            </app-input-text-container>
          </div>
          <div class="col-sm-6 col-md-5 form-group">
            <app-input-text-container [label]="'CELULAR'">
              <input
                required
                name="celular"
                class="campo-cpf form-control"
                type="tel"
                id="celular"
                formControlName="telephoneNumber"
                autocomplete="off"
                mask="(00) 00000-0000"
              />
            </app-input-text-container>
          </div>
        </div>
      </div>

      <div class="container-fluid d-flex justify-content-center mt-2" *ngIf="recaptchaOn">
        <div class="form-group">
          <re-captcha formControlName="recaptcha" name="recaptcha" id="g-recaptcha-response" [siteKey]="siteKey">
          </re-captcha>
        </div>
      </div>
    </div>

    <div *ngIf="isLogin && !isNotCliente">
      <div *ngIf="!useMockLogin" class="container-form">
        <iframe title="iframeLogin" [src]="urlLoginIframe" id="iframe-resumo" class="iframe-resumo"></iframe>
      </div>

      <div class="container-fliud mt-4">
        <div class="row d-flex justify-content-center">
          <form *ngIf="useMockLogin" [formGroup]="formLogin">
            <fieldset>
              <div class="form-row">
                <div class="form-group col-xs-4">
                  <app-input-text-container [label]="'Login'">
                    <input
                      type="text"
                      placeholder="Digite"
                      formControlName="name"
                      name="name"
                      class="ajust-width text-uppercase"
                      id="login"
                    />
                  </app-input-text-container>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <div class="form-row">
                <div class="form-group col-xs-4">
                  <app-input-text-container [label]="'Password'">
                    <input
                      type="password"
                      placeholder="Digite"
                      formControlName="password"
                      name="password"
                      class="ajust-width"
                      id="password"
                    />
                  </app-input-text-container>
                </div>
              </div>
            </fieldset>
            <button type="button" class="mt-5 mb-5 button-default" (click)="login()">{{ 'Login' | translate }}</button>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div
    class="container-fluid btn-footer footer-container d-flex"
    (click)="goToLogin()"
    *ngIf="!isLogin && !isNotCliente"
  >
    <div class="row w-100">
      <div class="col-sm-9 btn-footer__label">
        <p class="p-continue">Continuar</p>
      </div>
      <div class="col-sm-3 btn-footer__arrow text-right">
        <p>
          <i class="material-icons">arrow_forward</i>
        </p>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isNotCliente">
  <app-modal [show]="modal"></app-modal>
</div>
