import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';

import { CoreModule } from '@app/core/core.module';
import { LoadingModule } from '@shared/widgets/loading/loading.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { SimulationModule } from './simulation/simulation.module';

import { CryptoModule } from 'arch-onefinancial-crypto.lib';
import { cryptoConfiguration } from './cripto.config';
import { SwalService } from '@shared/services/swal/swal.service';
import { AuthModule, AuthServices } from 'arch-onefinancial-auth.lib';
import { authConfiguration } from './auth.config';
import { AuthGuard } from '@app/core/auth/auth.guard';
import { ConsultProposalModule } from './consult-proposal/consult-proposal.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    SimulationModule,
    ConsultProposalModule,
    CoreModule,
    LoadingModule,
    BrowserAnimationsModule,
    CryptoModule.forRoot(cryptoConfiguration),
    AuthModule.forRoot(authConfiguration)
  ],
  exports: [LoadingModule, BrowserAnimationsModule],
  providers: [SwalService, AuthGuard, AuthServices],
  bootstrap: [AppComponent]
})
export class AppModule {}
