import { Component, OnDestroy } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { DataLayerService } from './shared/service/dataLayer/dataLayer.service';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@app/state';
import { StorageEncryptService } from '@shared/services/storageEncrypt.service';
export let browserRefresh = false;
import * as fromLogin from '@app/state/login';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnDestroy {
  subscription: Subscription;
  typePortability: string;
  loanTypeKey: string;


  constructor(public router: Router, private dataLayerService: DataLayerService, private store$: Store<AppState>,
    private storageEncryptService: StorageEncryptService) {
    this.store$.dispatch(new fromLogin.actions.IntegrationPublic());
    this.subscription = router.events.subscribe((route) => {
      if (route instanceof NavigationStart) {
        browserRefresh = !router.navigated;
        if (!browserRefresh || (route.id === 3 && route.url === '/dados-pessoais')) {

          const goal = this.storageEncryptService.getSessionStore('goal');
          const type = this.storageEncryptService.getSessionStore('type');

          if ((!type || type === 'undefined' || type === 'null') && goal !== null) {
            this.dataLayerService.sendPageViewToDataLayer(route.url, goal);
          } else if (goal && type) {
            const goalType = `${goal}-${type}`;
            this.dataLayerService.sendPageViewToDataLayer(route.url, goalType);
          }


          if (!goal) {
            this.dataLayerService.sendPageViewToDataLayer(route.url, '99');
          }

        }
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
