import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';

import { reducers } from '.';
import { environment } from 'src/environments/environment';
import { CustomSerializerProvider } from '@app/shared/services/router-store.service';
import { ParticipantsEffects } from './participants/participants.effects';
import { ParametersEffects } from './parameters/parameters.effects';
import { ProposalEffects } from './proposal/proposal.effects';
import { SimulationEffects } from './simulation/simulation.effects';
import { LoginEffects } from './login/login.effects';
import { HelperEffects } from './helpers/helpers.effects';
import { ConsultProposalEffects } from './consult/consult.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot(reducers),
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router'
    }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([
      ParticipantsEffects,
      ParametersEffects,
      ProposalEffects,
      SimulationEffects,
      LoginEffects,
      HelperEffects,
      ConsultProposalEffects
    ])
  ],
  providers: [CustomSerializerProvider]
})
export class StateModule {}
