import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../../login/services/login.service';
import { environment } from 'src/environments/environment';
import { StorageEncryptService } from '@shared/services/storageEncrypt.service';
import { ProposalSummaryService } from '../proposal-summary/proposal-summary.service';
import { AppState } from '@app/state';
import { Store } from '@ngrx/store';
import * as fromProposal from '@app/state/proposal';
@Component({
  selector: 'app-middleware-token-origination',
  templateUrl: './middleware-token-origination.component.html',
  styleUrls: ['./middleware-token-origination.component.scss']
})
export class MiddlewareTokenOriginationComponent {
  token: string;
  typeClient: string;

  constructor(
    private loginService: LoginService,
    private route: ActivatedRoute,
    private proposalSummaryService: ProposalSummaryService,
    private router: Router,
    private storageEncryptService: StorageEncryptService,
    private store$: Store<AppState>
  ) {
    this.route.queryParams.subscribe(params => {
      if (environment.featureToggle.useMockLogin === 'false') {
        const token = 'token';
        const typeClient = 'typeClient';


        if (params[token]) {
          this.token = decodeURIComponent(params[token]).replace(/\s/g, '+');
          this.typeClient = params[typeClient];

          /* Obter client credencials */
          const cpf = JSON.parse(this.storageEncryptService.getSessionStore('usuarioLogado')).cpf;
          const userData = JSON.parse(this.storageEncryptService.getSessionStore('userData'));

          this.proposalSummaryService
            .tokenOpenFinancial(userData.access_token, this.token, cpf, this.typeClient, environment.clientID)
            .subscribe(response => {
              const idSimulacao =
                this.storageEncryptService.getSessionStore('idProposta') !== null
                  ? this.storageEncryptService.getSessionStore('idProposta')
                  : this.storageEncryptService.getSessionStore('idSimulation');
              this.storageEncryptService.setSessionStore('userData', JSON.stringify(response));
              this.storageEncryptService.setSessionStore('token_logged', JSON.stringify(response));
              this.storageEncryptService.setSessionStore('renovado', JSON.stringify(true));
              this.store$.dispatch(new fromProposal.actions.SetSimulationID({ simulationId: idSimulacao}));
              this.store$.dispatch(new fromProposal.actions.SetIsLoggedSecondScreen(true));
              this.router.navigate(['/dados-proposta']);
            });
        }
      }
    });
  }
  
}
