import { ConsultActionTypes, ConsultActions } from './consult.actions';

export interface ConsultProposallState {
  borrowerData: { name: string; telephoneNumber: string; documentNumber: string;} | null;
};

export const initialState: ConsultProposallState = {
  borrowerData: null
};

export function consultReducer(state = initialState, action: ConsultActions) {
  switch (action.type) {
    case ConsultActionTypes.BORROWER_VERIFICATION_SUCESS: {
      return {
        ...state,
        borrowerData: action.payload.borrowerData
      };
    }

    default:
      return state;
  }
}
