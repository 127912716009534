import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { NgxCurrencyModule } from 'ngx-currency';
import { FormValidationService } from '@app/shared/services/form-validation.service';
import { SharedModule } from '@app/shared/shared.module';
import { SimulationRoutingModule } from './simulation-routing.module';
import { FormPersonalDataComponent } from './personal-data/components/form-personal-data/form-personal-data.component';
import { PersonalDataContainerComponent } from './personal-data/personal-data.container';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormOtherParticipantComponent } from './personal-data/components/form-other-participant/form-other-participant.component';
import { SimulationResultContainerComponent } from './simulation-result/simulation-result.container';
import { FormSimulationResultComponent } from './simulation-result/components/form-simulation-result/form-simulation-result.component';
import { FormConditionResultComponent } from './simulation-result/components/form-condition-result/form-condition-result.component';
import { SimulationSummaryComponent } from './simulation-result/components/simulation-summary/simulation-summary.component';
import { ProposalDataContainerComponent } from './proposal-data/proposal-data.container';
import { FormProposalDataComponent } from './proposal-data/components/form-proposal-data/form-proposal-data.component';
import { ProposalSummaryContainerComponent } from './proposal-summary/proposal-summary.container';
import { BtnExtractSimulationComponent } from './simulation-result/components/btn-extract-simulation/btn-extract-simulation.component';
import { AuthGuard } from '@app/core/auth/auth.guard';
import { SharedModule as SharedModuleCliente } from './../shared/shared.module';
import { SimulationComponent } from '@projects/cliente/src/app/simulation/simulation.component';
import { HeaderProposalSummaryComponent } from './proposal-summary/components/header-proposal-summary/header-proposal-summary.component';
import { SimulationGuard } from '@app/core/simulation/simulation.guard';
import { FormDadosProponenteComponent } from './proposal-summary/components/form-dados-proponente/form-dados-proponente.component';
import { HomeContainerComponent } from './home/home.container';
import { MiddlewareTokenOriginationComponent } from './middleware-token-origination/middleware-token-origination.component';
import { MiddlewareHomeComponent } from './middleware-home/middleware-home.component';
import { ConfirmacaoSmsContainerComponent } from './confirmacao-sms/confirmacao-sms.container';
import { ValidatedTokenSmsGuard } from '@app/core/simulation/validatedTokenSms.guard';
import { MiddlewareProposalSumaryComponent } from './middleware-proposal-sumary/middleware-proposal-sumary.component';
import { LoadingModule } from '@shared/widgets/loading/loading.module';

@NgModule({
  declarations: [
    FormPersonalDataComponent,
    PersonalDataContainerComponent,
    FormOtherParticipantComponent,
    FormConditionResultComponent,
    FormSimulationResultComponent,
    FormProposalDataComponent,
    ProposalDataContainerComponent,
    SimulationResultContainerComponent,
    SimulationSummaryComponent,
    ProposalSummaryContainerComponent,
    BtnExtractSimulationComponent,
    SimulationComponent,
    HeaderProposalSummaryComponent,
    FormDadosProponenteComponent,
    HomeContainerComponent,
    MiddlewareTokenOriginationComponent,
    MiddlewareHomeComponent,
    ConfirmacaoSmsContainerComponent,
    MiddlewareProposalSumaryComponent
  ],
  imports: [
    CommonModule,
    SimulationRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgxMaskModule,
    SharedModuleCliente,
    NgxMaskModule.forRoot(),
    NgxCurrencyModule,
    NgSelectModule,
    LoadingModule
  ],
  providers: [AuthGuard, SimulationGuard, ValidatedTokenSmsGuard]
})
export class SimulationModule {}
