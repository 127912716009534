<div class="formulario">
    <div class="container">
      <div class="row">
        <div class="col">
          <h2>{{ 'modules.simulation.proposal-data.nice' | translate }}</h2>
          <h2 class="sub">{{ 'modules.simulation.proposal-data.now tell me more about the property' | translate }}</h2>
        </div>
      </div>
      <form action="" [formGroup]="formProposalData">
        <fieldset>
          <div class="form-row">
            <div class="form-group col">
              <label for="">{{ 'Qual seu objetivo?' | translate }}</label>
            </div>
          </div>
          <div class="form-row mb-3" *ngFor="let o of loanObjectives; let i = index">
            <div class="form-group form-group-radio col">
              <input
                [value]="o.loanObjectiveId"
                [id]="'objetivo' + (i + 1)"
                type="radio"
                formControlName="loanTypeKey"
                (change)="onFieldChanged('loanTypeKey')"
              />
              <label [for]="'objetivo' + (i + 1)" class="label-radio"
              [ngClass]="{'font-weight-bold text-dark': formProposalData.get('loanTypeKey').value === o.loanObjectiveId, 'desfoc': formProposalData.get('loanTypeKey').value !== o.loanObjectiveId && formProposalData.get('loanTypeKey').value !== null}">{{ o.loanObjectiveDescription | titlecase }}</label>
              <app-helper-button appHelperTooltip [helperName]="o.tooltipCode"></app-helper-button>
            </div>
          </div>
        </fieldset>

        <fieldset *ngIf="submitedContractList">
          <div class="form-row">
            <div class="form-group col">
              <!-- Contrat Number -->
              <app-input-text-container
                [label]="'Número do contrato' | translate"
                helperName="contractNumber"
                [isLabelStrong]="false"
                [isEditable]="singleContract"
                (clickEdit)="onClickEdit()"
              >
                <input
                  type="text"
                  placeholder="Digite"
                  formControlName="codigoContrato"
                  id="contractNumber"
                />
              </app-input-text-container>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col">
              <!-- Operation Type -->
              <app-input-text-container
                [label]="'Tipo de operação' | translate"
                helperName="operationType"
                [isLabelStrong]="false"
              >
                <input
                  type="text"
                  placeholder="Digite"
                  formControlName="descricaoObjetivoFinanciamento"
                  id="operationType"
                />
              </app-input-text-container>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col">
              <!-- Address -->
              <app-input-text-container
                [label]="'Endereço' | translate"
                helperName="address"
                [isLabelStrong]="false"
              >
                <input
                  type="text"
                  placeholder="Digite"
                  formControlName="endereco"
                  id="address"
                />
              </app-input-text-container>
            </div>
          </div>
        </fieldset>

        <fieldset [ngClass]="{ 'd-none': !isVisible('portabilityTypeKey') }">
          <div class="form-row">
            <div class="form-group col">
              <label for="">{{ 'modules.simulation.proposal-data.what do you want to do' | translate }}</label>
            </div>
          </div>
          <div class="form-row mb-3" *ngFor="let p of portabilityTypes; let ip = index">
            <div class="form-group form-group-radio col">
              <input
                [value]="p.key"
                [id]="'portabilidade' + (ip + 1)"
                type="radio"
                formControlName="portabilityTypeKey"
                (change)="onFieldChanged('portabilityTypeKey')"
              />
              <label [for]="'portabilidade' + (ip + 1)" class="label-radio"
              [ngClass]="{'font-weight-bold text-dark': formProposalData.get('portabilityTypeKey').value === p.key, 'desfoc': formProposalData.get('portabilityTypeKey').value !== p.key && formProposalData.get('portabilityTypeKey').value !== null}">{{ p.description | titlecase }}</label>
            </div>
          </div>
        </fieldset>

        <!-- Property Type -->
        <fieldset *ngIf="isVisible('buildingTypeKey')">
          <div class="form-row">
            <div class="form-group col">
              <label for="">{{ 'modules.simulation.proposal-data.type of property' | translate }}</label>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group form-group-radio col-12 col-md-4" *ngFor="let b of buildingTypes; let i = index">
              <div *ngIf="(b.key !== 'N')">
                  <input [value]="b.key" [id]="'tipoImovel' + (i + 1)" type="radio" formControlName="buildingTypeKey"
                  (change)="onFieldChanged('buildingTypeKey')"/>
                  <label [for]="'tipoImovel' + (i + 1)" class="label-radio">{{ b.description }}</label>
              </div>
              <!--USE TERRENO-->
              <div *ngIf="(b.key === 'N' && selectedLoanTypeKey === 4)">
                  <input [value]="b.key" [id]="'tipoImovel' + (i + 1)" type="radio" formControlName="buildingTypeKey"
                  (change)="onFieldChanged('buildingTypeKey')"/>
                  <label [for]="'tipoImovel' + (i + 1)" class="label-radio">{{ b.description }}</label>
              </div>
            </div>
          </div>
        </fieldset>

        <fieldset *ngIf="isVisible('quizResponseCode')">
          <div class="form-row">
            <div class="form-group col">
              <app-input-text-container
                [label]="'modules.simulation.proposal-data.when you intend to get the property' | translate">
                <ng-select appNgSelectTab id="dataAquisicao" formControlName="quizResponseCode" bindLabel="description"
                  bindValue="key" [items]="estimatedOperatingPeriods" [clearable]="false" [selectOnTab]="true"
                  (change)="onFieldChanged('quizResponseCode' , $event)"></ng-select>
              </app-input-text-container>
            </div>
          </div>
        </fieldset>

        <fieldset *ngIf="isVisible('propertyLocationKey') || showPropertyLocationKey">
          <div class="form-row">
            <div class="form-group col">
              <!-- Property Location -->
              <app-input-text-container
                [label]="'modules.simulation.proposal-data.what is the location of the property' | translate">
                <ng-select appNgSelectTab id="selectUfLicIdentificacao" formControlName="propertyLocationKey"
                  bindLabel="key" bindValue="key" [clearable]="false" [selectOnTab]="true" [items]="federalStates"
                  (change)="onFieldChanged('propertyLocationKey' , $event)">
                </ng-select>
              </app-input-text-container>
            </div>
          </div>
        </fieldset>

        <fieldset *ngIf="isVisible('propertyValue')">
          <div class="form-row">
            <div class="form-group col">
              <!-- Property Value -->
              <app-input-text-container
                [label]="'modules.simulation.proposal-data.what is the price of the property' | translate"
                [minValue]="minPropertyValue" formatMinMax="currency">
                <input type="tel" placeholder="R$" formControlName="propertyValue" id="vlrdoImovel" maxlength="16"
                  currencyMask (blur)="onFieldChanged('propertyValue')" />
              </app-input-text-container>
            </div>
          </div>
        </fieldset>

        <fieldset *ngIf="isVisible('evaluationPrice')">
          <div class="form-row">
            <div class="form-group col">
              <!-- Evaluation Value -->
              <app-input-text-container [label]="'modules.simulation.proposal-data.evaluation price' | translate"
                [minValue]="minEvaluationValue" [acceptZero]="false" [helperName]="'valorAvaliacao'"
                formatMinMax="currency">
                <input type="tel" placeholder="R$" formControlName="evaluationPrice" id="vlrdeAvaliacao" maxlength="16"
                  currencyMask (blur)="onFieldChanged('evaluationPrice')" />
              </app-input-text-container>
            </div>
          </div>
        </fieldset>

        <fieldset *ngIf="isVisible('loanAmountValue')">
          <div class="form-row">
            <div class="form-group col">
              <!-- Loan Value -->
              <app-input-text-container
                [label]="label | translate"
                [minValue]="minLoanAmountValue" [maxValue]="maxLoanAmountValue" [helperName]="'loanAmountValue'"
                formatMinMax="currency">
                <input type="tel" placeholder="R$" formControlName="loanAmountValue" maxlength="16" id="vlFinanciar"
                  currencyMask (blur)="onFieldChanged('loanAmountValue')" />
              </app-input-text-container>
            </div>
          </div>
        </fieldset>

        <fieldset *ngIf="isVisible('termInYears')">
          <div class="form-row">
            <div class="form-group col">
              <!-- Deadline -->
              <app-input-text-container [label]="'Qual o prazo em anos?' | translate" [minValue]="minTermInYears"
                [maxValue]="maxTermInYears" [labelMinValue]="'common.label minimum value' | translate"
                [labelMaxValue]="'common.label maximum value' | translate" helperName="financingMonthPeriod">
                <input type="tel" placeholder="Digite" formControlName="termInYears" autocomplete="off"
                  id="vlrPrazosemAnos" maxlength="15" [patterns]="customPatterns" [validation]="false"
                  (blur)="onFieldChanged('termInYears')" />
              </app-input-text-container>
            </div>
          </div>
        </fieldset>

        <!-- Deferred Payment -->
        <div *ngIf="deferredPayment?.showDeferredPaymentBlock">
          <fieldset>
            <div class="form-row">
              <div class="form-group col">
                <app-input-text-container
                  [helperName]="isComercial('buildingTypeKey') ? 'deferredPaymentCom' : 'deferredPaymentRes'"
                  [label]="'modules.simulation.proposal-data.deferredPayment' | translate">
                  <ng-select appNgSelectTab id="modalidadeCarencia" formControlName="deferredPaymentKey" bindValue="key"
                    bindLabel="description" [selectOnTab]="true" [clearable]="false"
                    [items]="deferredPayment?.deferredPaymentOptions" (change)="onFieldChanged('deferredPaymentKey')">
                  </ng-select>
                </app-input-text-container>
              </div>
            </div>
          </fieldset>

          <fieldset [ngClass]="{ 'd-none': !isDeferredPaymentValue() }">
            <div class="form-row">
              <div class="form-group col">
                <app-input-text-container [label]="'modules.simulation.proposal-data.paymentAmout' | translate">
                  <ng-select appNgSelectTab id="parcelaCarencia" formControlName="monthsDeferredPaymentQt"
                    [selectOnTab]="true" [items]="arrCarencia" [selectOnTab]="true"
                    (change)="onFieldChanged('monthsDeferredPaymentQt')">
                  </ng-select>
                </app-input-text-container>
              </div>
            </div>
          </fieldset>
        </div>

        <fieldset *ngIf="isVisible('isTransferTaxInClosingCostCredit')">
          <div class="form-row">
            <div class="form-group col">
              <!-- Itbi -->
              <app-input-radio-container [label]="
                  'modules.simulation.proposal-data.wish you to financing the ITBI tax and register (5% of the price of the property)'
                    | translate
                " helperName="isTransferTaxInClosingCostCredit">
                <div class="form-row">
                  <div class="form-group form-group-radio col">
                    <input type="radio" id="financiarDespesas1" formControlName="isTransferTaxInClosingCostCredit"
                      [value]="true" (change)="onFieldChanged('isTransferTaxInClosingCostCredit')" />
                    <label for="financiarDespesas1" class="label-radio">{{ 'common.yes' | translate }}</label>
                  </div>
                  <div class="form-group form-group-radio col">
                    <input type="radio" id="financiarDespesas3" formControlName="isTransferTaxInClosingCostCredit"
                      [value]="false" (change)="onFieldChanged('isTransferTaxInClosingCostCredit')" />
                    <label for="financiarDespesas3" class="label-radio">{{ 'common.no' | translate }}</label>
                  </div>
                </div>
              </app-input-radio-container>
            </div>
          </div>
        </fieldset>

        <fieldset *ngIf="isVisible('isIOFIncludedInClosingCostCredit')">
          <div class="form-row">
            <div class="form-group col">
              <!-- Iof -->
              <app-input-radio-container
                [label]="'modules.simulation.proposal-data.wish you to financing the IOF' | translate"
                helperName="isIOFIncludedInClosingCostCredit">
                <div class="form-row">
                  <div class="form-group form-group-radio col">
                    <input type="radio" id="financiarDespesas2" formControlName="isIOFIncludedInClosingCostCredit"
                      [value]="true" (change)="onFieldChanged('isIOFIncludedInClosingCostCredit')" />
                    <label for="financiarDespesas2" class="label-radio">{{ 'common.yes' | translate }}</label>
                  </div>
                  <div class="form-group form-group-radio col">
                    <input type="radio" id="financiarDespesas4" formControlName="isIOFIncludedInClosingCostCredit"
                      [value]="false" (change)="onFieldChanged('isIOFIncludedInClosingCostCredit')" />
                    <label for="financiarDespesas4" class="label-radio">{{ 'common.no' | translate }}</label>
                  </div>
                </div>
              </app-input-radio-container>
            </div>
          </div>
        </fieldset>

        <fieldset *ngIf="isVisible('isAppraisalfeesInClosingCostCredit')">
          <div class="form-row">
            <div class="form-group col">
              <!-- Evaluation Guaranteed -->
              <div *ngIf="selectedLoanTypeKey !== 55 && !isencaoTarifaUseCasa">
                  <app-input-radio-container [label]="
                      'modules.simulation.proposal-data.wish you to financing the evaluation of guaranteed' | translate
                  " helperName="isAppraisalfeesInClosingCostCredit">
                  <div class="form-row">
                      <div class="form-group form-group-radio col">
                      <input type="radio" id="financiarGarantiaSim" formControlName="isAppraisalfeesInClosingCostCredit"
                          [value]="true" (change)="onFieldChanged('isAppraisalfeesInClosingCostCredit')" />
                      <label for="financiarGarantiaSim" class="label-radio">{{ 'common.yes' | translate }}</label>
                      </div>
                      <div class="form-group form-group-radio col">
                      <input type="radio" id="financiarGarantiaNao" formControlName="isAppraisalfeesInClosingCostCredit"
                          [value]="false" (change)="onFieldChanged('isAppraisalfeesInClosingCostCredit')" />
                      <label for="financiarGarantiaNao" class="label-radio">{{ 'common.no' | translate }}</label>
                      </div>
                  </div>
                  </app-input-radio-container>
              </div>

              <!--ISENÇÃO DE TARIFA DE AVALIAÇÃO DE GARANTIA EMPRESTIMO USE CASA-->
              <div *ngIf="isencaoTarifaUseCasa && selectedLoanTypeKey !== 55">
                  <label>{{ 'modules.simulation.proposal-data.wish you to financing the evaluation of guaranteed' | translate }}</label>
                  <p class="p-isencao">Boa notícia, devido ao valor de empréstimo, não será cobrada a tarifa de avaliação da garantia. Aproveite esta condição!</p>
              </div>

              <!--ISENÇÃO DE TARIFA DE AVALIAÇÃO DE GARANTIA RENOVADO-->
              <div *ngIf="selectedLoanTypeKey === 55">
                  <label>{{ 'modules.simulation.proposal-data.wish you to financing the evaluation of guaranteed' | translate }}</label>
                  <p class="p-isencao">Boa notícia: Não será cobrada a tarifa de avaliação da garantia. Aproveite essa condição!</p>
              </div>

            </div>
          </div>
        </fieldset>

        <div class="mt-5 d-flex justify-content-center" *ngIf="recaptchaOn">
          <re-captcha formControlName="recaptcha" name="recaptcha" id="g-recaptcha-response" [siteKey]="siteKey">
          </re-captcha>
        </div>

        <div class="group-buttons">
          <button type="button" class="button-default" (click)="onSubmitFormProposalData()" id="btnSubmitPreAnalise">
            {{ 'button.simulate' | translate }}
          </button>
        </div>
      </form>

      <div class="d-flex justify-content-center">
          <div class="termos-uso">
              <p class="d-flex justify-content-center mb-0"> {{ 'footer.By clicking Simulate, you agree to our' | translate }} </p>
              <a href="https://www.santander.com.br/institucional-santander/seguranca/politica-de-privacidade"
              (click)="termsUseEvent()"
              target="_blank">
                  {{ 'footer.Terms of Use and Privacy Policy and Cookies Policy' | translate }}
              </a>
          </div>
      </div>

      <div class="group-buttons">
        <button type="button" type="button" class="button-back" (click)="onBack()" id="buttonVoltar">
          <span class="material-icons">keyboard_arrow_left</span>
          <strong>{{ 'button.back' | translate }}</strong>
        </button>
      </div>
    </div>
  </div>
