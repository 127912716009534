import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of, merge, Observable } from 'rxjs';
import { catchError, map, switchMap, delay, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoginService } from './../../../../projects/cliente/src/app/login/services/login.service';
import { UserData } from './../../../../projects/cliente/src/app/login/models/user.model';
import { AuthService } from '@app/core/auth/services/auth.service';
import { ErrorService } from '@app/shared/services/error.service';
import { environment } from 'src/environments/environment';

import * as fromErrorHandling from './../error-handling';
import * as fromProposal from './../proposal';
import * as actions from './login.actions';
import * as models from './../../../../projects/cliente/src/app/login/models/integration.model';
import { StorageEncryptService } from '@shared/services/storageEncrypt.service';
import { AuthServices } from 'arch-onefinancial-auth.lib';

@Injectable()
export class LoginEffects {
  constructor(
    private actions$: Actions,
    private loginService: LoginService,
    private authService: AuthService,
    private router: Router,
    private errorService: ErrorService,
    private storageEncryptService: StorageEncryptService,
    private authServices: AuthServices
  ) { }

  @Effect()
  login$ = this.actions$.pipe(
    ofType<actions.Login>(actions.LoginActionsTypes.LOGIN),
    switchMap(action =>
      this.loginService.login(action.payload.form).pipe(
        map(response => {
          this.loginService.setUser({
            access_token: response.access_token,
            refresh_token: response.refresh_token,
            user_name: action.payload.form.name
          });

          return new actions.LoginSuccess({ username: action.payload.form.name.toUpperCase() });
        }),
        catchError(() => setLoginError('errorMessages.Login Error. Please verify user and password.'))
      )
    )
  );
  @Effect()
  loginWithoutSSO$ = this.actions$.pipe(
    ofType<actions.LoginWithoutSSO>(actions.LoginActionsTypes.LOGIN_WITHOUT_SSO),
    switchMap(action => {
      const nome = action.payload.nome;
      const senha = action.payload.senha;
      return this.authServices.loginWithoutSSO(nome, senha).pipe(
        switchMap((res: any) => {
          this.storageEncryptService.setSessionStore('userData', JSON.stringify(res));
          const userName: any = this.authServices.getUserInfo();
          return [
            new actions.LoginWithoutSSOSuccess(userName.sub,),
            new fromProposal.actions.SetIsLoggedSecondScreen(true),
            new actions.LoginSucess({ isLogged: true })
          ]
        }),
        catchError(err => {
          if (err.extensions) {
            return of([
              new fromErrorHandling.actions.AddError(this.errorService.addGraphQLAlert(err)),
              new actions.LoginSucess({ isLogged: false })
            ]);
          }

          return of(new fromErrorHandling.actions.AddError(this.errorService.addEmptyAlert('USER_ERROR')));
        })
      )
    })
  );

  @Effect()
  loginCipher$ = this.actions$.pipe(
    ofType<actions.LoginCipher>(actions.LoginActionsTypes.LOGIN_CIPHER),
    switchMap(action =>
      this.loginService.loginCipher(action.payload.form).pipe(
        switchMap(response => {
          this.loginService.setUser({
            access_token: response.access_token,
            refresh_token: response.refresh_token,
            user_name: action.payload.form.name.toUpperCase()
          });

          return [
            new actions.LoginCipherSuccess({ username: action.payload.form.name.toUpperCase() }),
            new fromProposal.actions.SetIsLoggedSecondScreen(true)
          ];
        }),
        catchError(err => {
          if (err.extensions) {
            return of(new fromErrorHandling.actions.AddError(this.errorService.addGraphQLAlert(err)));
          }

          return of(new fromErrorHandling.actions.AddError(this.errorService.addEmptyAlert('USER_ERROR')));
        })
      )
    )
  );

  @Effect()
  loginPublic$ = this.actions$.pipe(
    ofType<actions.LoginPublic>(actions.LoginActionsTypes.LOGIN_PUBLIC),
    switchMap(() =>
      this.loginService.loginPublic().pipe(
        map(response => {
          this.loginService.setUser(response);
          return new actions.LoginSuccess({ username: null });
        }),
        catchError(() => setLoginError('errorMessages.Login Error. Please verify user and password.'))
      )
    )
  );

  @Effect()
  integrationPublic$ = this.actions$.pipe(
    ofType<actions.IntegrationPublic>(actions.LoginActionsTypes.INTEGRATION_PUBLIC),
    switchMap(() =>
      this.loginService.loginPublic().pipe(
        switchMap(response => {
          const tokenPP = this.storageEncryptService.getSessionStore('userData');
          const history = JSON.parse(this.storageEncryptService.getSessionStore(models.HISTORY)) || [];
          const urlOrigin = getUrlOriginBack(history);

          this.loginService.setUser(response);

          return [
            new actions.IntegrationSuccess({
              username: null,
              urlRequest: { url: this.storageEncryptService.getSessionStore('urlRequest') },
              tokenPP,
              urlOrigin
            }),
            new actions.ClearIntegrationData()
          ];
        }),
        catchError(() => {
          return setLoginError('errorMessages.Integration Error. Please go back to the original site and try again.');
        })
      )
    )
  );

  @Effect()
  integration = this.actions$.pipe(
    ofType<actions.Integration>(actions.LoginActionsTypes.INTEGRATION),
    switchMap(action =>
      this.loginService.refresh(action.payload.integration.refreshToken).pipe(
        switchMap(response => {
          const tokenPP = this.storageEncryptService.getSessionStore(models.TOKEN_SIM)
            ? this.storageEncryptService.getSessionStore('token')
            : '';
          const history = JSON.parse(this.storageEncryptService.getSessionStore(models.HISTORY)) || [];
          const urlOrigin = getUrlOriginBack(history);

          this.storageEncryptService.removeSession('token');

          this.loginService.setUser({
            access_token: response.access_token,
            refresh_token: response.refresh_token,
            user_name: action.payload.integration.parameters.username
          });

          return [
            new actions.IntegrationSuccess({
              username: action.payload.integration.parameters.username.toUpperCase(),
              urlRequest: { url: this.storageEncryptService.getSessionStore('urlRequest') },
              tokenPP,
              urlOrigin
            }),
            new actions.ClearIntegrationData()
          ];
        }),
        catchError(err => {
          return setLoginError('errorMessages.Integration Error. Please go back to the original site and try again.');
        })
      )
    )
  );

  @Effect()
  loginRemoveAlert = this.actions$.pipe(
    ofType<actions.LoginRemoveAlert>(actions.LoginActionsTypes.LOGIN_REMOVE_ALERT),
    map(action => new actions.LoginRemoveAlertSuccess({ id: action.payload.id }))
  );

  // @Effect()
  // logout = this.actions$.pipe(
  //   ofType<actions.Logout>(actions.LoginActionsTypes.LOGOUT),
  //   switchMap(() =>
  //     this.loginService.logout().pipe(
  //       map(() => new actions.LogoutRevoke()),
  //       catchError(err => {
  //         if (err.extensions) {
  //           return of(new actions.LogoutError(this.alertService.addGraphQLAlert(err.message)));
  //         }
  //         return of(new actions.LogoutError(this.alertService.addAlertError(err)));
  //       })
  //     )
  //   )
  // );

  @Effect()
  logoutRevoke = this.actions$.pipe(
    ofType<actions.LogoutRevoke>(actions.LoginActionsTypes.LOGOUT_REVOKE),
    switchMap(() =>
      this.loginService.logoutRevoke().pipe(
        map(() => {
          const userData: UserData = this.loginService.getUser();
          if (userData) this.loginService.clearUser();
          return new actions.Redirect();
        })
      )
    )
  );

  @Effect({ dispatch: false })
  clearIntegrationData = this.actions$.pipe(
    ofType<actions.ClearIntegrationData>(actions.LoginActionsTypes.CLEAR_INTEGRATION_DATA),
    tap(() => {
      this.storageEncryptService.removeSession(models.USER);
      this.storageEncryptService.removeSession(models.TOKEN_SIM);
      this.storageEncryptService.removeSession(models.URL_REQUEST);
    })
  );

  @Effect({ dispatch: false })
  resolvePendingRequests = this.actions$.pipe(
    ofType<actions.ResolvePendingRequests>(actions.LoginActionsTypes.RESOLVE_PENDING_REQUESTS),
    map(() => this.authService.resolvePendingRequests())
  );

  @Effect({ dispatch: false })
  redirect = this.actions$.pipe(
    ofType<actions.Redirect>(actions.LoginActionsTypes.REDIRECT),
    map(() => this.authServices.backToSSOLogin())
  );

  /**
   * Redirect user to URL
   */
  @Effect({ dispatch: false })
  redirectRenovado = this.actions$.pipe(
    ofType<actions.RedirectRenovado>(actions.LoginActionsTypes.REDIRECT_RENOVADO),
    tap(action => this.router.navigate([action.payload.url]))
  );
}

function getUrlOriginBack(history: Array<string>) {
  const res = history.find(u => {
    return u.indexOf(environment.urlPortalCliente) !== -1;
  });

  return res || environment.urlPortalCliente;
}

function setLoginError(message: string): Observable<any> {
  const id = new Set();

  return merge(
    of(new actions.IntegrationError({ id, message })),
    of(new actions.LoginRemoveAlert({ id })).pipe(delay(10000))
  );
}
