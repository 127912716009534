import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { calculateProposalValuesRange_calculateProposalValuesRange_deferredPayment } from '@shared/graphql/types/query-types';
import { DataLayerSantanderService } from '@projects/cliente/src/app/shared/service/dataLayer/novo-data-layer.service';
import { StorageEncryptService } from '@shared/services/storageEncrypt.service';
@Component({
  selector: 'app-form-proposal-data',
  templateUrl: './form-proposal-data.component.html',
  styleUrls: ['./form-proposal-data.component.scss']
})
export class FormProposalDataComponent implements OnChanges {
  @Input() formProposalData: FormGroup;
  @Input() loanObjectives: Array<any>;
  @Input() codigoDistrito: Array<any>;
  @Input() buildingTypes: Array<any>;
  @Input() federalStates: Array<any>;
  @Input() singleContract: boolean;
  @Input() objetivoFinanciamento: Array<any>;
  @Input() tipoUtilizacaoImovel: Array<any>;
  @Input() deferredPayment: calculateProposalValuesRange_calculateProposalValuesRange_deferredPayment;
  @Input() arrCarencia: Array<any>;
  @Input() estimatedOperatingPeriods: Array<any>;
  @Input() visibleFields: Array<any>;
  @Input() editableFields: Array<any>;
  @Input() portabilityTypes: Array<any>;
  @Input() minPropertyValue: number;
  @Input() minEvaluationValue: number;
  @Input() minTermInYears: number;
  @Input() maxTermInYears: number;
  @Input() minLoanAmountValue: number;
  @Input() maxLoanAmountValue: number;
  @Input() selectedLoanTypeKey: any;
  @Input() flagPortability: string;
  @Input() tipoImovelState: string;
  @Input() loanTypes: any;
  @Input() loanAmountLabel: string;
  @Input() label;
  @Input() termInMonthsLabel: string;
  @Input() showPropertyLocationKey: boolean;
  @Input() isSimulationInProgress: boolean;
  @Input() allowCarencia;
  @Input() submitedContractList: boolean;
  @Input() isencaoTarifaUseCasa: boolean;

  @Output() fieldChanged = new EventEmitter();
  @Output() submitFormProposalData = new EventEmitter();
  @Output() back = new EventEmitter();
  @Output() focusOutEvaluationValue = new EventEmitter();
  @Output() clickEdit = new EventEmitter();

  changesArray: { controlName: string, value: string, eventType: string }[] = [];

  customPatterns = {
    C: { pattern: new RegExp('[1-9]') },
    0: { pattern: new RegExp('[0-9]') }
  };

  isencaoTarifaAvaliacao = environment.featureToggle.isencaoTarifaAvaliacao;

  siteKey = environment.recaptcha;
  size: string;
  theme: string;
  type: string;
  recaptchaOn = environment.featureToggle.recaptchaOn === 'false' ? false : true;

  productName = null;

  constructor(private dataLayerSantander: DataLayerSantanderService, private storageEncryptService: StorageEncryptService,
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isSimulationInProgress && changes.isSimulationInProgress.currentValue === false) {
      this.formProposalData.disable();
    }
  }
  /**
   * Check if buildingTypeKey is type of property
   */
  isComercial(fieldName: string): boolean {
    return this.formProposalData.get(fieldName).value === 2;
  }

  /**
   * Check visible fields
   */
  isVisible(fieldName: string): boolean {
    if (!this.visibleFields) return;

    const exceptions = [];
    const field = this.formProposalData.get(fieldName);
    const isFieldVisible = this.visibleFields.find(f => f === fieldName);
    const isFieldEditable = this.editableFields.find(f => f === fieldName);
    const fieldState = isFieldEditable && this.isSimulationInProgress ? 'enable' : 'disable';

    if (fieldName === 'propertyLocationKey' && this.showPropertyLocationKey) {
      return;
    }

    if (isFieldVisible && fieldName === 'portabilityTypeKey' && this.flagPortability && this.isSimulationInProgress) {
      field.disable();
      return true;
    }

    if (fieldName === 'isAppraisalfeesInClosingCostCredit' && this.isencaoTarifaUseCasa) {
      return true;
    }

    if (isFieldVisible && fieldName === 'buildingTypeKey' && this.tipoImovelState && this.isSimulationInProgress) {
      this.formProposalData.get('buildingTypeKey').disable();
      return true;
    }

    if (field && !isFieldVisible) {
      field.disable();
      return false;
    }

    if (fieldName === 'buildingTypeKey' && this.selectedLoanTypeKey === 55) {
      field.disable();
      return true;
    }

    if (field && !exceptions.includes(fieldName)) {
      field[fieldState]();
    }

    return true;
  }

  isDeferredPaymentValue() {
    if (
      this.formProposalData &&
      this.formProposalData.get('deferredPaymentKey').value &&
      this.formProposalData.get('deferredPaymentKey').value !== 'N'
    ) {
      this.formProposalData.get('monthsDeferredPaymentQt').enable();
      return true;
    } else {
      this.formProposalData.get('monthsDeferredPaymentQt').disable();
      return false;
    }
  }

  termsUseEvent() {
    this.sendToClickEvent(false, 'clicou', 'link:termos-uso', this.productName)
  }

  getValuesAndSendClickEvent(controlName, event?) {
    const controlNameValue = this.formProposalData.get(controlName).value;

    const valueTranslateRadio = this.translateValuesRadio(controlName, controlNameValue);
    const valueTranslateField = this.translateValuesField(controlName);
    const valueTranslateSelect = this.translateValuesSelect(controlName, event?.description);

    if (valueTranslateRadio) this.updateChangesArray(controlName, 'radio:' + valueTranslateRadio, 'selecionou');
    if (valueTranslateField) this.updateChangesArray(controlName, 'input:' + valueTranslateField, 'preencheu');
    if (valueTranslateSelect) this.updateChangesArray(controlName, 'dropdown:' + valueTranslateSelect, 'selecionou');
  }

  updateChangesArray(controlName: string, value: string, eventType: string) {
    const index = this.changesArray.findIndex(item => item.controlName === controlName);

    if (index !== -1) {
      // Substituir pelo novo valor se já existe
      this.changesArray[index] = { controlName, value, eventType };
    } else {
      // Adicionar novo item ao array
      this.changesArray.push({ controlName, value, eventType });
    }
  }

  sendToClickEvent(nonInterection, eventAction, eventLabel, productName?) {
    this.dataLayerSantander.sendCustomToDataLayer(
      nonInterection,
      eventAction,
      eventLabel,
      productName
    );
  }

  isProductName() {
    return this.formProposalData.get('loanTypeKey').value;
  }

  triggerEvents() {
    let productName = this.storageEncryptService.getSessionStore('productName');

    if (!productName) {
      this.transforProductName(this.isProductName());
    }

    this.changesArray.forEach((change) => {
      this.sendToClickEvent(false, change.eventType, change.value, productName);
    });

    // Limpar o array após disparar os eventos
    this.changesArray = [];
  }

  onFieldChanged(controlName: string, event?): void {
    this.getValuesAndSendClickEvent(controlName, event);

    this.fieldChanged.emit({ value: this.formProposalData.controls[controlName].value, controlName });
    if (controlName === 'loanTypeKey') {
      this.formProposalData.get('propertyLocationKey').setValue(null);
      this.fieldChanged.emit({
        value: this.formProposalData.controls[`propertyLocationKey`].value,
        controlName: 'propertyLocationKey'
      });
      this.formProposalData.get('loanAmountValue').setValue(null);
      this.fieldChanged.emit({
        value: this.formProposalData.controls[`loanAmountValue`].value,
        controlName: 'loanAmountValue'
      });
      this.formProposalData.get('termInYears').setValue(null);
      this.fieldChanged.emit({
        value: this.formProposalData.controls[`termInYears`].value,
        controlName: 'termInYears'
      });
      this.formProposalData.get('isAppraisalfeesInClosingCostCredit').setValue(null);
      this.fieldChanged.emit({
        value: this.formProposalData.controls[`isAppraisalfeesInClosingCostCredit`].value,
        controlName: 'isAppraisalfeesInClosingCostCredit'
      });
      this.formProposalData.get('isIOFIncludedInClosingCostCredit').setValue(null);
      this.fieldChanged.emit({
        value: this.formProposalData.controls[`isIOFIncludedInClosingCostCredit`].value,
        controlName: 'isIOFIncludedInClosingCostCredit'
      });
      this.formProposalData.get('isTransferTaxInClosingCostCredit').setValue(null);
      this.fieldChanged.emit({
        value: this.formProposalData.controls[`isTransferTaxInClosingCostCredit`].value,
        controlName: 'isTransferTaxInClosingCostCredit'
      });
    }
  }

  transforProductName(fieldNameValue) {
    let translatedFieldName: string;
    if (fieldNameValue === 4) {
      translatedFieldName = 'emprestimo-use-casa';
    } else if (fieldNameValue === 3) {
      translatedFieldName = 'financiamento-imovel';
    } else if (fieldNameValue === 46) {
      translatedFieldName = 'portabilidade';
    }
    this.productName = translatedFieldName;
    this.storageEncryptService.setSessionStore('productName', this.productName);
  }

  translateValuesRadio(fieldName, fieldNameValue?) {
    let translatedFieldName: string;
    switch (fieldName) {
      case 'loanTypeKey':
        this.transforProductName(fieldNameValue);
        break;
      case 'buildingTypeKey':
        if (fieldNameValue === 'R') {
          translatedFieldName = 'residencial';
        } else if (fieldNameValue === 'C') {
          translatedFieldName = 'comercial';
        } else if (fieldNameValue === 'N') {
          translatedFieldName = 'terreno';
        }
        break;
      case 'isIOFIncludedInClosingCostCredit':
        if (fieldNameValue === true) {
          translatedFieldName = 'financiar-iof:sim';
        } else if (fieldNameValue === false) {
          translatedFieldName = 'financiar-iof:nao';
        }
        break;
      case 'isAppraisalfeesInClosingCostCredit':
        if (fieldNameValue === true) {
          translatedFieldName = 'financiar-tarifa-avaliacao-garantia:sim';
        } else if (fieldNameValue === false) {
          translatedFieldName = 'financiar-tarifa-avaliacao-garantia:nao';
        }
        break;
      case 'isTransferTaxInClosingCostCredit':
        if (fieldNameValue === true) {
          translatedFieldName = 'financiar-itbi-registro-imovel:sim';
        } else if (fieldNameValue === false) {
          translatedFieldName = 'financiar-itbi-registro-imovel::nao';
        }
        break;
    }
    return translatedFieldName;
  }

  translateValuesField(fieldName) {
    let translatedFieldName: string;
    switch (fieldName) {
      case 'termInYears':
        translatedFieldName = 'prazo-anos';
        break;
      case 'propertyValue':
        translatedFieldName = 'valor-imovel';
        break;
      case 'loanAmountValue':
        translatedFieldName = 'valor-financiar';
        break;
    }
    return translatedFieldName
  }
  translateValuesSelect(fieldName, descricao) {
    let translatedFieldName: string;
    switch (fieldName) {
      case 'quizResponseCode':
        if (descricao === 'EM ATÉ TRÊS MESES') {
          translatedFieldName = 'adquirir:ate-3-meses';
        } else if (descricao === 'EM ATÉ SEIS MESES') {
          translatedFieldName = 'adquirir:ate-6-meses';
        } else if (descricao === 'APÓS SEIS MESES') {
          translatedFieldName = 'adquirir:apos-6-meses';
        }
        break;
      case 'propertyLocationKey':
        translatedFieldName = 'localizacao:' + descricao.toLowerCase();
        break;
    }
    return translatedFieldName
  }

  /**
   * Focus out evaluationPrice
   */
  onFocusOutEvaluationValue() {
    this.focusOutEvaluationValue.emit();
  }

  /**
   * Emit "submitFormProposalData" event to [Proposal] container
   */
  onSubmitFormProposalData() {
    this.triggerEvents();
    this.sendToClickEvent(false, 'clicou', 'botao:simular', this.productName);
    this.submitFormProposalData.emit();
  }

  /**
   * Emit "back" event to [Proposa] container
   */
  onBack() {
    this.sendToClickEvent(false, 'clicou', 'botao:voltar', this.productName)
    this.back.emit();
  }

  /**
   * Emit onClick edit event
   */
  onClickEdit() {
    return this.clickEdit.emit();
  }
}
