<div class="simulation-result">
  <app-timeline [fase]="3" [nomeFase]="'Resultado Simulação'" [scrollPage]="85"> </app-timeline>

  <app-form-simulation-result *ngIf="simulationData && simulationDomains"
    [formSimulationResult]="formSimulationResult"
    [formConditionResult]="formConditionResult"
    [simulationData]="simulationData"
    [minTermInMonths]="minTermInMonths"
    [maxTermInMonths]="maxTermInMonths"
    [maxLoanAmountValue]="maxLoanAmountValue"
    [minLoanAmountValue]="minLoanAmountValue"
    [calculatedSimulationResult]="calculatedSimulationResult"
    [offerChosen]="simulationData.offerChosen"
    [visibleFields]="visibleFields"
    [termInYearsList]="termInYearsList"
    [simulationDomains]="simulationDomains"
    [isSimulationInProgress]="isSimulationInProgress"
    (fieldChanged)="fieldChanged($event)"
    (expensesValueChanged)="expensesValueChanged($event)"
    (save)="save()"
    [offerList]="offerList"
    [hasUmaOferta]="hasUmaOferta"
    [isUseCasaMais]="isUseCasaMais"
    (changesEmitted)="handleChangesEmittedSimulation($event)">
  </app-form-simulation-result>

  <app-form-condition-result *ngIf="simulationData && simulationDomains"
    [formConditionResult]="formConditionResult"
    [simulationDomains]="simulationDomains"
    [loanTypeName]="simulationData?.loanTypeName"
    [arrCarencia]="arrCarencia"
    [customPortfolioName]="simulationData?.customerPortfolioName"
    [hasCarencia]="hasCarencia"
    [isSimulationInProgress]="isSimulationInProgress"
    (fieldChanged)="fieldChanged($event)"
    (conditionChanged)="conditionChanged($event)"
    [isUseCasaMais]="isUseCasaMais"
    [isencaoTarifaUseCasa]="isencaoTarifaUseCasa"
    (changesEmitted)="handleChangesEmitted($event)"
    >
  </app-form-condition-result>

  <app-simulation-summary *ngIf="valuesWithRelationship || valuesNoRelationship"
    [formSimulationResult]="formSimulationResult"
    [formConditionResult]="formConditionResult"
    [valuesWithRelationship]="valuesWithRelationship"
    [valuesNoRelationship]="valuesNoRelationship"
    [offerChosen]="simulationData.offerChosen"
    [hasCarencia]="hasCarencia"
    (generatePDF)="generatePDF()"
    [isUseCasaMais]="isUseCasaMais"
    (extractPdfEmailSimulation)="extractPdfEmailSimulation($event)"
    (save)="save()"
    (back)="back()">
  </app-simulation-summary>

  <app-modal [show]="modal"></app-modal>
</div>
