import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';

import * as actions from './parametros-perfil.actions';
import { environment } from 'src/environments/environment';
import { StorageEncryptService } from '@shared/services/storageEncrypt.service';

@Injectable()
export class ParametrosPerfilEffects {
  constructor(private actions$: Actions, private storageEncryptService: StorageEncryptService) {}

  @Effect()
  reset = this.actions$.pipe(
    ofType<actions.Reset>(actions.ParametrosPerfilActionTypes.RESET),
    map(() => {
      this.storageEncryptService.removeLocal('parametrosPerfil');
      return new actions.ResetSucesso();
    })
  );

  @Effect()
  recuperarTokenPP = this.actions$.pipe(
    ofType<actions.RecuperarTokenPP>(actions.ParametrosPerfilActionTypes.RECUPERAR_TOKEN_PP),
    map(() => {
      const tokenPP = this.storageEncryptService.getSessionStore('token_sim') ? 
      this.storageEncryptService.getSessionStore('token') : '';
      const urlOrigin = getUrlOriginBack(JSON.parse(this.storageEncryptService.getSessionStore('history')));

      this.storageEncryptService.removeSession('token');
      return new actions.SalvarTokenPP({ tokenPP, urlOrigin });
    })
  );
}

function getUrlOriginBack(history: Array<string>) {
  const res = history.find(u => {
    return u.indexOf(environment.urlPortalParceiro) !== -1;
  });

  return res || environment.urlCoreAntigo + environment.urlCoreAntigoMenu;
}
