import { Injectable, Inject } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalOptions } from '../interfaces/ModalOptions.interface';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  /** Store modal instances inside an array */
  private stack = [];

  constructor(private ngbModalService: NgbModal, @Inject(DOCUMENT) private document: Document) {
    this.stack = [];
  }

  /**
   * Add modal instance to stack making it possible to use it everywhere
   * @param modalInstance
   */
  addModal(modalInstance, options: ModalOptions): void {
    this.stack.push({
      identifier: modalInstance.identifier,
      instance: modalInstance,
      modalRef: null,
      isOpen: false,
      options
    });

    this.setIdentifierAttribute(modalInstance);

    /**
     * Check if modal should start automatically, it needs setTimeout to not break the application when running
     * Error: ExpressionChangedAfterItHasBeenCheckedError
     */
    if (options.autostart) {
      setTimeout(() => this.openModal(modalInstance.identifier));
    }
  }

  /**
   * Set identifier attribute on Modal Component tag
   *
   * @private
   * @param {*} modalInstance
   * @returns {void}
   * @memberof ModalService
   */
  private setIdentifierAttribute(modalInstance): void {
    if (
      !modalInstance ||
      !modalInstance.contentModal ||
      !modalInstance.contentModal.elementRef ||
      !modalInstance.contentModal.elementRef.nativeElement ||
      !modalInstance.contentModal.elementRef.nativeElement.parentNode ||
      modalInstance.contentModal.elementRef.nativeElement.parentNode.tagName !== 'APP-MODAL-NEW'
    ) {
      return;
    }

    modalInstance.contentModal.elementRef.nativeElement.parentNode.setAttribute('identifier', modalInstance.identifier);
  }

  /**
   * Get modal instance from stack
   * @param id
   */
  getModal(id: string): any {
    const modal = this.stack.find(m => m.identifier === id);

    if (!modal) {
      throw new Error(`Cannot find modal with identifier ${id}`);
    }

    return modal;
  }

  /**
   * Get modal stack count
   */
  getModalStackCount(): number {
    return this.stack.length;
  }

  /**
   * Check if modal exists
   *
   * @param {string} id
   * @returns {boolean}
   * @memberof ModalService
   */
  hasModal(id: string): boolean {
    return !!this.stack.find(m => m.identifier === id);
  }

  /**
   * Check if modal is not supposed to be visible, this is defined by parameters
   *
   * @private
   * @param {string} identifier
   * @returns {boolean}
   * @memberof ModalNewService
   */
  private isModalVisible(identifier: string): boolean {
    const modalEl = this.document.querySelector(`app-modal-new[identifier="${identifier}"]`);
    return modalEl && !modalEl.classList.contains('vivereNaoVisivel');
  }

  /**
   * Open NgbModal based on a modal instance
   * @param id
   */
  openModal(id: string): void {
    const modal = this.getModal(id);

    /**
     * Prevent modal from opening when it is not supposed to be visible to the user
     */
    if (!this.isModalVisible(modal.identifier)) return;

    /**
     * Add NgbModalRef to the stack
     */
    this.stack = this.stack.map(m => {
      if (m.identifier === id) {
        m.modalRef = this.ngbModalService.open(modal.instance.contentModal, modal.options);
        m.isOpen = true;
      }

      return m;
    });

    /** Emit "open" event to parent container */
    modal.instance.openFinished.emit();

    /** Add event listener to modal backdrop */
    this.addBackdropEventListener(modal);
  }

  /**
   * Add event listener to backdrop click
   *
   * @private
   * @param {*} modal
   * @memberof ModalService
   */
  private addBackdropEventListener(modal): void {
    setTimeout(() => {
      const backdrop = this.document.querySelector('.modal');

      backdrop.addEventListener('click', event => {
        const e = event as any;
        if (e.target.nodeName !== 'NGB-MODAL-WINDOW') return;

        /** Emit "close" event to parent container */
        modal.instance.closeFinished.emit();
      });
    });
  }

  closeModal(id: string): NgbModalRef {
    const modalInstance = this.getModal(id);

    if (!modalInstance.modalRef) {
      return;
    }

    const modalIdx = this.stack.findIndex(m => m.identifier === id);
    this.stack[modalIdx] = { ...this.stack[modalIdx], isOpen: false };

    /** Emit "close" event to parent container */
    modalInstance.instance.closeFinished.emit();

    return modalInstance.modalRef.close();
  }

  /**
   * Remove modal instance from stack
   * @param id
   */
  removeModal(id: string): void {
    const modal = this.stack.find(m => m.identifier === id);

    if (modal) {
      this.stack = this.stack.filter(m => m.identifier !== id);
    }
  }

  /**
   * Clear entire modal stack
   */
  clearStack(): void {
    this.stack.forEach(m => {
      this.closeModal(m.identifier);
      this.removeModal(m.identifier);
    });

    /** Certifies that every modal is closed */
    this.ngbModalService.dismissAll();
  }
}
