import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppState } from '@app/state';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as fromParticipants from '@app/state/participants';
import * as fromProposal from '@app/state/proposal';
import { StorageEncryptService } from '@shared/services/storageEncrypt.service';
import { DataLayerSantanderService } from '../../shared/service/dataLayer/novo-data-layer.service';

@Component({
  selector: 'app-confirmacao-sms',
  templateUrl: './confirmacao-sms.container.html',
  styleUrls: ['./confirmacao-sms.container.scss']
})
export class ConfirmacaoSmsContainerComponent implements OnInit, OnDestroy {


  formCodeSms: FormGroup
  telefone: string;
  simulationId: string;
  codeInvalid = false

  pathImageClient = environment.pathClientAssets;

  private subscription: Subscription = new Subscription();

  constructor(private formBuilder: FormBuilder, private router: Router, private store: Store<AppState>,
    private storageEncryptService: StorageEncryptService , private dataLayerSantander: DataLayerSantanderService) { }

  ngOnInit(): void {
    this.createFormCodeSms();
    this.createSubscriptions();
    this.load();
    this.telefone = this.storageEncryptService.getSessionStore('params');
  }

  load() {
    this.generateTokenSms();
    this.sendPageView(undefined, '/simuladorcliente/confirmacao-sms');
  }

  generateTokenSms() {
    this.store.dispatch(new fromParticipants.actions.GenerateTokenSms
      ({ simulationId: this.simulationId, participatingOrder: 0 }));
  }

  resendTokenSms() {
    this.generateTokenSms();
    this.sendToClickEvent(false, 'clicou', 'link:reenviar-codigo');
  }

  validateTokenSms() {
    this.store.dispatch(new fromParticipants.actions.ValidateTokenSms(
      { simulationId: this.simulationId, participatingOrder: 0, token: this.formCodeSms.value.code }));

    this.sendToClickEvent(false, 'clicou', 'botao:confirmar-codigo-token');
  }

  createSubscriptions() {
    this.subscribeToIdProposal();
    this.subscribeToValidateToken();
  }

  subscribeToIdProposal() {
    this.subscription.add(
      this.store.pipe(select(fromProposal.selectors.selectSimulationID)).subscribe(state => {
        if (!state) return;
        this.simulationId = state;
      })
    );
  }

  subscribeToValidateToken() {
    this.subscription.add(
      this.store.pipe(select(fromParticipants.selectors.selectValidateToken)).subscribe(state => {
        if (state === false) {
          this.formCodeSms.get('code').setValue(null);
          this.codeInvalid = true;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  createFormCodeSms(): void {
    this.formCodeSms = this.formBuilder.group({
      code: [null, [Validators.required]]
    });
  }

  comeBack() {
    this.router.navigate(['dados-pessoais']);
    this.storageEncryptService.removeSession('params');
    this.sendToClickEvent(false, 'clicou', 'link:alterar');
  }

  changeInvalidCode() {
    this.codeInvalid = false;
  }

  sendToClickEvent(nonInterection, eventAction, eventLabel) {
    this.dataLayerSantander.sendCustomToDataLayer(
      nonInterection,
      eventAction,
      eventLabel
    );
  }

  sendPageView(url, customUrl) {
    this.dataLayerSantander.sendPageViewToDataLayer(
      url,
      customUrl
    );
  }
}
