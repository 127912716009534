import { createSelector } from '@ngrx/store';
import { AppState } from '..';

export const selectProposal = (state: AppState) => state.proposal;

export const selectSimulationID = createSelector(
  selectProposal,
  state => state.simulationId
);

export const selectLoanTypeKey= createSelector(
  selectProposal,
  state => state.loanTypeKey
);


export const selectBusinessKey = createSelector(
  selectProposal,
  state => state.businessKey
);

export const selectCurrentTaskKey = createSelector(
  selectProposal,
  state => state.currentTaskKey
);

export const selectProposalData = createSelector(
  selectProposal,
  state => {
    const proposalData = { ...state };
    delete proposalData.simulationId;
    return proposalData;
  }
);

export const selectProposalID = createSelector(
  selectProposal,
  state => state.idProposta
);

export const selectPropertyLocation = createSelector(
  selectProposal,
  state => state.propertyLocationKey
);

export const selectPropertyValue = createSelector(
  selectProposal,
  state => state.propertyValue
);

export const selectQtParcCarencia = createSelector(
  selectProposal,
  state => state.monthsDeferredPaymentQt
);

export const selectSelectedOptions = createSelector(
  selectProposal,
  state => ({
    amortizationTypeKey: state.amortizationTypeKey,
    campaignKey: state.campaignKey,
    insurancePolicyKey: state.insurancePolicyKey,
    insurerKey: state.insurerKey,
    marketSegmentKey: state.marketSegmentKey,
    monthWithoutPaymentKey: state.monthWithoutPaymentKey,
    offerKey: state.offerKey,
    paymentTypeKey: state.paymentTypeKey,
    termInYearsKey: state.termInYearsKey,
    deferredPaymentKey: state.deferredPaymentKey
  })
);

export const selectSelectedOptionsResettable = createSelector(
  selectProposal,
  state => ({
    amortizationTypeKey: state.amortizationTypeKey,
    campaignKey: state.campaignKey,
    insurancePolicyKey: state.insurancePolicyKey,
    insurerKey: state.insurerKey,
    marketSegmentKey: state.marketSegmentKey,
    monthWithoutPaymentKey: state.monthWithoutPaymentKey,
    offerKey: state.offerKey,
    paymentTypeKey: state.paymentTypeKey,
    termInYearsKey: state.termInYearsKey
  })
);

export const selectPdfHash = createSelector(
  selectProposal,
  state => state.pdfHash
);

export const selectDisableRepasse = createSelector(
  selectProposal,
  state => state.disableRepasse
);

export const selectBuildingList = createSelector(
  selectProposal,
  state => state.BuildingList
);

export const selectMonthsToCompleteWork = createSelector(
  selectProposal,
  state => state.monthsToCompleteWork
);

export const selectSaveFinancingObjective= createSelector(
  selectProposal,
  state => state.saveFinancingObjective
);
export const selectSimulationContractList = createSelector(
  selectProposal,
  state => state.simulationContractList
);

export const selectWarrantySharingOffers = createSelector(
  selectProposal,
  state => state.warrantySharingOffers
);

export const selectIsLoggedSecondScreen = createSelector(
  selectProposal,
  state => state.isLoggedSecondScreen
);

export const selectSaveRenewed = createSelector(
  selectProposal,
  state => state.isSuccessful
);
