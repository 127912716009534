<div>
  <app-timeline [fase]="1" [nomeFase]="'Dados Pessoais'" [scrollPage]="85"> </app-timeline>

  <app-form-personal-data
    [hasOtherParticipant]="hasOtherParticipant"
    [formPersonalData]="formPersonalData"
    [telephoneNumberMask]="telephoneNumberMask"
    [firstProponentCpf]="firstProponentCpf"
    [visibleFields]="visibleFields"
    [editableFields]="editableFields"
    [isSimulationInProgress]="isSimulationInProgress"
    (submitForm)="onSubmitFormPersonalData()"
    (blurMonthlyIncome)="openModalmonthlyIncome()"
    (showNoAuthorizesEmailAndSmsModal)="showNoAuthorizesEmailAndSmsModal()"
    (focusOutCpf)="onFocusOutCpf($event)"
    (clickBtnSubmit)="clickBtnSubmit($event)"
  >
    <app-form-other-participant
      *ngIf="hasOtherParticipant"
      [formOtherParticipant]="formOtherParticipant"
      [telephoneNumberMask]="telephoneNumberMask"
      [visibleFields]="visibleFields"
      [editableFields]="editableFields"
      [isSimulationInProgress]="isSimulationInProgress"
    ></app-form-other-participant>
  </app-form-personal-data>

  <app-modal [show]="modal"></app-modal>
</div>
