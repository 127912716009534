import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Store, select } from '@ngrx/store';
import { AppState } from '@app/state';
import { SafeUrl } from '@angular/platform-browser';
import { Subscription, BehaviorSubject } from 'rxjs';
import { ModalTemplateComponent } from '@app/shared/widgets/modal-template/modal-template.component';
import { ModalAlertComponent } from '@app/shared/widgets/modal-alert/modal-alert.component';
import { ErrorService } from '@app/shared/services/error.service';
import { Router } from '@angular/router';
import { ProposalSummaryService } from './proposal-summary.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormValidationService } from '@shared/services/form-validation.service';
import { PersonData } from '@app/state/participants/participants.reducer';
import { AuthServices } from 'arch-onefinancial-auth.lib';

import * as fromSimulation from '@app/state/simulation';
import * as fromParticipants from '@app/state/participants';
import * as fromErrorHandling from '@app/state/error-handling';
import * as fromProposal from '@app/state/proposal';
import { DataLayerService } from '../../shared/service/dataLayer/dataLayer.service';
import { CryptoService } from '@app/core/auth/services/crypto.service';
import { StorageEncryptService } from '@shared/services/storageEncrypt.service';
import { DataLayerSantanderService } from '../../shared/service/dataLayer/novo-data-layer.service';

declare const DLECC: any;

@Component({
  selector: 'app-proposal-summary',
  templateUrl: './proposal-summary.container.html',
  styleUrls: ['./proposal-summary.container.scss']
})
export class ProposalSummaryContainerComponent implements OnInit, OnDestroy {
  pathImageClient: string = environment.pathClientAssets;

  proposalId: string;
  simulationId: string;
  urlLoginIframe: SafeUrl;
  borrowers: Array<any>;
  mainBorrower: any;
  clientPublicKey: string;
  compradorPrincipalId: string;
  segundoProponenteId: string;
  exibeLogin: boolean;
  isFinalized: boolean;
  hasInconsistencies: boolean;
  ticket: string;
  hasCarencia: boolean;
  hasRelacionamento: boolean;
  propertyValue: number;
  nameExibition: any;
  formProponentData: FormGroup;
  alreadyCalledThisFunction = false;
  utmSource = this.storageEncryptService.getSessionStore('utmSource');
  parametrosLogin: {
    name: string;
    cpf: string;
    phone: string;
    urlBase: string;
    urlSuccess: string;
    urlError: string;
    ticket: string;
  };
  isRenovado: boolean;
  useMockLogin = environment.featureToggle.useMockLogin === 'true' ? true : false;

  private isBackBtnDisabled: boolean;
  private selectedBorrower: PersonData;
  private subscription: Subscription = new Subscription();

  modal = new BehaviorSubject({
    show: false,
    template: ModalTemplateComponent,
    content: Object,
    closed: () => { }
  });

  url: string;
  routeIcludes: string;
  urlRecirectloginSantander: string;
  urlPortalRedirect: string;
  previousRoute: string;
  public formLogin = this.formBuilder.group({
    nome: [null],
    senha: [null]
  });

  constructor(
    private store$: Store<AppState>,
    private errorService: ErrorService,
    private router: Router,
    private proposalSummaryService: ProposalSummaryService,
    private formBuilder: FormBuilder,
    private formValidationsService: FormValidationService,
    private dataLayerService: DataLayerService,
    private storageEncryptService: StorageEncryptService,
    private cryptoService: CryptoService,
    private authServices: AuthServices,
    private dataLayerSantander: DataLayerSantanderService
  ) {
    this.clientPublicKey = DLECC.init();
  }

  ngOnInit(): void {
    this.isActiveNewRedirect();
    this.createFormProponentData();
    this.createSubscriptions();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  login() {
    const name = this.formLogin.get('nome').value;
    const senha = this.formLogin.get('senha').value;
    this.authServices.loginWithoutSSO(name, senha).subscribe(res => {
      this.storageEncryptService.setSessionStore('userData', JSON.stringify(res));
      window.location.href = `${environment.urlPortalCliente}/#/v2/resumo-da-proposta/enviar`;
    });
  }

  createSubscriptions(): void {
    this.subscribeToSimulationId();
    this.subscribeToProposalId();
    this.subscribeToBorrowers();
    this.subscribeLoanTypeKey();
    this.subscribeToPreviousTask();
    this.subscribeToErrors();
  }

  isActiveNewRedirect() {
    if (environment.featureToggle.newRedirect === 'true') {
      this.url = `${environment.urlNovaSimulacaoCliente}#/middleware-proposal-sumary`;
      this.routeIcludes = 'middleware-proposal-sumary';
      this.urlRecirectloginSantander = '/#/middleware-proposal-sumary';
      this.urlPortalRedirect = environment.urlNovaSimulacaoCliente;
    } else {
      this.routeIcludes = 'v2/resumo-da-proposta';
      this.urlRecirectloginSantander = '/#/v2/resumo-da-proposta/enviar';
      this.urlPortalRedirect = environment.urlPortalCliente;
    }
  }

  /**
   * Add event listener to useMockLogin
   *
   * @param {*} event
   * @returns
   * @memberof ProposalSummaryContainerComponent
   */
  @HostListener('window:message', ['$event'])
  mockListener(event: any) {
    if (!this.canUseMockListener(event) || !this.borrowers || this.borrowers.length < 1) {
      return;
    }

    if (environment.featureToggle.newRedirect === 'true') {
      this.url = `${environment.urlNovaSimulacaoCliente}#/middleware-proposal-sumary`;
    } else {
      this.url = event.data;
    }

    this.store$.dispatch(
      new fromSimulation.actions.RedirectResultadoAnaliseCredito({
        proposalId: this.proposalId,
        borrowerId: this.borrowers[0].id,
        url: this.url
      })
    );
  }

  /**
   * Validate if user can make actions inside Mock Login Iframe
   *
   * @memberof ProposalSummaryContainerComponent
   */
  private canUseMockListener(event: any): boolean {
    return (
      environment.featureToggle.useMockLogin === 'true' &&
      event &&
      event.data &&
      typeof event.data === 'string' &&
      event.data.includes(this.routeIcludes)
    );
  }

  private createFormProponentData(): void {
    this.formProponentData = this.formBuilder.group({
      name: [{ value: null, disabled: true }],
      email: [null, [Validators.required, Validators.pattern(this.formValidationsService.getPatternEmail())]],
      phone: [null, [Validators.required, Validators.pattern(this.formValidationsService.getPatternPhone())]]
    });
  }

  private load(): void {
    this.store$.dispatch(new fromSimulation.actions.GetSummary());
    this.store$.dispatch(new fromParticipants.actions.UpdatePersonasStatus());
  }

  private subscribeToProposalId(): void {
    this.subscription.add(
      this.store$.select(fromSimulation.selectors.selectIdProposta).subscribe(state => {
        this.proposalId = state;
      })
    );
  }

  private subscribeToSimulationId(): void {
    this.subscription.add(
      this.store$.select(fromProposal.selectors.selectSimulationID).subscribe(state => {
        this.simulationId = state;
        this.handleOldArchitetureRecovery();

        if (this.simulationId) {
          this.load();
        }
      })
    );
  }
  /**
   * Handle proposal id when coming back from old architeture
   *
   * @private
   * @memberof ProposalSummaryContainerComponent
   */
  private handleOldArchitetureRecovery(): void {
    const session = JSON.parse(this.storageEncryptService.getSessionStore('data'));

    if (!this.simulationId && session && session.idProposta) {
      this.simulationId = session.idProposta;
    }
  }

  /**
   * Subscribe to Previous Task to determine if back button is disabled
   *
   * @private
   * @memberof ProposalDataContainerComponent
   */
  private subscribeToPreviousTask(): void {
    this.subscription.add(
      this.store$.select(fromSimulation.selectors.selectPreviousTask).subscribe(state => {
        this.isBackBtnDisabled = state !== '/resultado-simulacao' && !state.includes('resultado-simulacao-nova-oferta');
        this.previousRoute = state;
      })
    );
  }

  private subscribeToErrors(): void {
    this.subscription.add(
      this.store$.pipe(select(fromErrorHandling.selectors.selectErrorMessage)).subscribe(state => {
        const exceptions = ['CADASTROIPSI25082020140356'];

        if (!state || state === '' || exceptions.includes(state)) return;

        this.modal.next({
          show: true,
          template: ModalAlertComponent,
          content: Object({
            title: 'common.attention',
            message: this.errorService.handleError(state)
          }),
          closed: () => this.store$.dispatch(new fromErrorHandling.actions.ClearError())
        });
      })
    );
  }

  private subscribeToBorrowers(): void {
    this.subscription.add(
      this.store$.select(fromParticipants.selectors.selectBorrowers).subscribe(state => {
        if (!state || state.length < 1) return;
        this.nameExibition = this.getNameExibition(state);

        this.borrowers = state;
        this.hasInconsistencies = this.borrowers.some(b => b.isRegistrationInconsistent);
        this.isFinalized = this.borrowers.every(b => b.isRegistrationCompleted);

        if ((!this.isFinalized || this.hasInconsistencies) && !this.alreadyCalledThisFunction) {
          this.alreadyCalledThisFunction = true;
          this.dataLayerService.sendPageViewToDataLayer(
            window.location.href,
            '/funil/credito/negocios-imobiliarios/acesso-ncc'
          );
        }

        if (this.borrowers.length > 0 && !this.isFinalized) {
          this.storageEncryptService.setSessionStore('isFromSimulation', JSON.stringify(true));
        }

        if (this.borrowers.length > 0 && !this.hasInconsistencies && this.isFinalized) {
          this.setupResultadoAnaliseCreditoSession(this.borrowers[0]);
          const usuarioLogado = { cpf: this.borrowers[0].documentNumber };
          this.storageEncryptService.setSessionStore('usuarioLogado', JSON.stringify(usuarioLogado));

          if (!this.useMockLogin) {
            this.getTicketIframe(this.borrowers[0]);
          }
        }
      })
    );
  }

  subscribeLoanTypeKey(): void {
    this.subscription.add(
      this.store$.select(fromProposal.selectors.selectLoanTypeKey).subscribe(state => {
        if (!state) return;
        this.isRenovado = parseInt(state, 10) === 55 ? true : false;
      })
    );
  }

  /**
   * Setup session storage for integration with Resultado Analise Credito on Canais
   */
  private setupResultadoAnaliseCreditoSession(borrower): void {
    const data = {
      idProposta: this.proposalId,
      idProponente: borrower.id
    };

    this.storageEncryptService.setSessionStore('data', JSON.stringify(data));
  }

  /*
   * To generate an url string to call Login Santander Iframe
   */
  private getTicketIframe(borrower): void {
    this.proposalSummaryService.getTicket(this.clientPublicKey).subscribe(resTicket => {
      this.parametrosLogin = {
        name: borrower.name,
        cpf: borrower.documentNumber,
        phone: borrower.telephoneNumber,
        urlBase: environment.urlOrigClienteDef,
        urlSuccess: `${this.urlPortalRedirect}${this.urlRecirectloginSantander}`.replace(/\/\/$/gm, '/'),
        urlError: `${environment.urlPortalCliente}/#/v2/resumo-da-proposta/error`.replace(/\/\/$/gm, '/'),
        ticket: resTicket.ticket
      };

      this.exibeLogin = true;

      this.urlLoginIframe = this.proposalSummaryService.santanderLogin(
        this.parametrosLogin,
        resTicket.serverPublicKey,
        's'
      );
    });
  }

  getNameExibition(borrowers) {
    let fullName = borrowers
      .map(b => b.name.split(' ')[0].toLowerCase())
      .map(name => name[0].toUpperCase() + name.substr(1));

    if (borrowers.length > 1) {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      fullName = fullName.reduce((fullName, currName) => `${fullName}​​​ e ${currName}​​​`).trim();
    } else {
      fullName = fullName[0].trim();
    }
    return fullName;
  }

  confirmSummary(): void {
    this.store$.dispatch(
      new fromSimulation.actions.ConfirmSummary({
        modalAlert: this.openModalAlert,
        portal: 'PC'
      })
    );
  }

  openModalAlert(): void {
    this.modal.next({
      show: true,
      template: ModalAlertComponent,
      content: Object({
        title: 'common.attention',
        message: 'Por Favor, tente confimar novamente!'
      }),
      closed: () => this.store$.dispatch(new fromErrorHandling.actions.ClearError())
    });
  }

  fillProponentData(proponent: any): void {
    if (!this.selectedBorrower || proponent.documentNumber !== this.selectedBorrower.documentNumber) {
      this.selectedBorrower = this.borrowers.find(b => b.documentNumber === proponent.documentNumber);

      this.formProponentData.patchValue({
        name: this.selectedBorrower.name,
        email: this.selectedBorrower.email,
        phone: this.selectedBorrower.telephoneNumber
      });
    }
  }

  onRedirectCredito() {
    const urlLegado = `${environment.urlPortalCliente}/#/v2/resumo-da-proposta/enviar`;
    // if (environment.featureToggle.newRedirect === 'true') {
    //   this.url = `${environment.urlNovaSimulacaoCliente}#/middleware-proposal-sumary`;
    // } else {
    //   this.url = urlLegado;
    // }

    this.store$.dispatch(
      new fromSimulation.actions.RedirectResultadoAnaliseCredito({
        proposalId: this.proposalId,
        borrowerId: this.borrowers[0].id,
        url: urlLegado
      })
    );
  }

  redirectTo(index: number): void {
    const borrowerId = this.borrowers[index].id;
    const data = {
      idProposta: this.proposalId,
      idProponente: borrowerId
    };

    this.storageEncryptService.setSessionStore('data', JSON.stringify(data));

    this.store$.dispatch(
      new fromSimulation.actions.RedirectPersonas({
        urlPortal: environment.urlPortalCliente,
        simulationId: this.cryptoService.encrypt(this.proposalId),
        borrowerId: this.cryptoService.encrypt(borrowerId)
      })
    );
  }

  back() {
    let productName = this.storageEncryptService.getSessionStore('productName');

    this.sendToClickEvent(false, 'clicou', 'botao:voltar', productName)
    if (this.previousRoute.includes('resultado-simulacao-nova-oferta')) {
      const paramsRoute = this.breakUrl(this.previousRoute);
      const hasUmaOferta = paramsRoute.hasUmaOferta ? { hasUmaOferta: paramsRoute.hasUmaOferta } : {};
      this.router.navigate(['/resultado-simulacao-nova-oferta'], { queryParams: { ...paramsRoute, ...hasUmaOferta } });
    } else {
      this.router.navigate(['/resultado-simulacao']);
    }
  }

  breakUrl(url: string): { [key: string]: string } {
    const regex = /[?&]([^=&]+)=([^&]*)/g;
    let match;
    const params: { [key: string]: string } = {};

    while (match = regex.exec(url)) {
      const key = decodeURIComponent(match[1]);
      const value = decodeURIComponent(match[2]);
      params[key] = value;
    }

    return params;
  }

  sendToClickEvent(nonInterection, eventAction, eventLabel, productName) {
    this.dataLayerSantander.sendCustomToDataLayer(
      nonInterection,
      eventAction,
      eventLabel,
      productName
    );
  }

}
