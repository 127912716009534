import { Component, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { DataLayerSantanderService } from '@projects/cliente/src/app/shared/service/dataLayer/novo-data-layer.service';
import { StorageEncryptService } from '@shared/services/storageEncrypt.service';

@Component({
  selector: 'app-btn-extract-simulation',
  templateUrl: './btn-extract-simulation.component.html',
  styleUrls: ['./btn-extract-simulation.component.scss']
})
export class BtnExtractSimulationComponent {
  @Input() allowPrint = false;
  @Input() allowEmail = false;

  @Output() extractSimulation = new EventEmitter();

  constructor(private dataLayerSantander: DataLayerSantanderService,  private storageEncryptService: StorageEncryptService) {}



  onExtractSimulation(type) {
    let productName = this.storageEncryptService.getSessionStore('productName');
    this.sendToClickEvent(false , 'clicou' , 'link:enviar-simulacao-' + type, productName)
    this.extractSimulation.emit(type);

  }

  sendToClickEvent(nonInterection, eventAction, eventLabel, productName) {
    this.dataLayerSantander.sendCustomToDataLayer(
      nonInterection,
      eventAction,
      eventLabel,
      productName
    );
  }
}
