import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Pipe({ name: 'percentPipe' })
export class PercentPipe implements PipeTransform {
  private locale;

  constructor(private translateService: TranslateService) {
    this.translateService.onLangChange.subscribe(($event: LangChangeEvent) => {
      this.locale = $event.lang;
    });
  }

  transform(value: string | number, minimumFractionDigits?: number, hasSuffix = true): string {
    if (!value) return '';

    const locale = this.locale || 'pt-BR';
    const fractionDigits = minimumFractionDigits ? minimumFractionDigits : 2;
    value = typeof value === 'number' ? value : +value;
    value = new Intl.NumberFormat(locale, { minimumFractionDigits: fractionDigits }).format(value);

    return hasSuffix ? `${value} %` : `${value}`;
  }
}
