import gql from 'graphql-tag';

export const GET_PROPOSAL_DATA_PARAMETERS = gql`
  query getProposalDataParametersSimulation(
    $channel: String!
    $simulationId: ID
    $tpPessoa: String
    $loanTypeKey: String
  ) {
    getProposalDataParametersSimulation(
      channel: $channel
      simulationId: $simulationId
      tpPessoa: $tpPessoa
      loanTypeKey: $loanTypeKey
    ) {
      visibleFields
      editableFields
      buildingTypes {
        key
        description
      }
      loanObjectives {
        tooltipCode
        loanObjectiveId
        loanObjectiveBusinessName
        standardCode
        loanObjectiveDescription
        loanObjectiveCodeIntegration
        isShowModal
        isDefaultOption
        portabilityTypes {
          key
          description
        }
        visibleFields
        editableFields
      }
      federalStates {
        key
        description
      }
      estimatedOperatingPeriods {
        key
        description
      }
      isSimulationInProgress
    }
  }
`;

export const CALCULATE_PROPERTY_VALUE_RANGE = gql`
  query calculatePropertyValueRange(
    $loanTypeKey: ID
    $buildingTypeKey: ID
    $propertyLocationKey: ID
    $portabilityTypeKey: ID
  ) {
    calculatePropertyValueRange(
      loanTypeKey: $loanTypeKey
      buildingTypeKey: $buildingTypeKey
      propertyLocationKey: $propertyLocationKey
      portabilityTypeKey: $portabilityTypeKey
    ) {
      minPropertyValue
      maxPropertyValue
    }
  }
`;

export const CALCULATE_PROPOSAL_VALUE_RANGE = gql`
  query calculateProposalValuesRange($input: InputSaveProposalData) {
    calculateProposalValuesRange(input: $input) {
      proposalValuesRange {
        minLoanAmountValue
        maxLoanAmountValue
        minTermInMonths
        maxTermInMonths
        minTermInYears
        maxTermInYears
      }
      deferredPayment {
        showDeferredPaymentBlock
        deferredPaymentOptions {
          key
          description
          defaultOption
          deferredPaymentType
          minMonthsDeferredPayment
          maxMonthsDeferredPayment
        }
      }
      isAppraisalFeeFree
    }
  }
`;

export const GET_SIMULATION_DATA = gql`
  query getSimulationData($simulationId: ID!) {
    getSimulationData(simulationId: $simulationId) {
      simulationData {
        simulationId
        selectedProposalOptions {
          channel
          loanTypeKey
          buildingTypeKey
          propertyLocationKey
          portabilityTypeKey
        }
        simulationEntryValues {
          propertyValue
          evaluationValue
          downPaymentAmount
          fgtsAmount
          loanAmountValue
          isPropertyNew
          termInMonths
          termInYears
          monthsDeferredPaymentQt
        }
        isAppraisalfeesInClosingCostCredit
        isIOFIncludedInClosingCostCredit
        isTransferTaxInClosingCostCredit
        deferredPaymentKey
        expensesValue
        quizResponseCode
      }
      __typename
    }
  }
`;

export const GET_SIMULATION_DATA_PARAMETERS = gql`
  query getSimulationDataParameters($input: InputCalculateSimulation!) {
    getSimulationDataParameters(input: $input) {
      months {
        code
        name
      }
      calculateSimulation {
        visibleFields
        editableFields
        simulationValues {
         originalContractParameters{
          balanceValue
          remainingPaymentTermInMonths
          originalWarrantyValue
          }
          simulationFeaturesValues {
            loanTypeKey
            loanTypeName
            customerPortfolioName
            buildingTypeKey
            isHomeEquity
            trIndexerKey
            isNegotiatedRate
            isEmployee
          }
          simulationEntryValues {
            propertyValue
            evaluationValue
            downPaymentAmount
            fgtsAmount
            loanAmountValue
            isPropertyNew
            termInMonths
            termInYears
            monthsDeferredPaymentQt
          }
          closingCostsCredit {
            appraisalFeesValue
            isAppraisalFeeFree
          }
          simulationCalculatedValues {
            totalMonthlyIncome
            minDownPaymentAmount
            maxDownPaymentAmount
            expensesValue
            loanAmountValueWithExpenses
          }
          calculatedSimulationResults {
            calculatedSimulationType
            firstPaymentAmountInWorks
            firstPaymentAmount
            finalPaymentAmount
            ltvRatio
            estimatedTRRate
            usesEstimatedTRrate
            cetRate
            ceshRate
            cemRate
            interestRates {
              monthInterestRate
              yearInterestRate
              nominalInterestRate
              flexibleInterestRate
              minFlexibleInterestRate
              maxFlexibleInterestRate
              usesFlexibleInterestRate
            }
            paymentAmountOnDeferredPeriod
          }
          closingCostsCredit {
            isTransferTaxInClosingCostCredit
            isAppraisalfeesInClosingCostCredit
            isIOFIncludedInClosingCostCredit
            appraisalFeesValue
            isAppraisalFeeFree
            transferTaxValue
            iofValue
          }
          calculatedProposalValuesRange {
            minLoanAmountValue
            maxLoanAmountValue
            minTermInMonths
            maxTermInMonths
            minTermInYears
            maxTermInYears
          }
          selectedOptions {
            termInYearsKey
            campaignKey
            marketSegmentKey
            offerKey
            paymentTypeKey
            amortizationTypeKey
            monthWithoutPaymentKey
            insurerKey
            insurancePolicyKey
            deferredPaymentKey
            typeOfferWarrantyShared
          }
        }
        simulationDomains {
          showFlexibleInterestRateBlock
          showFGTSBlock
          showDownPaymentAmountBlock
          showExpensesValueBlock
          showLoanAmountValueWithExpensesBlock
          showLoanAmountValueBlock
          campaigns {
            showThisBlock
            options {
              key
              description
              defaultOption
            }
          }
          marketSegments {
            showThisBlock
            options {
              key
              description
              defaultOption
            }
          }
          offers {
            showThisBlock
            options {
              key
              description
              defaultOption
            }
          }
          paymentType {
            showThisBlock
            options {
              key
              description
              defaultOption
            }
          }
          amortizationTypes {
            showThisBlock
            options {
              key
              description
              defaultOption
            }
          }
          insurers {
            showThisBlock
            options {
              key
              description
              defaultOption
            }
          }
          insurancePolicies {
            showThisBlock
            options {
              key
              description
              defaultOption
            }
          }
          termInYearsList {
            showThisBlock
            options {
              key
              description
              defaultOption
            }
          }
          monthWithoutPayment {
            showThisBlock
            options {
              key
              description
              defaultOption
            }
          }
          deferredPayment {
            showDeferredPaymentBlock
            deferredPaymentOptions {
              key
              description
              defaultOption
              deferredPaymentType
              minMonthsDeferredPayment
              maxMonthsDeferredPayment
            }
          }
        }
      }
      isSimulationInProgress
    }
  }
`;

export const CALCULATE_SIMULATION_DATA = gql`
  query calculateSimulation($input: InputCalculateSimulation!) {
    calculateSimulation(input: $input) {
      visibleFields
      editableFields
      simulationValues {
        simulationFeaturesValues {
          loanTypeKey
          loanTypeName
          customerPortfolioName
          buildingTypeKey
          isHomeEquity
          trIndexerKey
          isNegotiatedRate
          isEmployee
        }
        simulationEntryValues {
          propertyValue
          evaluationValue
          downPaymentAmount
          fgtsAmount
          loanAmountValue
          isPropertyNew
          termInMonths
          termInYears
          monthsDeferredPaymentQt
        }
        simulationCalculatedValues {
          totalMonthlyIncome
          minDownPaymentAmount
          maxDownPaymentAmount
          expensesValue
          loanAmountValueWithExpenses
        }
        closingCostsCredit {
          appraisalFeesValue
          isAppraisalFeeFree
        }
        calculatedSimulationResults {
          calculatedSimulationType
          firstPaymentAmountInWorks
          firstPaymentAmount
          finalPaymentAmount
          ltvRatio
          estimatedTRRate
          usesEstimatedTRrate
          cetRate
          ceshRate
          cemRate
          interestRates {
            monthInterestRate
            yearInterestRate
            nominalInterestRate
            flexibleInterestRate
            minFlexibleInterestRate
            maxFlexibleInterestRate
            usesFlexibleInterestRate
          }
          paymentAmountOnDeferredPeriod
        }
        closingCostsCredit {
          isTransferTaxInClosingCostCredit
          isAppraisalfeesInClosingCostCredit
          isIOFIncludedInClosingCostCredit
          appraisalFeesValue
          isAppraisalFeeFree
          transferTaxValue
          iofValue
        }
        calculatedProposalValuesRange {
          minLoanAmountValue
          maxLoanAmountValue
          minTermInMonths
          maxTermInMonths
          minTermInYears
          maxTermInYears
        }
        selectedOptions {
          termInYearsKey
          campaignKey
          marketSegmentKey
          offerKey
          paymentTypeKey
          amortizationTypeKey
          monthWithoutPaymentKey
          insurerKey
          insurancePolicyKey
          deferredPaymentKey
          typeOfferWarrantyShared
        }
      }
      simulationDomains {
        showFlexibleInterestRateBlock
        showFGTSBlock
        showDownPaymentAmountBlock
        showExpensesValueBlock
        showLoanAmountValueWithExpensesBlock
        showLoanAmountValueBlock
        campaigns {
          showThisBlock
          options {
            key
            description
            defaultOption
          }
        }
        marketSegments {
          showThisBlock
          options {
            key
            description
            defaultOption
          }
        }
        offers {
          showThisBlock
          options {
            key
            description
            defaultOption
          }
        }
        paymentType {
          showThisBlock
          options {
            key
            description
            defaultOption
          }
        }
        amortizationTypes {
          showThisBlock
          options {
            key
            description
            defaultOption
          }
        }
        insurers {
          showThisBlock
          options {
            key
            description
            defaultOption
          }
        }
        insurancePolicies {
          showThisBlock
          options {
            key
            description
            defaultOption
          }
        }
        termInYearsList {
          showThisBlock
          options {
            key
            description
            defaultOption
          }
        }
        monthWithoutPayment {
          showThisBlock
          options {
            key
            description
            defaultOption
          }
        }
        deferredPayment {
          showDeferredPaymentBlock
          deferredPaymentOptions {
            key
            description
            defaultOption
            deferredPaymentType
            minMonthsDeferredPayment
            maxMonthsDeferredPayment
          }
        }
      }
    }
  }
`;

export const GET_EMPREENDIMENTOS = gql`
  query getEmpreendimentos($idEmpreendimento: String!, $tipoConsulta: String) {
    getEmpreendimentos(idEmpreendimento: $idEmpreendimento, tipoConsulta: $tipoConsulta) {
      idProposta
      cdUtilImovel
      nmEmpreendimento
      idEmpreendimento
      idUf
      obra {
        qtPrazoMesesFimObra
      }
    }
  }
`;

export const GET_BUILDINGS = gql`
  query listarEmpreendimentosSimulacao(
    $idSimulacao: ID
    $tipoImovel: String
    $nomeEmpreendimento: String
    $loanTypeKey: String
  ) {
    listarEmpreendimentosSimulacao(
      idSimulacao: $idSimulacao
      tipoImovel: $tipoImovel
      nomeEmpreendimento: $nomeEmpreendimento
      loanTypeKey: $loanTypeKey
    ) {
      idEmpreendimento
      nomeEmpreendimento
      nomeGrupo
      cepEmpreendimento
      descricaoLogradouro
      numeroLogradouro
      descricaoComplemento
      descricaoBairro
      descricaoCidade
      idUf
      tipoLogradouro
      __typename
    }
  }
`;

export const GET_PDF_SIMULATION_DATA = gql`
  query getPdfSimulationProposal($simulationId: String!) {
    getPdfSimulation(simulationId: $simulationId) {
      pdfHash
    }
  }
`;

export const LISTAR_CONTRATOS_EMPRESTIMO_SIMULACAO = gql`
  query listarContratosEmprestimoSimulacao($idSimulacao: ID, $loanTypeKey: String) {
    listarContratosEmprestimoSimulacao(idSimulacao: $idSimulacao, loanTypeKey: $loanTypeKey) {
      codigoContrato
      codigoContratoTombamento
      descricaoObjetivoFinanciamento
      cep
      descricaoLogradouro
      numeroLogradouro
      descricaoComplemento
      descricaoBairro
      descricaoCidade
      idUf
      tipoLogradouro
      tipoImovel
      nmBloco
      nrUnidade
      vlGarantiaOriginal
      flagElegivelRenovado
      flagElegivelMarcoGarantia
      codigoContratoInter
    }
  }
`;

export const GET_WARRANTY_SHARING_OFFERS = gql`
  query getWarrantySharingOffers($inputOffersMarcoGuarantees: InputOffersMarcoGuarantees) {
    getWarrantySharingOffers(inputOffersMarcoGuarantees: $inputOffersMarcoGuarantees) {
      offers {
        tpOfferMarcoGuarantee
      }
    }
  }
`;

