import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appHelperTooltip]'
})
export class HelperTooltipDirective {
  constructor(private elRef: ElementRef, private renderer: Renderer2) {}

  @HostListener('click') toggleTooltip() {
    const questionHeader = this.elRef.nativeElement.closest('body').querySelector('.help-footer .question');
    const questionText = questionHeader.querySelector('.text');

    this.renderer.setStyle(questionHeader, 'min-height', `${questionText.offsetHeight + 30}px`);
  }
}
