<div class="row warning-row" [ngClass]="{'valor-maximo-usecasamais': isUseCasaMais}">
  <div
    class="col-6 warning-msg margin"
    [class.invalid]="isInvalidMin"
    [class.text-error]="isInvalidMin"
    *ngIf="minValue"
  >
    {{ labelMinValue ? labelMinValue : ('common.minimum value' | translate) }}
    <span *ngIf="formatMinMax !== 'currency'">{{ minValue }}</span>
    <span *ngIf="formatMinMax === 'currency'">{{ minValue | currencyPipe }}</span>
  </div>

  <div
    class="col-6 warning-msg text-nowrap"
    [class.invalid]="isInvalidMax"
    [class.text-error]="isInvalidMax"
    *ngIf="maxValue"
  >
    {{ labelMaxValue ? labelMaxValue : ('common.maximum value' | translate) }}
    <span *ngIf="formatMinMax !== 'currency'">{{ maxValue }}</span>
    <span *ngIf="formatMinMax === 'currency'">{{ maxValue | currencyPipe }}</span>
  </div>
</div>
