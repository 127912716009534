<div class="pagina-splash">
  <section class="destaque">
    <div class="texto-banner">
      <p>{{ 'modules.simulation.home.banner text' | translate }}</p>
    </div>

    <form action="">
      <div *ngIf="disablePortal">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-6 intro disable-portal">
              <h3>{{ 'modules.simulation.home.portal in maintenance' | translate }}</h3>
              <h4>{{ 'modules.simulation.home.come back later' | translate }}</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="container" *ngIf="!disablePortal">
        <div class="row">
          <div class="col-12 col-lg-6 intro">
            <p class="p-padrao-splash" [innerHTML]="'modules.simulation.home.splash top' | translate"></p>
            <div class="buttons button-main button-splash">
              <button class="col-xs-12 button-default" id="btnSubmitProposta" type="button" (click)="goToSimulation()">
                {{ 'modules.simulation.home.simulate and contract' | translate }}
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="input-box">
              <div class="form-group">
                <p class="p-padrao-splash" [innerHTML]="'modules.simulation.home.splash bottom' | translate"></p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 intro">
            <div class="buttons button-main button-splash">
              <button class="col-xs-12 button-default" style="margin-top: 2em" (click)="goToProposal()">
                {{ 'modules.simulation.home.consult' | translate }}
                <i class="material-icons busca">search</i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </section>
</div>
