import { initialState, LoginState } from './login.state';
import { LoginActions, LoginActionsTypes } from './login.actions';

export function loginReducer(state = initialState, action: LoginActions): LoginState {
  switch (action.type) {
    case LoginActionsTypes.LOGIN_SUCCESS:

    case LoginActionsTypes.LOGIN_CIPHER_SUCCESS: {
      return {
        ...state,
        username: action.payload.username,
        urlRequest: { url: null },
        isLoggedIn: true
      };
    }

    case LoginActionsTypes.RESET_LOGIN_STATE:
      return {
        ...state,
        isLogged: null
      };

    case LoginActionsTypes.LOGIN_WITHOUT_SSO_SUCCESS: {
      return {
        ...state,
        username: action.payload,
        urlRequest: { url: null },
        isLoggedIn: true
      };
    }

    case LoginActionsTypes.LOGIN_SUCESS: {
      return {
        ...state,
        isLogged: action.payload.isLogged,
      };
    }

    case LoginActionsTypes.INTEGRATION_SUCCESS: {
      return {
        ...state,
        username: action.payload.username,
        urlRequest: action.payload.urlRequest,
        isLoggedIn: true,
        tokenPP: action.payload.tokenPP,
        urlOrigin: action.payload.urlOrigin,
        integrationSuces: true
      };
    }

    case LoginActionsTypes.LOGIN_ERROR:
    case LoginActionsTypes.LOGIN_CIPHER_ERROR:
    case LoginActionsTypes.INTEGRATION_ERROR: {
      return {
        ...initialState,
        alerts: [...state.alerts, action.payload]
      };
    }

    case LoginActionsTypes.LOGIN_REMOVE_ALERT_SUCCESS: {
      return {
        ...state,
        alerts: state.alerts.filter(a => action.payload.id !== a.id)
      };
    }

    case LoginActionsTypes.CLEAR_USER_DATA:
    // case LoginActionsTypes.LOGOUT_SUCCESS: {
    //   return initialState;
    // }

    default:
      return state;
  }
}
