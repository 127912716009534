<div class="wrapper" [ngClass]="{ active: isMenuActive }">
  <!-- Offset menu -->
  <app-offset-menu [isActive]="isMenuActive" (isLogout)="onLogout($event)"></app-offset-menu>

  <div class="wrapper__page-wrapper">
    <!-- Header -->
    <app-header [showMenuExit]="showMenuExit" (toggleMenu)="onToggleMenu()"></app-header>

    <!-- Content -->
    <router-outlet></router-outlet>

    <!-- Helper -->
    <app-helper></app-helper>

    <!-- Chat button -->
    <app-chat-footer #chatFooter [isChatBot]="true"></app-chat-footer>

    <!-- Footer -->
    <app-footer #footer></app-footer>
  </div>
</div>
