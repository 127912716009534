<form [formGroup]="formOtherParticipant">
  <fieldset *ngIf="isVisible('isPrimaryBorrower')">
    <div class="form-row">
      <div class="form-group col">
        <!-- second participant -->
        <app-input-checkbox-container
          [label]="'modules.simulation.personal-data.is the new proponent the main one' | translate"
          for="participantePrincipal"
        >
          <input
            type="checkbox"
            id="participantePrincipal"
            [value]="false"
            formControlName="isPrimaryBorrower"
            name="principalParticipant"
          />
        </app-input-checkbox-container>
      </div>
    </div>
  </fieldset>

  <fieldset>
    <div class="form-row">
      <div class="form-group col">
        <!-- Name -->
        <app-input-text-container
          [label]="'modules.simulation.personal-data.what is the name of the other participant' | translate"
          [customErrorMessage]="'common.please type the full name' | translate"
        >
          <input
            class="text-uppercase"
            type="text"
            id="nomeOutroParticipante"
            autocomplete="off"
            [placeholder]="'common.full name' | translate"
            formControlName="name"
            maxlength="65"
          />
        </app-input-text-container>
      </div>
    </div>
  </fieldset>

  <fieldset>
    <div class="form-row">
      <div class="form-group col">
        <!-- CPF -->
        <app-input-text-container
          [label]="'modules.simulation.personal-data.what is the cpf' | translate"
          [customErrorMessage]="'common.invalid CPF' | translate"
        >
          <input
            type="tel"
            id="cpfOutroParticipante"
            formControlName="documentNumber"
            mask="000.000.000-00"
            autocomplete="off"
            [placeholder]="'common.type' | translate"
          />
        </app-input-text-container>
      </div>
    </div>
  </fieldset>

  <fieldset>
    <div class="form-row">
      <div class="form-group col">
        <!-- Birth Date -->
        <app-input-text-container
          helperName="birthDate"
          [label]="'modules.simulation.personal-data.and the date of his birth' | translate"
          [customErrorMessage]="'common.age must be greater than 16 and less than 80 yers old' | translate"
        >
          <input
            type="tel"
            id="dataOutroParticipante"
            placeholder="dd/mm/aaaa"
            formControlName="birthDate"
            mask="00/00/0000"
            autocomplete="off"
            [dropSpecialCharacters]="false"
          />
        </app-input-text-container>
      </div>
    </div>
  </fieldset>

  <fieldset>
    <div class="form-row">
      <div class="form-group col">
        <!-- Monthly Income -->
        <app-input-text-container
          helperName="monthlyIncomeOtherParticipant"
          [label]="'modules.simulation.personal-data.what is that persons income' | translate"
          [customErrorMessage]="'common.value less than minimum' | translate"
        >
          <input
            maxlength="16"
            type="tel"
            id="rendaMensalBrutaOutroParticipante"
            placeholder="R$"
            autocomplete="off"
            formControlName="monthlyIncome"
            currencyMask
          />
        </app-input-text-container>
      </div>
    </div>
  </fieldset>
  
  <fieldset>
    <div class="form-row">
      <div class="form-group col">
        <!-- Phone -->
        <app-input-text-container
          helperName="phone"
          [label]="'modules.simulation.personal-data.what is your phone number' | translate"
          [customErrorMessage]="'common.invalid phone number' | translate"
        >
          <input
            type="tel"
            id="telefone"
            placeholder="( )"
            formControlName="telephoneNumber"
            autocomplete="off"
            mask="(00) 00000-0000"
          />
        </app-input-text-container>
      </div>
    </div>
  </fieldset>


  <!-- <fieldset>
    <div class="form-row">
      <div class="form-group col">
        <app-input-radio-container
          [label]="'Para efeito de obter condições diferenciadas, o cliente é funcionário do Grupo Santander?'"
        >
          <div class="form-row">
            <div class="form-group form-group-radio col">
              <input [value]="true" type="radio" id="outroParticipanteGrupoSantanderSim" formControlName="isEmployee" />
              <label for="outroParticipanteGrupoSantanderSim" class="label-radio">{{ 'common.yes' | translate }}</label>
            </div>
            <div class="form-group form-group-radio col">
              <input
                [value]="false"
                type="radio"
                id="outroParticipanteGrupoSantanderNao"
                formControlName="isEmployee"
              />
              <label for="outroParticipanteGrupoSantanderNao" class="label-radio">{{ 'common.no' | translate }}</label>
            </div>
          </div>
        </app-input-radio-container>
      </div>
    </div>
  </fieldset> -->
</form>
