import { Action } from '@ngrx/store';

export enum LoginActionsTypes {
  LOGIN = '[Login] Login',
  LOGIN_SUCCESS = '[Login] Login success',
  LOGIN_ERROR = '[Login] Login error',

  LOGIN_CIPHER = '[Login] Login Cipher Old Architecture',
  LOGIN_CIPHER_SUCCESS = '[Login] Login Cipher Old Architecture Success',
  LOGIN_CIPHER_ERROR = '[Login] Login Cipher Old Architecture Error',

  LOGIN_WITHOUT_SSO = '[Login] Login Without SSO',
  LOGIN_WITHOUT_SSO_SUCCESS = '[Login] Login Without SSO Success',
  LOGIN_WITHOUT_SSO_ERROR = '[Login] Login Without SSO Error',
  LOGIN_SUCESS = '[Login] Login Sucess',

  LOGIN_PUBLIC = '[Login] Login Public',
  LOGIN_PUBLIC_SUCCESS = '[Login] Login Public success',
  LOGIN_PUBLIC_ERROR = '[Login] Login Public error',

  INTEGRATION = '[Login] Integration',
  INTEGRATION_SUCCESS = '[Login] Integration success',
  INTEGRATION_ERROR = '[Login] Integration error',

  INTEGRATION_PUBLIC = '[Login] Integration Public',

  // LOGOUT = '[Login] Logout',
  // LOGOUT_SUCCESS = '[Login] Logout success',
  // LOGOUT_ERROR = '[Login] Logout error',

  LOGOUT_REVOKE = '[Global] Logout Revoke',

  LOGIN_REMOVE_ALERT = '[Login] Remove alert',
  LOGIN_REMOVE_ALERT_SUCCESS = '[Login] Remove alert success',

  CLEAR_INTEGRATION_DATA = '[Login] Clear integration data',
  CLEAR_USER_DATA = '[Login] Clear user data',

  RESOLVE_PENDING_REQUESTS = '[Login] Resolve pending requests',

  REDIRECT = '[Login] Redirect',
  REDIRECT_RENOVADO = '[Login] Login Renovado Redirect',

  RESET_LOGIN_STATE = '[Login] Reset Login State'
}

export class Login implements Action {
  readonly type = LoginActionsTypes.LOGIN;
  constructor(public payload: any) {}
}

export class LoginSuccess implements Action {
  readonly type = LoginActionsTypes.LOGIN_SUCCESS;
  constructor(public payload: any) {}
}

export class LoginError implements Action {
  readonly type = LoginActionsTypes.LOGIN_ERROR;
  constructor(public payload: any) {}
}

export class LoginCipher implements Action {
  readonly type = LoginActionsTypes.LOGIN_CIPHER;
  constructor(public payload: any) {}
}

export class LoginCipherSuccess implements Action {
  readonly type = LoginActionsTypes.LOGIN_CIPHER_SUCCESS;
  constructor(public payload: any) {}
}

export class LoginCipherError implements Action {
  readonly type = LoginActionsTypes.LOGIN_CIPHER_ERROR;
  constructor(public payload: any) {}
}

export class ResetLoginState implements Action {
  readonly type = LoginActionsTypes.RESET_LOGIN_STATE;
  constructor() {}
}
export class LoginWithoutSSO implements Action {
  readonly type = LoginActionsTypes.LOGIN_WITHOUT_SSO;
  constructor(public payload: {  nome: string, senha: string }) {}
}

export class LoginWithoutSSOSuccess implements Action {
  readonly type = LoginActionsTypes.LOGIN_WITHOUT_SSO_SUCCESS;
  constructor(public payload: any) {}
}

export class LoginSucess implements Action {
  readonly type = LoginActionsTypes.LOGIN_SUCESS;
  constructor(public payload: any) {}
}

export class LoginWithoutSSOError implements Action {
  readonly type = LoginActionsTypes.LOGIN_WITHOUT_SSO_ERROR;
  constructor(public payload: any) {}
}


export class LoginPublic implements Action {
  readonly type = LoginActionsTypes.LOGIN_PUBLIC;
  constructor() {}
}

export class LoginPublicError implements Action {
  readonly type = LoginActionsTypes.LOGIN_PUBLIC_ERROR;
  constructor(public payload: any) {}
}

export class Integration implements Action {
  readonly type = LoginActionsTypes.INTEGRATION;
  constructor(public payload: any) {}
}

export class IntegrationSuccess implements Action {
  readonly type = LoginActionsTypes.INTEGRATION_SUCCESS;
  constructor(public payload: any) {}
}

export class IntegrationError implements Action {
  readonly type = LoginActionsTypes.INTEGRATION_ERROR;
  constructor(public payload: any) {}
}

export class IntegrationPublic implements Action {
  readonly type = LoginActionsTypes.INTEGRATION_PUBLIC;
  constructor() {}
}

// export class Logout implements Action {
//   readonly type = LoginActionsTypes.LOGOUT;
//   constructor() {}
// }

// export class LogoutSuccess implements Action {
//   readonly type = LoginActionsTypes.LOGOUT_SUCCESS;
//   constructor() {}
// }

// export class LogoutError implements Action {
//   readonly type = LoginActionsTypes.LOGOUT_ERROR;
//   constructor(public payload: any) {}
// }

export class LogoutRevoke implements Action {
  readonly type = LoginActionsTypes.LOGOUT_REVOKE;
  constructor() {}
}

export class LoginRemoveAlert implements Action {
  readonly type = LoginActionsTypes.LOGIN_REMOVE_ALERT;
  constructor(public payload: any) {}
}

export class LoginRemoveAlertSuccess implements Action {
  readonly type = LoginActionsTypes.LOGIN_REMOVE_ALERT_SUCCESS;
  constructor(public payload?: any) {}
}

export class ClearIntegrationData implements Action {
  readonly type = LoginActionsTypes.CLEAR_INTEGRATION_DATA;
  constructor() {}
}

export class ClearUserData implements Action {
  readonly type = LoginActionsTypes.CLEAR_USER_DATA;
  constructor() {}
}

export class ResolvePendingRequests implements Action {
  readonly type = LoginActionsTypes.RESOLVE_PENDING_REQUESTS;
  constructor() {}
}

export class Redirect implements Action {
  readonly type = LoginActionsTypes.REDIRECT;
  constructor() {}
}

export class RedirectRenovado implements Action {
  readonly type = LoginActionsTypes.REDIRECT_RENOVADO;
  constructor(public payload?: any) {}
}

export type LoginActions =
  | Login
  | LoginSuccess
  | LoginError
  | LoginCipher
  | LoginCipherSuccess
  | LoginCipherError
  | LoginPublic
  | LoginPublicError
  | Integration
  | IntegrationSuccess
  | IntegrationError
  | IntegrationPublic
  // | Logout
  // | LogoutSuccess
  // | LogoutError
  | LogoutRevoke
  | LoginRemoveAlert
  | LoginRemoveAlertSuccess
  | ClearIntegrationData
  | ClearUserData
  | ResolvePendingRequests
  | Redirect
  | LoginWithoutSSO
  | LoginWithoutSSOSuccess
  | LoginSucess
  | ResetLoginState;
