<div>
  <app-timeline [fase]="1" [nomeFase]="'Dados da Proposta'" [scrollPage]="85"></app-timeline>

  <div class="formulario">
    <div class="container light-gray mt-5 mb-5">
      <div class="row dark-gray">
        <div class="col text-center">
            <div class="description-cool mt-5">{{ 'Legal!' | translate }}</div>
            <div class="title mb-5">{{ 'Agora precisamos que confirme o seu número de telefone para continuar' | translate }}</div>
        </div>
      </div>
      <form action="" class="text-center">
        <fieldset>
          <div class="form-row mt-4 mb-3">
            <div class="form-group form-group-radio col">
              <p class="description">Digite o Código SMS Token enviado para seu celular e continue na simulação.
              </p>
              <p class="description">Telefone celular: <strong>{{telefone}}</strong> &nbsp;

                <span (click)="comeBack()"  class="text-alterar">Alterar <img src="assets/{{ pathImageClient }}/images/alterar-dados.svg"
                    alt="alterar" class="icon-alterar icon--arrow-circle" /></span>
              </p>

              <h3 class="description">Informe o código recebido:</h3>
              <div [formGroup]="formCodeSms" class="input-tel mb-8">
                <div class="form-group mt-1">
                  <app-input-text-container [codeInvalid]="codeInvalid">
                    <input maxlength="6" type="text" id="code" autocomplete="off" formControlName="code"
                      mask="000000" (input)="changeInvalidCode()"/>
                  </app-input-text-container>
                  <a (click)="resendTokenSms()" class="reenvio-sms">Reenviar código</a>
                </div>
              </div>


              <div class="d-flex justify-content-center mb-3 margin-top-btn">
                <button (click)="validateTokenSms()" type="button" class="button-default">CONFIRMAR CÓDIGO TOKEN</button>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  </div>
</div>
