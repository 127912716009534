import { Component, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { personalDataConstant } from '@shared/constants/personal-data.constant';
import { StorageEncryptService } from '@shared/services/storageEncrypt.service';

@Component({
  selector: 'app-form-personal-data',
  templateUrl: './form-personal-data.component.html',
  styleUrls: ['./form-personal-data.component.scss']
})
export class FormPersonalDataComponent {
  @Input() formPersonalData: FormGroup;
  @Input() hasOtherParticipant: boolean;
  @Input() telephoneNumberMask: string;
  @Input() visibleFields: Array<string>;
  @Input() editableFields: Array<string>;
  @Input() isSimulationInProgress: boolean;
  @Input() firstProponentCpf: string;

  @Output() clickBtnSubmit = new EventEmitter();
  @Output() submitForm = new EventEmitter();
  @Output() toggleHelper = new EventEmitter();
  @Output() blurMonthlyIncome = new EventEmitter();
  @Output() showNoAuthorizesEmailAndSmsModal = new EventEmitter();
  @Output() focusOutCpf = new EventEmitter();

  readonly PERSONAL_DATA_CONSTANT = personalDataConstant;

  isApproved: boolean = null;

  constructor(private storageEncryptService: StorageEncryptService) {}



  /**
   * Emit "onSubmit" event to [Personal Data] container
   */
  onSubmit() {
    this.submitForm.emit();
    this.storageEncryptService.setSessionStore('params', this.formPersonalData.value.telephoneNumber)
    this.formPersonalData.valid ? this.clickBtnSubmit.emit(true) : null
  }

  onBlurMonthlyIncome() {
    this.blurMonthlyIncome.emit();
  }

  onShowNoAuthorizesEmailAndSmsModal() {
    this.showNoAuthorizesEmailAndSmsModal.emit();
  }

  onFocusOutCpf() {
    if (!this.firstProponentCpf || this.firstProponentCpf === this.formPersonalData.get('documentNumber').value) {
      return;
    }
    this.focusOutCpf.emit({ documentNumber: this.formPersonalData.get('documentNumber').value, order: 1 });
  }

  /**
   * Check visible fields
   */
  isVisible(fieldName: string): boolean {
    if (!this.visibleFields) return;

    const exceptions = [];
    const field = this.formPersonalData.get(fieldName);
    const isFieldVisible = this.visibleFields.find(f => f === this.PERSONAL_DATA_CONSTANT[fieldName]);
    const isFieldEditable = this.editableFields.find(f => f === this.PERSONAL_DATA_CONSTANT[fieldName]);
    const fieldState = isFieldEditable && this.isSimulationInProgress ? 'enable' : 'disable';

    if (field && !isFieldVisible) {
      field.disable();
      return false;
    }

    if (field && !exceptions.includes(fieldName)) {
      field[fieldState]();
    }

    return true;
  }
}
