import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { TokenResponse } from '../../login/services/login.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

declare const DLECC: any;

const httpOptionsCredentials = {
  headers: new HttpHeaders({
    Authorization: 'Basic ' + environment.apiKeyExternal,
    'Content-Type': 'application/x-www-form-urlencoded'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ProposalSummaryService {
  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  getTicket(clientPublicKey): any {
    return this.http.post(
      `${environment.apiSantanderEndpoint}cryptographic-security/v1/key/public/js?gw-app-key=${environment.gwAppKey}`,
      { clientPublicKey, system: 'PAY' }
    );
  }

  public loginClientCredencials(): Observable<TokenResponse> {
    const bodyCredentials = new HttpParams()
      .set('grant_type', 'client_credentials')
      .set('scope', 'ufs_view gruposeconomicos_view');

    return this.http.post<any>(environment.tokenApiRootUrl, bodyCredentials, httpOptionsCredentials);
  }

  tokenNovaArquitetura(accesToken, tokenBanco, cpfUsuario, clientType): any {
    const params = {
      userToken: tokenBanco,
      cpf: cpfUsuario,
      grant_type: 'password',
      clientType
    };
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + accesToken,
        'Content-Type': 'application/json'
      })
    };

    return this.http.post(`${environment.apiRootUrl}/authvalidation/externalAuthCanais`, params, httpOptions);
  }

  tokenOpenFinancial(accesToken, tokenBanco, cpfUsuario, clientType, clientID): any {
    const params = {
      partnerToken: tokenBanco,
      cpf: cpfUsuario,
      grant_type: 'password',
      clientType,
      clientID
    };
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + accesToken,
        'Content-Type': 'application/json'
      })
    };

    return this.http.post(`${environment.apiRootUrl}/authvalidation/openFinancialLoginCanais`, params, httpOptions);
  }

  santanderLogin(parametrosLogin, serverPublicKey, t) {
    const enc = environment.featureToggle.useMockLogin === 'true' ? x => x : DLECC.encryptToApp;

    let urlLoginIframe = environment.authUrl + '/#/login';
    urlLoginIframe += '?name=' + enc(parametrosLogin.name, serverPublicKey);
    urlLoginIframe += '&cpf=' + enc(parametrosLogin.cpf, serverPublicKey);
    urlLoginIframe += '&phone=' + enc(parametrosLogin.phone, serverPublicKey);
    urlLoginIframe += '&urlSuccess=' + enc(parametrosLogin.urlSuccess, serverPublicKey);
    urlLoginIframe += '&urlError=' + enc(parametrosLogin.urlError, serverPublicKey);
    urlLoginIframe += '&partner=' + enc(environment.loginSantanderPartner, serverPublicKey);
    urlLoginIframe += '&ticket=' + parametrosLogin.ticket;

    return this.sanitizer.bypassSecurityTrustResourceUrl(urlLoginIframe);
  }
}
