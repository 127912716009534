import { Component, Output, EventEmitter, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() showMenuExit: boolean;

  @Output() toggleMenu = new EventEmitter();

  pathImageClient = environment.pathClientAssets;
  versao = environment.version;
  menuOpen = false;

  constructor(private router: Router) {}

  

  onToggleMenu(): void {
    this.menuOpen = !this.menuOpen;
    this.toggleMenu.emit();
  }

  onExit(): void {
    this.router.navigate(['/home']);
  }
}
