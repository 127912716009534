import { ParametersActions, ParametersActionTypes } from './parameters.actions';

export interface MenuPerfil {
  id: string | null;
  cd: string | null;
}


export interface ParametersState {
  minDateOfBirth: number | null;
  maxDateOfBirth: number | null;
  minMonthlyIncome: number | null;
  minPropertyValue: number | null;
  maxPropertyValue: number | null;
  minFinancingValue: number | null;
  maxFinancingValue: number | null;
  minFinancingMonthPeriod: number | null;
  maxFinancingMonthPeriod: number | null;
  maxTermInMonths: number | null;
  minTermInMonths: number | null;
  maxTermInYears: number | null;
  minTermInYears: number | null;
  minLoanAmountValue: number | null;
  maxLoanAmountValue: number | null;
  minDownPaymentAmount: number | null;
  maxDownPaymentAmount: number | null;
  deferredPayment: any | null;
  deferredPaymentGroup: any | null;
  isFree: boolean | null;
  allowCarencia: any | null;
  isSimulationInProgress: boolean | null;
  buildingTypes: [BuildingTypes] | any;
  isOccuped: [any] | any;
  loanObjectives: [any] | any;
  federalStates: [any] | any;
  estimatedOperatingPeriods: [any] | any;
  visibleFields: [any] | any;
  editableFields: [any] | any;
  menuPerfil: MenuPerfil[] | null;
  isAppraisalFeeFree: any | null;
  developments: Developments[] | null;
  offersList: TabelaGeral[];
  balanceValue: any | null;
  remainingPaymentTermInMonths: any | null;
}

export interface Developments {
  idProposta: string | null;
  nmFornecCanal: string | null;
  nmEmpreendimento: string | null;
  idEmpreendimento: string | null;
  documentNumber: string | null;
}

export interface BuildingTypes {
  key: string | null;
  description: string | null;
}


export interface TabelaGeral {
  id: string;
  codigo: string;
  descricao: string;
}


export const initialState: ParametersState = {
  minDateOfBirth: null,
  maxDateOfBirth: null,
  minMonthlyIncome: null,
  minPropertyValue: null,
  maxPropertyValue: null,
  minFinancingValue: null,
  maxFinancingValue: null,
  minFinancingMonthPeriod: null,
  maxFinancingMonthPeriod: null,
  maxLoanAmountValue: null,
  minLoanAmountValue: null,
  maxTermInMonths: null,
  minTermInMonths: null,
  maxTermInYears: null,
  minTermInYears: null,
  minDownPaymentAmount: null,
  maxDownPaymentAmount: null,
  deferredPayment: null,
  deferredPaymentGroup: null,
  isFree: false,
  allowCarencia: null,
  isSimulationInProgress: null,
  buildingTypes: [],
  isOccuped: [],
  loanObjectives: [],
  federalStates: [],
  estimatedOperatingPeriods: [],
  visibleFields: [],
  editableFields: [],
  menuPerfil: null,
  isAppraisalFeeFree: null,
  developments: null,
  offersList: [],
  balanceValue: null,
  remainingPaymentTermInMonths: null
};

export function parametersReducer(state = initialState, action: ParametersActions) {
  switch (action.type) {
    case ParametersActionTypes.PARTICIPANTS_LOAD_SUCCESS: {
      return {
        ...state,
        maxDateOfBirth: action.payload.maxDateOfBirth,
        minDateOfBirth: action.payload.minDateOfBirth,
        minMonthlyIncome: action.payload.minMonthlyIncome,
        visibleFields: action.payload.visibleFields,
        editableFields: action.payload.editableFields,
        isSimulationInProgress: action.payload.isSimulationInProgress
      };
    }

    case ParametersActionTypes.PROPOSAL_LOAD_SUCCESS: {
      return {
        ...state,
        buildingTypes: action.payload.buildingTypes,
        loanObjectives: action.payload.loanObjectives,
        federalStates: action.payload.federalStates,
        estimatedOperatingPeriods: action.payload.estimatedOperatingPeriods,
        isSimulationInProgress: action.payload.isSimulationInProgress,
        visibleFields: action.payload.visibleFields || [],
        editableFields: action.payload.editableFields || []
      };
    }

    case ParametersActionTypes.SIMULATION_LOAD_SUCCESS: {
      return {
        ...state,
        ...action.payload.simulationDomains,
        months: action.payload.months,
        isSimulationInProgress: action.payload.isSimulationInProgress,
        visibleFields: action.payload.visibleFields || [],
        editableFields: action.payload.editableFields || [],
        isAppraisalFeeFree: action.payload.isAppraisalFeeFree,
        balanceValue: action.payload.balanceValue,
        remainingPaymentTermInMonths: action.payload.remainingPaymentTermInMonths
      };
    }

    case ParametersActionTypes.GET_PROPERTY_RANGE_SUCCESS: {
      return {
        ...state,
        minPropertyValue: action.payload.minPropertyValue,
        maxPropertyValue: action.payload.maxPropertyValue
      };
    }

    case ParametersActionTypes.GET_PROPOSAL_RANGE_SUCCESS: {
      return {
        ...state,
        minLoanAmountValue: action.payload.proposalValuesRange.minLoanAmountValue,
        maxLoanAmountValue: action.payload.proposalValuesRange.maxLoanAmountValue,
        minTermInMonths: action.payload.proposalValuesRange.minTermInMonths,
        maxTermInMonths: action.payload.proposalValuesRange.maxTermInMonths,
        minTermInYears: action.payload.proposalValuesRange.minTermInYears,
        maxTermInYears: action.payload.proposalValuesRange.maxTermInYears,
        deferredPaymentGroup: action.payload.deferredPayment,
        isFree: action.payload.isAppraisalFeeFree,
        allowCarencia: action.payload.allowCarencia
      };
    }

    case ParametersActionTypes.SET_MIN_PROPERTY_VALUE: {
      return {
        ...state,
        minPropertyValue: action.payload
      };
    }

    case ParametersActionTypes.SET_FINANCE_LOAN_VALUE: {
      return {
        ...state,
        minLoanAmountValue: action.payload.minLoanAmountValue,
        maxLoanAmountValue: action.payload.maxLoanAmountValue
      };
    }

    case ParametersActionTypes.SET_PROPOSAL_DOMAINS: {
      return {
        ...state,
        ...action.payload.simulationDomains
      };
    }

    case ParametersActionTypes.RECOVER_MENU_PROFILE_SUCESS: {
      return {
        ...state,
        menuPerfil: action.payload
      };
    }
    case ParametersActionTypes.GET_DEVELOPMENTS_SUCESS: {
      return {
        ...state,
        developments: action.payload
      };
    }

    case ParametersActionTypes.OFFERS_LIST_SUCESS: {
      return {
        ...state,
        offersList: action.payload
      };
    }

    default:
      return state;
  }
}
