import gql from 'graphql-tag';

export const GET_SUMMARY = gql`
  query getSimulationSummary($simulationId: String!) {
    getSimulationSummary(simulationId: $simulationId) {
      proposalId
      simulationId
      hasSecondBorrower
      loanData {
        customerPortfolioName
        deferredPaymentDescription
        loanTypeKey
        loanTypeName
        isBlockedSettlementStatement
        isChangeablePayment
        isPrimeLendingRate
        termInMonths
        contractedAmortizationPeriodInMonths
        monthsDeferredPaymentPeriod
        monthsDeferredPaymentQt
        ceshRate
        primeCeshRate
        cetRate
        primeCetRate
        cemRate
        primeCemRate
        yearInterestRate
        primeYearInterestRate
        deferredPaymentKey
        paymentTypeDescription
        appraisalFeesValue
        fgtsAmount
        amountFinanced
        amountFinancedWithExpenses
        propertyValue
        downPaymentAmount
        PMTfirstPaymentAmount
        primePMTfirstPaymentAmount
        firstPaymentAmount
        primefirstPaymentAmount
        finalPaymentAmount
        primefinalPaymentAmount
        isUseCasa
        isLoanPortability
        amortizationTermInMonths
        isUseImovel
      }
      firstBorrower {
        id
        order
        name
        birthDate
        documentNumber
        email
        telephoneNumber
        monthlyIncome
        authorizesEmailAndSms
        isEmployee
        isPrimaryBorrower
        isFromPersonas
      }
      secondBorrower {
        id
        order
        name
        birthDate
        documentNumber
        email
        telephoneNumber
        monthlyIncome
        authorizesEmailAndSms
        isEmployee
        isPrimaryBorrower
        isFromPersonas
      }
    }
  }
`;

// export const BACK_SIMULATION_DATA = gql`
//   query simulationDataBack($businessKey: String!) {
//     simulationDataBack(businessKey: $businessKey) {
//       businessKey
//       currentTaskKey
//     }
//   }
// `;

// export const SUMMARY_DATA_BACK = gql`
//   query summaryDataBack($businessKey: String!) {
//     summaryDataBack(businessKey: $businessKey) {
//       businessKey
//       currentTaskKey
//     }
//   }
// `;

export const GET_PDF_SIMULATION = gql`
  query getPdfSimulation($simulationId: String!) {
    getPdfSimulation(simulationId: $simulationId) {
      pdfHash
    }
  }
`;


export const GET_WARRANTY_SHARING_SIMULATION = gql`
  query  getWarrantySharingSimulation($nslang1: String!, $nslang2: String!, $nslang3: String!) {
    getWarrantySharingSimulation(nslang1: $nslang1, nslang2: $nslang2, nslang3: $nslang3) {
      simulationId
      selectedProposalOptions {
        channel
        loanTypeKey
        buildingTypeKey
        propertyLocationKey
        portabilityTypeKey
        quizResponseCode
        typeOfferWarrantyShared
      }
      simulationEntryValues {
        propertyValue
        evaluationValue
        downPaymentAmount
        fgtsAmount
        loanAmountValue
        isPropertyNew
        termInMonths
        termInYears
        monthsDeferredPaymentQt
      }
      selectedOptions {
        termInYearsKey
        campaignKey
        marketSegmentKey
        offerKey
        paymentTypeKey
        amortizationTypeKey
        monthWithoutPaymentKey
        insurerKey
        insurancePolicyKey
        deferredPaymentKey
        typeOfferWarrantyShared
      }
      isTransferTaxInClosingCostCredit
      isAppraisalfeesInClosingCostCredit
      isIOFIncludedInClosingCostCredit
      estimatedTRRate
      usesEstimatedTRrate
      interestRatesToCalculate {
        flexibleInterestRate
	      interestRateType
      }
    }
  }
`;
