<section class="caracteristica-proposta section-gray">
  <div class="container">
    <h2>{{ 'modules.simulation.result.if you want you can customize your conditions' | translate }}:</h2>
    <div class="row">
      <div class="col-12 col-lg-6" *ngIf="loanTypeName">
        <h5 class="py-3">
          <strong class="pr-2">{{ 'common.product' | translate }}:</strong>
          <span>{{ loanTypeName }}</span>
        </h5>
      </div>
      <div class="col-12 col-lg-6 helperPosition" *ngIf="customPortfolioName">
        <h5 class="py-3">
          <strong class="pr-2">{{ 'modules.simulation.result.background operation' | translate }}:</strong>
          <span>{{ customPortfolioName }}</span>
          <app-helper-button appHelperTooltip helperName="backgroundOperation"></app-helper-button>
        </h5>
      </div>
    </div>
    <form action="" [formGroup]="formConditionResult">
      <div class="form-row row">
        <div class="form-row col-sm-12" *ngIf="isUseCasaMais">
          <div class="form-group col-sm-4">
            <!-- Iof -->
            <app-input-radio-container
              [label]="'modules.simulation.proposal-data.wish you to financing the IOF' | translate">
              <div class="form-row">
                <div class="form-group form-group-radio col">
                  <input type="radio" id="financiarDespesas2" formControlName="isIOFIncludedInClosingCostCredit"
                    [value]="true" (change)="onFieldChanged('isIOFIncludedInClosingCostCredit')" />
                  <label for="financiarDespesas2" class="label-radio">{{ 'common.yes' | translate }}</label>
                </div>
                <div class="form-group form-group-radio col">
                  <input type="radio" id="financiarDespesas4" formControlName="isIOFIncludedInClosingCostCredit"
                    [value]="false" (change)="onFieldChanged('isIOFIncludedInClosingCostCredit')" />
                  <label for="financiarDespesas4" class="label-radio">{{ 'common.no' | translate }}</label>
                </div>
              </div>
            </app-input-radio-container>
          </div>


          <div class="form-group col-sm-8 ">
            <!-- Evaluation Guaranteed -->
            <app-input-radio-container *ngIf="!isencaoTarifaUseCasa" [label]="
                    'modules.simulation.proposal-data.Would you like to finance the Guarantee Evaluation Fee?' | translate
                " helperName="isAppraisalfeesInClosingCostCredit">
              <div class="form-row">
                <div class="form-group form-group-radio col">
                  <input type="radio" id="financiarGarantiaSim" formControlName="isAppraisalfeesInClosingCostCredit"
                    [value]="true" (change)="onFieldChanged('isAppraisalfeesInClosingCostCredit')" />
                  <label for="financiarGarantiaSim" class="label-radio">{{ 'common.yes' | translate }}</label>
                </div>
                <div class="form-group form-group-radio col">
                  <input type="radio" id="financiarGarantiaNao" formControlName="isAppraisalfeesInClosingCostCredit"
                    [value]="false" (change)="onFieldChanged('isAppraisalfeesInClosingCostCredit')" />
                  <label for="financiarGarantiaNao" class="label-radio">{{ 'common.no' | translate }}</label>
                </div>
              </div>
            </app-input-radio-container>
            <div *ngIf="isencaoTarifaUseCasa">
                <label>{{ 'modules.simulation.proposal-data.wish you to financing the evaluation of guaranteed' | translate }}</label>
                <p class="p-isencao">Boa notícia, devido ao valor de empréstimo, não será cobrada a tarifa de avaliação da garantia. Aproveite esta condição!</p>
            </div>
          </div>
        </div>

        <!-- Segment -->
        <div class="form-group col-12 col-lg-4" *ngIf="simulationDomains?.marketSegments?.showThisBlock">
          <app-input-text-container [label]="'common.segment' | translate">
            <ng-select appNgSelectTab [items]="simulationDomains?.marketSegments?.options" [selectOnTab]="true"
              [clearable]="false" bindLabel="description" bindValue="key" placeholder="Selecione"
              formControlName="marketSegmentKey" id="selectSegmento" (change)="onSelectBlur('marketSegmentKey', $event)">
            </ng-select>
          </app-input-text-container>
        </div>

        <!-- Insurer -->
        <div class="form-group col-12 col-lg-4" *ngIf="simulationDomains?.insurers?.showThisBlock">
          <app-input-text-container [label]="'common.insurer' | translate" helperName="insurerKey">
            <ng-select appNgSelectTab [items]="simulationDomains?.insurers?.options" [selectOnTab]="true"
              [clearable]="false" bindLabel="description" bindValue="key" placeholder="Selecione"
              formControlName="insurerKey" id="selectTipoSeguradora" (change)="onSelectBlur('insurerKey', $event)">
            </ng-select>
          </app-input-text-container>
        </div>

        <!-- Insurance Policies -->
        <div class="form-group col-12 col-lg-4" *ngIf="simulationDomains?.insurancePolicies?.showThisBlock">
          <app-input-text-container [label]="'common.insurance policies' | translate" helperName="apoliceType">
            <ng-select appNgSelectTab [items]="simulationDomains?.insurancePolicies?.options" [selectOnTab]="true"
              [clearable]="false" bindLabel="description" bindValue="key" placeholder="Selecione"
              formControlName="insurancePolicyKey" id="selectTipoSeguradora" (change)="onSelectBlur('insurancePolicyKey', $event)">
            </ng-select>
          </app-input-text-container>
        </div>

        <!-- Offers -->
        <div class="form-group col-12 col-lg-4" *ngIf="simulationDomains?.offers?.showThisBlock">
          <app-input-text-container [label]="'common.offers' | translate" helperName="offerKey">
            <ng-select appNgSelectTab [items]="listaOfertas" [selectOnTab]="true"
              [clearable]="false" bindLabel="description" bindValue="key" placeholder="Selecione"
              formControlName="offerKey" id="selectTipoOfertas" (change)="onSelectBlur('offerKey', $event)">
            </ng-select>
          </app-input-text-container>
        </div>

        <!-- Payment type -->
        <div class="form-group col-12 col-lg-4" *ngIf="simulationDomains?.paymentType?.showThisBlock">
          <app-input-text-container [label]="'common.payment type' | translate" helperName="paymentType">
            <ng-select appNgSelectTab [items]="simulationDomains?.paymentType?.options" [selectOnTab]="true"
              [clearable]="false" bindLabel="description" bindValue="key" placeholder="Selecione"
              formControlName="paymentTypeKey" id="selectTipoParcela" (change)="onSelectBlur('paymentTypeKey', $event)">
            </ng-select>
          </app-input-text-container>
        </div>

        <!-- Campaign -->
        <div class="form-group col-12 col-lg-4" *ngIf="simulationDomains?.campaigns?.showThisBlock">
          <app-input-text-container [label]="'common.campaign' | translate" helperName="campaign">
            <ng-select appNgSelectTab [items]="simulationDomains?.campaigns?.options" [selectOnTab]="true"
              [clearable]="false" bindLabel="description" bindValue="key" placeholder="Selecione"
              formControlName="campaignKey" (change)="onSelectBlur('campaignKey', $event)">
            </ng-select>
          </app-input-text-container>
        </div>

        <!-- Modalidade da carência -->
        <div class="form-group col-12 col-lg-4" *ngIf="simulationDomains?.monthWithoutPayment?.showThisBlock">
          <app-input-text-container [label]="'common.month Without Payment' | translate">
            <ng-select appNgSelectTab [items]="simulationDomains?.monthWithoutPayment?.options" [selectOnTab]="true"
              [clearable]="false" bindLabel="description" bindValue="key" placeholder="Selecione"
              formControlName="monthWithoutPaymentKey" (change)="onSelectBlur('monthWithoutPaymentKey', $event)">
            </ng-select>
          </app-input-text-container>
        </div>

        <!-- Modalidade da carência -->
        <div class="form-group col-12 col-lg-4" *ngIf="simulationDomains?.deferredPayment?.showDeferredPaymentBlock">
          <app-input-text-container [label]="'common.deferred Payment' | translate">
            <ng-select appNgSelectTab bindLabel="description" bindValue="key" placeholder="Selecione"
              formControlName="deferredPaymentKey" [clearable]="false" [selectOnTab]="true"
              [items]="simulationDomains?.deferredPayment?.deferredPaymentOptions" (change)="setParcCarenciaNull($event)">
            </ng-select>
          </app-input-text-container>
        </div>

        <!-- Prazo Carência -->
        <div class="form-group col-12 col-lg-4" *ngIf="isDeferredPaymentValue()">
          <app-input-text-container [label]="'common.deferred period' | translate">
            <ng-select appNgSelectTab [items]="arrCarencia" placeholder="Selecione"
              formControlName="monthsDeferredPaymentQt" [clearable]="false" [selectOnTab]="true" (change)="onSelectBlur('monthsDeferredPaymentQt', $event)">
            </ng-select>
          </app-input-text-container>
        </div>
      </div>
    </form>
  </div>
</section>
