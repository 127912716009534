import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';

import { reducerMap } from './store/loading.reducers';
import { LoadingInterceptor } from './loading.interceptor';
import { SpinnerComponent } from './ui/spinner/spinner.component';
import { initialLoadingState } from './store/loading.state';
import { LoadingIndicatorContainerComponent } from './loading-indicator/loading-indicator.container';

@NgModule({
  imports: [
    StoreModule.forFeature('loading', reducerMap, {
      initialState: initialLoadingState
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true
    }
  ],
  declarations: [SpinnerComponent, LoadingIndicatorContainerComponent],
  exports: [SpinnerComponent, LoadingIndicatorContainerComponent]
})
export class LoadingModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: LoadingModule
  ) {
    // if (parentModule) {
    //   throw new Error('LoadingModule is already loaded. Import it in the AppModule only');
    // }
  }

  static forRoot(): ModuleWithProviders<LoadingModule> {
    return {
      ngModule: LoadingModule,
      providers: []
    };
  }
}
