export const environment = {
  production: true,
  pathClientAssets: 'santander',
  cont: '${CONT_SIM_CLIENTE}',
  version: '2.25.0-SNAPSHOT (16/07/2024 10:04:03)',
  recaptcha: '${RECAPTCHA_SIMULACAO_CLIENTE}',

  tokenRevoke: '/${APP_PATH_API}/${APP_PATH_REVOKE}',
  apiRootUrl: '/${APP_PATH_API}',
  tokenApiRootUrl: '/${APP_PATH_API}/${APP_PATH_TOKEN}',
  apiKeyApiSecret: '${SIMULACAO_APP_KEY}',
  urlTermCondicoes: '${URL_TERM_CONDICOES}',
  apiKeyExternal: '${SERVICOS_EXTERNOS_APP_KEY}',
  baseUrl: '${APP_URL_BASE_NEGOCIO}',

  /** IFRAME SANTANDER */
  gwAppKey: 'c1238870f89101341bb6005056906329',
  loginSantanderPartner: 'imobiliario',
  authUrl: '${URL_SANTANDER_AUTH}',
  apiSantanderEndpoint: '${URL_SANTANDER_API_ENDPOINT}',

  urlCoreAntigo: '${URL_CORE_ANT}',
  urlCoreAntigoMenu: '/login/iframePrincipal.html?funcao=index',
  urlPortalParceiro: '${URL_ORIG_PARCEIRO_ANT}',
  urlPortalCliente: '${URL_ORIG_CLIENTE_ANT}',
  urlPortalGerente: '${URL_ORIG_GERENTE_ANT}',
  urlMortgage: '${URL_MORT}',
  urlOrigClienteDef: '${URL_ORIG_CLIENTE_DEF}',
  urlChatBot: '${URL_CHATBOT}',
  urlNovaSimulacaoCliente: '${URL_NOVA_SIMULACAO_CLIENTE}',

  skin: '${SKIN}',
  validaCarimbo: '${VALIDA_CARIMBO_PC}',

  apiRootBase: '${APP_URL_BASE_NEGOCIO}',
  namespace: '${ELASTIC_APM_ENVIRONMENT}',
  clientID: '${CLIENT_ID_PORTAL_CLIENTE}',
  client_id_simulacao: '${CLIENT_ID_SIMULACAO}',
  clientSecretSimulacao: '${CLIENT_SECRET_SIMULACAO}',
  /** FEATURE TOGGLE */
  featureToggle: {
    newRedirect: '${ENABLE_NEW_REDIRECT}',
    newConsultProposal: '${ENABLE_NEW_CONSULT_PROPOSAL}',
    ENABLE_USECASAMAIS_PG: '${ENABLE_USECASAMAIS_PG}',
    ENABLE_USECASAMAIS_PC: '${ENABLE_USECASAMAIS_PC}',
    cryptoStorageActive: '${ENCRYPT_STORAGE_ACTIVE}',
    recaptchaOn: '${RECAPTCHA_SIMULACAO_CLIENTE_ON}',
    objetivoDefault: false,
    fgUtmSource: true,
    fgOferta: "${ENABLE_BONIFICADO}",
    fgSdk: true,
    useMockLogin: '${MOCK_LOGIN_SIMULACAO_CLIENTE}',
    disablePortal: '${DISABLE_PORTAL_SIM_CLI}',
    fgRepasse: { active: true },
    isencaoTarifaAvaliacao: '${ISENCAO_TARIFA_AVALICAO}',
    asymmetricKey: '${ASYMMETRIC_KEY}',
    crypto: {
      active: '${ENCRYPT_ENABLE_SIM_CLI}',
      key: '${ENCRYPT_KEY}',
      iv: '${ENCRYPT_IV}'
    }
  }
};

if (environment.featureToggle.useMockLogin === 'true') {
  environment.apiSantanderEndpoint = '${URL_MOCK_LOGIN_CLIENTE}/';
  environment.authUrl = '${URL_MOCK_LOGIN_CLIENTE}';
}
