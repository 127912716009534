import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DataLayerSantanderService } from '@projects/cliente/src/app/shared/service/dataLayer/novo-data-layer.service';
import { environment } from '@projects/cliente/src/environments/environment';
import { StorageEncryptService } from '@shared/services/storageEncrypt.service';

@Component({
  selector: 'app-simulation-summary',
  templateUrl: './simulation-summary.component.html',
  styleUrls: ['./simulation-summary.component.scss']
})
export class SimulationSummaryComponent implements OnInit {
  @Input() formSimulationResult: FormGroup;
  @Input() formConditionResult: FormGroup;
  @Input() valuesWithRelationship: any;
  @Input() valuesNoRelationship: any;
  @Input() offerChosen: string;
  @Input() hasCarencia: boolean;
  @Input() isUseCasaMais: boolean;

  @Output() generatePDF = new EventEmitter();
  @Output() save = new EventEmitter();
  @Output() back = new EventEmitter();
  @Output() extractPdfEmailSimulation = new EventEmitter();

  fgOferta = environment.featureToggle.fgOferta === 'true' ? true : false;

  constructor(private dataLayerSantander: DataLayerSantanderService, private storageEncryptService: StorageEncryptService,
  ) { }

  ngOnInit() {
  }

  /**
   * Emit "generatePDF" event to [Simulation Result] container
   */
  onGeneratePDF(): void {
    this.generatePDF.emit();
  }

  /**
   * Emit "save" event to [Simulation Result] container
   */
  onSave() {
    let productName = this.storageEncryptService.getSessionStore('productName');
    this.sendToClickEvent(false, 'clicou', 'botao:continue-para-analisar-credito:inferior', productName);
    this.save.emit();
  }

  /**
   * Emit "back" event to [Simulation Result] container
   */
  onBack() {
    let productName = this.storageEncryptService.getSessionStore('productName');
    this.sendToClickEvent(false, 'clicou', 'botao:voltar', productName);
    this.back.emit();
  }

  extractSimulation(type) {
    this.extractPdfEmailSimulation.emit(type);
  }

  sendToClickEvent(nonInterection, eventAction, eventLabel, productName) {
    this.dataLayerSantander.sendCustomToDataLayer(
      nonInterection,
      eventAction,
      eventLabel,
      productName
    );
  }
}
