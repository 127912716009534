import gql from 'graphql-tag';

export const SAVE_PERSONAL = gql`
  mutation savePersonalDataSimulation($input: InputSavePersonalData!) {
    savePersonalDataSimulation(input: $input) {
      isSuccessful
      simulationId
    }
  }
`;

export const SAVE_PERSONAL_PJ = gql`
 mutation savePersonalDataSimulationPJ($input: InputSavePersonalDataPJ!) {
  savePersonalDataSimulationPJ(input: $input) {
    isSuccessful
    simulationId
  }
}
`;

export const UPDATE_PERSONAS_STATUS = gql`
  mutation updatePersonasStatus($simulationId: ID!) {
    updatePersonasStatus(simulationId: $simulationId) {
      documentNumber
      isPrimaryBorrower
      name
      isFromPersonas
      isRegistrationCompleted
      isRegistrationInconsistent
    }
  }
`;
export const MUTATE_GENERATE_TOKEN_SMS = gql`
mutation generateTokenSmsSimulation($input: InputGenerateTokenSmsSimulation){
  generateTokenSmsSimulation(input: $input)
}
`;

export const MUTATE_VALIDATE_TOKEN_SMS = gql`
mutation validateTokenSmsSimulation($input: InputValidateTokenSmsSimulation){
  validateTokenSmsSimulation(input: $input)
}
`;
